import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  doubleCheckContainer: {
    display: 'flex',
    marginTop: 25 + 'px',
  },

  doubleCheckText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  doubleCheckTextWithLabels: {
    marginBottom: 'auto',
    marginTop: 33 + 'px',
  },

  error: {
    color: 'red',
  },
});

export default function DoubleCheck(props) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    selection: null,
    siChecked: false,
    noChecked: false,
  });

  const onSelect = (_type) => {
    if (_type === 'si') {
      setValues({ ...values, siChecked: true, noChecked: false, selection: true });
    }

    if (_type === 'no') {
      setValues({ ...values, siChecked: false, noChecked: true, selection: false });
    }

    // Si hay un callback 'onChange' y una prop 'name', devuelvo al parent
    if (props.onChange && props.name) {
      let selection = _type === 'si' ? true : false;
      props.onChange({ name: props.name, selection: selection });
    }
  };

  let comp = null;
  if (props.withLabels) {
    comp = (
      <div className={classes.doubleCheckContainer}>
        <FormControlLabel
          style={{ marginLeft: 0 + 'px' }}
          value="si"
          control={<Checkbox color="primary" />}
          label={props.withLabels ? 'Si' : null}
          labelPlacement={props.withLabels ? 'top' : null}
          checked={values.siChecked}
          onChange={() => {
            onSelect('si');
          }}
        />

        <FormControlLabel
          style={{ marginLeft: 0 + 'px' }}
          value="no"
          control={<Checkbox color="primary" />}
          label={props.withLabels ? 'No' : null}
          labelPlacement={props.withLabels ? 'top' : null}
          checked={values.noChecked}
          onChange={() => {
            onSelect('no');
          }}
        />

        <p className={classes.doubleCheckTextWithLabels + ' ' + (props.error ? classes.error : '')}>
          {props.text ? props.text : null}
        </p>
      </div>
    );
  } else {
    comp = (
      <div className={classes.doubleCheckContainer}>
        <FormControlLabel
          style={{ marginLeft: 0 + 'px' }}
          value="si"
          control={<Checkbox color="primary" />}
          checked={values.siChecked}
          onChange={() => {
            onSelect('si');
          }}
        />

        <FormControlLabel
          style={{ marginLeft: 0 + 'px' }}
          value="no"
          control={<Checkbox color="primary" />}
          checked={values.noChecked}
          onChange={() => {
            onSelect('no');
          }}
        />

        <p className={classes.doubleCheckText + ' ' + (props.error ? classes.error : '')}>
          {props.text ? props.text : null}
        </p>
      </div>
    );
  }

  return comp;
}
