import React, { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';
import './App.css';
import { URLpreffix } from './config';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Components
import HomeTests from './components/Tests';

import Welcome from './components/Login/Welcome';
import Welcome2 from './components/Login/Welcome2';
import LoginEmpleados from './components/Login/LoginEmpleados';
import LoginCapataz from './components/Login/LoginCapataz';
import InstructivoInstalacion from './components/Login/InstructivoInstalacion';

import HomeEmpleadosFull from './components/Empleado/HomeEmpleadosFull';
import HomeCapataz from './components/Capataz/HomeCapataz';

import AltaTrabajadorP1 from './components/AltaTrabajador/AltaTrabajadorP1';
import AltaTrabajadorP2 from './components/AltaTrabajador/AltaTrabajadorP2';
import AltaTrabajadorP3 from './components/AltaTrabajador/AltaTrabajadorP3';
import AltaTrabajadorP4 from './components/AltaTrabajador/AltaTrabajadorP4';

import IngresoTrabajadorP1 from './components/IngresosEgresos/IngresoTrabajadorP1';
import IngresoTrabajadorP2 from './components/IngresosEgresos/IngresoTrabajadorP2';

import ControlTrabajadorP1 from './components/ControlTrabajador/ControlTrabajadorP1';
import ControlTrabajadorP2 from './components/ControlTrabajador/ControlTrabajadorP2';
import ControlTrabajadorP3 from './components/ControlTrabajador/ControlTrabajadorP3';

import ControlTrabajadorUEP1 from './components/Capataz/ControlTrabajadorUE/ControlTrabajadorUEP1';
import ControlTrabajadorUEDNI from './components/Capataz/ControlTrabajadorUE/ControlTrabajadorUEDNI';
import ControlTrabajadorUEP2 from './components/Capataz/ControlTrabajadorUE/ControlTrabajadorUEP2';
import ControlTrabajadorUEP3 from './components/Capataz/ControlTrabajadorUE/ControlTrabajadorUEP3';

import ListadoTrabajadoresP1 from './components/ListadoTrabajadores/ListadoTrabajadoresP1';
import ListadoTrabajadoresP2 from './components/ListadoTrabajadores/ListadoTrabajadoresP2';
import ListadoTrabajadoresP3 from './components/ListadoTrabajadores/ListadoTrabajadoresP3';

import ControlLoteP1 from './components/ControlLotes/ControlLoteP1';
import ControlLoteP2 from './components/ControlLotes/ControlLoteP2';
import ControlLoteP3 from './components/ControlLotes/ControlLoteP3';

import ControlEntregaFrutaP1 from './components/ControlEntregaFruta/ControlEntregaFrutaP1';
import ControlEntregaFrutaP2 from './components/ControlEntregaFruta/ControlEntregaFrutaP2';
import ControlEntregaFrutaP3 from './components/ControlEntregaFruta/ControlEntregaFrutaP3';

import ReporteIncidenciaP1 from './components/Incidencias/ReporteIncidenciaP1';
import ReporteIncidenciaP2 from './components/Incidencias/ReporteIncidenciaP2';
import ReporteIncidenciaP3 from './components/Incidencias/ReporteIncidenciaP3';

import IncidenciasV2P1 from './components/IncidenciasV2/IncidenciasV2P1';
import IncidenciasV2P2 from './components/IncidenciasV2/IncidenciasV2P2';
import IncidenciasV2P3 from './components/IncidenciasV2/IncidenciasV2P3';

import ControlDocumentacionP1 from './components/Capataz/ControlDocumentacion/ControlDocumentacionP1';
import ControlDocumentacionP2 from './components/Capataz/ControlDocumentacion/ControlDocumentacionP2';
import ControlDocumentacionP3 from './components/Capataz/ControlDocumentacion/ControlDocumentacionP3';

import IngresoEgreso from './components/IngresosEgresos/IngresoEgreso';
import Ayuda from './components/Ayuda';

import FormularioValidacionV2 from './components/SegundaParteV2/FormularioValidacion';
import FormularioValidacionV2YerbaMate from './components/SegundaParteV2/FormularioValidacionYerbaMate';

import { logOut, cleanUserDataLS } from './requests/users';
import { isLoggedIn, getUserType } from './helpers/helpers';

/////////////////////////////////////////////////////////
// Al realizar logOut se debe elimiar la data local    //
// del usuario                                         //
const appLogOut = () => {
  cleanUserDataLS();

  if (navigator.onLine && getUserType === 'empresa') {
    // Si tengo conexion a internet, ademas de eliminar el localStorage, hago logout en directus
    logOut().then(() => {});

    return window.location.replace(URLpreffix + '/');
  } else {
    return window.location.replace(URLpreffix + '/');
  }
};

function App() {
  useEffect(() => {
    window.addEventListener('focus', () => {
      console.log(
        'Should logout? ',
        localStorage.getItem('app') !== 'arandanos' && localStorage.getItem('app') !== null,
      );
      if (localStorage.getItem('app') !== 'arandanos' && localStorage.getItem('app') !== null) {
        localStorage.setItem('app', 'arandanos');
        appLogOut();
      }
    });
  }, []);

  // If user is logged in and 'app' is not 'yerba_mate' logout
  // Note: null LS 'app' means it is an Arandanos session as
  // this prop was introduced with Yerba Mate's app
  if (localStorage.getItem('app') !== 'arandanos' && localStorage.getItem('app') !== null) {
    localStorage.setItem('app', 'arandanos');
    return (
      <Switch>
        <Route component={appLogOut} />
      </Switch>
    );
  }

  //////////////////////////////////////////////////////////
  // ROUTER PARA USUARIOS NO LOGGEADOS                    //
  if (!isLoggedIn()) {
    return (
      <Switch>
        <Route exact path={URLpreffix + '/tests'} component={HomeTests} />

        <Route exact path={URLpreffix + '/'} component={Welcome} />
        <Route exact path={URLpreffix + '/welcome2'} component={Welcome2} />
        <Route exact path={URLpreffix + '/loginCapataz'} component={LoginCapataz} />
        <Route exact path={URLpreffix + '/loginEmpleados'} component={LoginEmpleados} />
        <Route exact path={URLpreffix + '/comoInstalar'} component={InstructivoInstalacion} />
        <Route exact path={URLpreffix + '/Ayuda'} component={Ayuda} />

        <Route component={Welcome} />
      </Switch>
    );
  }
  // END ROUTER PARA USUARIOS NO LOGGEADOS               //
  /////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  // ROUTER PARA USUARIOS EXTERNOS                        //
  if (getUserType() === 'capataz') {
    return (
      <Switch>
        <Route exact path={URLpreffix + '/tests'} component={HomeTests} />
        <Route exact path={URLpreffix + '/homeCapataz'} component={HomeCapataz} />

        <Route
          exact
          path={URLpreffix + '/ControlDocumentacionP1'}
          component={ControlDocumentacionP1}
        />
        <Route
          exact
          path={URLpreffix + '/ControlDocumentacionP2'}
          component={ControlDocumentacionP2}
        />
        <Route
          exact
          path={URLpreffix + '/ControlDocumentacionP3'}
          component={ControlDocumentacionP3}
        />

        <Route
          exact
          path={URLpreffix + '/ControlTrabajadorUEP1'}
          component={ControlTrabajadorUEP1}
        />
        <Route
          exact
          path={URLpreffix + '/ControlTrabajadorUEDNI'}
          component={ControlTrabajadorUEDNI}
        />
        <Route
          exact
          path={URLpreffix + '/ControlTrabajadorUEP2'}
          component={ControlTrabajadorUEP2}
        />
        <Route
          exact
          path={URLpreffix + '/ControlTrabajadorUEP3'}
          component={ControlTrabajadorUEP3}
        />

        <Route exact path={URLpreffix + '/comoInstalar'} component={InstructivoInstalacion} />
        <Route exact path={URLpreffix + '/Ayuda'} component={Ayuda} />
        <Route exact path={URLpreffix + '/logout'} component={appLogOut} />

        <Route exact path={URLpreffix + '/ReporteIncidenciaP1'} component={ReporteIncidenciaP1} />
        <Route exact path={URLpreffix + '/ReporteIncidenciaP2'} component={ReporteIncidenciaP2} />
        <Route exact path={URLpreffix + '/ReporteIncidenciaP3'} component={ReporteIncidenciaP3} />

        <Route exact path={URLpreffix + '/SituacionRiesgoP1'} component={IncidenciasV2P1} />
        <Route exact path={URLpreffix + '/SituacionRiesgoP2'} component={IncidenciasV2P2} />
        <Route exact path={URLpreffix + '/SituacionRiesgoP3'} component={IncidenciasV2P3} />

        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP1'}
          component={ControlEntregaFrutaP1}
        />
        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP2'}
          component={ControlEntregaFrutaP2}
        />
        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP3'}
          component={ControlEntregaFrutaP3}
        />

        <Route component={HomeCapataz} />
      </Switch>
    );
  }
  // END ROUTER PARA USUARIOS EXTERNOS                   //
  /////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  // ROUTER PARA USUARIOS EMPRESA                         //
  if (getUserType() === 'empresa') {
    return (
      <Switch>
        <Route exact path={URLpreffix + '/tests'} component={HomeTests} />
        <Route exact path={URLpreffix + '/homeEmpleados'} component={HomeEmpleadosFull} />

        <Route
          exact
          path={URLpreffix + '/FormularioValidacion'}
          component={FormularioValidacionV2}
        />
        <Route
          exact
          path={URLpreffix + '/FormularioValidacionYerbaMate'}
          component={FormularioValidacionV2YerbaMate}
        />
        <Route
          exact
          path={URLpreffix + '/dev/FormularioValidacionV2'}
          component={FormularioValidacionV2}
        />

        <Route exact path={URLpreffix + '/comoInstalar'} component={InstructivoInstalacion} />
        <Route exact path={URLpreffix + '/Ayuda'} component={Ayuda} />
        <Route exact path={URLpreffix + '/logout'} component={appLogOut} />

        <Route exact path={URLpreffix + '/ingresoEgreso'} component={IngresoEgreso} />

        <Route exact path={URLpreffix + '/AltaTrabajadorP1'} component={AltaTrabajadorP1} />
        <Route exact path={URLpreffix + '/AltaTrabajadorP2'} component={AltaTrabajadorP2} />
        <Route exact path={URLpreffix + '/AltaTrabajadorP3'} component={AltaTrabajadorP3} />
        <Route exact path={URLpreffix + '/AltaTrabajadorP4'} component={AltaTrabajadorP4} />
        <Route exact path={URLpreffix + '/IngresoTrabajadorP1'} component={IngresoTrabajadorP1} />
        <Route exact path={URLpreffix + '/IngresoTrabajadorP2'} component={IngresoTrabajadorP2} />

        <Route exact path={URLpreffix + '/ControlTrabajadorP1'} component={ControlTrabajadorP1} />
        <Route exact path={URLpreffix + '/ControlTrabajadorP2'} component={ControlTrabajadorP2} />
        <Route exact path={URLpreffix + '/ControlTrabajadorP3'} component={ControlTrabajadorP3} />

        <Route
          exact
          path={URLpreffix + '/ListadoTrabajadoresP1'}
          component={ListadoTrabajadoresP1}
        />
        <Route
          exact
          path={URLpreffix + '/ListadoTrabajadoresP2'}
          component={ListadoTrabajadoresP2}
        />
        <Route
          exact
          path={URLpreffix + '/ListadoTrabajadoresP3'}
          component={ListadoTrabajadoresP3}
        />

        <Route exact path={URLpreffix + '/ControlLoteP1'} component={ControlLoteP1} />
        <Route exact path={URLpreffix + '/ControlLoteP2'} component={ControlLoteP2} />
        <Route exact path={URLpreffix + '/ControlLoteP3'} component={ControlLoteP3} />

        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP1'}
          component={ControlEntregaFrutaP1}
        />
        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP2'}
          component={ControlEntregaFrutaP2}
        />
        <Route
          exact
          path={URLpreffix + '/ControlEntregaFrutaP3'}
          component={ControlEntregaFrutaP3}
        />

        <Route exact path={URLpreffix + '/ReporteIncidenciaP1'} component={ReporteIncidenciaP1} />
        <Route exact path={URLpreffix + '/ReporteIncidenciaP2'} component={ReporteIncidenciaP2} />
        <Route exact path={URLpreffix + '/ReporteIncidenciaP3'} component={ReporteIncidenciaP3} />

        <Route exact path={URLpreffix + '/SituacionRiesgoP1'} component={IncidenciasV2P1} />
        <Route exact path={URLpreffix + '/SituacionRiesgoP2'} component={IncidenciasV2P2} />
        <Route exact path={URLpreffix + '/SituacionRiesgoP3'} component={IncidenciasV2P3} />

        <Route component={HomeEmpleadosFull} />
      </Switch>
    );
  }
  // END ROUTER PARA USUARIOS EMPRESA                     //
  //////////////////////////////////////////////////////////

  return null;
}

export default App;
