import React from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import CardAvatar from '../../Misc/CardAvatar';

export default function CardTADetalle(props) {
  return (
    <Card className="mb1">
      <CardHeader
        avatar={<CardAvatar nombre={props.nombre} />}
        title={props.nombre + ' ' + props.apellido}
        subheader={'DNI ' + props.dni}
      />

      <CardContent>
        <Typography variant="body2" component="p">
          <strong>DATOS DEL TRABAJADOR</strong>
          <br />
          <br />
          <strong>Nombre:</strong> {props.nombre}
          <br />
          <strong>Apellido:</strong> {props.apellido}
          <br />
          <strong>DNI:</strong> {props.dni}
          <br />
          <strong>Fecha de nacimiento:</strong> {props.fecha_nacimiento}
          <br />
          <strong>Género:</strong> {props.genero}
          <br />
          <strong>Contratista:</strong>{' '}
          {props.contratista !== null ? props.contratista.nombre : null}
          <br />
          <strong>Persona de la empresa responsable:</strong> {props.responsable_empresa_a_cargo}
          <br />
          <strong>Asiste actualmente a la escuela:</strong> {props.asiste_escuela ? 'SI' : 'NO'}
          <br />
          <strong>Asiste a algun programa de formación:</strong>{' '}
          {props.asiste_formacion ? 'SI' : 'NO'}
          <br />
          <strong>Vino a trabajar con un familiar, amigo/a o conocido/a:</strong>{' '}
          {props.asiste_con_familia ? 'SI' : 'NO'}
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
}
