import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import DoubleCheck from '../Misc/DoubleCheck';
import Loader from '../Misc/Loader';

import { addRecord, updateDataBases } from '../../indexedDB/operations';
import { URLpreffix } from '../../config';
import { getIDInCorrectType, getCurrentDateTime } from '../../helpers/helpers';

class ReporteInicidenciaP1 extends React.Component {
  constructor(props) {
    super(props);

    let usuario_tipo = localStorage.getItem('usuario_tipo');

    let registrado_usuario_id = null;
    let dni_usuario_externo = null;
    let contratista_id = null;
    let empresa_id = null;

    if (usuario_tipo === 'capataz') {
      registrado_usuario_id = null;
      empresa_id = null;
      dni_usuario_externo = localStorage.getItem('usuario_dni');
      contratista_id = localStorage.getItem('usuario_contratista_id');
    }
    if (usuario_tipo === 'empresa') {
      registrado_usuario_id = parseInt(localStorage.getItem('usuario_id'));
      empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));
      dni_usuario_externo = null;
      contratista_id = null;
    }

    let queryParams = qs.parse(props.location.search);

    let monitoreo_lote_asociado = null;
    let lote_id = null;

    if (queryParams.lote_id) {
      monitoreo_lote_asociado = true;
      lote_id = getIDInCorrectType(queryParams.lote_id);
    }

    this.state = {
      showLoader: false,

      tipo_incidencia: '',
      fecha_horario: getCurrentDateTime(),

      registrado_usuario_id: registrado_usuario_id,
      dni_usuario_externo: dni_usuario_externo,

      alta_ta_asociado: null,
      usuario_alta_ta: null,

      monitoreo_lote_asociado: monitoreo_lote_asociado,
      lote_id: lote_id,

      nombre_sujeto: null,
      genero_sujeto: '',
      lugar_nacimineto_sujeto: null,
      edad_sujeto: null,
      numero_documento_sujeto: null,
      acompaniado_por_adulto: '',
      observaciones: null,

      responsable_empresa: null,

      empresa_id: empresa_id,
      contratista_id: contratista_id,

      tieneDNI: null,

      genero_sujetoError: false,
      acompaniado_por_adultoError: false,
      observacionesError: false,
      tieneDNIError: false,
      tipo_incidenciaError: false,

      showRegistrarIncidenciaMessage: false,
    };
  }

  componentDidMount() {}

  resetForm = () => {
    this.setState({
      nombre_sujeto: null,
      genero_sujeto: '',
      lugar_nacimineto_sujeto: null,
      edad_sujeto: null,
      numero_documento_sujeto: null,
      acompaniado_por_adulto: '',
      observaciones: null,

      responsable_empresa: null,

      tieneDNI: null,

      genero_sujetoError: false,
      acompaniado_por_adultoError: false,
      observacionesError: false,
      tieneDNIError: false,
      tipo_incidenciaError: false,

      showRegistrarIncidenciaMessage: false,
    });
  };

  handleChange = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value,
      [prop + 'Error']: false,
      showRegistrarIncidenciaMessage: false,
    });
    if (prop === 'tipo_incidencia') this.resetForm();
  };

  handleDoubleCheck = (sel) => {
    this.setState({
      [sel.name]: sel.selection,
      [sel.name + 'Error']: false,
      showRegistrarIncidenciaMessage: false,
    });
  };

  onClickRegistrarIncidencia = () => {
    // Validaciones
    let errs = {};
    let validationSuccess = true;

    if (
      this.state.tipo_incidencia ===
        'Presencia de niños/as o adolescentes no registrados/as en el campo' ||
      this.state.tipo_incidencia === 'Presencia de niños/as acompañando un adulto en el campo'
    ) {
      if (this.state.genero_sujeto === '') {
        errs.genero_sujetoError = true;
        validationSuccess = false;
      }

      if (this.state.acompaniado_por_adulto === '') {
        errs.acompaniado_por_adultoError = true;
        validationSuccess = false;
      }

      if (this.state.tieneDNI === null) {
        errs.tieneDNIError = true;
        validationSuccess = false;
      }

      if (this.state.tipo_incidencia === '') {
        errs.tipo_incidenciaError = true;
        validationSuccess = false;
      }
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showRegistrarIncidenciaMessage: true });
      return;
    }

    this.setState({ showLoader: true });

    let incidencia = {
      id: 'tmp-incidencia-' + Date.now(),
      tipo_incidencia: this.state.tipo_incidencia,
      fecha_horario: this.state.fecha_horario,
      dni_usuario_externo: this.state.dni_usuario_externo,
      registrado_usuario_id: this.state.registrado_usuario_id,
      alta_ta_asociado: this.state.alta_ta_asociado,
      usuario_alta_ta: this.state.usuario_alta_ta,
      monitoreo_lote_asociado: this.state.monitoreo_lote_asociado,
      lote_id: this.state.lote_id,

      contratista_id: this.state.contratista_id,
      empresa_id: this.state.empresa_id,

      nombre_sujeto: this.state.nombre_sujeto,
      genero_sujeto: this.state.genero_sujeto === '' ? null : this.state.genero_sujeto,
      lugar_nacimineto_sujeto: this.state.lugar_nacimineto_sujeto,
      edad_sujeto: this.state.edad_sujeto,
      numero_documento_sujeto: this.state.numero_documento_sujeto,
      acompaniado_por_adulto:
        this.state.acompaniado_por_adulto === '' ? null : this.state.acompaniado_por_adulto,
      observaciones: this.state.observaciones,
      responsable_empresa: this.state.responsable_empresa,
    };

    addRecord('incidencias', incidencia)
      .then(() => {
        // Tipo 1:
        // Presencia de niños/as o adolescentes no registrados/as en el campo
        // Presencia de niños/as acompañando un adulto en el campo

        // Tipo 2:
        // Niño/a o adolescente no registrado que quiere ingresar al campo
        // Niño/a o adolescente no registrado que quiere subir al transporte

        let tipo = '2';
        if (
          this.state.tipo_incidencia ===
          'Presencia de niños/as o adolescentes no registrados/as en el campo'
        )
          tipo = '1';
        if (
          this.state.tipo_incidencia === 'Presencia de niños/as acompañando un adulto en el campo'
        )
          tipo = '1';

        // Si es Tipo 2, no hay una instancia de actualizacion de la incidencia -> updateDataBases antes de avanzar!
        if (tipo === '2') {
          updateDataBases().then(() => {
            this.props.history.push(
              URLpreffix + '/ReporteIncidenciaP2?id=' + incidencia.id + '&tipo=' + tipo,
            );
          });
        } else {
          this.props.history.push(
            URLpreffix + '/ReporteIncidenciaP2?id=' + incidencia.id + '&tipo=' + tipo,
          );
        }
      })
      .catch((err) => console.error(err));
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h5">Reporte de incidencia:</Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel
                  error={this.state.tipo_incidenciaError}
                  htmlFor="outlined-age-native-simple"
                >
                  Tipo de incidente
                </InputLabel>
                <Select
                  native
                  label="Tipo de incidente"
                  error={this.state.tipo_incidenciaError}
                  inputProps={{
                    name: 'Tipo de incidente',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={this.handleChange('tipo_incidencia')}
                >
                  <option aria-label="None" value="" />
                  <option
                    aria-label="None"
                    value="Presencia de niños/as o adolescentes no registrados/as en el campo"
                  >
                    Presencia de niños/as o adolescentes no registrados/as en el campo
                  </option>
                  <option
                    aria-label="None"
                    value="Niño/a o adolescente no registrado que quiere ingresar al campo"
                  >
                    Niño/a o adolescente no registrado que quiere ingresar al campo
                  </option>
                  <option
                    aria-label="None"
                    value="Niño/a o adolescente no registrado que quiere subir al transporte"
                  >
                    Niño/a o adolescente no registrado que quiere subir al transporte
                  </option>
                  <option
                    aria-label="None"
                    value="Presencia de niños/as acompañando un adulto en el campo"
                  >
                    Presencia de niños/as acompañando un adulto en el campo
                  </option>
                </Select>
              </FormControl>

              {this.state.tipo_incidencia ===
                'Presencia de niños/as o adolescentes no registrados/as en el campo' ||
              this.state.tipo_incidencia ===
                'Presencia de niños/as acompañando un adulto en el campo' ||
              true ? (
                <React.Fragment>
                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic1"
                      onChange={this.handleChange('nombre_sujeto')}
                      label="Preguntar: ¿Cómo te llamas?"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <InputLabel
                      error={this.state.genero_sujetoError}
                      htmlFor="outlined-age-native-simple"
                    >
                      Género
                    </InputLabel>
                    <Select
                      native
                      label="Género"
                      error={this.state.genero_sujetoError}
                      inputProps={{
                        name: 'Género',
                        id: 'outlined-age-native-simple',
                      }}
                      onChange={this.handleChange('genero_sujeto')}
                    >
                      <option aria-label="None" value="" />
                      <option value={'otro'}>Otro</option>
                      <option value={'hombre'}>Masculino</option>
                      <option value={'mujer'}>Femenino</option>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic1"
                      onChange={this.handleChange('lugar_nacimineto_sujeto')}
                      label="Preguntar: ¿De que localidad sos?"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic1"
                      onChange={this.handleChange('edad_sujeto')}
                      label="Preguntar: ¿Cuántos años tenés?"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <DoubleCheck
                      error={this.state.tieneDNIError}
                      withLabels
                      onChange={this.handleDoubleCheck}
                      name="tieneDNI"
                      text="Preguntar: ¿Tenés documento (DNI)?"
                    />
                  </FormControl>

                  {this.state.tieneDNI === true ? (
                    <FormControl fullWidth variant="outlined" className="mb1">
                      <TextField
                        id="outlined-basic-dni"
                        label="Preguntar: ¿Sabes el número?"
                        variant="outlined"
                        onChange={this.handleChange('numero_documento_sujeto')}
                        value={null}
                        error={false}
                        helperText={null}
                      />
                    </FormControl>
                  ) : null}

                  <Typography variant="p" className="labelCheckbox mb1 mt1">
                    Observaciones
                  </Typography>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <DoubleCheck
                      error={this.state.acompaniado_por_adultoError}
                      withLabels
                      onChange={this.handleDoubleCheck}
                      name="acompaniado_por_adulto"
                      text="¿El o la niña/o estaba acompañado por alguna persona adulta?"
                    />
                  </FormControl>

                  <Typography variant="p" className="">
                    Información adicional:
                  </Typography>
                  <TextField
                    id="observacionesInput"
                    label=""
                    multiline
                    fullWidth
                    rows={4}
                    value={null}
                    variant="outlined"
                    onChange={this.handleChange('observaciones')}
                  />
                </React.Fragment>
              ) : null}

              {(this.state.tipo_incidencia ===
                'Niño/a o adolescente no registrado que quiere ingresar al campo' ||
                this.state.tipo_incidencia ===
                  'Niño/a o adolescente no registrado que quiere subir al transporte') &&
              false ? (
                <React.Fragment>
                  <Typography variant="p" className="">
                    Información adicional:
                  </Typography>
                  <TextField
                    id="observacionesInput"
                    label=""
                    multiline
                    fullWidth
                    rows={4}
                    value={null}
                    variant="outlined"
                    onChange={this.handleChange('observaciones')}
                  />
                </React.Fragment>
              ) : null}
            </Grid>

            {this.state.tipo_incidencia !== null && this.state.tipo_incidencia !== '' ? (
              <Grid item xs={12} className="mb1 text-center">
                <Button
                  onClick={this.onClickRegistrarIncidencia}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Registrar incidencia
                </Button>
                {this.state.showRegistrarIncidenciaMessage ? (
                  <Grid item xs={12} className="mb1 mt1">
                    <Alert severity="error">
                      <AlertTitle>
                        <b>Campos sin completar.</b>
                      </AlertTitle>
                      Falta completar campos obligatorios.
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ReporteInicidenciaP1);
