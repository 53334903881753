import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import CardAvatar from '../../Misc/CardAvatar';

import { URLpreffix } from '../../../config';
import { getTimeFromString } from '../../../helpers/helpers';


export default function CardTAResumen(props) {

  let history = useHistory();

  const esEgreso = props.hoyEgreso !== null ? true : false;

  const hrEg = props.hoyEgreso !== null ? <Chip size="small" label={getTimeFromString(props.hoyEgreso.fecha_horario)} /> : "✗";
  const capEg = props.hoyEgreso !== null ? props.hoyEgreso.capataz_a_cargo : "✗";
  const lugEg = props.hoyEgreso !== null ? props.hoyEgreso.lugar : "✗";

  const hrIng = props.hoyIngreso !== null ? <Chip size="small" label={getTimeFromString(props.hoyIngreso.fecha_horario)} /> : "✗";
  const capIng = props.hoyIngreso !== null ? props.hoyIngreso.capataz_a_cargo : "✗";
  const lugIng = props.hoyIngreso !== null ? props.hoyIngreso.lugar : "✗";
  const mon = props.hoyMonitoreo !== null ? "Sí" : "No";

  return (
    <Card className="mb1">
      <CardHeader
        avatar={
          <CardAvatar nombre={props.nombre} />
        }
        title={props.nombre+" "+props.apellido}
        subheader={"DNI "+props.dni}
      />
      
      <CardContent>
        {
          esEgreso ? (
            <Typography variant="body2" component="p">
              <strong>DATOS DE HOY</strong><br/>
              Horario de egreso: { hrEg }<br />
              Capataz: { capEg } <br />
              Lugar: { lugEg } <br />
              ¿Ya fue monitoreado?: <Chip size="small" label={mon} />
            </Typography>
          ) : (
            <Typography variant="body2" component="p">
              <strong>DATOS DE HOY</strong><br/>
              Horario de ingreso: { hrIng }<br />
              Capataz: { capIng } <br />
              Lugar: { lugIng } <br />
              ¿Ya fue monitoreado?: <Chip size="small" label={mon} />
            </Typography>
          )
        }
      </CardContent>
        
        <CardActions >
          <Button onClick={() => { history.push(URLpreffix+'/ListadoTrabajadoresP2?id='+props.id); }} color="primary" >
            Ver registro historico
          </Button>
        </CardActions>
    </Card>
  );
}
