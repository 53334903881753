import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ContactsIcon from '@material-ui/icons/Contacts';
import FeedbackIcon from '@material-ui/icons/Feedback';

import { URLpreffix } from '../../config';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigationC from '../Navigation/MyBottomNavigationC';
import LogoFooter from '../Misc/LogoFooter';

import AllInboxIcon from '@material-ui/icons/AllInbox';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  margin: {
    margin: '20px',
  },
  withoutLabel: {
    marginTop: '60px',
  },
  textField: {
    width: '25ch',
  },
  mainContainer: {},
  mb1: {
    marginBottom: '20px',
  },
  mb2: {
    marginBottom: '40px',
  },
  mr1: {
    marginRight: '20px',
  },
  p0: {
    padding: '0px',
  },
});

export default function HomeCapataz() {
  const classes = useStyles();

  let history = useHistory();

  return (
    <main className="Home">
      <NavBar disableBackButton withUpdateButton checkPendingsEnabled />

      <Container maxWidth="sm" className={classes.mainContainer}>
        <Typography className="mb-15" variant="h6" color="textSecondary">
          Seleccione una opción
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className="home-empleados-root">
              <CardActionArea>
                <CardContent
                  onClick={() => {
                    history.push(URLpreffix + '/SituacionRiesgoP1');
                  }}
                  className="home-empleados-cardContentHome"
                >
                  <FeedbackIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                  <Typography variant="body2" color="textSecondary" component="p">
                    Reporte situaciones que pueden ser de riesgo TI, tanto dentro como fuera del
                    campo.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    history.push(URLpreffix + '/SituacionRiesgoP1');
                  }}
                  color="primary"
                  className="home-empleados-fs13"
                >
                  Reporte situación de riesgo TI
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardContent
                  onClick={() => {
                    history.push(URLpreffix + '/ControlTrabajadorUEP1');
                  }}
                  className={classes.cardContentHome}
                >
                  <Badge className="mb-10" badgeContent={null} color="error">
                    <CheckCircleOutlineIcon className={classes.mb0_5} style={{ fontSize: 40 }} />
                  </Badge>

                  <Typography variant="body2" color="textSecondary" component="p">
                    Monitoreo de trabajadores/as adolescentes por Capataces
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    history.push(URLpreffix + '/ControlTrabajadorUEP1');
                  }}
                  color="primary"
                  className={classes.fs13}
                >
                  Monitorear T.A.
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardContent
                  onClick={() => {
                    history.push(URLpreffix + '/ControlDocumentacionP1');
                  }}
                  className={classes.cardContentHome}
                >
                  <Badge className="mb-10" badgeContent={null} color="error">
                    <ContactsIcon className={classes.mb0_5} style={{ fontSize: 40 }} />
                  </Badge>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Control de la documentación y alta para subir al transporte
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    history.push(URLpreffix + '/ControlDocumentacionP1');
                  }}
                  color="primary"
                  className={classes.fs13}
                >
                  Controlar
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className="home-empleados-root">
              <CardActionArea>
                <CardContent
                  onClick={() => {
                    history.push(URLpreffix + '/ControlEntregaFrutaP1');
                  }}
                  className="home-empleados-cardContentHome"
                >
                  <AllInboxIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                  <Typography variant="body2" color="textSecondary" component="p">
                    Control de puntos de entrega de fruta
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    history.push(URLpreffix + '/ControlEntregaFrutaP1');
                  }}
                  color="primary"
                  className="home-empleados-fs13"
                >
                  Monitorear punto
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <LogoFooter />
      </Container>

      <MyBottomNavigationC />
    </main>
  );
}
