import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


export default function PromptMessage(props) {

  return (
    <Dialog
      open={props.showDialog}
      onClose={props.closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title ? props.title : null}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            props.content ? props.content : null
          }
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => { props.closeDialog(); }} color="primary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>

    </Dialog>
  );

}
