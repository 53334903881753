import { API_URI } from '../config';

import { isOnlineV2 } from '../helpers/helpers';

const LIMIT = 50;

// control_documentacion y control_ta_usuarios_externos no
// se mantienen actualizadas; solo se envian datos nuevos
// y se eliminan los locales que fueron enviados.
const remote_collections = [
  //"actualizaciones_db",
  'adolescentes',
  'contratistas',
  'control_lotes',
  'control_entrega_fruta',
  'control_ta',
  'empresas',
  'ingresos_egresos',
  'lotes',
  // 'precontroles',
];

// Funcion - Consigue del server todos los elementos de una collection, en una sola
//           fase (sin paginar) o en multiples (paginado)
// Params - _collection (string), _pagination (opcional)(object), _prevData (interno de la funcion)(array)
// Return - Promise
const getItems = (_collection, _pagination, _prevData, _fields, _filter) => {
  return new Promise((resolve, reject) => {
    console.log('getItems pagination:', _pagination);
    console.log('_prevData:', _prevData);
    let url = API_URI + '/items/' + _collection + '?';

    if (_fields) url += '&fields=' + _fields;

    if (_filter) url += '&' + _filter;

    if (!Array.isArray(_prevData)) _prevData = [];
    let allData = [];
    let limit = LIMIT;
    let page = null;
    if (_pagination) {
      if (_pagination.enabled === true) {
        if (!_pagination.originalResolve) _pagination.originalResolve = resolve;

        if (!_prevData) {
          // Primera pagina...
          page = 1;
        } else {
          // Consigo la pagina siguiente del obj pagination
          page = _pagination.next;
          allData.concat(_prevData);
        }

        url += '&limit=' + limit + '&page=' + page;
        _pagination.next = page + 1;
      }
    }

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('GET ITEMS!');

        if (_pagination) {
          if (_pagination.enabled) {
            // Si hay data, tengo que rellamar a getItems para que consiga proxima page
            if (json.data.length > 0) {
              //allData.concat(json.data);
              getItems(_collection, _pagination, [..._prevData, ...json.data], _fields, _filter);
            } else {
              // No hay mas data que buscar; devuelvo data
              _pagination.originalResolve(_prevData);
              resolve();
            }
          } ///
        } else {
          // Request sin pagination; devuelvo lo que llego de esta unica req
          resolve(json.data);
        }
      });
  });
};

// Funcion - Nivel de abstraccion superior de getItems; inicia el proceso de conseguir los
//           elementos de una collection desde el server
// Params - _collection (string), _pagination (opcional)(null OR true)
// Return - Promise
export const getItemsFromCollection = (_collection, _paginated, _fields, _filter) => {
  return new Promise((resolve, reject) => {
    let pagination = _paginated && _paginated !== null ? { enabled: true, next: 1 } : null;
    let fields = _fields ? _fields : false;
    let filter = _filter ? _filter : false;

    console.log('Pagination en getItemsFromCollection:');
    console.log(pagination);

    // Pido items de _collection con o sin paginacion
    getItems(_collection, pagination, null, fields, filter).then((items) => {
      resolve({ [_collection]: items });
    });
  });
};

// Funcion - Consigue todos los elementos de todas las collections (ultima version remota)
//           de la DB
// Return - Promise
export const getLastRemoteVersion = () => {
  return new Promise((resolve, reject) => {
    // v1.0 -> Se actualiza todo junto despues de recibir la data
    // Considerar posibilidad de procesar tabla por tabla a medida que llega la data
    let promArr = [];
    for (let i = 0; i < remote_collections.length; i++) {
      promArr.push(getItemsFromCollection(remote_collections[i], true));
    }

    Promise.all(promArr).then((lastVersion) => {
      resolve(lastVersion);
    });
  });
};

export const getUsersData = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/users?fields=first_name,last_name,empresa_id,id';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        resolve(json.data);
      })
      .catch((err) => console.error(err));
  });
};

/**
 * Checkea si existe un TA con el DNI indicado
 * @function
 * @param {int / string} _id - DNI a revisar
 * @return {bool} - True si el TA existe, False si el TA no existe
 */
export const checklIfTAExists = (_dni) => {
  return new Promise((resolve, reject) => {
    let empresa_id = null;
    if (localStorage.getItem('usuario_tipo') === 'empresa') {
      empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));
    }

    if (typeof _dni === 'string') _dni = parseInt(_dni);

    let url = API_URI + '/items/adolescentes?filter[dni][eq]=' + _dni;

    if (empresa_id !== null) url += '&filter[empresa_id][eq]=' + empresa_id;

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.data.length === 0) resolve(false);
        else resolve(true);
      })
      .catch((err) => console.error(err));
  });
};

/**
 * Envia un nuevo trabajador a directus
 * @function
 * @param {object} _data_ta - Nuevo TA a ingresar en DB.
 * @return {promise} - resolve: Object como fue creado en directus, reject: null
 */
export const sendNewTA = (_data_ta) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/adolescentes';
    let body = { ..._data_ta };

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        resolve(json);
      })
      .catch((err) => reject(err));
  });
};

/**
 * Envia un item de la collection especificada al servidor
 * @function
 * @param {string} _collection - Collection a la que pertenece el item a enviar
 * @param {object} _data - Datos del item a enviar
 * @param {string} _tmp_id - (optional) ID temporal de lo que se haya mandado;
 *                           si esta definido, se devuelve junto con el ID real devuelto por el server
 * @param {int} _lote_id - (optional) ID de lote; solo se usa cuando se actualizan incidencias
 * @return {promise} Object con id del server cuando no hay error. Si esta definido _tmp_id, el object devuelve ambos ids
 */
export const sendNewItem = (_collection, _data, _tmp_id = undefined, _lote_id = undefined) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/' + _collection;
    let body = { ..._data };

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        console.log('Finalizo envio de item!');
        let idValues = { remote_id: json.data.id };
        if (_tmp_id !== undefined) idValues.local_id = _tmp_id;
        if (_lote_id !== undefined) idValues.lote_id = _lote_id;
        resolve(idValues);
      })
      .catch((err) => console.error(err));
  });
};

// Esta funcion se usa para la subida de documentos en el registro de adolescentes
export const sendFile = (_file, _userData) => {
  // userData -> { empresa_id, nombre, apellido, dni }
  return new Promise((resolve, reject) => {
    if (_file === null) resolve(null);

    const formData = new FormData();

    let title =
      'doc' +
      _userData.file_number +
      '-' +
      _userData.nombre +
      '-' +
      _userData.apellido +
      '-' +
      _userData.dni +
      '-' +
      _userData.empresa_id;
    title = title.toLowerCase();
    let filename_download = title;

    formData.append('file', _file);
    formData.append('filename_download', filename_download);
    formData.append('title', title);

    const options = {
      method: 'POST',
      body: formData,
    };

    fetch(API_URI + '/files', options)
      .then((resp) => resp.json())
      .then((json) => resolve(json.data.id))
      .catch((err) => resolve(null));
  });
};

// Esta funcion se usa para la subida de documentos en la SegundaParte
export const sendFileV2 = (_file, _fileTitle, _special) => {
  return new Promise((resolve, reject) => {
    if (_file === null) resolve(null);

    const formData = new FormData();

    let title = _fileTitle ? _fileTitle : 'default-' + Date.now();
    title = title.toLowerCase();
    let filename_download = title;

    formData.append('file', _file);
    formData.append('filename_download', filename_download);
    formData.append('title', title);

    const options = {
      method: 'POST',
      body: formData,
    };

    if (_special) {
      // Devuelvo id y full url en un obj
      fetch(API_URI + '/files?meta=id,data', options)
        .then((resp) => resp.json())
        .then((json) => resolve({ id: json.data.id, full_url: json.data.data.full_url }))
        .catch((err) => resolve(null));
    } else {
      fetch(API_URI + '/files', options)
        .then((resp) => resp.json())
        .then((json) => resolve(json.data.id))
        .catch((err) => resolve(null));
    }
  });
};

export const deleteFile = (_file_id, _union_id, _union_database) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'DELETE',
    };

    fetch(API_URI + '/files/' + _file_id, options)
      .then((resp) => {
        fetch(API_URI + '/items/' + _union_database + '/' + _union_id, options).then((json) =>
          resolve(json),
        );
      })
      .catch((err) => {
        console.error(err);
        reject(null);
      });
  });
};

export const deleteFileStandAlone = (_fileId) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'DELETE',
    };

    fetch(API_URI + '/files/' + _fileId, options).then((resp) => {
      resolve();
    });
  });
};

export const scrapRenatre = (_uid) => {
  return new Promise((resolve, reject) => {
    if (!_uid) resolve(null);

    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        fetch('https://monitoreo-ta.c-bucket.site/scrap-card.php?id=' + _uid)
          .then((response) => response.json())
          .then((json) => {
            if (json.data !== null) json.data.dni = parseInt(json.data.dni);

            resolve(json.data);
          });
      } else {
        resolve(null);
      }
    });
  });
};

export const getFormValidation = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/validaciones?fields=*.*.*.*&filter[empresa][eq]=' + _empresa_id;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        console.log('Resultados getvalidacionform:');
        console.log(json);

        // Si vuelve vacio es porque todavia no se creo el formulario;
        // Lo creo y devuelvo

        resolve(json.data);
      });
  });
};

export const getFormValidationV2YerbaMate = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    let url =
      API_URI +
      '/items/validaciones_v2_yerba_mate?fields=*.*.*.*&filter[empresa][eq]=' +
      _empresa_id;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        console.log('Resultados getvalidacionformV2:');
        console.log(json);

        // Si vuelve vacio es porque todavia no se creo el formulario;
        // Lo creo y devuelvo

        resolve(json.data);
      });
  });
};

export const getFormValidationV2 = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/validaciones_v2?fields=*.*.*.*&filter[empresa][eq]=' + _empresa_id;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        console.log('Resultados getvalidacionformV2:');
        console.log(json);

        // Si vuelve vacio es porque todavia no se creo el formulario;
        // Lo creo y devuelvo

        resolve(json.data);
      });
  });
};

export const getEmpresa = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/empresas?filter[id][eq]=' + _empresa_id;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        resolve(json.data);
      });
  });
};

export const updateValidationForm = (_form_id, _field, _value) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/validaciones/' + _form_id;
    let body = { [_field]: _value };

    let options = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Finalizo actualizacion del form!');
        console.log(json);

        resolve();
      })
      .catch((err) => console.error(err));
  });
};

export const updateValidationFormV2 = (_form_id, _field, _value) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/validaciones_v2/' + _form_id;
    let body = { [_field]: _value };

    let options = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Finalizo actualizacion del form!');
        console.log(json);

        resolve();
      })
      .catch((err) => console.error(err));
  });
};

export const updateValidationFormV2YerbaMate = (_form_id, _field, _value) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/validaciones_v2_yerba_mate/' + _form_id;
    let body = { [_field]: _value };

    let options = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Finalizo actualizacion del form!');
        console.log(json);

        resolve();
      })
      .catch((err) => console.error(err));
  });
};

export const sendResponsable = (_reportId, _nombre, _rol) => {
  return new Promise((resolve, reject) => {
    sendNewItem('validaciones_responsables', { nombre: _nombre, rol: _rol }).then((res) => {
      let idResponsable = res.remote_id;
      console.log('idResponsable: ' + idResponsable);
      console.log('reportId: ' + _reportId);

      sendNewItem('estandar_1_2_responsables_validaciones_responsables', {
        validaciones_id: _reportId,
        validaciones_responsables_id: idResponsable,
      }).then((res) => {
        let unionId = res.remote_id;
        resolve({ id: idResponsable, unionId: unionId });
      });
    });
  });
};

export const sendCapacitacion = (_reportId, _data) => {
  return new Promise((resolve, reject) => {
    // fecha: fecha,
    // tema: tema,
    // contenidos: contenidos,
    // lugar: lugar,
    // asistentes: asistentes,
    // documento: documento,
    // observaciones: observaciones

    // asistentes: "123 - Capataces"
    // documento_respaldatorio: 88
    // fecha: "2020-10-11"
    // id: 1
    // lugar: "Lugar test"
    // observaciones: "observaciones"
    // programa: "Programa test"
    // tema:

    // Primero envio el archivo...
    sendFileV2(_data.documento, _data.documento.name, true).then((res) => {
      let _documentoId = res.id;
      let _fullUrl = res.full_url;

      let obj = {
        asistentes: _data.asistentes,
        documento_respaldatorio: _documentoId,
        fecha: _data.fecha,
        lugar: _data.lugar,
        observaciones: _data.observaciones,
        programa: _data.contenidos,
        tema: _data.tema,
      };

      sendNewItem('validaciones_capacitaciones', obj).then((res) => {
        let idCapacitacion = res.remote_id;

        sendNewItem('estandar_2_1_capacitaciones_validaciones_capacitaciones', {
          validaciones_id: _reportId,
          validaciones_capacitaciones_id: idCapacitacion,
        }).then((res) => {
          let unionId = res.remote_id;

          resolve({
            id: idCapacitacion,
            unionId: unionId,
            documentoId: _documentoId,
            fileUrl: _fullUrl,
          });
        });
      });
    });
  });
};

export const sendCapacitacionV2 = (_reportId, _data) => {
  return new Promise((resolve, reject) => {
    // fecha: fecha,
    // tema: tema,
    // contenidos: contenidos,
    // lugar: lugar,
    // asistentes: asistentes,
    // documento: documento,
    // observaciones: observaciones

    // asistentes: "123 - Capataces"
    // documento_respaldatorio: 88
    // fecha: "2020-10-11"
    // id: 1
    // lugar: "Lugar test"
    // observaciones: "observaciones"
    // programa: "Programa test"
    // tema:

    // Primero envio el archivo...
    sendFileV2(_data.documento, _data.documento.name, true).then((res) => {
      let _documentoId = res.id;
      let _fullUrl = res.full_url;

      let obj = {
        asistentes: _data.asistentes,
        documento_respaldatorio: _documentoId,
        fecha: _data.fecha,
        lugar: _data.lugar,
        observaciones: _data.observaciones,
        programa: _data.contenidos,
        tema: _data.tema,
      };

      sendNewItem('validaciones_capacitaciones', obj).then((res) => {
        let idCapacitacion = res.remote_id;

        sendNewItem('estandar_2_1_capacitaciones_validaciones_capacitaciones', {
          validaciones_v2_id: _reportId,
          validaciones_capacitaciones_id: idCapacitacion,
        }).then((res) => {
          let unionId = res.remote_id;

          resolve({
            id: idCapacitacion,
            unionId: unionId,
            documentoId: _documentoId,
            fileUrl: _fullUrl,
          });
        });
      });
    });
  });
};

export const updateCapacitacion = (_reporteId, _data) => {
  console.log('Lo que llega a updateCapacitacion');
  console.log(_data);
  return new Promise((resolve, reject) => {
    // Si tengo un archivo nuevo, tengo que cargarlo antes de mandar la actualizacion...
    console.log(typeof _data.documento);
    if (typeof _data.documento === 'object' && _data.documento !== null) {
      sendFileV2(_data.documento, _data.documento.name, true).then((res) => {
        let _documentoId = res.id;
        let _fullUrl = res.full_url;

        let body = {
          asistentes: _data.asistentes,
          documento_respaldatorio: _documentoId,
          fecha: _data.fecha,
          lugar: _data.lugar,
          observaciones: _data.observaciones,
          programa: _data.contenidos,
          tema: _data.tema,
        };

        let url = API_URI + '/items/validaciones_capacitaciones/' + _data.id;

        let options = {
          method: 'PATCH',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        };

        fetch(url, options).then((res) => {
          resolve({ documentoId: _documentoId, fullUrl: _fullUrl, actualizarDocumento: true });
        });
      });
    } else {
      // No tengo archivo nuevo.. mando tal cual
      let url = API_URI + '/items/validaciones_capacitaciones/' + _data.id;

      let body = {
        asistentes: _data.asistentes,
        documento_respaldatorio: _data.documento,
        fecha: _data.fecha,
        lugar: _data.lugar,
        observaciones: _data.observaciones,
        programa: _data.contenidos,
        tema: _data.tema,
      };

      let options = {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(url, options).then((res) => {
        resolve({ documentoId: null, actualizarDocumento: false });
      });
    }
  });
};

export const deleteItem = (_collection, _id, _unionCollection, _unionId) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'DELETE',
    };

    fetch(API_URI + '/items/' + _collection + '/' + _id, options).then((resp) => {
      // Si unionId y unionCollection estan definidos, tambien los elimino...
      if (_unionId && _unionCollection) {
        fetch(API_URI + '/items/' + _unionCollection + '/' + _unionId, options).then((resp) => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  });
};
