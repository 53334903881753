import XLSX from 'xlsx';
import saveAs from 'save-as';

import { sameDay, horasTrabajadasMs } from '../helpers/helpers';
import { getItemsFromCollection } from '../requests/data';

const HEADERS = {
  adolescentes: [
    'ID',
    'DNI',
    'UID RENATRE',
    'Nombre',
    'Apellido',
    'Fecha de nacimiento',
    'Genero',
    'Documento respaldatorio 1',
    'Documento respaldatorio 2',
    'Documento respaldatorio 3',
    'Empresa',
    'Contratista',
    'Responsable a cargo',
    'Asiste con familia',
    'Asiste a la escuela',
    'Asiste a cursos de formacion',
  ],

  control_documentacion: [
    'ID',
    'DNI Usuario',
    'Nombre Usuario',
    'Apellido Usuario',
    'Empresa',
    'Contratista',
    'Fecha y Hora del Control',
    'DNI Adolescente',
    '¿Se le permitió subir al transporte?',
  ],

  controles_lotes: [
    'ID',
    'Lote ID',
    'Nombre Lote',
    'Geolocalizacion',
    'Contratista',
    'Capataz',
    'Fecha y Hora del Control',
    '¿Se realizo cosecha?',
    'Motivo no cosecha',
    'DNI controlado 1',
    'DNI controlado 2',
    'DNI controlado 3',
    'DNI controlado 4',
    'DNI controlado 5',
    '¿Se registro caso sospechoso?',
    'Observaciones',
  ],

  controles_entrega_fruta: [
    'ID',
    'Lote ID',
    'Nombre Lote',
    'Geolocalizacion',
    'Contratista',
    'Capataz',
    'Fecha y Hora del Control',
    '¿Se realizo cosecha?',
    'Motivo no cosecha',
    'DNI controlado 1',
    'DNI controlado 2',
    'DNI controlado 3',
    'DNI controlado 4',
    'DNI controlado 5',
    '¿Se registro caso sospechoso?',
    'Observaciones',
  ],

  incidencias: [
    'ID',
    'ID Usuario',
    'Nombre Usuario',
    'Apellido Usuario',
    'Empresa',
    'Fecha y Hora de Incidencia',
    'Tipo de incidencia',
    '¿Asociada a alta de TA?',
    '¿Asociada a Monitoreo de Lote?',
    'ID Lote',
    'Nombre Lote',
    'Nombre Sujeto',
    'DNI Sujeto',
    'Edad Sujeto',
    'Género Sujeto',
    'Lugar de Nacimiento Sujeto',
    '¿Acompañaba a adulto?',
    'Responsable Empresa',
    'Observaciones',
  ],

  horas_trabajadas: [
    'ID',
    'Usuario Nombre',
    'Usuario Apellido',
    'Empresa',
    'Fecha y Hora de Ingreso',
    'Fecha y Hora de Egreso',
    'Horas Trabajadas',
    'Geolocalización',
    'Lugar',
    'TA Nombre',
    'TA Apellido',
    'TA DNI',
    'Capataz a cargo',
  ],

  ingresos_egresos: [
    'ID',
    'Usuario Nombre',
    'Usuario Apellido',
    'Empresa',
    'Tipo de Registro',
    'Fecha y Hora',
    'Geolocalización',
    'Lugar',
    'TA Nombre',
    'TA Apellido',
    'TA DNI',
    'Capataz a cargo',
  ],

  logs_usuarios_externos: ['ID', 'Fecha y Hora', 'Contratista', 'DNI', 'Nombre', 'Apellido'],

  //acompaniado_por_adulto: true
  //alta_ta_asociado: false
  //dni_usuario_externo: null
  //edad_sujeto: "12"
  //fecha_horario: "2020-09-17 12:43:31"
  //genero_sujeto: "mujer"
  //id: 66
  //lote_id: null
  //lugar_nacimineto_sujeto: "No sabe"
  //monitoreo_lote_asociado: false
  //nombre_sujeto: "Maria"
  //numero_documento_sujeto: "4500001"
  //observaciones: "Observacion"
  //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name: "Empleado", last_name: "Prueba", …}
  //responsable_empresa: "Jose gutierrez"
  //tipo_incidencia: "Presencia de niños/as o adolescentes no registrados/as en el campo"
  //usuario_alta_ta: null
};

const exportXLSX = (_sheets) => {
  // Formato de cada sheet:
  // { name: (string), data: (aoa) }

  // Nuevo workbook
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: 'MonitoreoTA Webapp Data',
    Subject: 'Export',
    Author: 'MonitoreoTA App',
    CreatedDate: new Date(),
  };

  for (let i = 0; i < _sheets.length; i++) {
    wb.SheetNames.push(_sheets[i].name);
    let ws = XLSX.utils.aoa_to_sheet(_sheets[i].data);
    wb.Sheets[_sheets[i].name] = ws;
  }

  var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  // Envio el archivo al cliente (descarga)
  saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    'MonitoreoTA Webapp Data.xlsx',
  );
};

export const createAdolescentesSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';

    getItemsFromCollection('adolescentes', true, '*.*', filter).then((res) => {
      console.log(res.adolescentes);

      //apellido: "Ramirez"
      //asiste_con_familia: true
      //asiste_escuela: true
      //asiste_formacion: true
      //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
      //dni: 33241362
      //documentacion_respaldatoria_1: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
      //documentacion_respaldatoria_2: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
      //documentacion_respaldatoria_3: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
      //empresa_id: {id: 1, nombre: "Empresa Modelo"}
      //fecha_nacimiento: "2003-12-12"
      //genero: "hombre"
      //id: 32
      //nombre: "José"
      //responsable_empresa_a_cargo: "Hector Lopez"
      //uid_renatre: null
      //usuario_aprueba_alta_id

      let arr = [];
      arr.push(HEADERS['adolescentes']);
      for (let i = 0; i < res.adolescentes.length; i++) {
        let row = [
          res.adolescentes[i].id || '', //'ID',
          res.adolescentes[i].dni || '', //'DNI',
          res.adolescentes[i].uid_renatre || '', //'UID RENATRE',
          res.adolescentes[i].nombre || '', //'Nombre',
          res.adolescentes[i].apellido || '', //'Apellido',
          res.adolescentes[i].fecha_nacimiento || '', //'Fecha de nacimiento',
          res.adolescentes[i].genero || '', //'Genero',
          res.adolescentes[i].documentacion_respaldatoria_1
            ? res.adolescentes[i].documentacion_respaldatoria_1.data.full_url
            : '', //'Documento respaldatorio 1',
          res.adolescentes[i].documentacion_respaldatoria_2
            ? res.adolescentes[i].documentacion_respaldatoria_2.data.full_url
            : '', //'Documento respaldatorio 2',
          res.adolescentes[i].documentacion_respaldatoria_3
            ? res.adolescentes[i].documentacion_respaldatoria_3.data.full_url
            : '', //'Documento respaldatorio 3',
          res.adolescentes[i].empresa_id ? res.adolescentes[i].empresa_id.nombre : '', //'Empresa',
          res.adolescentes[i].contratista_id ? res.adolescentes[i].contratista_id.nombre : '', //'Contratista',
          res.adolescentes[i].responsable_empresa_a_cargo || '', //'Responsable a cargo',
          res.adolescentes[i].asiste_con_familia ? 'Si' : 'No', //'Asiste con familia',
          res.adolescentes[i].asiste_escuela ? 'Si' : 'No', //'Asiste a la escuela',
          res.adolescentes[i].asiste_formacion ? 'Si' : 'No', //'Asiste a cursos de formacion'
        ];

        arr.push(row);
      }

      let sheet = {
        name: 'Trabajadores Adolescentes',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

export const createControlesDocumentacionSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';

    getItemsFromCollection('control_documentacion', true, '*.*', filter).then((res) => {
      console.log(res.control_documentacion);

      //adolescente_habilitado: true
      //apellido_usuario_externo: "Ejemplo"
      //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
      //dni_adolescente: 33241362
      //dni_usuario_externo: 11111111
      //empresa_id: {id: 1, nombre: "Empresa Modelo"}
      //fecha_horario: "2020-09-17 10:40:54"
      //id: 155
      //nombre_usuario_externo: "Transportista"

      let arr = [];
      arr.push(HEADERS['control_documentacion']);
      for (let i = 0; i < res.control_documentacion.length; i++) {
        let row = [
          res.control_documentacion[i].id || '', //'ID',
          res.control_documentacion[i].dni_usuario_externo || '', //'DNI Usuario',
          res.control_documentacion[i].nombre_usuario_externo || '', //'Nombre Usuario',
          res.control_documentacion[i].apellido_usuario_externo || '', //'Apellido Usuario',
          res.control_documentacion[i].empresa_id
            ? res.control_documentacion[i].empresa_id.nombre
            : '', //'Empresa',
          res.control_documentacion[i].contratista_id
            ? res.control_documentacion[i].contratista_id.nombre
            : '', //'Contratista',
          res.control_documentacion[i].fecha_horario || '', //'Fecha y Hora del Control',
          res.control_documentacion[i].dni_adolescente || '', //'DNI Adolescente',
          res.control_documentacion[i].adolescente_habilitado ? 'Si' : 'No', //'¿Se permitio ingreso?'
        ];

        arr.push(row);
      }

      let sheet = {
        name: 'Controles Documentacion',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

export const createLogsUsuariosExternosSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[contratista_id.empresa_asociada_id][eq]=' + _empresaId : '';

    getItemsFromCollection('logs_usuarios_externos', true, '*.*', filter).then((res) => {
      console.log(res.logs_usuarios_externos);

      //capataz: "Lopez"
      //caso_sospechoso: false
      //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
      //control_dni: true
      //fecha_horario: "2020-09-17 12:40:06"
      //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
      //hay_cosecha: true
      //id: 30
      //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
      //observaciones: "Observaciones"
      //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

      let arr = [];
      arr.push(HEADERS['logs_usuarios_externos']);
      for (let i = 0; i < res.logs_usuarios_externos.length; i++) {
        let row = [
          res.logs_usuarios_externos[i].id || '', //'ID',
          res.logs_usuarios_externos[i].fecha_horario || '', //'Fecha y Hora del Control',
          res.logs_usuarios_externos[i].contratista_id
            ? res.logs_usuarios_externos[i].contratista_id.nombre
            : '', //'Contratista',
          res.logs_usuarios_externos[i].dni || '', //'DNI',
          res.logs_usuarios_externos[i].nombre || '', //'Nombre',
          res.logs_usuarios_externos[i].apellido || '', //'Apellido',
        ];

        arr.push(row);
      }

      let sheet = {
        name: 'Logins Usuarios Externos',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

export const createControlesLotesSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[lote_id.empresa_id][eq]=' + _empresaId : '';

    getItemsFromCollection('control_lotes', true, '*.*', filter).then((res) => {
      console.log(res.control_lotes);

      //capataz: "Lopez"
      //caso_sospechoso: false
      //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
      //control_dni: true
      //fecha_horario: "2020-09-17 12:40:06"
      //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
      //hay_cosecha: true
      //id: 30
      //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
      //observaciones: "Observaciones"
      //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

      let arr = [];
      arr.push(HEADERS['controles_lotes']);
      for (let i = 0; i < res.control_lotes.length; i++) {
        let row = [
          res.control_lotes[i].id || '', //'ID',
          res.control_lotes[i].lote_id ? res.control_lotes[i].lote_id.id : '', //'Lote ID',
          res.control_lotes[i].lote_id ? res.control_lotes[i].lote_id.nombre : '', //'Nombre Lote',
          res.control_lotes[i].geo_lat_long || '', //'Geolocalizacion',
          res.control_lotes[i].contratista_id ? res.control_lotes[i].contratista_id.nombre : '', //'Contratista',
          res.control_lotes[i].capataz || '', //'Capataz',
          res.control_lotes[i].fecha_horario || '', //'Fecha y Hora del Control',
          res.control_lotes[i].hay_cosecha ? 'Si' : 'No', //'¿Se realizo cosecha?',
          res.control_lotes[i].motivo_no_cosecha ? res.control_lotes[i].motivo_no_cosecha : '-',
          res.control_lotes[i].dni_fecha_nacimiento_trabajador_1
            ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_1
            : '-',
          res.control_lotes[i].dni_fecha_nacimiento_trabajador_2
            ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_2
            : '-',
          res.control_lotes[i].dni_fecha_nacimiento_trabajador_3
            ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_3
            : '-',
          res.control_lotes[i].dni_fecha_nacimiento_trabajador_4
            ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_4
            : '-',
          res.control_lotes[i].dni_fecha_nacimiento_trabajador_5
            ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_5
            : '-',
          res.control_lotes[i].caso_sospechoso ? 'Si' : 'No', //'¿Se registro caso sospechoso?',
          res.control_lotes[i].observaciones || '', //'Observaciones'
        ];

        arr.push(row);
      }

      let sheet = {
        name: 'Controles Lotes',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

export const createControlesEntregaFrutaSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[lote_id.empresa_id][eq]=' + _empresaId : '';

    getItemsFromCollection('control_entrega_fruta', true, '*.*', filter).then((res) => {
      console.log(res.control_entrega_fruta);

      //capataz: "Lopez"
      //caso_sospechoso: false
      //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
      //control_dni: true
      //fecha_horario: "2020-09-17 12:40:06"
      //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
      //hay_cosecha: true
      //id: 30
      //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
      //observaciones: "Observaciones"
      //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

      let arr = [];
      arr.push(HEADERS['controles_entrega_fruta']);
      for (let i = 0; i < res.control_entrega_fruta.length; i++) {
        let row = [
          res.control_entrega_fruta[i].id || '', //'ID',
          res.control_entrega_fruta[i].lote_id ? res.control_entrega_fruta[i].lote_id.id : '', //'Lote ID',
          res.control_entrega_fruta[i].lote_id ? res.control_entrega_fruta[i].lote_id.nombre : '', //'Nombre Lote',
          res.control_entrega_fruta[i].geo_lat_long || '', //'Geolocalizacion',
          res.control_entrega_fruta[i].contratista_id
            ? res.control_entrega_fruta[i].contratista_id.nombre
            : '', //'Contratista',
          res.control_entrega_fruta[i].capataz || '', //'Capataz',
          res.control_entrega_fruta[i].fecha_horario || '', //'Fecha y Hora del Control',
          res.control_entrega_fruta[i].hay_cosecha ? 'Si' : 'No', //'¿Se realizo cosecha?',
          res.control_entrega_fruta[i].motivo_no_cosecha
            ? res.control_entrega_fruta[i].motivo_no_cosecha
            : '-',
          res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_1
            ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_1
            : '-',
          res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_2
            ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_2
            : '-',
          res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_3
            ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_3
            : '-',
          res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_4
            ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_4
            : '-',
          res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_5
            ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_5
            : '-',
          res.control_entrega_fruta[i].caso_sospechoso ? 'Si' : 'No', //'¿Se registro caso sospechoso?',
          res.control_entrega_fruta[i].observaciones || '', //'Observaciones'
        ];

        arr.push(row);
      }

      let sheet = {
        name: 'Controles Ptos Ega de Fruta',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

export const createIncidenciasSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    //let filter = _empresaId ? 'filter[contratista_id.empresa_asociada_id][eq]='+_empresaId : '';
    let filter = '';

    getItemsFromCollection('incidencias', true, '*.*.*', filter).then((res) => {
      console.log(res.incidencias);

      //acompaniado_por_adulto: true
      //alta_ta_asociado: false
      //dni_usuario_externo: null
      //edad_sujeto: "12"
      //fecha_horario: "2020-09-17 12:43:31"
      //genero_sujeto: "mujer"
      //id: 66
      //lote_id: null
      //lugar_nacimineto_sujeto: "No sabe"
      //monitoreo_lote_asociado: false
      //nombre_sujeto: "Maria"
      //numero_documento_sujeto: "4500001"
      //observaciones: "Observacion"
      //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name: "Empleado", last_name: "Prueba", …}
      //responsable_empresa: "Jose gutierrez"
      //tipo_incidencia: "Presencia de niños/as o adolescentes no registrados/as en el campo"
      //usuario_alta_ta: null

      let arr = [];
      arr.push(HEADERS['incidencias']);
      for (let i = 0; i < res.incidencias.length; i++) {
        // Obtengo nombre de la empresa
        let empresaNombre = '';
        let empresaIdThis = null;
        if (res.incidencias[i].empresa_id) {
          empresaNombre = res.incidencias[i].empresa_id.nombre;
          empresaIdThis = res.incidencias[i].empresa_id.id;
        }

        if (res.incidencias[i].contratista_id) {
          empresaNombre = res.incidencias[i].contratista_id.empresa_asociada_id.nombre;
          empresaIdThis = res.incidencias[i].contratista_id.empresa_asociada_id.id;
        }

        if (res.incidencias[i].registrado_usuario_id && parseInt(_empresaId) === empresaIdThis) {
          let loaded = false;
          let row = [];

          let enableDataSujeto = true;
          if (
            res.incidencias[i].tipo_incidencia ===
              'Niño/a o adolescente no registrado que quiere ingresar al campo' ||
            res.incidencias[i].tipo_incidencia ===
              'Niño/a o adolescente no registrado que quiere subir al transporte'
          ) {
            enableDataSujeto = false;
          }

          if (res.incidencias[i].alta_ta_asociado && !loaded) {
            // Incidencia de Alta TA
            row = [
              res.incidencias[i].id || '', //'ID',
              res.incidencias[i].registrado_usuario_id.id, //'ID Usuario',
              res.incidencias[i].registrado_usuario_id.first_name, //'Nombre Usuario',
              res.incidencias[i].registrado_usuario_id.last_name, //'Apellido Usuario',
              empresaNombre,
              res.incidencias[i].fecha_horario || '', //'Fecha y Hora de Incidencia',
              res.incidencias[i].tipo_incidencia || '', //'Tipo de incidencia',
              'Si', //'¿Asociada a alta de TA?',
              'No', //'¿Asociada a Monitoreo de Lote?',
              'No aplica', //'ID Lote',
              'No aplica', //'Nombre Lote',
              res.incidencias[i].nombre_sujeto && enableDataSujeto
                ? res.incidencias[i].nombre_sujeto
                : 'No aplica', //'Nombre Sujeto',
              res.incidencias[i].numero_documento_sujeto && enableDataSujeto
                ? res.incidencias[i].numero_documento_sujeto
                : 'No aplica', //'DNI Sujeto',
              res.incidencias[i].edad_sujeto && enableDataSujeto
                ? res.incidencias[i].edad_sujeto
                : 'No aplica', //'Edad Sujeto',
              res.incidencias[i].genero_sujeto && enableDataSujeto
                ? res.incidencias[i].genero_sujeto
                : 'No aplica', //'Género Sujeto',
              res.incidencias[i].lugar_nacimineto_sujeto && enableDataSujeto
                ? res.incidencias[i].lugar_nacimineto_sujeto
                : 'No aplica', //'Lugar de Nacimiento Sujeto',
              res.incidencias[i].acompaniado_por_adulto && enableDataSujeto
                ? res.incidencias[i].acompaniado_por_adulto
                : 'No aplica', //'¿Acompañaba a adulto?',
              res.incidencias[i].responsable_empresa && enableDataSujeto
                ? res.incidencias[i].responsable_empresa
                : 'No aplica', //'Responsable Empresa',
              res.incidencias[i].observaciones || '', //'Observaciones'
            ];

            loaded = true;
          }

          if (res.incidencias[i].lote_id && !loaded) {
            // Incidencia de Lote
            row = [
              res.incidencias[i].id || '', //'ID',
              res.incidencias[i].registrado_usuario_id.id, //'ID Usuario',
              res.incidencias[i].registrado_usuario_id.first_name, //'Nombre Usuario',
              res.incidencias[i].registrado_usuario_id.last_name, //'Apellido Usuario',
              empresaNombre,
              res.incidencias[i].fecha_horario || '', //'Fecha y Hora de Incidencia',
              res.incidencias[i].tipo_incidencia || '', //'Tipo de incidencia',
              'No', //'¿Asociada a alta de TA?',
              'Si', //'¿Asociada a Monitoreo de Lote?',
              res.incidencias[i].lote_id.id, //'ID Lote',
              res.incidencias[i].lote_id.nombre, //'Nombre Lote',
              res.incidencias[i].nombre_sujeto && enableDataSujeto
                ? res.incidencias[i].nombre_sujeto
                : 'No aplica', //'Nombre Sujeto',
              res.incidencias[i].numero_documento_sujeto && enableDataSujeto
                ? res.incidencias[i].numero_documento_sujeto
                : 'No aplica', //'DNI Sujeto',
              res.incidencias[i].edad_sujeto && enableDataSujeto
                ? res.incidencias[i].edad_sujeto
                : 'No aplica', //'Edad Sujeto',
              res.incidencias[i].genero_sujeto && enableDataSujeto
                ? res.incidencias[i].genero_sujeto
                : 'No aplica', //'Género Sujeto',
              res.incidencias[i].lugar_nacimineto_sujeto && enableDataSujeto
                ? res.incidencias[i].lugar_nacimineto_sujeto
                : 'No aplica', //'Lugar de Nacimiento Sujeto',
              res.incidencias[i].acompaniado_por_adulto && enableDataSujeto
                ? res.incidencias[i].acompaniado_por_adulto
                : 'No aplica', //'¿Acompañaba a adulto?',
              res.incidencias[i].responsable_empresa && enableDataSujeto
                ? res.incidencias[i].responsable_empresa
                : 'No aplica', //'Responsable Empresa',
              res.incidencias[i].observaciones || '', //'Observaciones'
            ];

            loaded = true;
          }

          if (!loaded) {
            // Default
            row = [
              res.incidencias[i].id || '', //'ID',
              res.incidencias[i].registrado_usuario_id
                ? res.incidencias[i].registrado_usuario_id.id
                : '', //'ID Usuario',
              res.incidencias[i].registrado_usuario_id
                ? res.incidencias[i].registrado_usuario_id.first_name
                : '', //'Nombre Usuario',
              res.incidencias[i].registrado_usuario_id
                ? res.incidencias[i].registrado_usuario_id.last_name
                : '', //'Apellido Usuario',
              empresaNombre,
              res.incidencias[i].fecha_horario || '', //'Fecha y Hora de Incidencia',
              res.incidencias[i].tipo_incidencia || '', //'Tipo de incidencia',
              res.incidencias[i].alta_ta_asociado ? 'Si' : 'No', //'¿Asociada a alta de TA?',
              res.incidencias[i].monitoreo_lote_asociado ? 'Si' : 'No', //'¿Asociada a Monitoreo de Lote?',
              res.incidencias[i].lote_id ? res.incidencias[i].lote_id.id : 'No aplica', //'ID Lote',
              res.incidencias[i].lote_id ? res.incidencias[i].lote_id.nombre : 'No aplica', //'Nombre Lote',
              res.incidencias[i].nombre_sujeto && enableDataSujeto
                ? res.incidencias[i].nombre_sujeto
                : 'No aplica', //'Nombre Sujeto',
              res.incidencias[i].numero_documento_sujeto && enableDataSujeto
                ? res.incidencias[i].numero_documento_sujeto
                : 'No aplica', //'DNI Sujeto',
              res.incidencias[i].edad_sujeto && enableDataSujeto
                ? res.incidencias[i].edad_sujeto
                : 'No aplica', //'Edad Sujeto',
              res.incidencias[i].genero_sujeto && enableDataSujeto
                ? res.incidencias[i].genero_sujeto
                : 'No aplica', //'Género Sujeto',
              res.incidencias[i].lugar_nacimineto_sujeto && enableDataSujeto
                ? res.incidencias[i].lugar_nacimineto_sujeto
                : 'No aplica', //'Lugar de Nacimiento Sujeto',
              res.incidencias[i].acompaniado_por_adulto && enableDataSujeto
                ? res.incidencias[i].acompaniado_por_adulto
                : 'No aplica', //'¿Acompañaba a adulto?',
              res.incidencias[i].responsable_empresa && enableDataSujeto
                ? res.incidencias[i].responsable_empresa
                : 'No aplica', //'Responsable Empresa',
              res.incidencias[i].observaciones || '', //'Observaciones'
            ];

            loaded = true;
          }

          if (row.length > 0) {
            arr.push(row);
          }
        }
      }

      let sheet = {
        name: 'Controles Lotes',
        data: arr,
      };

      resolve(sheet);
      //dataExport(arr);

      //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
    });
  });
};

var createHorasTrabajadasSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    // Si _empresaId no esta definido, busco de todas las empresas
    let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';
    getItemsFromCollection('ingresos_egresos', true, '*.*.*', filter).then((res) => {
      console.log('ingresos_egresos:');
      console.log(res.ingresos_egresos);

      let ingresos = [];
      let egresos = [];
      //console.log("ingresos/egresos:");
      //console.log(res[collection]);

      for (let i = 0; i < res.ingresos_egresos.length; i++) {
        if (res.ingresos_egresos[i].tipo === 'ingreso') ingresos.push(res.ingresos_egresos[i]);
        else egresos.push(res.ingresos_egresos[i]);
      }

      //console.log("Solo ingresos:");
      //console.log(ingresos);
      //console.log("Solo egresos:");
      //console.log(egresos);

      for (let i = 0; i < ingresos.length; i++) {
        // Busco el egreso que corresponde a este ingreso...
        ingresos[i].fecha_egreso = null;
        for (let j = 0; j < egresos.length; j++) {
          // Solo puede haber un ingreso y egreso por dia por TA;
          // busco ingreso y egreso con mismo dia para el mismo ID de TA...
          if (
            ingresos[i].adolescente_id.id === egresos[j].adolescente_id.id &&
            sameDay(ingresos[i].fecha_horario, egresos[j].fecha_horario)
          ) {
            ingresos[i].fecha_egreso = egresos[j].fecha_horario;
            ingresos[i].horas_trabajadas_ms = horasTrabajadasMs(
              ingresos[i].fecha_horario,
              ingresos[i].fecha_egreso,
            );
            //totalHorasMs += ingresos[i].horas_trabajadas_ms;

            let horas = Math.floor(ingresos[i].horas_trabajadas_ms / 1000 / 60 / 60);
            let minutos = Math.floor(
              (ingresos[i].horas_trabajadas_ms - horas * 1000 * 60 * 60) / 1000 / 60,
            );

            ingresos[i].horas_trabajadas_str =
              horas.toString() + ':' + (minutos < 10 ? '0' + minutos.toString() : minutos);
          }
        }
      }

      var data = [];
      data.push(HEADERS['horas_trabajadas']);
      for (var i = 0; i < ingresos.length; i++) {
        data.push([
          ingresos[i].id ? ingresos[i].id : '', //'ID',
          ingresos[i].registrado_usuario_id ? ingresos[i].registrado_usuario_id.first_name : '', //'Usuario Nombre',
          ingresos[i].registrado_usuario_id ? ingresos[i].registrado_usuario_id.last_name : '', //'Usuario Apellido',
          ingresos[i].empresa_id ? ingresos[i].empresa_id.nombre : '', //'Empresa',
          ingresos[i].fecha_horario ? ingresos[i].fecha_horario : '', //'Fecha y Hora de Ingreso',
          ingresos[i].fecha_egreso ? ingresos[i].fecha_egreso : 'No se registro egreso', //'Fecha y Hora de Egreso',
          ingresos[i].fecha_egreso ? ingresos[i].horas_trabajadas_str : 'No se registro egreso', //'Horas Trabajadas',
          ingresos[i].geo_lat_long ? ingresos[i].geo_lat_long : '', //'Geolocalización',
          ingresos[i].lugar ? ingresos[i].lugar : '', //'Lugar',
          ingresos[i].adolescente_id ? ingresos[i].adolescente_id.nombre : '', //'TA Nombre',
          ingresos[i].adolescente_id ? ingresos[i].adolescente_id.apellido : '', //'TA Apellido',
          ingresos[i].adolescente_id ? ingresos[i].adolescente_id.dni : '', //'TA DNI',
          ingresos[i].capataz_a_cargo ? ingresos[i].capataz_a_cargo : '', //'Capataz a cargo'
        ]);
      }

      var sheet = {
        name: 'Horas Trabajadas',
        data: data,
      };

      resolve(sheet);
    });
  });
};

var createIngresosEgresosSheet = (_empresaId) => {
  return new Promise((resolve, reject) => {
    let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';
    getItemsFromCollection('ingresos_egresos', true, '*.*.*', filter).then((res) => {
      console.log('ingresos_egresos:');
      console.log(res.ingresos_egresos);

      var data = [];
      data.push(HEADERS['ingresos_egresos']);
      for (var i = 0; i < res.ingresos_egresos.length; i++) {
        data.push([
          res.ingresos_egresos[i].id, //'ID',
          res.ingresos_egresos[i].registrado_usuario_id.first_name, //'Usuario Nombre',
          res.ingresos_egresos[i].registrado_usuario_id.last_name, //'Usuario Apellido',
          res.ingresos_egresos[i].empresa_id.nombre, //'Empresa',
          res.ingresos_egresos[i].tipo, //'Tipo de Registro',
          res.ingresos_egresos[i].fecha_horario, //'Fecha y Hora',
          res.ingresos_egresos[i].geo_lat_long, //'Geolocalización',
          res.ingresos_egresos[i].lugar, //'Lugar',
          res.ingresos_egresos[i].adolescente_id.nombre, //'TA Nombre',
          res.ingresos_egresos[i].adolescente_id.apellido, //'TA Apellido',
          res.ingresos_egresos[i].adolescente_id.dni, //'TA DNI',
          res.ingresos_egresos[i].capataz_a_cargo, //'Capataz a cargo'
        ]);
      }

      var sheet = {
        name: 'Ingresos y Egresos',
        data: data,
      };

      resolve(sheet);
    });
  });
};

export const exportAppData = (_types, _empresaId) => {
  if (!_types) {
    console.log('Debe haber especificado por lo menos 1 tipo...');
    return;
  }

  if (!Array.isArray(_types)) {
    console.log('Los tipos deben ser indicados en un array...');
    return;
  }

  let empresa = _empresaId ? _empresaId : null;

  let arrProm = [];

  if (_types[0] !== 'todo') {
    for (let i = 0; i < _types.length; i++) {
      if (_types[i] === 'adolescentes') {
        arrProm.push(createAdolescentesSheet(empresa));
      }

      if (_types[i] === 'controles_documentacion') {
        arrProm.push(createControlesDocumentacionSheet(empresa));
      }

      if (_types[i] === 'controles_lotes') {
        arrProm.push(createControlesLotesSheet(empresa));
      }

      if (_types[i] === 'controles_entrega_fruta') {
        arrProm.push(createControlesEntregaFrutaSheet(empresa));
      }

      if (_types[i] === 'incidencias') {
        arrProm.push(createIncidenciasSheet(empresa));
      }

      if (_types[i] === 'incidencias_v2') {
        // arrProm.push(createIncidenciasSheet(empresa));

        // TODO: this is an horrofic provisory solution made by
        // me when I was in Mexico; fix it :'(
        var headers = {
          adolescentes: [
            'ID',
            'DNI',
            'UID RENATRE',
            'Nombre',
            'Apellido',
            'Fecha de nacimiento',
            'Genero',
            'Documento respaldatorio 1',
            'Documento respaldatorio 2',
            'Documento respaldatorio 3',
            'Empresa',
            'Contratista',
            'Responsable a cargo',
            'Asiste con familia',
            'Asiste a la escuela',
            'Asiste a cursos de formacion',
          ],

          control_documentacion: [
            'ID',
            'DNI Usuario',
            'Nombre Usuario',
            'Apellido Usuario',
            'Empresa',
            'Contratista',
            'Fecha y Hora del Control',
            'DNI Adolescente',
            '¿Se le permitió subir al transporte?',
          ],

          controles_lotes: [
            'ID',
            'Lote ID',
            'Empresa',
            'Nombre Lote',
            'Geolocalizacion',
            'Contratista',
            'Capataz',
            'Fecha y Hora del Control',
            '¿Se realizo cosecha?',
            '¿Se realizo control de DNI?',
            '¿Se registro caso sospechoso?',
            'Observaciones',
          ],

          incidencias: [
            'ID',
            'ID Usuario',
            'Nombre Usuario',
            'Apellido Usuario',
            'Empresa',
            'Fecha y Hora de Incidencia',
            'Tipo de incidencia',
            '¿Asociada a alta de TA?',
            '¿Asociada a Monitoreo de Lote?',
            'ID Lote',
            'Nombre Lote',
            'Nombre Sujeto',
            'DNI Sujeto',
            'Edad Sujeto',
            'Género Sujeto',
            'Lugar de Nacimiento Sujeto',
            '¿Acompañaba a adulto?',
            'Responsable Empresa',
            'Observaciones',
          ],

          incidencias_v2: [
            'ID',
            'ID Usuario',
            'Nombre Usuario',
            'Apellido Usuario',
            'Empresa',
            'Fecha y Hora del Registro',
            '¿Dentro o fuera del campo?',
            'Tipo de Situación',
            '¿Asociada a alta de TA?',
            '¿Asociada a Monitoreo de Lote?',
            'ID Lote',
            'Nombre Lote',
            'Provincia',
            '¿Es posible dialogar con el sujeto?',
            'Nombre del sujeto',
            'Género del sujeto',
            'Localidad del sujeto',
            'Edad del sujeto',
            '¿Cuenta con permiso para trabajar?',
            '¿Quién otorgó el permiso?',
            '¿Asiste a la escuela?',
            '¿A qué grado va?',
            'Nombre de la escuela',
            '¿Se encuentra con acompañante?', // sujeto_acompaniado
            'Parentezco del acompañante', // parentezco_acompanante
            'Descripción de situación', //descripcion_situacion
            'Acciones que tomó la empresa', // acciones_empresa
            'Observaciones adicionales', // observaciones_adicionales
            // alta_ta_asociado
            // usuario_alta_ta
            // monitoreo_lote_asociado
            // lote_id
            //x edad_aproximada_sujeto
          ],

          contratistas: ['ID', 'Nombre', 'Empresa'],

          control_ta: [
            'ID',
            'Nombre Usuario',
            'Apellido Usuario',
            'Empresa',
            'Fecha y Hora',
            'Geolocalización',
            'Nombre TA',
            'Apellido TA',
            'DNI TA',
            '¿Se encuentra en su lugar de trabajo?',
            'Tareas Realizadas',
            '¿Brazos por arriba de cabeza?',
            '¿Está agachado/a?',
            '¿Está expuesto/a a altas temperaturas?',
            '¿Está expuesto/a a bajas temperaturas?',
            '¿Fue alguien a controlar?',
            '¿Hay agua potable disponible?',
            '¿Levanta más de 5kg?',
            '¿Requiere protección?',
            '¿Utiliza protección?',
            '¿Utiliza agroquímicos?',
            '¿Utiliza herramientas peligrosas?',
            '¿Utiliza maquinaria?',
            '¿Utiliza identificación?',
            'Observaciones',
          ],

          empresas: ['ID', 'Nombre'],

          lotes: ['ID', 'Empresa', 'Nombre'],

          ingresos_egresos: [
            'ID',
            'Usuario Nombre',
            'Usuario Apellido',
            'Empresa',
            'Tipo de Registro',
            'Fecha y Hora',
            'Geolocalización',
            'Lugar',
            'TA Nombre',
            'TA Apellido',
            'TA DNI',
            'Capataz a cargo',
          ],

          controles_ta_ue: [
            'ID',
            'DNI Usuario Externo',
            'Contratista',
            'Empresa',
            'Fecha y Hora',
            'Geolocalización',
            'Semana',
            'Lote',
            'Nombre TA',
            'Apellido TA',
            'DNI TA',
            '¿Hay acceso a agua potable?',
            '¿Fue alguien a controlar?',
            'Tareas Realizadas',
            '¿Utiliza herramientas peligrosas o maquinaria?',
            '¿Utiliza protección?',
            'Observaciones',
          ],

          actividad: [
            'ID', // id: "2147"
            'Fecha y Hora', // fecha_hora: "2020-09-17 13:43:14"
            'Tipo', // collection: "control_documentacion"
            'Acción', // action: "create"
            'Detalle',
            'Usuario ID', // xxxx_usuario_id
            'Usuario Nombre', // xxxx_nombre_usuario
            'Usuario Apellido', // xxxx_apellido_usuario
            'Usuario Externo DNI', // xxxx_ue_dni
            'Empresa', // xxxx_nombre_empresa
            'IP', // ip
            //adolescentes_apellido_usuario: null
            //adolescentes_id: null
            //adolescentes_nombre_usuario: null
            //adolescentes_usuario_id: null

            //control_documentacion_id: "157"
            //control_documentacion_ue_dni: "123902392"
            //control_lotes_apellido_usuario: null
            //control_lotes_id: null
            //control_lotes_nombre_usuario: null
            //control_lotes_usuario_id: null
            //control_ta_apellido_usuario: null
            //control_ta_id: null
            //control_ta_nombre_usuario: null
            //control_ta_usuario_id: null
            //control_ta_usuarios_externos_id: null
            //control_ta_usuarios_externos_ue_dni: null
            //directus_users_apellido_usuario: null
            //directus_users_id: null
            //directus_users_nombre_usuario: null
            //directus_users_usuario_id: null

            //incidencias_apellido_usuario: null
            //incidencias_id: null
            //incidencias_nombre_usuario: null
            //incidencias_ue_dni: null
            //incidencias_usuario_id: null
            //ingresos_egresos_apellido_usuario: null
            //ingresos_egresos_id: null
            //ingresos_egresos_nombre_usuario: null
            //ingresos_egresos_usuario_id: null
            //ip: "181.46.139.8"
            //validaciones_apellido_usuario: null
            //validaciones_id: null
            //validaciones_nombre_usuario: null
            //validaciones_usuario_id: null
          ],

          logs_usuarios_externos: [
            'ID',
            'Fecha y Hora',
            'Contratista',
            'DNI',
            'Nombre',
            'Apellido',
          ],
        };

        var INCLUDED_EMPRESAS = [];

        INCLUDED_EMPRESAS.push(parseInt(empresa));

        // eslint-disable-next-line
        var _createIncidenciasV2Sheet = (_empresaId) => {
          return new Promise((resolve, reject) => {
            // Si _empresaId no esta definido, busco de todas las empresas
            let filter = '';

            getItemsFromCollection('incidencias_v2', true, '*.*.*', filter).then((res) => {
              // registrado_usuario_id
              // dni_usuario_externo
              // fecha_horario	datetime
              // dentro_o_fuera_del_campo
              // tipo_de_situacion
              // provincia
              // posible_dialogar
              // nombre_sujeto
              // genero_sujeto
              // localidad_sujeto
              // edad_sujeto
              // permiso_trabajo_sujeto
              // quien_otorgo_permiso
              // asiste_escuela
              // grado_sujeto
              // nombre_escuela
              // descripcion_situacion
              // sujeto_acompaniado
              // parentezco_acompanante
              // alta_ta_asociado
              // usuario_alta_ta
              // monitoreo_lote_asociado
              // lote_id
              // edad_aproximada_sujeto
              // acciones_empresa
              // observaciones_adicionales
              console.log('DEBUG', res);
              let arr = [];
              arr.push(headers['incidencias_v2']);
              for (let i = 0; i < res.incidencias_v2.length; i++) {
                // Obtengo nombre de la empresa
                let empresaNombre = '';
                let empresaIdThis = null;
                if (res.incidencias_v2[i].empresa_id) {
                  empresaNombre = res.incidencias_v2[i].empresa_id.nombre;
                  empresaIdThis = res.incidencias_v2[i].empresa_id.id;
                }

                if (res.incidencias_v2[i].contratista_id) {
                  empresaNombre = res.incidencias_v2[i].contratista_id.empresa_asociada_id.nombre;
                  empresaIdThis = res.incidencias_v2[i].contratista_id.empresa_asociada_id.id;
                }

                if (INCLUDED_EMPRESAS.includes(empresaIdThis)) {
                  if (
                    (res.incidencias_v2[i].registrado_usuario_id &&
                      parseInt(_empresaId) === empresaIdThis) ||
                    true
                  ) {
                    let loaded = false;
                    let row = [];

                    let nombre_sujeto = res.incidencias_v2[i].nombre_sujeto
                      ? res.incidencias_v2[i].nombre_sujeto
                      : '';
                    let genero_sujeto = res.incidencias_v2[i].genero_sujeto
                      ? res.incidencias_v2[i].genero_sujeto
                      : '';
                    let localidad_sujeto = res.incidencias_v2[i].localidad_sujeto
                      ? res.incidencias_v2[i].localidad_sujeto
                      : '';
                    let edad_sujeto = res.incidencias_v2[i].edad_sujeto
                      ? res.incidencias_v2[i].edad_sujeto
                      : '';
                    let permiso_trabajo_sujeto = res.incidencias_v2[i].permiso_trabajo_sujeto
                      ? res.incidencias_v2[i].permiso_trabajo_sujeto
                      : '';
                    let quien_otorgo_permiso = res.incidencias_v2[i].quien_otorgo_permiso
                      ? res.incidencias_v2[i].quien_otorgo_permiso
                      : '';
                    let asiste_escuela = res.incidencias_v2[i].asiste_escuela
                      ? res.incidencias_v2[i].asiste_escuela
                      : '';
                    let grado_sujeto = res.incidencias_v2[i].grado_sujeto
                      ? res.incidencias_v2[i].grado_sujeto
                      : '';
                    let nombre_escuela = res.incidencias_v2[i].nombre_escuela
                      ? res.incidencias_v2[i].nombre_escuela
                      : '';

                    if (edad_sujeto !== 16 && edad_sujeto !== 17) {
                      permiso_trabajo_sujeto = 'No aplica';
                      quien_otorgo_permiso = 'No aplica';
                    }

                    if (asiste_escuela === 'No') {
                      grado_sujeto = 'No aplica';
                      nombre_escuela = 'No aplica';
                    }

                    // No se puede dialogar
                    if (!res.incidencias_v2[i].posible_dialogar) {
                      nombre_sujeto = 'No aplica';
                      genero_sujeto = 'No aplica';
                      localidad_sujeto = 'No aplica';
                      edad_sujeto = res.incidencias_v2[i].edad_aproximada_sujeto
                        ? res.incidencias_v2[i].edad_aproximada_sujeto
                        : '';
                      permiso_trabajo_sujeto = 'No aplica';
                      quien_otorgo_permiso = 'No aplica';
                      asiste_escuela = 'No aplica';
                      grado_sujeto = 'No aplica';
                      nombre_escuela = 'No aplica';
                    }

                    let sujeto_acompaniado = 'No';
                    let parentezco_acompanante = 'No aplica';
                    if (res.incidencias_v2[i].sujeto_acompaniado) {
                      sujeto_acompaniado = 'Si';
                      parentezco_acompanante = res.incidencias_v2[i].parentezco_acompanante;
                    }

                    let descripcion_situacion = res.incidencias_v2[i].descripcion_situacion
                      ? res.incidencias_v2[i].descripcion_situacion
                      : '';
                    let acciones_empresa = res.incidencias_v2[i].acciones_empresa
                      ? res.incidencias_v2[i].acciones_empresa
                      : '';
                    let observaciones_adicionales = res.incidencias_v2[i].observaciones_adicionales
                      ? res.incidencias_v2[i].observaciones_adicionales
                      : '';

                    let alta_ta_asociado = 'No';
                    let monitoreo_lote_asociado = 'No';
                    let lote_id = 'No aplica';
                    let lote_nombre = 'No aplica';
                    if (res.incidencias_v2[i].alta_ta_asociado) {
                      alta_ta_asociado = 'Si';
                    }

                    if (res.incidencias_v2[i].monitoreo_lote_asociado) {
                      monitoreo_lote_asociado = 'Si';
                      lote_id = res.incidencias_v2[i].lote_id.id;
                      lote_nombre = res.incidencias_v2[i].lote_id.nombre;
                    }

                    if (!loaded) {
                      // Default
                      row = [
                        res.incidencias_v2[i].id || '', //'ID',
                        res.incidencias_v2[i].registrado_usuario_id.id, //'ID Usuario',
                        res.incidencias_v2[i].registrado_usuario_id.first_name, //'Nombre Usuario',
                        res.incidencias_v2[i].registrado_usuario_id.last_name, //'Apellido Usuario',
                        empresaNombre,
                        res.incidencias_v2[i].fecha_horario || '', //'Fecha y Hora de Incidencia',
                        res.incidencias_v2[i].dentro_o_fuera_del_campo,
                        res.incidencias_v2[i].tipo_de_situacion || '', //'Tipo de situacion',
                        alta_ta_asociado, // '¿Asociada a alta de TA?',
                        monitoreo_lote_asociado, // '¿Asociada a Monitoreo de Lote?',
                        lote_id, // 'ID Lote',
                        lote_nombre, // 'Nombre Lote',
                        res.incidencias_v2[i].provincia || '',
                        res.incidencias_v2[i].posible_dialogar ? 'Si' : 'No',
                        nombre_sujeto,
                        genero_sujeto,
                        localidad_sujeto,
                        edad_sujeto,
                        permiso_trabajo_sujeto,
                        quien_otorgo_permiso,
                        asiste_escuela,
                        grado_sujeto,
                        nombre_escuela,
                        sujeto_acompaniado,
                        parentezco_acompanante,
                        descripcion_situacion,
                        acciones_empresa,
                        observaciones_adicionales,
                      ];

                      loaded = true;
                    }

                    if (row.length > 0) {
                      arr.push(row);
                    }
                  }
                }
              }

              let sheet = {
                name: 'Situaciones Riesgo TI',
                data: arr,
              };

              resolve(sheet);
            });
          });
        };

        arrProm.push(_createIncidenciasV2Sheet(empresa));
      }

      if (_types[i] === 'horas_trabajadas') {
        arrProm.push(createHorasTrabajadasSheet(empresa));
      }

      if (_types[i] === 'ingresos_egresos') {
        arrProm.push(createIngresosEgresosSheet(empresa));
      }

      if (_types[i] === 'logs_usuarios_externos') {
        arrProm.push(createLogsUsuariosExternosSheet(empresa));
      }
    }
  } else {
    arrProm.push(createAdolescentesSheet(empresa));
    arrProm.push(createControlesDocumentacionSheet(empresa));
    arrProm.push(createControlesLotesSheet(empresa));
    arrProm.push(createControlesEntregaFrutaSheet(empresa));
    arrProm.push(createIncidenciasSheet(empresa));
    arrProm.push(createHorasTrabajadasSheet(empresa));
    arrProm.push(createIngresosEgresosSheet(empresa));
    arrProm.push(createLogsUsuariosExternosSheet(empresa));
  }

  Promise.all(arrProm).then((sheets) => {
    console.log('Promise All de ExportAppData:');
    console.log(sheets);

    exportXLSX(sheets);
  });
};

export const exportDataEmpresa = (_empresaId) => {
  return new Promise((resolve, reject) => {
    var headers = {
      adolescentes: [
        'ID',
        'DNI',
        'UID RENATRE',
        'Nombre',
        'Apellido',
        'Fecha de nacimiento',
        'Genero',
        'Documento respaldatorio 1',
        'Documento respaldatorio 2',
        'Documento respaldatorio 3',
        'Empresa',
        'Contratista',
        'Responsable a cargo',
        'Asiste con familia',
        'Asiste a la escuela',
        'Asiste a cursos de formacion',
      ],

      control_documentacion: [
        'ID',
        'DNI Usuario',
        'Nombre Usuario',
        'Apellido Usuario',
        'Empresa',
        'Contratista',
        'Fecha y Hora del Control',
        'DNI Adolescente',
        '¿Se le permitió subir al transporte?',
      ],

      controles_lotes: [
        'ID',
        'Lote ID',
        'Nombre Lote',
        'Geolocalizacion',
        'Contratista',
        'Capataz',
        'Fecha y Hora del Control',
        '¿Se realizo cosecha?',
        'Motivo no cosecha',
        'DNI controlado 1',
        'DNI controlado 2',
        'DNI controlado 3',
        'DNI controlado 4',
        'DNI controlado 5',
        '¿Se registro caso sospechoso?',
        'Observaciones',
      ],

      controles_entrega_fruta: [
        'ID',
        'Lote ID',
        'Nombre Lote',
        'Geolocalizacion',
        'Contratista',
        'Capataz',
        'Fecha y Hora del Control',
        '¿Se realizo cosecha?',
        'Motivo no cosecha',
        'DNI controlado 1',
        'DNI controlado 2',
        'DNI controlado 3',
        'DNI controlado 4',
        'DNI controlado 5',
        '¿Se registro caso sospechoso?',
        'Observaciones',
      ],

      incidencias: [
        'ID',
        'ID Usuario',
        'Nombre Usuario',
        'Apellido Usuario',
        'Empresa',
        'Fecha y Hora de Incidencia',
        'Tipo de incidencia',
        '¿Asociada a alta de TA?',
        '¿Asociada a Monitoreo de Lote?',
        'ID Lote',
        'Nombre Lote',
        'Nombre Sujeto',
        'DNI Sujeto',
        'Edad Sujeto',
        'Género Sujeto',
        'Lugar de Nacimiento Sujeto',
        '¿Acompañaba a adulto?',
        'Responsable Empresa',
        'Observaciones',
      ],

      incidencias_v2: [
        'ID',
        'ID Usuario',
        'Nombre Usuario',
        'Apellido Usuario',
        'Empresa',
        'Fecha y Hora del Registro',
        '¿Dentro o fuera del campo?',
        'Tipo de Situación',
        '¿Asociada a alta de TA?',
        '¿Asociada a Monitoreo de Lote?',
        'ID Lote',
        'Nombre Lote',
        'Provincia',
        '¿Es posible dialogar con el sujeto?',
        'Nombre del sujeto',
        'Género del sujeto',
        'Localidad del sujeto',
        'Edad del sujeto',
        '¿Cuenta con permiso para trabajar?',
        '¿Quién otorgó el permiso?',
        '¿Asiste a la escuela?',
        '¿A qué grado va?',
        'Nombre de la escuela',
        '¿Se encuentra con acompañante?', // sujeto_acompaniado
        'Parentezco del acompañante', // parentezco_acompanante
        'Descripción de situación', //descripcion_situacion
        'Acciones que tomó la empresa', // acciones_empresa
        'Observaciones adicionales', // observaciones_adicionales
        // alta_ta_asociado
        // usuario_alta_ta
        // monitoreo_lote_asociado
        // lote_id
        //x edad_aproximada_sujeto
      ],

      contratistas: ['ID', 'Nombre', 'Empresa'],

      control_ta: [
        'ID',
        'Nombre Usuario',
        'Apellido Usuario',
        'Empresa',
        'Fecha y Hora',
        'Geolocalización',
        'Nombre TA',
        'Apellido TA',
        'DNI TA',
        '¿Se encuentra en su lugar de trabajo?',
        'Tareas Realizadas',
        '¿Brazos por arriba de cabeza?',
        '¿Está agachado/a?',
        '¿Está expuesto/a a altas temperaturas?',
        '¿Está expuesto/a a bajas temperaturas?',
        '¿Fue alguien a controlar?',
        '¿Hay agua potable disponible?',
        '¿Levanta más de 5kg?',
        '¿Requiere protección?',
        '¿Utiliza protección?',
        '¿Utiliza agroquímicos?',
        '¿Utiliza herramientas peligrosas?',
        '¿Utiliza maquinaria?',
        '¿Utiliza identificación?',
        'Observaciones',
      ],

      empresas: ['ID', 'Nombre'],

      lotes: ['ID', 'Empresa', 'Nombre'],

      ingresos_egresos: [
        'ID',
        'Usuario Nombre',
        'Usuario Apellido',
        'Empresa',
        'Tipo de Registro',
        'Fecha y Hora',
        'Geolocalización',
        'Lugar',
        'TA Nombre',
        'TA Apellido',
        'TA DNI',
        'Capataz a cargo',
      ],

      controles_ta_ue: [
        'ID',
        'DNI Usuario Externo',
        'Contratista',
        'Empresa',
        'Fecha y Hora',
        'Geolocalización',
        'Semana',
        'Lote',
        'Nombre TA',
        'Apellido TA',
        'DNI TA',
        '¿Hay acceso a agua potable?',
        '¿Fue alguien a controlar?',
        'Tareas Realizadas',
        '¿Utiliza herramientas peligrosas o maquinaria?',
        '¿Utiliza protección?',
        'Observaciones',
      ],

      actividad: [
        'ID', // id: "2147"
        'Fecha y Hora', // fecha_hora: "2020-09-17 13:43:14"
        'Tipo', // collection: "control_documentacion"
        'Acción', // action: "create"
        'Detalle',
        'Usuario ID', // xxxx_usuario_id
        'Usuario Nombre', // xxxx_nombre_usuario
        'Usuario Apellido', // xxxx_apellido_usuario
        'Usuario Externo DNI', // xxxx_ue_dni
        'Empresa', // xxxx_nombre_empresa
        'IP', // ip
        //adolescentes_apellido_usuario: null
        //adolescentes_id: null
        //adolescentes_nombre_usuario: null
        //adolescentes_usuario_id: null

        //control_documentacion_id: "157"
        //control_documentacion_ue_dni: "123902392"
        //control_lotes_apellido_usuario: null
        //control_lotes_id: null
        //control_lotes_nombre_usuario: null
        //control_lotes_usuario_id: null
        //control_ta_apellido_usuario: null
        //control_ta_id: null
        //control_ta_nombre_usuario: null
        //control_ta_usuario_id: null
        //control_ta_usuarios_externos_id: null
        //control_ta_usuarios_externos_ue_dni: null
        //directus_users_apellido_usuario: null
        //directus_users_id: null
        //directus_users_nombre_usuario: null
        //directus_users_usuario_id: null

        //incidencias_apellido_usuario: null
        //incidencias_id: null
        //incidencias_nombre_usuario: null
        //incidencias_ue_dni: null
        //incidencias_usuario_id: null
        //ingresos_egresos_apellido_usuario: null
        //ingresos_egresos_id: null
        //ingresos_egresos_nombre_usuario: null
        //ingresos_egresos_usuario_id: null
        //ip: "181.46.139.8"
        //validaciones_apellido_usuario: null
        //validaciones_id: null
        //validaciones_nombre_usuario: null
        //validaciones_usuario_id: null
      ],
    };

    var INCLUDED_EMPRESAS = [];

    INCLUDED_EMPRESAS.push(parseInt(_empresaId));

    var _createAdolescentesSheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';

        getItemsFromCollection('adolescentes', true, '*.*', filter).then((res) => {
          //apellido: "Ramirez"
          //asiste_con_familia: true
          //asiste_escuela: true
          //asiste_formacion: true
          //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
          //dni: 33241362
          //documentacion_respaldatoria_1: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
          //documentacion_respaldatoria_2: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
          //documentacion_respaldatoria_3: {id: 1, storage: "local", private_hash: "t7lcn50856o4o4o8", filename_disk: "e0f161f7-46a5-4556-8f68-70b2398025dc.png", filename_download: "Sin título.png", …}
          //empresa_id: {id: 1, nombre: "Empresa Modelo"}
          //fecha_nacimiento: "2003-12-12"
          //genero: "hombre"
          //id: 32
          //nombre: "José"
          //responsable_empresa_a_cargo: "Hector Lopez"
          //uid_renatre: null
          //usuario_aprueba_alta_id

          let arr = [];
          arr.push(headers['adolescentes']);
          for (let i = 0; i < res.adolescentes.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.adolescentes[i].empresa_id.id)) {
              let row = [
                res.adolescentes[i].id || '', //'ID',
                res.adolescentes[i].dni || '', //'DNI',
                res.adolescentes[i].uid_renatre || '', //'UID RENATRE',
                res.adolescentes[i].nombre || '', //'Nombre',
                res.adolescentes[i].apellido || '', //'Apellido',
                res.adolescentes[i].fecha_nacimiento || '', //'Fecha de nacimiento',
                res.adolescentes[i].genero || '', //'Genero',
                res.adolescentes[i].documentacion_respaldatoria_1
                  ? res.adolescentes[i].documentacion_respaldatoria_1.data.full_url
                  : '', //'Documento respaldatorio 1',
                res.adolescentes[i].documentacion_respaldatoria_2
                  ? res.adolescentes[i].documentacion_respaldatoria_2.data.full_url
                  : '', //'Documento respaldatorio 2',
                res.adolescentes[i].documentacion_respaldatoria_3
                  ? res.adolescentes[i].documentacion_respaldatoria_3.data.full_url
                  : '', //'Documento respaldatorio 3',
                res.adolescentes[i].empresa_id ? res.adolescentes[i].empresa_id.nombre : '', //'Empresa',
                res.adolescentes[i].contratista_id ? res.adolescentes[i].contratista_id.nombre : '', //'Contratista',
                res.adolescentes[i].responsable_empresa_a_cargo || '', //'Responsable a cargo',
                res.adolescentes[i].asiste_con_familia ? 'Si' : 'No', //'Asiste con familia',
                res.adolescentes[i].asiste_escuela ? 'Si' : 'No', //'Asiste a la escuela',
                res.adolescentes[i].asiste_formacion ? 'Si' : 'No', //'Asiste a cursos de formacion'
              ];

              arr.push(row);
            }
          }

          let sheet = {
            name: 'Trabajadores Adolescentes',
            data: arr,
          };

          resolve(sheet);
        });
      });
    };

    var _createControlesDocumentacionSheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = _empresaId ? 'filter[empresa_id][eq]=' + _empresaId : '';

        getItemsFromCollection('control_documentacion', true, '*.*', filter).then((res) => {
          //adolescente_habilitado: true
          //apellido_usuario_externo: "Ejemplo"
          //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
          //dni_adolescente: 33241362
          //dni_usuario_externo: 11111111
          //empresa_id: {id: 1, nombre: "Empresa Modelo"}
          //fecha_horario: "2020-09-17 10:40:54"
          //id: 155
          //nombre_usuario_externo: "Transportista"

          let arr = [];
          arr.push(headers['control_documentacion']);
          for (let i = 0; i < res.control_documentacion.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.control_documentacion[i].empresa_id.id)) {
              let row = [
                res.control_documentacion[i].id || '', //'ID',
                res.control_documentacion[i].dni_usuario_externo || '', //'DNI Usuario',
                res.control_documentacion[i].nombre_usuario_externo || '', //'Nombre Usuario',
                res.control_documentacion[i].apellido_usuario_externo || '', //'Apellido Usuario',
                res.control_documentacion[i].empresa_id
                  ? res.control_documentacion[i].empresa_id.nombre
                  : '', //'Empresa',
                res.control_documentacion[i].contratista_id
                  ? res.control_documentacion[i].contratista_id.nombre
                  : '', //'Contratista',
                res.control_documentacion[i].fecha_horario || '', //'Fecha y Hora del Control',
                res.control_documentacion[i].dni_adolescente || '', //'DNI Adolescente',
                res.control_documentacion[i].adolescente_habilitado ? 'Si' : 'No', //'¿Se permitio ingreso?'
              ];

              arr.push(row);
            }
          }

          let sheet = {
            name: 'Controles Documentacion',
            data: arr,
          };

          resolve(sheet);
        });
      });
    };

    var _createLogsUsuariosExternosSheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = _empresaId
          ? 'filter[contratista_id.empresa_asociada_id][eq]=' + _empresaId
          : '';

        getItemsFromCollection('logs_usuarios_externos', true, '*.*', filter).then((res) => {
          console.log(res.logs_usuarios_externos);

          //capataz: "Lopez"
          //caso_sospechoso: false
          //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
          //control_dni: true
          //fecha_horario: "2020-09-17 12:40:06"
          //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
          //hay_cosecha: true
          //id: 30
          //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
          //observaciones: "Observaciones"
          //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

          let arr = [];
          arr.push(HEADERS['logs_usuarios_externos']);
          for (let i = 0; i < res.logs_usuarios_externos.length; i++) {
            let row = [
              res.logs_usuarios_externos[i].id || '', //'ID',
              res.logs_usuarios_externos[i].fecha_horario || '', //'Fecha y Hora del Control',
              res.logs_usuarios_externos[i].contratista_id
                ? res.logs_usuarios_externos[i].contratista_id.nombre
                : '', //'Contratista',
              res.logs_usuarios_externos[i].dni || '', //'DNI',
              res.logs_usuarios_externos[i].nombre || '', //'Nombre',
              res.logs_usuarios_externos[i].apellido || '', //'Apellido',
            ];

            arr.push(row);
          }

          let sheet = {
            name: 'Logins Usuarios Externos',
            data: arr,
          };

          resolve(sheet);
          //dataExport(arr);

          //exportXLSX(res.adolescentes[0].empresa_id.nombre, arr);
        });
      });
    };

    var _createControlesLotesSheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = _empresaId
          ? 'filter[contratista_id.empresa_asociada_id][eq]=' + _empresaId
          : '';

        getItemsFromCollection('control_lotes', true, '*.*.*', filter).then((res) => {
          //capataz: "Lopez"
          //caso_sospechoso: false
          //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
          //control_dni: true
          //fecha_horario: "2020-09-17 12:40:06"
          //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
          //hay_cosecha: true
          //id: 30
          //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
          //observaciones: "Observaciones"
          //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

          let arr = [];
          arr.push(headers['controles_lotes']);
          for (let i = 0; i < res.control_lotes.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.control_lotes[i].lote_id.empresa_id.id)) {
              let row = [
                res.control_lotes[i].id || '', //'ID',
                res.control_lotes[i].lote_id ? res.control_lotes[i].lote_id.id : '', //'Lote ID',
                res.control_lotes[i].lote_id ? res.control_lotes[i].lote_id.nombre : '', //'Nombre Lote',
                res.control_lotes[i].geo_lat_long || '', //'Geolocalizacion',
                res.control_lotes[i].contratista_id
                  ? res.control_lotes[i].contratista_id.nombre
                  : '', //'Contratista',
                res.control_lotes[i].capataz || '', //'Capataz',
                res.control_lotes[i].fecha_horario || '', //'Fecha y Hora del Control',
                res.control_lotes[i].hay_cosecha ? 'Si' : 'No', //'¿Se realizo cosecha?',
                res.control_lotes[i].motivo_no_cosecha
                  ? res.control_lotes[i].motivo_no_cosecha
                  : '-',
                res.control_lotes[i].dni_fecha_nacimiento_trabajador_1
                  ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_1
                  : '-',
                res.control_lotes[i].dni_fecha_nacimiento_trabajador_2
                  ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_2
                  : '-',
                res.control_lotes[i].dni_fecha_nacimiento_trabajador_3
                  ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_3
                  : '-',
                res.control_lotes[i].dni_fecha_nacimiento_trabajador_4
                  ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_4
                  : '-',
                res.control_lotes[i].dni_fecha_nacimiento_trabajador_5
                  ? res.control_lotes[i].dni_fecha_nacimiento_trabajador_5
                  : '-',
                res.control_lotes[i].caso_sospechoso ? 'Si' : 'No', //'¿Se registro caso sospechoso?',
                res.control_lotes[i].observaciones || '', //'Observaciones'
              ];

              arr.push(row);
            }
          }

          let sheet = {
            name: 'Controles Lotes',
            data: arr,
          };

          resolve(sheet);
        });
      });
    };

    var _createControlesEntregaFrutaSheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = _empresaId
          ? 'filter[contratista_id.empresa_asociada_id][eq]=' + _empresaId
          : '';

        getItemsFromCollection('control_entrega_fruta', true, '*.*.*', filter).then((res) => {
          //capataz: "Lopez"
          //caso_sospechoso: false
          //contratista_id: {id: 30, empresa_asociada_id: 1, nombre: "Contratista Modelo A"}
          //control_dni: true
          //fecha_horario: "2020-09-17 12:40:06"
          //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
          //hay_cosecha: true
          //id: 30
          //lote_id: {id: 158, empresa_id: 1, nombre: "LOTE MODELO 2"}
          //observaciones: "Observaciones"
          //registrado_usuario_id: {id: 7, status: "active", role: 4, first_name

          let arr = [];
          arr.push(headers['controles_entrega_fruta']);
          for (let i = 0; i < res.control_entrega_fruta.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.control_entrega_fruta[i].lote_id.empresa_id.id)) {
              let row = [
                res.control_entrega_fruta[i].id || '', //'ID',
                res.control_entrega_fruta[i].lote_id ? res.control_entrega_fruta[i].lote_id.id : '', //'Lote ID',
                res.control_entrega_fruta[i].lote_id
                  ? res.control_entrega_fruta[i].lote_id.nombre
                  : '', //'Nombre Lote',
                res.control_entrega_fruta[i].lote_id.empresa_id.nombre, // Empresa
                res.control_entrega_fruta[i].geo_lat_long || '', //'Geolocalizacion',
                res.control_entrega_fruta[i].contratista_id
                  ? res.control_entrega_fruta[i].contratista_id.nombre
                  : '', //'Contratista',
                res.control_entrega_fruta[i].capataz || '', //'Capataz',
                res.control_entrega_fruta[i].fecha_horario || '', //'Fecha y Hora del Control',
                res.control_entrega_fruta[i].hay_cosecha ? 'Si' : 'No', //'¿Se realizo cosecha?',
                res.control_entrega_fruta[i].motivo_no_cosecha
                  ? res.control_entrega_fruta[i].motivo_no_cosecha
                  : '-',
                res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_1
                  ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_1
                  : '-',
                res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_2
                  ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_2
                  : '-',
                res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_3
                  ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_3
                  : '-',
                res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_4
                  ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_4
                  : '-',
                res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_5
                  ? res.control_entrega_fruta[i].dni_fecha_nacimiento_trabajador_5
                  : '-',
                res.control_entrega_fruta[i].caso_sospechoso ? 'Si' : 'No', //'¿Se registro caso sospechoso?',
                res.control_entrega_fruta[i].observaciones || '', //'Observaciones'
              ];

              arr.push(row);
            }
          }

          let sheet = {
            name: 'Controles Ptos Ega de Fruta',
            data: arr,
          };

          resolve(sheet);
        });
      });
    };

    var _createIncidenciasV2Sheet = (_empresaId) => {
      return new Promise((resolve, reject) => {
        // Si _empresaId no esta definido, busco de todas las empresas
        let filter = '';

        getItemsFromCollection('incidencias_v2', true, '*.*.*', filter).then((res) => {
          // registrado_usuario_id
          // dni_usuario_externo
          // fecha_horario	datetime
          // dentro_o_fuera_del_campo
          // tipo_de_situacion
          // provincia
          // posible_dialogar
          // nombre_sujeto
          // genero_sujeto
          // localidad_sujeto
          // edad_sujeto
          // permiso_trabajo_sujeto
          // quien_otorgo_permiso
          // asiste_escuela
          // grado_sujeto
          // nombre_escuela
          // descripcion_situacion
          // sujeto_acompaniado
          // parentezco_acompanante
          // alta_ta_asociado
          // usuario_alta_ta
          // monitoreo_lote_asociado
          // lote_id
          // edad_aproximada_sujeto
          // acciones_empresa
          // observaciones_adicionales
          console.log('DEBUGGGGGGGGGG', res);
          let arr = [];
          arr.push(headers['incidencias_v2']);
          for (let i = 0; i < res.incidencias_v2.length; i++) {
            // Obtengo nombre de la empresa
            let empresaNombre = '';
            let empresaIdThis = null;
            if (res.incidencias_v2[i].empresa_id) {
              empresaNombre = res.incidencias_v2[i].empresa_id.nombre;
              empresaIdThis = res.incidencias_v2[i].empresa_id.id;
            }

            if (res.incidencias_v2[i].contratista_id) {
              empresaNombre = res.incidencias_v2[i].contratista_id.empresa_asociada_id.nombre;
              empresaIdThis = res.incidencias_v2[i].contratista_id.empresa_asociada_id.id;
            }

            if (INCLUDED_EMPRESAS.includes(empresaIdThis)) {
              if (
                (res.incidencias_v2[i].registrado_usuario_id &&
                  parseInt(_empresaId) === empresaIdThis) ||
                true
              ) {
                let loaded = false;
                let row = [];

                let nombre_sujeto = res.incidencias_v2[i].nombre_sujeto
                  ? res.incidencias_v2[i].nombre_sujeto
                  : '';
                let genero_sujeto = res.incidencias_v2[i].genero_sujeto
                  ? res.incidencias_v2[i].genero_sujeto
                  : '';
                let localidad_sujeto = res.incidencias_v2[i].localidad_sujeto
                  ? res.incidencias_v2[i].localidad_sujeto
                  : '';
                let edad_sujeto = res.incidencias_v2[i].edad_sujeto
                  ? res.incidencias_v2[i].edad_sujeto
                  : '';
                let permiso_trabajo_sujeto = res.incidencias_v2[i].permiso_trabajo_sujeto
                  ? res.incidencias_v2[i].permiso_trabajo_sujeto
                  : '';
                let quien_otorgo_permiso = res.incidencias_v2[i].quien_otorgo_permiso
                  ? res.incidencias_v2[i].quien_otorgo_permiso
                  : '';
                let asiste_escuela = res.incidencias_v2[i].asiste_escuela
                  ? res.incidencias_v2[i].asiste_escuela
                  : '';
                let grado_sujeto = res.incidencias_v2[i].grado_sujeto
                  ? res.incidencias_v2[i].grado_sujeto
                  : '';
                let nombre_escuela = res.incidencias_v2[i].nombre_escuela
                  ? res.incidencias_v2[i].nombre_escuela
                  : '';

                if (edad_sujeto !== 16 && edad_sujeto !== 17) {
                  permiso_trabajo_sujeto = 'No aplica';
                  quien_otorgo_permiso = 'No aplica';
                }

                if (asiste_escuela === 'No') {
                  grado_sujeto = 'No aplica';
                  nombre_escuela = 'No aplica';
                }

                // No se puede dialogar
                if (!res.incidencias_v2[i].posible_dialogar) {
                  nombre_sujeto = 'No aplica';
                  genero_sujeto = 'No aplica';
                  localidad_sujeto = 'No aplica';
                  edad_sujeto = res.incidencias_v2[i].edad_aproximada_sujeto
                    ? res.incidencias_v2[i].edad_aproximada_sujeto
                    : '';
                  permiso_trabajo_sujeto = 'No aplica';
                  quien_otorgo_permiso = 'No aplica';
                  asiste_escuela = 'No aplica';
                  grado_sujeto = 'No aplica';
                  nombre_escuela = 'No aplica';
                }

                let sujeto_acompaniado = 'No';
                let parentezco_acompanante = 'No aplica';
                if (res.incidencias_v2[i].sujeto_acompaniado) {
                  sujeto_acompaniado = 'Si';
                  parentezco_acompanante = res.incidencias_v2[i].parentezco_acompanante;
                }

                let descripcion_situacion = res.incidencias_v2[i].descripcion_situacion
                  ? res.incidencias_v2[i].descripcion_situacion
                  : '';
                let acciones_empresa = res.incidencias_v2[i].acciones_empresa
                  ? res.incidencias_v2[i].acciones_empresa
                  : '';
                let observaciones_adicionales = res.incidencias_v2[i].observaciones_adicionales
                  ? res.incidencias_v2[i].observaciones_adicionales
                  : '';

                let alta_ta_asociado = 'No';
                let monitoreo_lote_asociado = 'No';
                let lote_id = 'No aplica';
                let lote_nombre = 'No aplica';
                if (res.incidencias_v2[i].alta_ta_asociado) {
                  alta_ta_asociado = 'Si';
                }

                if (res.incidencias_v2[i].monitoreo_lote_asociado) {
                  monitoreo_lote_asociado = 'Si';
                  lote_id = res.incidencias_v2[i].lote_id.id;
                  lote_nombre = res.incidencias_v2[i].lote_id.nombre;
                }

                if (!loaded) {
                  // Default
                  row = [
                    res.incidencias_v2[i].id || '', //'ID',
                    res.incidencias_v2[i].registrado_usuario_id.id, //'ID Usuario',
                    res.incidencias_v2[i].registrado_usuario_id.first_name, //'Nombre Usuario',
                    res.incidencias_v2[i].registrado_usuario_id.last_name, //'Apellido Usuario',
                    empresaNombre,
                    res.incidencias_v2[i].fecha_horario || '', //'Fecha y Hora de Incidencia',
                    res.incidencias_v2[i].dentro_o_fuera_del_campo,
                    res.incidencias_v2[i].tipo_de_situacion || '', //'Tipo de situacion',
                    alta_ta_asociado, // '¿Asociada a alta de TA?',
                    monitoreo_lote_asociado, // '¿Asociada a Monitoreo de Lote?',
                    lote_id, // 'ID Lote',
                    lote_nombre, // 'Nombre Lote',
                    res.incidencias_v2[i].provincia || '',
                    res.incidencias_v2[i].posible_dialogar ? 'Si' : 'No',
                    nombre_sujeto,
                    genero_sujeto,
                    localidad_sujeto,
                    edad_sujeto,
                    permiso_trabajo_sujeto,
                    quien_otorgo_permiso,
                    asiste_escuela,
                    grado_sujeto,
                    nombre_escuela,
                    sujeto_acompaniado,
                    parentezco_acompanante,
                    descripcion_situacion,
                    acciones_empresa,
                    observaciones_adicionales,
                  ];

                  loaded = true;
                }

                if (row.length > 0) {
                  arr.push(row);
                }
              }
            }
          }

          let sheet = {
            name: 'Situaciones Riesgo TI',
            data: arr,
          };

          resolve(sheet);
        });
      });
    };

    var _createContratistasSheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('contratistas', true, '*.*.*', '').then((res) => {
          // empresa_asociada_id: {id: 6, nombre: "Extraberries"}
          // id: 15
          // nombre: "

          var data = [];
          data.push(headers['contratistas']);
          for (var i = 0; i < res.contratistas.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.contratistas[i].empresa_asociada_id.id)) {
              var c = [
                res.contratistas[i].id,
                res.contratistas[i].nombre,
                res.contratistas[i].empresa_asociada_id.nombre,
              ];

              data.push(c);
            }
          }

          var sheet = {
            name: 'Contratistas',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var _createControlesTaSheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('control_ta', true, '*.*.*', '').then((res) => {
          //adolescente_id: {id: 33, empresa_id: {…}, nombre: "Lucia", apellido: "Lopez", asiste_escuela: false, …}
          //brazos_arriba: false
          //empresa_id: {id: 1, nombre: "Empresa Modelo"}
          //esta_agachado: false
          //expuesto_alta_temp: false
          //expuesto_baja_temp: false
          //fecha_horario: "2020-09-17 12:38:01"
          //fue_alguien: false
          //geo_lat_long: "lat:-34.6225973,lng:-58.4385418"
          //hay_agua: false
          //id: 37
          //levanta_mas_5k: false
          //lugar_de_trabajo: true
          //observaciones: "Observaciones "
          //registrado_usuario_id: {id: 7, status: "active", role: {…}, first_name: "Empleado", last_name: "Prueba", …}
          //requiere_proteccion: true
          //tareas_realizadas: "Control de peso de las bandejas|Identificación de plagas y enfermedades"
          //utiliza_agroquimicos: false
          //utiliza_herramientas_peligrosas: false
          //utiliza_identificacion: true
          //utiliza_maquinaria: true
          //utiliza_proteccion: true

          var data = [];
          data.push(headers['control_ta']);
          for (var i = 0; i < res.control_ta.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.control_ta[i].empresa_id.id)) {
              data.push([
                res.control_ta[i].id, //'ID',
                res.control_ta[i].registrado_usuario_id
                  ? res.control_ta[i].registrado_usuario_id.first_name
                  : '', //'Nombre Usuario',
                res.control_ta[i].registrado_usuario_id
                  ? res.control_ta[i].registrado_usuario_id.last_name
                  : '', //'Apellido Usuario',
                res.control_ta[i].empresa_id ? res.control_ta[i].empresa_id.nombre : '', //'Empresa',
                res.control_ta[i].fecha_horario ? res.control_ta[i].fecha_horario : '', //'Fecha y Hora',
                res.control_ta[i].geo_lat_long ? res.control_ta[i].geo_lat_long : '', //'Geolocalización',
                res.control_ta[i].adolescente_id ? res.control_ta[i].adolescente_id.dni : '', //'DNI TA',
                res.control_ta[i].adolescente_id ? res.control_ta[i].adolescente_id.nombre : '', //'Nombre TA',
                res.control_ta[i].adolescente_id ? res.control_ta[i].adolescente_id.apellido : '', //'Apellido TA',
                res.control_ta[i].lugar_de_trabajo ? 'Si' : 'No', //'¿Se encuentra en su lugar de trabajo?',
                res.control_ta[i].tareas_realizadas ? res.control_ta[i].tareas_realizadas : '', //'Tareas Realizadas',
                res.control_ta[i].brazos_arriba ? 'Si' : 'No', //'¿Brazos por arriba de cabeza?',
                res.control_ta[i].esta_agachado ? 'Si' : 'No', //'¿Está agachado/a?',
                res.control_ta[i].expuesto_alta_temp ? 'Si' : 'No', //'¿Está expuesto/a a altas temperaturas?',
                res.control_ta[i].expuesto_baja_temp ? 'Si' : 'No', //'¿Está expuesto/a a bajas temperaturas?',
                res.control_ta[i].fue_alguien ? 'Si' : 'No', //'¿Fue alguien a controlar?',
                res.control_ta[i].hay_agua ? 'Si' : 'No', //'¿Hay agua potable disponible?',
                res.control_ta[i].levanta_mas_5k ? 'Si' : 'No', //'¿Levanta más de 5kg?',
                res.control_ta[i].requiere_proteccion ? 'Si' : 'No', //'¿Requiere protección?',
                res.control_ta[i].utiliza_proteccion ? 'Si' : 'No', //'¿Utiliza protección?',
                res.control_ta[i].utiliza_agroquimicos ? 'Si' : 'No', //'¿Utiliza agroquímicos?',
                res.control_ta[i].utiliza_herramientas_peligrosas ? 'Si' : 'No', //'¿Utiliza herramientas peligrosas?',
                res.control_ta[i].utiliza_maquinaria ? 'Si' : 'No', //'¿Utiliza maquinaria?',
                res.control_ta[i].utiliza_identificacion ? 'Si' : 'No', //'¿Utiliza identificación?',
                res.control_ta[i].observaciones ? res.control_ta[i].observaciones : '', //'Observaciones'
              ]);
            }
          }

          var sheet = {
            name: 'Controles TA',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var _createEmpresasSheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('empresas', true, '*.*.*', '').then((res) => {
          var data = [];
          data.push(headers['empresas']);
          for (var i = 0; i < res.empresas.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.empresas[i].id)) {
              data.push([res.empresas[i].id, res.empresas[i].nombre]);
            }
          }

          var sheet = {
            name: 'Empresas',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var _createLotesSheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('lotes', true, '*.*.*', '').then((res) => {
          var data = [];
          data.push(headers['lotes']);
          for (var i = 0; i < res.lotes.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.lotes[i].empresa_id.id)) {
              data.push([res.lotes[i].id, res.lotes[i].empresa_id.nombre, res.lotes[i].nombre]);
            }
          }

          var sheet = {
            name: 'Lotes',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var _createIngresosEgresosSheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('ingresos_egresos', true, '*.*.*', '').then((res) => {
          var data = [];
          data.push(headers['ingresos_egresos']);
          for (var i = 0; i < res.ingresos_egresos.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.ingresos_egresos[i].empresa_id.id)) {
              data.push([
                res.ingresos_egresos[i].id, //'ID',
                res.ingresos_egresos[i].registrado_usuario_id.first_name, //'Usuario Nombre',
                res.ingresos_egresos[i].registrado_usuario_id.last_name, //'Usuario Apellido',
                res.ingresos_egresos[i].empresa_id.nombre, //'Empresa',
                res.ingresos_egresos[i].tipo, //'Tipo de Registro',
                res.ingresos_egresos[i].fecha_horario, //'Fecha y Hora',
                res.ingresos_egresos[i].geo_lat_long, //'Geolocalización',
                res.ingresos_egresos[i].lugar, //'Lugar',
                res.ingresos_egresos[i].adolescente_id.nombre, //'TA Nombre',
                res.ingresos_egresos[i].adolescente_id.apellido, //'TA Apellido',
                res.ingresos_egresos[i].adolescente_id.dni, //'TA DNI',
                res.ingresos_egresos[i].capataz_a_cargo, //'Capataz a cargo'
              ]);
            }
          }

          var sheet = {
            name: 'Ingresos y Egresos',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var _createControlesTaUESheet = () => {
      return new Promise((resolve, reject) => {
        getItemsFromCollection('control_ta_usuarios_externos', true, '*.*.*', '').then((res) => {
          var data = [];
          data.push(headers['controles_ta_ue']);
          for (var i = 0; i < res.control_ta_usuarios_externos.length; i++) {
            if (INCLUDED_EMPRESAS.includes(res.control_ta_usuarios_externos[i].empresa_id.id)) {
              var c = [
                res.control_ta_usuarios_externos[i].id
                  ? res.control_ta_usuarios_externos[i].id
                  : '', //'ID',
                res.control_ta_usuarios_externos[i].dni_usuario_externo
                  ? res.control_ta_usuarios_externos[i].dni_usuario_externo
                  : '', //'DNI Usuario Externo',
                res.control_ta_usuarios_externos[i].contratista_id
                  ? res.control_ta_usuarios_externos[i].contratista_id.nombre
                  : '', //'Contratista',
                res.control_ta_usuarios_externos[i].empresa_id
                  ? res.control_ta_usuarios_externos[i].empresa_id.nombre
                  : '', //'Empresa',
                res.control_ta_usuarios_externos[i].fecha_horario
                  ? res.control_ta_usuarios_externos[i].fecha_horario
                  : '', //'Fecha y Hora',
                res.control_ta_usuarios_externos[i].geo_lat_long
                  ? res.control_ta_usuarios_externos[i].geo_lat_long
                  : '', //'Geolocalización',
                res.control_ta_usuarios_externos[i].semana
                  ? res.control_ta_usuarios_externos[i].semana
                  : '', //'Semana',
                res.control_ta_usuarios_externos[i].lote_id
                  ? res.control_ta_usuarios_externos[i].lote_id.nombre
                  : '', //'Lote',
                res.control_ta_usuarios_externos[i].adolescente_id
                  ? res.control_ta_usuarios_externos[i].adolescente_id.nombre
                  : '', //'Nombre TA',
                res.control_ta_usuarios_externos[i].adolescente_id
                  ? res.control_ta_usuarios_externos[i].adolescente_id.apellido
                  : '', //'Apellido TA',
                res.control_ta_usuarios_externos[i].adolescente_id
                  ? res.control_ta_usuarios_externos[i].adolescente_id.dni
                  : '', //'DNI TA',
                res.control_ta_usuarios_externos[i].acceso_agua ? 'Si' : 'No', //'¿Hay acceso a agua potable?',
                res.control_ta_usuarios_externos[i].fue_alguien_empresa ? 'Si' : 'No', //'¿Fue alguien a controlar?',
                res.control_ta_usuarios_externos[i].tareas_realizadas
                  ? res.control_ta_usuarios_externos[i].tareas_realizadas
                  : '', //'Tareas Realizadas',
                res.control_ta_usuarios_externos[i].utilizo_herramienta_maquinaria ? 'Si' : 'No', //'¿Utiliza herramientas peligrosas o maquinaria?',
                res.control_ta_usuarios_externos[i].utilizo_proteccion ? 'Si' : 'No', //'¿Utiliza protección?',
                res.control_ta_usuarios_externos[i].observaciones
                  ? res.control_ta_usuarios_externos[i].observaciones
                  : '', //'Observaciones'
              ];

              data.push(c);
            }
          }

          var sheet = {
            name: 'Controles TA (Usuarios Ext)',
            data: data,
          };

          resolve(sheet);
        });
      });
    };

    var arrProm = [
      _createAdolescentesSheet(),
      _createControlesDocumentacionSheet(),
      _createControlesLotesSheet(),
      _createIncidenciasV2Sheet(),
      _createContratistasSheet(),
      _createControlesTaSheet(),
      _createControlesEntregaFrutaSheet(),
      _createEmpresasSheet(),
      _createLotesSheet(),
      _createIngresosEgresosSheet(),
      _createControlesTaUESheet(),
      _createLogsUsuariosExternosSheet(),
    ];

    Promise.all(arrProm).then((sheets) => {
      exportXLSX(sheets);

      resolve();
    });
  });
};
