import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


export default function QuestionTitle (props) {

  const iconEnabled = typeof props.approved === 'undefined' ? false : true;

  return (
    <Grid item xs={12} className="mb1 question-title container">
      <Typography className="accordion subtitle">
        { props.title ? props.title : null }
      </Typography>
      <div>
        {
          iconEnabled ? (
            <React.Fragment>
              {
                props.approved === true ? (
                  <CheckCircleIcon style={{ color: '#55ed55', marginLeft: 25+'px' }}/>
                ) : null
              }
              
              {
                props.approved === false ? (
                  <CancelIcon style={{ color: '#ff5240', marginLeft: 25+'px' }}/>
                ) : null
              }              
            </React.Fragment>
          ) : null
        }
      </div>
    </Grid>
  );
}
