// Devuelve True si esta logeado o False si no lo esta
export const isLoggedIn = () => {
  if (
    localStorage.getItem('usuario_logged_in') === null ||
    localStorage.getItem('usuario_logged_in') === 'null' ||
    localStorage.getItem('usuario_logged_in') === 'false'
  )
    return false;

  return true;
};

// Devuelve True si esta online o False si no lo esta
export const isOnline = () => {
  if (navigator.onLine) return true;

  return false;
};

export const isOnlineV2 = () => {
  return new Promise((resolve, reject) => {
    let url = 'https://webapp-scs.dya.org.ar/checkonline.php';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.ok) resolve(true);
        else resolve(false);
      })
      .catch((err) => resolve(false));
  });
};

// Devuelve True si esta corriendo como App o False si esta en navegador
export const isStandAlone = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) return true;

  return false;
};

// Devuelve usuario_tipo si esta logeado, o null si no lo esta
export const getUserType = () => {
  if (
    localStorage.getItem('usuario_tipo') === null ||
    localStorage.getItem('usuario_tipo') === 'null'
  )
    return null;

  return localStorage.getItem('usuario_tipo');
};

export const setStateLS = (_name, _state) => {
  if (!_name || typeof _name !== 'string') return null;
  if (_state !== false && _state !== true) {
    // Si _state no es false ni true, seteo la variable a false
    return localStorage.setItem('stateAltaTrabajador', 'false');
  }

  return localStorage.setItem(_name, _state);
};

export const getStateLS = (_name) => {
  if (!_name || typeof _name !== 'string') return null;

  return localStorage.getItem(_name);
};

// Checkea si el parametro es un numero; si lo es, lo devuelve como entero, sino como string
export const getIDInCorrectType = (_s) => {
  if (!isNaN(parseInt(_s))) {
    // Es un numero -> ids oficiales en server son int, en local son string
    _s = parseInt(_s);
  }
  return _s;
};

// Devuelve (si es posible) la geolocation del user en formato lat:xxxxxxx,long:yyyyyyy
export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let p = 'lat:' + pos.coords.latitude + ',lng:' + pos.coords.longitude;
          resolve(p);
        },
        (err) => resolve(null),
      );
    } else {
      resolve(null);
    }
  });
};

export const getCurrentDateTime = () => {
  // Formato de string que se usa -> 2020-07-22 13:30:00
  let d = new Date();

  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();
  let sec = d.getSeconds();

  if (parseInt(month) < 10) month = '0' + month;
  if (parseInt(day) < 10) day = '0' + day;
  if (parseInt(hour) < 10) hour = '0' + hour;
  if (parseInt(min) < 10) min = '0' + min;
  if (parseInt(sec) < 10) sec = '0' + sec;

  let s = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;

  return s;
};

export const getCurrentDate = () => {
  // Formato de string que se usa -> 2020-07-22
  return getCurrentDateTime().split(' ')[0];
};

export const getTimeFromString = (_s) => {
  // Formato de string que se usa -> 2020-07-22 13:30:00
  let aux = _s.split(' ')[1];
  let time = '';
  if (parseInt(aux.split(':')[0]) > 12) {
    time = parseInt(aux.split(':')[0]) - 12 + ':' + aux.split(':')[1] + ' pm';
  } else {
    time = aux.split(':')[0] + ':' + aux.split(':')[1] + ' am';
  }

  return time;
};

export const getPrettyDateTimeFromString = (_s) => {
  // Formato de string que se usa -> 2020-07-22 13:30:00
  let month = parseInt(_s.split(' ')[0].split('-')[1]);
  let day = _s.split(' ')[0].split('-')[2];

  const dict = [
    '',
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];

  let pretty = day + ' ' + dict[month] + ' - ' + getTimeFromString(_s);

  return pretty;
};

export const getFormattedDateWithSlashes = (_s) => {
  // Formato que llega AAAA-MM-DD
  // Formato que sale  DD/MM/AAAA

  let aux = _s.split('-');

  return aux[2] + '/' + aux[1] + '/' + aux[0];
};

/**
 * Devuelve un string de fecha a partir de un Date object
 * @function
 * @param {object} _d - Date object
 * @return {string} Fecha en formato DD/MM/AAAA
 */
export const getPrettyDateFromObject = (_d) => {
  let day = _d.getDate();
  let month = _d.getMonth() + 1;
  let year = _d.getFullYear();

  day = day < 10 ? '0' + day.toString() : day.toString();
  month = month < 10 ? '0' + month.toString() : month.toString();

  let pretty = day + '/' + month + '/' + year;

  return pretty;
};

export const getTimeStampFromString = (_s) => {
  let d = new Date(_s);
  let ts = d.getTime();
  return ts;
};

/**
 * Devuelve que par de lotes corresponde
 * @function
 * @param {number} _cantidadLotes - Cantidad de lotes para calcular
 * @return {number} - Par correspondiente a dia actual con respecto al 1ro de Julio del 2020
 */
export const getParLotes = (_cantidadLotes) => {
  let lotes = _cantidadLotes;

  // Obtengo la cantidad de pares de lotes que hay
  // Nota: ahora se hace de a 4 lotes
  let paresLotes = lotes / 4; //parseInt(this.state.cantidadLotes);
  if (!Number.isInteger(paresLotes)) paresLotes = Math.ceil(paresLotes);

  // 1ro de Julio
  let fixedDate = new Date('2020-07-01T04:00:00');

  let varDate = new Date();

  let msDiff = varDate.getTime() - fixedDate.getTime();
  let daysDiff = Math.floor(msDiff / (1000 * 3600 * 24));

  let aux = daysDiff;
  if (aux > paresLotes) {
    while (aux > paresLotes) {
      aux -= paresLotes;
    }
  }

  return aux;
};

/**
 * Devuelve el ISOweekNumber de una fecha
 * @function
 * @param {object} _date - Date object
 * @return {number} ISOweekNumber
 */
export const getISOweek = (_date) => {
  // eslint-disable-next-line
  Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7,
      )
    );
  };

  return _date.getWeek();
};

export const random = (seed) => {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

export const getIndexes = (weekNumber, limit) => {
  const arr = random(weekNumber).toString().split('0.')[1].split('');

  let offsetLow = 1;
  let offsetHigh = limit - 1;
  let fromBottom = true;
  let doubleNumber = false;
  let i = 0;
  const indexes = [];
  while (indexes.length < 7) {
    let index = 0;
    if (doubleNumber) {
      index = arr[i] + arr[i + 1];
      i++;
    } else {
      index = arr[i];
    }

    let indexNumber = parseInt(index);

    if (indexNumber > limit) {
      indexNumber = fromBottom ? offsetLow : offsetHigh;

      if (fromBottom) {
        offsetLow++;
        fromBottom = false;
      } else {
        offsetHigh--;
        fromBottom = true;
      }
    }

    i++;
    doubleNumber = !doubleNumber;

    while (indexes.includes(indexNumber)) {
      indexNumber++;
      if (indexNumber >= limit) {
        indexNumber = 0;
      }
    }

    indexes.push(indexNumber);
  }

  return indexes;
};

/**
 * Devuelve el Año de la ISOweekNumber indicado
 * @function
 * @param {object} _date - Date object
 * @return {number} Año de la ISOweek
 */
export const getYearOfISOweek = (_date) => {
  // eslint-disable-next-line
  Date.prototype.getWeekYear = function () {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    return date.getFullYear();
  };

  return _date.getWeekYear();
};

/**
 * Devuelve la fecha de inicio de la ISOweek
 * @function
 * @param {number} _y - Año de la semana
 * @param {number} _w - Numero de la semana
 * @return {object} Date del inicio de la ISOweek
 */
export const getDateISOweek = (_y, _w) => {
  var simple = new Date(_y, 0, 1 + (_w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

/**
 * Valida si el adolescente es menor de 16 anios en
 * base a la fecha de nacimiento
 * @function
 * @param {string} _fecha_nacimiento - Fecha de nacimiento en formato 'AAAA-MM-DD'
 * @return {string} - 'en_rango' si tiene 16 o 17, 'menor' si es menor de 16 y 'mayor' si es mayor de 17
 */
export const edadEnRango = (_fecha_nacimiento) => {
  let aux = _fecha_nacimiento.split('-');
  let yNacimiento = parseInt(aux[0]);
  // eslint-disable-next-line
  let mNacimiento = parseInt(aux[1]);
  // eslint-disable-next-line
  let dNacimiento = parseInt(aux[2]);

  // Fecha actual
  let d = new Date();
  let yActual = d.getFullYear();
  // eslint-disable-next-line
  let mActual = d.getMonth() + 1;
  // eslint-disable-next-line
  let dActual = d.getDate();

  let diferenciaAnios = yActual - yNacimiento;

  if (diferenciaAnios < 16) return 'menor';

  if (diferenciaAnios > 17) return 'mayor';

  return 'en_rango';
};

/**
 * Devuelve si el usuario actual tiene acceso a la funcionalidad
 * indicada
 * @function
 * @param {string} _funcion - Funcionalidad a checkear (diccionario adentro de la funcion)
 * @return {boolean} - True si el user tiene acceso, False si no lo tiene
 */
export const hasAccessTo = (_funcion) => {
  // LISTA DE FUNCIONES:
  // todo -> Acceso a todo; es override, si esta presente no importa ningun otro
  // scs -> Acceso al Formulario SCS; este funciona aparte de 'todo' y lo ignora
  // altas -> Habilita Alta TA
  // ingresos -> Habilita Ingresos/Egresos
  // monitoreoTA -> Habilita MonitoreoTA
  // monitoreoL -> Habilita Monitoreo Lotes
  // listadoTA -> Habilita listado de TAs
  // monitoreoEF -> Habilita control entrega de fruta

  let permisos = localStorage.getItem('usuario_permisos');

  // Si no es string, niego acceso
  if (typeof _funcion !== 'string') return false;

  if (_funcion === 'scs') {
    if (permisos.includes(_funcion)) return true;
    else return false;
  }

  if (permisos.includes('todo')) return true;

  if (permisos.includes(_funcion)) return true;

  // Si no hubo match, niego acceso
  return false;
};

export const isIramUser = () => {
  const permisos = localStorage.getItem('usuario_permisos');
  return permisos.includes('iram');
};

export const sameDay = (d1, d2) => {
  if (typeof d1 === 'string') d1 = new Date(d1);
  if (typeof d2 === 'string') d2 = new Date(d2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const horasTrabajadasMs = (d1, d2) => {
  if (typeof d1 === 'string') d1 = new Date(d1);
  if (typeof d2 === 'string') d2 = new Date(d2);

  return d2.getTime() - d1.getTime();
};
