import React from 'react';
import { withRouter } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Alert } from '@material-ui/lab';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import FilesManager from './FilesManager/FilesManager';
import QuestionTitle from './Components/QuestionTitle';
import QuestionSelect from './Components/QuestionSelect';
import FloatBar from './Components/FloatBar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import ContentSeparator from '../Misc/ContentSeparator';
import Loader from '../Misc/Loader';

import { getFormattedDateWithSlashes, sameDay, horasTrabajadasMs } from '../../helpers/helpers';
import { exportAppData, exportDataEmpresa } from '../../helpers/data_export';
import {
  getEmpresa,
  getFormValidationV2,
  updateValidationFormV2,
  sendResponsable,
  deleteItem,
  sendCapacitacionV2,
  updateCapacitacion,
  deleteFileStandAlone,
  sendNewItem,
  getItemsFromCollection,
} from '../../requests/data';
import { DICCIONARIO_SELECTS, DICCIONARIO_WARNINGS, FIELDS_WITH_INFO } from '../../config';

class ValidacionFormulario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      load: false,

      empresaNombre: null,
      fechaUltimaActualizacion: null,

      valueEstandar1: 0,
      totalEstandar1: 0,
      severityEstandar1: 'error',
      valueEstandar2: 0,
      totalEstandar2: 0,
      severityEstandar2: 'error',
      valueEstandar3: 0,
      totalEstandar3: 0,
      severityEstandar3: 'error',
      valueEstandar4: 0,
      totalEstandar4: 0,
      severityEstandar4: 'error',

      valueGeneral: 0,
      floatBarOpacity: 1,
      totalGeneral: 0,
      severityGeneral: 'error',

      showDialogResponsables: false,
      agregarResponsableDisabled: true,
      showLoaderResponsable: false,
      nombreResponsable: '',
      rolResponsable: '',

      showDialogCapacitaciones: false,
      agregarCapacitacionDisabled: true,
      showLoaderCapacitacion: false,
      actualizandoCapacitacion: false,
      capacitacionId: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionUnionId: null,
      capacitacionFileId: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionFileName: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionFileUrl: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionContenidos: '',
      capacitacionDocumentoVerificacion: '',
      capacitacionFecha: '',
      capacitacionLugar: '',
      capacitacionObservaciones: '',
      capacitacionTema: '',
      capacitacionAsistentes: '',

      empresaId: localStorage.getItem('usuario_empresa_id'),
      reportId: null,

      estandar_1_1_value: null,
      estandar_1_1_warning: false,
      estandar_1_1_approved: false,
      estandar_1_1_files: [],

      estandar_1_2_value: null,
      estandar_1_2_warning: false,
      estandar_1_2_approved: false,
      estandar_1_2_files: [],

      estandar_2_1_value: null,
      estandar_2_1_warning: false,
      estandar_2_1_approved: false,
      estandar_2_1_files: [],

      estandar_2_2_value: null,
      estandar_2_2_warning: false,
      estandar_2_2_approved: false,
      estandar_2_2_files: [],

      estandar_2_3_value: null,
      estandar_2_3_warning: false,
      estandar_2_3_approved: false,
      estandar_2_3_files: [],
      estandar_2_3_disabled: false,

      estandar_2_4_value: null,
      estandar_2_4_warning: false,
      estandar_2_4_approved: false,
      estandar_2_4_files_1: [],
      estandar_2_4_files_2: [],
      estandar_2_4_files_3: [],
      estandar_2_4_files_4: [],

      estandar_2_5_value: null,
      estandar_2_5_warning: false,
      estandar_2_5_approved: false,
      estandar_2_5_files: [],

      estandar_2_6_value: null,
      estandar_2_6_warning: false,
      estandar_2_6_approved: false,
      estandar_2_6_1_value: '',
      estandar_2_6_files: [],

      estandar_3_1_value: null,
      estandar_3_1_warning: false,
      estandar_3_1_approved: false,
      estandar_3_1_files: [],

      estandar_3_2_value: null,
      estandar_3_2_warning: false,
      estandar_3_2_approved: false,
      estandar_3_2_files: [],
      estandar_3_2_disabled: false,

      estandar_3_3_value: null,
      estandar_3_3_warning: false,
      estandar_3_3_approved: false,
      estandar_3_3_files: [],

      estandar_3_4_value: null,
      estandar_3_4_warning: false,
      estandar_3_4_approved: false,
      estandar_3_4_files: [],

      estandar_3_5_value: null,
      estandar_3_5_warning: false,
      estandar_3_5_approved: false,
      estandar_3_5_1_value: '',
      estandar_3_5_files: [],

      estandar_3_6_value: null,
      estandar_3_6_warning: false,
      estandar_3_6_approved: false,
      estandar_3_6_1_value: null,
      estandar_3_6_1_warning: false,
      estandar_3_6_1_info: 'Buscando datos en la webapp...',
      estandar_3_6_2_value: '',
      estandar_3_6_files: [],

      estandar_3_7_value: null,
      estandar_3_7_warning: false,
      estandar_3_7_approved: false,
      estandar_3_7_1_value: null,
      estandar_3_7_1_warning: false,
      estandar_3_7_1_info: 'Buscando datos en la webapp...',
      estandar_3_7_2_value: '',
      estandar_3_7_files: [],

      estandar_3_8_value: null,
      estandar_3_8_warning: false,
      estandar_3_8_approved: false,
      estandar_3_8_1_value: null,
      estandar_3_8_1_warning: false,
      estandar_3_8_1_info: 'Buscando datos en la webapp...',
      estandar_3_8_2_value: '',
      estandar_3_8_files: [],

      estandar_3_9_value: null,
      estandar_3_9_warning: false,
      estandar_3_9_approved: false,
      estandar_3_9_1_info: 'Buscando datos en la webapp...',
      estandar_3_9_1_value: null,
      estandar_3_9_2_value: '',
      estandar_3_9_files: [],

      estandar_4_1_value: null,
      estandar_4_1_warning: false,
      estandar_4_1_approved: false,
      estandar_4_1_files: [],

      estandar_4_2_value: null,
      estandar_4_2_warning: false,
      estandar_4_2_approved: false,
      estandar_4_2_files: [],

      estandar_4_3_value: null,
      estandar_4_3_warning: false,
      estandar_4_3_approved: false,
      estandar_4_3_files: [],

      estandar_4_4_value: null,
      estandar_4_4_warning: false,
      estandar_4_4_approved: false,
      estandar_4_4_1_value: null,
      estandar_4_4_1_info: 'Buscando datos en la webapp...',
      estandar_4_4_2_value: '',
      estandar_4_4_files: [],

      estandar_4_5_value: null,
      estandar_4_5_warning: false,
      estandar_4_5_approved: false,
      estandar_4_5_1_value: null,
      estandar_4_5_1_info: 'Buscando datos en la webapp...',
      estandar_4_5_2_value: '',
      estandar_4_5_files: [],

      testfiles: [],
    };

    this.sendChangeTextTimeout = null;
  }

  componentDidMount() {
    ///////////////////////////////////////////////
    ///////////////////////////////////////////////
    //// CAMBIAR POR EL DINAMICO OBTENIDO DE CADA USER
    let empresa_id = this.state.empresaId;
    ///////////////////////////////////////////////
    getEmpresa(empresa_id).then((empresas) => {
      let empresa = null;
      if (empresas.length > 0) empresa = empresas[0];

      getFormValidationV2(empresa_id).then((form) => {
        // Si no existe el form, primero lo creo...
        if (form.length === 0) {
          sendNewItem('validaciones_v2', { empresa: empresa_id }).then((res) => {
            getFormValidationV2(empresa_id).then((form) => {
              // Fecha ultima actualizacion
              let fechaUltimaActualizacion = getFormattedDateWithSlashes(
                form[0].modified_on.split('T')[0],
              );

              // Cargo los datos del formulario...

              let obj = {};
              let keys = Object.keys(form[0]);
              for (let i = 0; i < keys.length; i++) {
                if (
                  keys[i].includes('estandar') &&
                  !keys[i].includes('responsables') &&
                  !keys[i].includes('capacitaciones')
                ) {
                  if (keys[i].includes('files')) {
                    obj[keys[i]] = form[0][keys[i]];
                  } else {
                    this.setValueAndWarning(keys[i], form[0][keys[i]]);

                    this.setFieldInfo(keys[i], form[0][keys[i]]);
                  }
                }
              }

              fetch('https://webapp-scs.dya.org.ar/directus/public/admin/get-fields-data.php')
                .then((res) => res.json())
                .then((fieldsData) => {
                  this.setState({
                    ...obj,

                    fechaUltimaActualizacion: fechaUltimaActualizacion,
                    empresaNombre: empresa ? empresa.nombre : null,

                    fieldsData: fieldsData.data,

                    reportId: form[0].id,
                    load: true,
                  });

                  this.checkValidity();
                });
            });
          });
        } else {
          // Ya esta cargado el form...
          // Cargo los datos del formulario...

          let fechaUltimaActualizacion = getFormattedDateWithSlashes(
            form[0].modified_on.split('T')[0],
          );

          let obj = {};
          let keys = Object.keys(form[0]);
          for (let i = 0; i < keys.length; i++) {
            if (
              keys[i].includes('estandar') &&
              !keys[i].includes('responsables') &&
              !keys[i].includes('capacitaciones')
            ) {
              if (keys[i].includes('files')) {
                obj[keys[i]] = form[0][keys[i]];
              } else {
                this.setValueAndWarning(keys[i], form[0][keys[i]]);

                this.setFieldInfo(keys[i], form[0][keys[i]]);
              }
            }
          }

          fetch('https://webapp-scs.dya.org.ar/directus/public/admin/get-fields-data.php')
            .then((res) => res.json())
            .then((fieldsData) => {
              this.setState({
                ...obj,

                fechaUltimaActualizacion: fechaUltimaActualizacion,
                empresaNombre: empresa ? empresa.nombre : null,

                fieldsData: fieldsData.data,

                reportId: form[0].id,
                load: true,
              });

              this.checkValidity();
            });
        }
      });
    });
  }

  agregarCapacitacion = () => {
    this.setState({ agregarCapacitacionDisabled: true, showLoaderCapacitacion: true });

    let fecha = this.state.capacitacionFecha;
    let tema = this.state.capacitacionTema;
    let contenidos = this.state.capacitacionContenidos;
    let lugar = this.state.capacitacionLugar;
    let asistentes = this.state.capacitacionAsistentes;
    let documento = this.state.capacitacionDocumentoVerificacion;
    let observaciones = this.state.capacitacionObservaciones;

    let obj = {
      fecha: fecha,
      tema: tema,
      contenidos: contenidos,
      lugar: lugar,
      asistentes: asistentes,
      documento: documento,
      observaciones: observaciones,
    };

    sendCapacitacionV2(this.state.reportId, obj).then((res) => {
      // Agrego la capacitacion a la lista...
      let c = {
        id: res.id,
        unionId: res.unionId,
        fileId: res.documentoId,
        fileName: documento.name,
        fileUrl: res.fileUrl,
        fecha: fecha,
        tema: tema,
        lugar: lugar,
        programa: contenidos,
        asistentes: asistentes,
        observaciones: observaciones,
      };

      let arr = [...this.state.estandar_2_1_capacitaciones, c];

      this.setState(
        {
          estandar_2_1_capacitaciones: arr,
          showDialogCapacitaciones: false,
          agregarCapacitacionDisabled: false,
          showLoaderCapacitacion: false,
        },
        () => {
          this.checkValidity();
          this.cleanCapacitacionForm();
        },
      );
    });
  };

  actualizarCapacitacion = () => {
    this.setState({ agregarCapacitacionDisabled: true, showLoaderCapacitacion: true });

    let id = this.state.capacitacionId;
    let fileId = this.state.capacitacionFileId;
    let fecha = this.state.capacitacionFecha;
    let tema = this.state.capacitacionTema;
    let contenidos = this.state.capacitacionContenidos;
    let lugar = this.state.capacitacionLugar;
    let asistentes = this.state.capacitacionAsistentes;
    let documento = this.state.capacitacionDocumentoVerificacion;
    let observaciones = this.state.capacitacionObservaciones;

    // Busco la capacitacion porque le tengo que actualizar los valores...
    let capacitaciones = this.state.estandar_2_1_capacitaciones;

    let obj = {
      id: id,
      fecha: fecha,
      tema: tema,
      contenidos: contenidos,
      lugar: lugar,
      asistentes: asistentes,
      documento: documento === fileId ? fileId : documento,
      observaciones: observaciones,
    };

    updateCapacitacion(this.state.reportId, obj).then((res) => {
      if (res.actualizarDocumento) {
        for (let i = 0; i < capacitaciones.length; i++) {
          if (capacitaciones[i].id === id) {
            capacitaciones[i].fileId = res.documentoId;
            capacitaciones[i].fileUrl = res.fullUrl;
            capacitaciones[i].fileName = documento.name;
            capacitaciones[i].documento = documento;
            capacitaciones[i].fecha = fecha;
            capacitaciones[i].tema = tema;
            capacitaciones[i].asistentes = asistentes;
            capacitaciones[i].lugar = lugar;
            capacitaciones[i].observaciones = observaciones;
            capacitaciones[i].programa = contenidos;
          }
        }
      } else {
        for (let i = 0; i < capacitaciones.length; i++) {
          if (capacitaciones[i].id === id) {
            capacitaciones[i].fecha = fecha;
            capacitaciones[i].tema = tema;
            capacitaciones[i].asistentes = asistentes;
            capacitaciones[i].lugar = lugar;
            capacitaciones[i].observaciones = observaciones;
            capacitaciones[i].programa = contenidos;
          }
        }
      }

      this.setState(
        {
          estandar_2_1_capacitaciones: capacitaciones,
          showDialogCapacitaciones: false,
          agregarCapacitacionDisabled: false,
          showLoaderCapacitacion: false,
        },
        () => {
          this.checkValidity();
          this.cleanCapacitacionForm();
        },
      );
    });
  };

  eliminarCapacitacion = (_id, _unionId, _fileId) => {
    // Lo saco de la lista de chips...
    let arr = [...this.state.estandar_2_1_capacitaciones];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === _id) {
        if (!_unionId) _unionId = arr[i].unionId; // En eliminar desde edicion no viene este dato
        arr.splice(i, 1);
        break;
      }
    }

    this.setState({ estandar_2_1_capacitaciones: arr }, () => {
      this.checkValidity();
    });

    deleteItem(
      'validaciones_capacitaciones',
      _id,
      'estandar_2_1_capacitaciones_validaciones_capacitaciones',
      _unionId,
    ).then((res) => {
      // Lo elimine de la DB; elimino el archivo asociado si existe...
      if (_fileId !== null) {
        deleteFileStandAlone(_fileId).then((res) => {
          this.cleanCapacitacionForm();
        });
      }
    });
  };

  agregarResponsable = () => {
    this.setState({ showLoaderResponsable: true, agregarResponsableDisabled: true });

    let nombre = this.state.nombreResponsable;
    let rol = this.state.rolResponsable;
    let reportId = this.state.reportId;

    sendResponsable(reportId, nombre, rol).then((res) => {
      let r = { id: res.id, unionId: res.unionId, nombre: nombre, rol: rol };

      let arr = [...this.state.estandar_1_2_responsables, r];

      this.setState(
        {
          estandar_1_2_responsables: arr,
          showLoaderResponsable: false,
          agregarResponsableDisabled: false,
          showDialogResponsables: false,
        },
        () => {
          this.checkValidity();
          this.cleanResponsableForm();
        },
      );
    });
  };

  eliminarResponsable = (_id, _unionId) => {
    // Lo saco de la lista de chips...
    let arr = [...this.state.estandar_1_2_responsables];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === _id) {
        arr.splice(i, 1);
        break;
      }
    }

    this.setState({ estandar_1_2_responsables: arr }, () => {
      this.checkValidity();
    });

    deleteItem(
      'validaciones_responsables',
      _id,
      'estandar_1_2_responsables_validaciones_responsables',
      _unionId,
    ).then((res) => {
      // Lo elimine de la DB...
    });
  };

  checkValidity = () => {
    this.setState({ floatBarOpacity: 0 });

    let obj = {};
    let totalEstandar1 = 0;
    let valueEstandar1 = 0;
    let totalEstandar2 = 0;
    let valueEstandar2 = 0;
    let totalEstandar3 = 0;
    let valueEstandar3 = 0;
    let totalEstandar4 = 0;
    let valueEstandar4 = 0;

    // estandar_1_1
    totalEstandar1++;
    if (this.state.estandar_1_1_value === '1' && this.state.estandar_1_1_files.length > 0) {
      obj['estandar_1_1_approved'] = true;
      valueEstandar1++;
    } else {
      obj['estandar_1_1_approved'] = false;
    }

    // estandar_1_2
    totalEstandar1++;
    if (this.state.estandar_1_2_value === '1' && this.state.estandar_1_2_files.length > 0) {
      obj['estandar_1_2_approved'] = true;
      valueEstandar1++;
    } else {
      obj['estandar_1_2_approved'] = false;
    }

    // estandar_2_1
    totalEstandar2++;
    if (this.state.estandar_2_1_value === '1' && this.state.estandar_2_1_files.length > 0) {
      obj['estandar_2_1_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_1_approved'] = false;
    }

    // estandar_2_2
    totalEstandar2++;
    if (this.state.estandar_2_2_value === '1' && this.state.estandar_2_2_files.length > 0) {
      obj['estandar_2_2_approved'] = true;
      valueEstandar2++;
    } else {
      if (this.state.estandar_2_2_value === '1') {
        // "SI" pero todavia no se subio el archivo respaldatorio
        obj['estandar_2_2_approved'] = false;
      } else {
        obj['estandar_2_2_approved'] = 'NA'; // Esta pregunta es 'trabaja con contratistas'; si es NO, no suma ni resta
        totalEstandar2--;
      }
    }

    // estandar_2_3
    totalEstandar2++;
    if (this.state.estandar_2_3_value === '1' && this.state.estandar_2_3_files.length > 0) {
      obj['estandar_2_3_approved'] = true;
      valueEstandar2++;
    } else {
      // Esta pregunta es dependiente de 2_2 (tiene contratistas)
      // Cuando el value es 3, no hay que mostrar el approved
      if (this.state.estandar_2_3_value === '3') {
        obj['estandar_2_3_approved'] = 'NA';
        totalEstandar2--;
      } else {
        obj['estandar_2_3_approved'] = false;
      }
    }

    // estandar_2_4
    totalEstandar2++;
    if (
      this.state.estandar_2_4_value === '1' &&
      this.state.estandar_2_4_files_1.length > 0 &&
      this.state.estandar_2_4_files_2.length > 0 &&
      this.state.estandar_2_4_files_3.length > 0
    ) {
      obj['estandar_2_4_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_4_approved'] = false;
    }

    // estandar_2_5
    totalEstandar2++;
    if (this.state.estandar_2_5_value === '1' && this.state.estandar_2_5_files.length > 0) {
      obj['estandar_2_5_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_5_approved'] = false;
    }

    // estandar_2_6
    totalEstandar2++;
    if (
      this.state.estandar_2_6_value === '1' &&
      this.state.estandar_2_6_1_value !== '' &&
      this.state.estandar_2_6_files.length > 0
    ) {
      obj['estandar_2_6_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_6_approved'] = false;
    }

    // estandar_3_1
    totalEstandar3++;
    if (this.state.estandar_3_1_value === '1' && this.state.estandar_3_1_files.length > 0) {
      obj['estandar_3_1_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_1_approved'] = false;
    }

    // estandar_3_2
    totalEstandar3++;
    if (this.state.estandar_3_2_value === '1' && this.state.estandar_3_2_files.length > 0) {
      obj['estandar_3_2_approved'] = true;
      valueEstandar3++;
    } else {
      // Esta pregunta es dependiente de 2_2 (tiene contratistas)
      // Cuando el value es 3, no hay que mostrar el approved
      if (this.state.estandar_3_2_value === '3') {
        obj['estandar_3_2_approved'] = 'NA';
        totalEstandar3--;
      } else {
        obj['estandar_3_2_approved'] = false;
      }
    }

    // estandar_3_3
    totalEstandar3++;
    if (this.state.estandar_3_3_value === '1' && this.state.estandar_3_3_files.length > 0) {
      obj['estandar_3_3_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_3_approved'] = false;
    }

    // estandar_3_4
    totalEstandar3++;
    if (this.state.estandar_3_4_value === '1' && this.state.estandar_3_4_files.length > 0) {
      obj['estandar_3_4_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_4_approved'] = false;
    }

    // estandar_3_5
    totalEstandar3++;
    if (
      this.state.estandar_3_5_value === '1' &&
      this.state.estandar_3_5_1_value !== '' &&
      this.state.estandar_3_5_files.length > 0
    ) {
      obj['estandar_3_5_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_5_approved'] = false;
    }

    // estandar_3_6
    totalEstandar3++;
    if (
      (this.state.estandar_3_6_value === '1' && this.state.estandar_3_6_1_value === '1') || // utiliza webapp
      (this.state.estandar_3_6_value === '1' &&
        this.state.estandar_3_6_1_value === '2' &&
        this.state.estandar_3_6_2_value !== '' &&
        this.state.estandar_3_6_files.length > 0) ||
      this.state.estandar_3_6_value === '5' // "No corresponde responder
    ) {
      obj['estandar_3_6_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_6_approved'] = false;
    }

    // estandar_3_7
    /*
     * Provisory Limon Solution
     * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
     * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
     * we should restore the ValidacionesV2 form for Arandanos.
     */
    totalEstandar3++;
    if (
      (this.state.estandar_3_7_value === '1' && this.state.estandar_3_7_1_value === '1') || // utiliza webapp
      (this.state.estandar_3_7_value === '1' &&
        this.state.estandar_3_7_1_value === '2' &&
        this.state.estandar_3_7_2_value !== '' &&
        this.state.estandar_3_7_files.length > 0) ||
      this.state.estandar_3_7_value === '5' // "No corresponde responder
    ) {
      obj['estandar_3_7_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_7_approved'] = false;
    }

    // estandar_3_8
    /*
     * Provisory Limon Solution
     * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
     * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
     * we should restore the ValidacionesV2 form for Arandanos.
     */
    totalEstandar3++;
    if (
      (this.state.estandar_3_8_value === '1' && this.state.estandar_3_8_1_value === '1') || // utiliza webapp
      (this.state.estandar_3_8_value === '1' &&
        this.state.estandar_3_8_1_value === '2' &&
        this.state.estandar_3_8_2_value !== '' &&
        this.state.estandar_3_8_files.length > 0) ||
      this.state.estandar_3_8_value === '5' // "No corresponde responder
    ) {
      obj['estandar_3_8_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_8_approved'] = false;
    }

    // estandar_3_9
    /*
     * Provisory Limon Solution
     * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
     * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
     * we should restore the ValidacionesV2 form for Arandanos.
     */
    // totalEstandar3++;
    // if (
    //   (this.state.estandar_3_9_value === '1' && this.state.estandar_3_9_1_value === '1') || // utiliza webapp
    //   (this.state.estandar_3_9_value === '1' &&
    //     this.state.estandar_3_9_1_value === '2' &&
    //     this.state.estandar_3_9_2_value !== '' &&
    //     this.state.estandar_3_9_files.length > 0)
    // ) {
    //   obj['estandar_3_9_approved'] = true;
    //   valueEstandar3++;
    // } else {
    //   obj['estandar_3_9_approved'] = false;
    // }

    // estandar_4_1
    totalEstandar4++;
    if (this.state.estandar_4_1_value === '1' && this.state.estandar_4_1_files.length > 0) {
      obj['estandar_4_1_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_1_approved'] = false;
    }

    // estandar_4_2
    totalEstandar4++;
    if (
      (this.state.estandar_4_2_value === '1' && this.state.estandar_4_2_files.length > 0) ||
      this.state.estandar_4_2_value === '5' // "No corresponde responder
    ) {
      obj['estandar_4_2_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_2_approved'] = false;
    }

    // estandar_4_3
    totalEstandar4++;
    if (this.state.estandar_4_3_value === '1' && this.state.estandar_4_3_files.length > 0) {
      obj['estandar_4_3_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_3_approved'] = false;
    }

    // estandar_4_4
    totalEstandar4++;
    if (
      (this.state.estandar_4_4_value === '1' && this.state.estandar_4_4_1_value === '1') || // utiliza webapp
      (this.state.estandar_4_4_value === '1' &&
        this.state.estandar_4_4_1_value === '2' &&
        this.state.estandar_4_4_2_value !== '' &&
        this.state.estandar_4_4_files.length > 0)
    ) {
      obj['estandar_4_4_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_4_approved'] = false;
    }

    // estandar_4_5
    /*
     * Provisory Limon Solution
     * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
     * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
     * we should restore the ValidacionesV2 form for Arandanos.
     */
    totalEstandar4++;
    if (
      (this.state.estandar_4_5_value === '1' && this.state.estandar_4_5_1_value === '1') || // utiliza webapp
      (this.state.estandar_4_5_value === '1' &&
        this.state.estandar_4_5_1_value === '2' &&
        this.state.estandar_4_5_2_value !== '' &&
        this.state.estandar_4_5_files.length > 0)
    ) {
      obj['estandar_4_5_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_5_approved'] = false;
    }

    // Calculo colores...
    let severityEstandar1 = 'error';
    if (valueEstandar1 === totalEstandar1) {
      severityEstandar1 = 'success';
    } else {
      if (valueEstandar1 >= totalEstandar1 / 2) {
        severityEstandar1 = 'warning';
      }
    }

    let severityEstandar2 = 'error';
    if (valueEstandar2 === totalEstandar2) {
      severityEstandar2 = 'success';
    } else {
      if (valueEstandar2 >= totalEstandar2 / 2) {
        severityEstandar2 = 'warning';
      }
    }

    let severityEstandar3 = 'error';
    if (valueEstandar3 === totalEstandar3) {
      severityEstandar3 = 'success';
    } else {
      if (valueEstandar3 >= totalEstandar3 / 2) {
        severityEstandar3 = 'warning';
      }
    }

    let severityEstandar4 = 'error';
    if (valueEstandar4 === totalEstandar4) {
      severityEstandar4 = 'success';
    } else {
      if (valueEstandar4 >= totalEstandar4 / 2) {
        severityEstandar4 = 'warning';
      }
    }

    // Total general...
    let valueGeneral = valueEstandar1 + valueEstandar2 + valueEstandar3 + valueEstandar4;
    let totalGeneral = totalEstandar1 + totalEstandar2 + totalEstandar3 + totalEstandar4;
    let severityGeneral = 'error';
    if (valueGeneral === totalGeneral) {
      severityGeneral = 'success';
    } else {
      if (valueGeneral >= totalGeneral / 2) {
        severityGeneral = 'warning';
      }
    }

    obj['totalEstandar1'] = totalEstandar1;
    obj['valueEstandar1'] = valueEstandar1;
    obj['totalEstandar2'] = totalEstandar2;
    obj['valueEstandar2'] = valueEstandar2;
    obj['totalEstandar3'] = totalEstandar3;
    obj['valueEstandar3'] = valueEstandar3;
    obj['totalEstandar4'] = totalEstandar4;
    obj['valueEstandar4'] = valueEstandar4;

    obj['totalGeneral'] = totalGeneral;
    obj['severityGeneral'] = severityGeneral;

    obj['severityEstandar1'] = severityEstandar1;
    obj['severityEstandar2'] = severityEstandar2;
    obj['severityEstandar3'] = severityEstandar3;
    obj['severityEstandar4'] = severityEstandar4;

    this.setState({ ...obj }, () => {
      setTimeout(() => {
        this.setState({ floatBarOpacity: 1, valueGeneral: valueGeneral });
      }, 300);
    });
  };

  setFieldInfo = (_field, _value) => {
    // Checkeo si es un field con info...
    if (!FIELDS_WITH_INFO.hasOwnProperty(_field)) return;

    if (!FIELDS_WITH_INFO[_field]['trueValues'].includes(_value)) return;

    let fields = FIELDS_WITH_INFO[_field].fields ? FIELDS_WITH_INFO[_field].fields : 'id';
    let filter = 'filter[empresa_id][eq]=' + this.state.empresaId;
    let collection = FIELDS_WITH_INFO[_field]['collection'];

    if (_field === 'estandar_4_1_6_1') {
      filter = false;
    }

    if (
      _field === 'estandar_3_9_1' ||
      _field === 'estandar_3_6_1' ||
      _field === 'estandar_3_7_1' ||
      _field === 'estandar_4_5_1'
    ) {
      filter = 'filter[lote_id.empresa_id][eq]=' + this.state.empresaId;
    }

    if (_field === 'estandar_3_8_1' || _field === 'estandar_3_7_1') {
      filter = 'filter[contratista_id.empresa_asociada_id][eq]=' + this.state.empresaId;
    }

    getItemsFromCollection(collection, true, fields, filter).then((res) => {
      if (_field === 'estandar_3_4_1') {
        this.setState({
          estandar_3_4_1_info:
            'Se realizaron ' + res[collection].length + ' controles de documentación.',
        });
      }

      if (_field === 'estandar_3_1_6') {
        this.setState({
          estandar_3_1_6_info: 'Se realizaron ' + res[collection].length + ' ingresos y egresos.',
        });
      }

      if (_field === 'estandar_3_8_1') {
        this.setState({
          estandar_3_8_1_info:
            'Se realizaron ' +
            res[collection].length +
            ' controles en los puntos de entrega de fruta de la empresa.',
        });
      }

      if (_field === 'estandar_3_9') {
        // Genero chips con TA + links de sus documentos...
        let tas = res[collection];
        let total = tas.length;
        let completo = 0;
        for (let i = 0; i < tas.length; i++) {
          let doc1 = tas[i].documentacion_respaldatoria_1;
          let doc2 = tas[i].documentacion_respaldatoria_2;
          let doc3 = tas[i].documentacion_respaldatoria_3;

          if (doc1 && doc2 && doc3) completo++;
        }

        this.setState({ estandar_3_9_info: { total: total, completo: completo } });
      }

      if (_field === 'estandar_3_6_1') {
        this.setState({
          estandar_3_6_1_info:
            'Se realizaron ' + res[collection].length + ' controles en los lotes de la empresa.',
        });
      }

      // *** Arandanos ***
      // if (_field === 'estandar_3_7_1') {
      //   this.setState({
      //     estandar_3_7_1_info:
      //       'Se realizaron ' +
      //       res[collection].length +
      //       ' controles en los puntos de entrega de fruta de la empresa.',
      //   });
      // }
      // *** Limon ***
      if (_field === 'estandar_3_7_1') {
        this.setState({
          estandar_3_7_1_info:
            'Se registraron ' +
            res[collection].length +
            ' inicios de sesión de usuarios externos de la empresa.',
        });
      }

      if (_field === 'estandar_3_9_1') {
        this.setState({
          estandar_3_9_1_info:
            'Se realizaron ' + res[collection].length + ' controles en los lotes de la empresa.',
        });
      }

      if (_field === 'estandar_4_5_1') {
        this.setState({
          estandar_4_5_1_info:
            'Se realizaron ' + res[collection].length + ' controles en los lotes de la empresa.',
        });
      }

      if (_field === 'estandar_4_4_1') {
        let cantidadIncidencias = 0;
        for (let i = 0; i < res[collection].length; i++) {
          if (res[collection][i]['registrado_usuario_id']) {
            if (
              res[collection][i]['registrado_usuario_id']['empresa_id'] ===
              parseInt(this.state.empresaId)
            ) {
              cantidadIncidencias++;
            }
          }
        }

        this.setState({
          estandar_4_4_1_info:
            'Se registraron ' + cantidadIncidencias + ' situaciones de riesgo TI.',
        });
      }
    });
  };

  cleanCapacitacionForm = () => {
    this.setState({
      capacitacionContenidos: '',
      capacitacionDocumentoVerificacion: '',
      capacitacionFecha: '',
      capacitacionLugar: '',
      capacitacionObservaciones: '',
      capacitacionTema: '',
      capacitacionAsistentes: '',

      capacitacionId: null,
      capacitacionFileId: null,
      capacitacionFileName: null,
      capacitacionFileUrl: null,

      actualizandoCapacitacion: false,
      agregarCapacitacionDisabled: true,
    });
  };

  cleanResponsableForm = () => {
    this.setState({
      nombreResponsable: '',
      rolResponsable: '',
    });
  };

  updateTextField = (_field, _value) => {
    if (_field.includes('estandar')) {
      this.setState({ [_field + '_value']: _value });
    } else {
      this.setState({ [_field]: _value });
    }

    // En el caso de Capacitacion y Responsable no envio la
    // actualizacion del campo, pero si reviso si ya se
    // puede enviar la capacitacion o responsable
    if (_field.includes('capacitacion')) {
      this.setState({ [_field]: _value }, () => {
        // Actualice el state; reviso si se debe habilitar
        // el boton de envio...
        if (
          this.state.capacitacionContenidos !== '' &&
          this.state.capacitacionDocumentoVerificacion !== '' &&
          this.state.capacitacionFecha !== '' &&
          this.state.capacitacionLugar !== '' &&
          this.state.capacitacionObservaciones !== '' &&
          this.state.capacitacionTema !== '' &&
          this.state.capacitacionAsistentes !== ''
        ) {
          this.setState({ agregarCapacitacionDisabled: false });
        } else {
          this.setState({ agregarCapacitacionDisabled: true });
        }
      });

      return;
    }

    if (_field.includes('Responsable')) {
      this.setState({ [_field]: _value }, () => {
        // Actualice el state; reviso si se debe habilitar
        // el boton de envio...
        if (this.state.nombreResponsable !== '' && this.state.rolResponsable !== '') {
          this.setState({ agregarResponsableDisabled: false });
        }
      });
    }

    if (this.sendChangeTextTimeout === null) {
      this.sendChangeTextTimeout = setTimeout(() => {
        clearTimeout(this.sendChangeTextTimeout);
        this.sendChangeTextTimeout = null;
        updateValidationFormV2(this.state.reportId, _field, _value).then(() => {
          this.checkValidity();
        });
      }, 3000);
    } else {
      clearTimeout(this.sendChangeTextTimeout);
      this.sendChangeTextTimeout = null;

      this.sendChangeTextTimeout = setTimeout(() => {
        clearTimeout(this.sendChangeTextTimeout);
        this.sendChangeTextTimeout = null;
        this.sendChangeTextTimeout = null;
        updateValidationFormV2(this.state.reportId, _field, _value).then(() => {
          this.checkValidity();
        });
      }, 3000);
    }
  };

  toggleDialog = (_id, _el) => {
    // Por ahora la actualizacion solo funciona con capacitaciones
    if (_el) {
      // Cargo los fields del formulario con los datos de la capacitacion que se esta actualizando...
      let capacitacion = this.state.estandar_2_1_capacitaciones.find((c) => c.id === _el);

      let obj = {
        capacitacionId: capacitacion.id, // Se usa en la actualizacion de las capacitaciones
        capacitacionContenidos: capacitacion.programa,
        capacitacionDocumentoVerificacion: '',
        // eslint-disable-next-line
        capacitacionDocumentoVerificacion: capacitacion.fileId,
        capacitacionFileId: capacitacion.fileId,
        capacitacionFileName: capacitacion.fileName,
        capacitacionFileUrl: capacitacion.fileUrl,
        capacitacionFecha: capacitacion.fecha,
        capacitacionLugar: capacitacion.lugar,
        capacitacionObservaciones: capacitacion.observaciones,
        capacitacionTema: capacitacion.tema,
        capacitacionAsistentes: capacitacion.asistentes,

        actualizandoCapacitacion: true,
        agregarCapacitacionDisabled: false, // Siempre que se abre el modal para actualizar tenemos todos los datos...
        ['show' + _id]: !this.state['show' + _id],
      };

      this.setState(obj);
    } else {
      this.setState({ ['show' + _id]: !this.state['show' + _id] });
    }
  };

  setValueAndWarning = (_field, _value) => {
    let enableWarning = false;
    let extraFieldUpdates = [];

    if (_field === 'estandar_1_1') {
      if (_value === '2' || _value === '3') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_1_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_2') {
      if (_value === '2') {
        enableWarning = true;

        // Esta es la pregunta de "trabaja con contratistas";
        // Cuando es "NO" se tiene que deshabilitar las preguntas dependientes de contratistas
        // NOTA: Si bien aca se setea el 'approved', tambien lo tenemos que setear en checkValidity
        this.setState({
          estandar_2_2_approved: 'NA',

          estandar_2_3_approved: 'NA',
          estandar_2_3_value: '3',
          estandar_2_3_disabled: true,
          estandar_2_3_warning: false,

          estandar_3_2_approved: 'NA',
          estandar_3_2_value: '3',
          estandar_3_2_disabled: true,
          estandar_3_2_warning: false,
        });

        let prom = updateValidationFormV2(this.state.reportId, 'estandar_2_3', '3');
        extraFieldUpdates.push(prom);

        prom = updateValidationFormV2(this.state.reportId, 'estandar_3_2', '3');
        extraFieldUpdates.push(prom);
      }

      if (_value === '1' || _value === null) {
        enableWarning = false;

        // Esta es la pregunta de "trabaja con contratistas";
        // Cuando es "NO" se tiene que deshabilitar las preguntas dependientes de contratistas
        // NOTA: Si bien aca se setea el 'approved', tambien lo tenemos que setear en checkValidity
        this.setState({
          estandar_2_2_approved: false,

          estandar_2_3_approved: false,
          estandar_2_3_value: null,
          estandar_2_3_disabled: false,
          estandar_2_3_warning: false,

          estandar_3_2_approved: false,
          estandar_3_2_value: null,
          estandar_3_2_disabled: false,
          estandar_3_2_warning: false,
        });

        let prom = updateValidationFormV2(this.state.reportId, 'estandar_2_3', null);
        extraFieldUpdates.push(prom);

        prom = updateValidationFormV2(this.state.reportId, 'estandar_3_2', null);
        extraFieldUpdates.push(prom);
      }
    }

    if (_field === 'estandar_2_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_6') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_6') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_7') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_8') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_9') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (extraFieldUpdates.length > 0) {
      Promise.all(extraFieldUpdates).then(() => {});
    }

    // Si existe un campo de warning para este field, lo actualizo
    if (typeof this.state[_field + '_warning'] !== 'undefined') {
      this.setState({ [_field + '_value']: _value, [_field + '_warning']: enableWarning }, () => {
        this.checkValidity();
      });
    } else
      this.setState({ [_field + '_value']: _value }, () => {
        this.checkValidity();
      });

    return true;
  };

  updateField = (_field, _value) => {
    this.setValueAndWarning(_field, _value);

    this.setFieldInfo(_field, _value);

    updateValidationFormV2(this.state.reportId, _field, _value).then(() => {});
  };

  getEstandarText = (key) => {
    const fieldsData = this.state.fieldsData;

    const item = fieldsData.find((e) => e.key === key.trim());

    if (!item) return '';

    return item.text || JSON.parse(item.options)?.title || '';
  };

  render() {
    if (!this.state.load) {
      return (
        <React.Fragment>
          <Loader show={true} />
          <NavBar />
          <MyBottomNavigation />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <NavBar />

        <div className="no-print">
          <FloatBar
            current={this.state.valueGeneral}
            total={this.state.totalGeneral}
            severity={this.state.severityGeneral}
            opacity={this.state.floatBarOpacity}
          />
        </div>

        <Container maxWidth="lg" style={{ marginTop: 105 + 'px' }} className="for-print mt-0">
          <Typography className="mb-15" variant="h6" color="textSecondary">
            Formulario de SCS
          </Typography>

          <Card
            className="mb1"
            style={{ marginLeft: -12 + 'px', marginRight: -12 + 'px', maxWidth: 'none' }}
          >
            <CardContent>
              <Typography variant="body2" component="p">
                <strong>Fecha última actualización:</strong> {this.state.fechaUltimaActualizacion}
                <br />
                <strong>Empresa:</strong> {this.state.empresaNombre} <br />
                <div className="no-print-rf">
                  <strong>Descargar datos de la empresa </strong>
                  <IconButton
                    className="no-print"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      exportDataEmpresa(this.state.empresaId);
                    }}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <br />
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Imprimir/Descargar SCS Completo
                  </Button>
                  <Button
                    style={{ marginLeft: 10 + 'px' }}
                    href="./files/SCS en Blanco.pdf"
                    download
                    variant="contained"
                    color="primary"
                  >
                    Imprimir/Descargar guía de preguntas
                  </Button>
                  <br />
                  <p style={{ fontSize: 12 + 'px' }}>
                    <strong>Nota:</strong> Si quiere descargar en PDF, en la opción "Destino" debe
                    elegir "Guardar como PDF".{' '}
                    <a href="./img/print-tut.png" target="_blank" style={{ color: '#000' }}>
                      Ver referencia
                    </a>
                  </p>
                </div>
              </Typography>
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            {/* PREGUNTA 1 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar1}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_1')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar1 + '/' + this.state.totalEstandar1}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  {/* Pregunta 1.1 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`1.1 ${this.getEstandarText('estandar_1_1')}`}
                      approved={this.state.estandar_1_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_1_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_1_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_1_1']}
                      value={this.state.estandar_1_1_value}
                      options={[
                        { text: 'Si lo tiene', value: DICCIONARIO_SELECTS['Si lo tiene'] },
                        { text: 'Está en proceso', value: DICCIONARIO_SELECTS['Está en proceso'] },
                        { text: 'No tiene', value: DICCIONARIO_SELECTS['No tiene'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_1_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography
                          style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                          className="no-print"
                        >
                          {this.getEstandarText('estandar_1_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_1_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_1_1_files_directus_files'}
                          files={this.state.estandar_1_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 1.2 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`1.2 ${this.getEstandarText('estandar_1_2')}`}
                      approved={this.state.estandar_1_2_approved}
                    />
                    <QuestionSelect
                      id="estandar_1_2"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_1_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_1_2']}
                      value={this.state.estandar_1_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_1_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_1_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_1_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_1_2_files_directus_files'}
                          files={this.state.estandar_1_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 2 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar2}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_2')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar2 + '/' + this.state.totalEstandar2}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  {/* Pregunta 2.1 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.1 ${this.getEstandarText('estandar_2_1')}`}
                      approved={this.state.estandar_2_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_2_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_2_1']}
                      value={this.state.estandar_2_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_1_files_directus_files'}
                          files={this.state.estandar_2_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 2.2 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.2 ${this.getEstandarText('estandar_2_2')}`}
                      approved={this.state.estandar_2_2_approved}
                    />

                    <QuestionSelect
                      id="estandar_2_2"
                      label="Seleccionar..."
                      showWarning={null}
                      warningMessage={null}
                      value={this.state.estandar_2_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_2_files_directus_files'}
                          files={this.state.estandar_2_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 2.3 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.3 ${this.getEstandarText('estandar_2_3')}`}
                      approved={this.state.estandar_2_3_approved}
                    />

                    <QuestionSelect
                      id="estandar_2_3"
                      label="Seleccionar..."
                      disabled={this.state.estandar_2_3_disabled}
                      showWarning={this.state.estandar_2_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_2_3']}
                      value={this.state.estandar_2_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                        { text: 'No aplica (no trabaja con contratistas)', value: '3' },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_3_files_directus_files'}
                          files={this.state.estandar_2_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 2.4 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.4 ${this.getEstandarText('estandar_2_4')}`}
                      approved={this.state.estandar_2_4_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_4"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_4_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_2_4']}
                      value={this.state.estandar_2_4_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_4_value === '1' ? (
                      <React.Fragment>
                        <Grid item xs={12} className="mb2">
                          {/* 2.3a */}
                          <Typography style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_2_4_files_1')}
                          </Typography>
                          <FilesManager
                            reportId={this.state.reportId}
                            id="estandar_2_4_files_1"
                            unionDataBase={'estandar_2_4_files_1_directus_files'}
                            files={this.state.estandar_2_4_files_1}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} className="mb2">
                          {/* 2.3b */}
                          <Typography style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_2_4_files_2')}
                          </Typography>
                          <FilesManager
                            reportId={this.state.reportId}
                            id="estandar_2_4_files_2"
                            unionDataBase={'estandar_2_4_files_2_directus_files'}
                            files={this.state.estandar_2_4_files_2}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} className="mb2">
                          {/* 2.3c */}
                          <Typography style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_2_4_files_3')}
                          </Typography>
                          <FilesManager
                            reportId={this.state.reportId}
                            id="estandar_2_4_files_3"
                            unionDataBase={'estandar_2_4_files_3_directus_files'}
                            files={this.state.estandar_2_4_files_3}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} className="mb2">
                          {/* 2.3e */}
                          <Typography style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_2_4_files_4')}
                          </Typography>
                          <FilesManager
                            reportId={this.state.reportId}
                            id="estandar_2_4_files_4"
                            unionDataBase={'estandar_2_4_files_4_directus_files'}
                            files={this.state.estandar_2_4_files_4}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 2.5 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.5 ${this.getEstandarText('estandar_2_5')}`}
                      approved={this.state.estandar_2_5_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_5"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_5_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_2_5']}
                      value={this.state.estandar_2_5_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_5_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_5_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_5_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_5_files_directus_files'}
                          files={this.state.estandar_2_5_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 2.6 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.6 ${this.getEstandarText('estandar_2_6')}`}
                      approved={this.state.estandar_2_6_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_6"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_6_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_2_6']}
                      value={this.state.estandar_2_6_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_6_value === '1' ? (
                      <React.Fragment>
                        <Grid item xs={12} className="mb1">
                          <Typography variant="p" className="">
                            {this.getEstandarText('estandar_2_6_1')}
                          </Typography>
                          <TextField
                            id="estandar_2_6_1"
                            label=""
                            multiline
                            fullWidth
                            rows={4}
                            value={this.state.estandar_2_6_1_value}
                            variant="outlined"
                            onChange={(e) => {
                              this.updateTextField('estandar_2_6_1', e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} className="mb2">
                          <Typography style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_2_6_files')}
                          </Typography>
                          <FilesManager
                            id="estandar_2_6_files"
                            reportId={this.state.reportId}
                            unionDataBase={'estandar_2_6_files_directus_files'}
                            files={this.state.estandar_2_6_files}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 3 */}
            <Accordion expanded={true}>
              <AccordionSummary
                //expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar3}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_3')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar3 + '/' + this.state.totalEstandar3}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  {/* Pregunta 3.1 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.1 ${this.getEstandarText('estandar_3_1')}`}
                      approved={this.state.estandar_3_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_3_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_1']}
                      value={this.state.estandar_3_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_1_value === '1' ? (
                      <React.Fragment>
                        <Grid item xs={12} className="mb2">
                          <Typography style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}>
                            {this.getEstandarText('estandar_3_1_files')}
                          </Typography>
                          <FilesManager
                            id="estandar_3_1_files"
                            reportId={this.state.reportId}
                            unionDataBase={'estandar_3_1_files_directus_files'}
                            files={this.state.estandar_3_1_files}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 3.2 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.2 ${this.getEstandarText('estandar_3_2')}`}
                      approved={this.state.estandar_3_2_approved}
                    />

                    <QuestionSelect
                      id="estandar_3_2"
                      label="Seleccionar..."
                      disabled={this.state.estandar_3_2_disabled}
                      showWarning={this.state.estandar_3_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_2']}
                      value={this.state.estandar_3_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                        { text: 'No aplica (no trabaja con contratistas)', value: '3' },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_2_files_directus_files'}
                          files={this.state.estandar_3_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 3.3 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.3 ${this.getEstandarText('estandar_3_3')}`}
                      approved={this.state.estandar_3_3_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_3"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_3']}
                      value={this.state.estandar_3_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_3_files_directus_files'}
                          files={this.state.estandar_3_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 3.4 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.4 ${this.getEstandarText('estandar_3_4')}`}
                      approved={this.state.estandar_3_4_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_4"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_4_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_4']}
                      value={this.state.estandar_3_4_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_4_value === '1' ? (
                      <React.Fragment>
                        <Grid item xs={12} className="mb2">
                          <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_3_4_files')}
                          </Typography>
                          <FilesManager
                            id="estandar_3_4_files"
                            reportId={this.state.reportId}
                            unionDataBase={'estandar_3_4_files_directus_files'}
                            files={this.state.estandar_3_4_files}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 3.5 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.5 ${this.getEstandarText('estandar_3_5')}`}
                      approved={this.state.estandar_3_5_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_5"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_5_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_5']}
                      value={this.state.estandar_3_5_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_5_value === '1' ? (
                      <React.Fragment>
                        <Grid item xs={12} className="mb1">
                          <Typography variant="p" className="">
                            {this.getEstandarText('estandar_3_5_1')}
                          </Typography>
                          <TextField
                            id="estandar_3_5_1"
                            label=""
                            multiline
                            fullWidth
                            rows={4}
                            value={this.state.estandar_3_5_1_value}
                            variant="outlined"
                            onChange={(e) => {
                              this.updateTextField('estandar_3_5_1', e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} className="mb2">
                          <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                            {this.getEstandarText('estandar_3_5_files')}
                          </Typography>
                          <FilesManager
                            id="estandar_3_5_files"
                            reportId={this.state.reportId}
                            unionDataBase={'estandar_3_5_files_directus_files'}
                            files={this.state.estandar_3_5_files}
                            updateParent={(_field, _value) => {
                              this.setState({ [_field]: _value }, () => {
                                this.checkValidity();
                              });
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/*
                   * Provisory Limon Solution
                   * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
                   * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
                   * we should restore the ValidacionesV2 form for Arandanos.
                   * 3.9 was moved to 3.6 in DB.
                   */}
                  {/* Pregunta 3.6 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.6 ${this.getEstandarText('estandar_3_6')}`}
                      approved={this.state.estandar_3_6_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_6"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_6_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_6']}
                      value={this.state.estandar_3_6_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_6_value !== null &&
                    this.state.estandar_3_6_value === '1' ? (
                      <React.Fragment>
                        <QuestionSelect
                          id="estandar_3_6_1"
                          label={this.getEstandarText('estandar_3_6_1')}
                          showWarning={this.state.estandar_3_6_1_warning}
                          warningMessage={DICCIONARIO_WARNINGS['estandar_3_6_1']}
                          value={this.state.estandar_3_6_1_value}
                          options={[
                            { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                            {
                              text: 'No, utiliza otros registros',
                              value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                            },
                          ]}
                          onChange={(id, value) => {
                            this.updateField(id, value);
                          }}
                        />

                        {this.state.estandar_3_6_1_value === '1' ? (
                          <Alert severity="info" className="alert-centered-icon">
                            {this.state.estandar_3_6_1_info}

                            <IconButton
                              className="no-print"
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={() => {
                                exportAppData(['controles_lotes'], this.state.empresaId);
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          </Alert>
                        ) : (
                          <React.Fragment>
                            {this.state.estandar_3_6_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_3_6_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_3_6_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_3_6_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_3_6_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                                    {this.getEstandarText('estandar_3_6_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_3_6_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_3_6_files_directus_files'}
                                    files={this.state.estandar_3_6_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/*
                   * Provisory Limon Solution
                   * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
                   * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
                   * we should restore the ValidacionesV2 form for Arandanos.
                   * 3.9 was moved to 3.6 in DB.
                   */}
                  {/* Pregunta 3.7 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.7 ${this.getEstandarText('estandar_3_7')}`}
                      approved={this.state.estandar_3_7_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_7"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_7_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_7']}
                      value={this.state.estandar_3_7_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_7_value !== null &&
                    this.state.estandar_3_7_value === '1' ? (
                      <React.Fragment>
                        <QuestionSelect
                          id="estandar_3_7_1"
                          label={this.getEstandarText('estandar_3_7_1')}
                          showWarning={this.state.estandar_3_7_1_warning}
                          warningMessage={DICCIONARIO_WARNINGS['estandar_3_7_1']}
                          value={this.state.estandar_3_7_1_value}
                          options={[
                            { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                            {
                              text: 'No, utiliza otros registros',
                              value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                            },
                          ]}
                          onChange={(id, value) => {
                            this.updateField(id, value);
                          }}
                        />

                        {this.state.estandar_3_7_1_value === '1' ? (
                          <Alert severity="info" className="alert-centered-icon">
                            {this.state.estandar_3_7_1_info}

                            <IconButton
                              className="no-print"
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={() => {
                                exportAppData(['controles_entrega_fruta'], this.state.empresaId);
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          </Alert>
                        ) : (
                          <React.Fragment>
                            {this.state.estandar_3_7_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_3_7_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_3_7_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_3_7_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_3_7_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                                    {this.getEstandarText('estandar_3_7_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_3_7_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_3_7_files_directus_files'}
                                    files={this.state.estandar_3_7_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 3.8 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.8 ${this.getEstandarText('estandar_3_8')}`}
                      approved={this.state.estandar_3_8_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_8"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_8_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_8']}
                      value={this.state.estandar_3_8_value}
                      disabled={this.state.estandar_3_8_value === '5'}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_8_value !== null &&
                    this.state.estandar_3_8_value === '1' ? (
                      <React.Fragment>
                        <QuestionSelect
                          id="estandar_3_8_1"
                          label={this.getEstandarText('estandar_3_8_1')}
                          showWarning={this.state.estandar_3_8_1_warning}
                          warningMessage={DICCIONARIO_WARNINGS['estandar_3_8_1']}
                          value={this.state.estandar_3_8_1_value}
                          options={[
                            { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                            {
                              text: 'No, utiliza otros registros',
                              value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                            },
                          ]}
                          onChange={(id, value) => {
                            this.updateField(id, value);
                          }}
                        />

                        {this.state.estandar_3_8_1_value === '1' ? (
                          <Alert severity="info" className="alert-centered-icon">
                            {this.state.estandar_3_8_1_info}

                            <IconButton
                              className="no-print"
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={() => {
                                exportAppData(['logs_usuarios_externos'], this.state.empresaId);
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          </Alert>
                        ) : (
                          <React.Fragment>
                            {this.state.estandar_3_8_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_3_8_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_3_8_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_3_8_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_3_8_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                                    {this.getEstandarText('estandar_3_8_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_3_8_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_3_8_files_directus_files'}
                                    files={this.state.estandar_3_8_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  {/* <ContentSeparator /> */}

                  {/* Pregunta 3.9 */}
                  {/* <React.Fragment>
                    <QuestionTitle
                      title={`3.9 ${this.getEstandarText('estandar_3_9')}`}
                      approved={this.state.estandar_3_9_approved}
                    />

                    <QuestionSelect
                      id="estandar_3_9"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_9_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_3_9']}
                      value={this.state.estandar_3_9_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_9_value !== null ? (
                      <React.Fragment>
                        {this.state.estandar_3_9_value === '1' ? (
                          <React.Fragment>
                            <QuestionSelect
                              id="estandar_3_9_1"
                              label={this.getEstandarText('estandar_3_9_1')}
                              showWarning={false}
                              warningMessage={null}
                              value={this.state.estandar_3_9_1_value}
                              options={[
                                { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                                {
                                  text: 'No, utiliza otros registros',
                                  value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                                },
                              ]}
                              onChange={(id, value) => {
                                this.updateField(id, value);
                              }}
                            />

                            {this.state.estandar_3_9_1_value === '1' ? (
                              <Alert severity="info" className="alert-centered-icon">
                                {this.state.estandar_3_9_1_info}

                                <IconButton
                                  className="no-print"
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  onClick={() => {
                                    exportAppData(['controles_lotes'], this.state.empresaId);
                                  }}
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                              </Alert>
                            ) : null}

                            {this.state.estandar_3_9_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_3_9_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_3_9_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_3_9_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_3_9_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography
                                    style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                                  >
                                    {this.getEstandarText('estandar_3_9_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_3_9_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_3_9_files_directus_files'}
                                    files={this.state.estandar_3_9_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment> */}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 4 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar4}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_4')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar4 + '/' + this.state.totalEstandar4}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  {/* Pregunta 4.1 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.1 ${this.getEstandarText('estandar_4_1')}`}
                      approved={this.state.estandar_4_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_4_1']}
                      value={this.state.estandar_4_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_4_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_1_files_directus_files'}
                          files={this.state.estandar_4_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 4.2 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.2 ${this.getEstandarText('estandar_4_2')}`}
                      approved={this.state.estandar_4_2_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_2"
                      showWarning={this.state.estandar_4_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_4_2']}
                      value={this.state.estandar_4_2_value}
                      disabled={this.state.estandar_4_2_value === '5'}
                      label="Seleccionar..."
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                        {
                          text: 'No corresponde responder',
                          value: DICCIONARIO_SELECTS['No corresponde responder'],
                        },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_4_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_2_files_directus_files'}
                          files={this.state.estandar_4_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 4.3 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.3 ${this.getEstandarText('estandar_4_3')}`}
                      approved={this.state.estandar_4_3_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_3"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_4_3']}
                      value={this.state.estandar_4_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography className="fs-14" style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_4_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_3_files_directus_files'}
                          files={this.state.estandar_4_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  {/* Pregunta 4.4 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.4 ${this.getEstandarText('estandar_4_4')}`}
                      approved={this.state.estandar_4_4_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_4"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_4_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_4_4']}
                      value={this.state.estandar_4_4_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_4_value !== null ? (
                      <React.Fragment>
                        {this.state.estandar_4_4_value === '1' ? (
                          <React.Fragment>
                            <QuestionSelect
                              id="estandar_4_4_1"
                              label={this.getEstandarText('estandar_4_4_1')}
                              showWarning={false}
                              warningMessage={null}
                              value={this.state.estandar_4_4_1_value}
                              options={[
                                { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                                {
                                  text: 'No, utiliza otros registros',
                                  value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                                },
                              ]}
                              onChange={(id, value) => {
                                this.updateField(id, value);
                              }}
                            />

                            {this.state.estandar_4_4_1_value === '1' ? (
                              <Alert severity="info" className="alert-centered-icon">
                                {this.state.estandar_4_4_1_info}

                                <IconButton
                                  className="no-print"
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  onClick={() => {
                                    exportAppData(['incidencias_v2'], this.state.empresaId);
                                  }}
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                              </Alert>
                            ) : null}

                            {this.state.estandar_4_4_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_4_4_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_4_4_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_4_4_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_4_4_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography
                                    style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                                  >
                                    {this.getEstandarText('estandar_4_4_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_4_4_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_4_4_files_directus_files'}
                                    files={this.state.estandar_4_4_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>

                  {/*
                   * Provisory Limon Solution
                   * These fields should be re-enabled for Arandanos 2024 - they were removed ONLY to
                   * make the SCS compatible for Limon as a provisory solution. Once Limon work finishes
                   * we should restore the ValidacionesV2 form for Arandanos.
                   */}
                  <ContentSeparator />

                  {/* Pregunta 4.5 */}
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.5 ${this.getEstandarText('estandar_4_5')}`}
                      approved={this.state.estandar_4_5_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_5"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_5_warning}
                      warningMessage={DICCIONARIO_WARNINGS['estandar_4_5']}
                      value={this.state.estandar_4_5_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_5_value !== null ? (
                      <React.Fragment>
                        {this.state.estandar_4_5_value === '1' ? (
                          <React.Fragment>
                            <QuestionSelect
                              id="estandar_4_5_1"
                              label={this.getEstandarText('estandar_4_5_1')}
                              showWarning={false}
                              warningMessage={null}
                              value={this.state.estandar_4_5_1_value}
                              options={[
                                { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                                {
                                  text: 'No, utiliza otros registros',
                                  value: DICCIONARIO_SELECTS['No, utiliza otros registros'],
                                },
                              ]}
                              onChange={(id, value) => {
                                this.updateField(id, value);
                              }}
                            />

                            {this.state.estandar_4_5_1_value === '1' ? (
                              <Alert severity="info" className="alert-centered-icon">
                                {this.state.estandar_4_5_1_info}

                                <IconButton
                                  className="no-print"
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  onClick={() => {
                                    exportAppData(['controles_lotes'], this.state.empresaId);
                                  }}
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                              </Alert>
                            ) : null}

                            {this.state.estandar_4_5_1_value === '2' ? (
                              <React.Fragment>
                                <Grid item xs={12} className="mb2">
                                  <Typography variant="p" className="">
                                    {this.getEstandarText('estandar_4_5_2')}
                                  </Typography>
                                  <TextField
                                    id="estandar_4_5_2"
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={this.state.estandar_4_5_2_value}
                                    variant="outlined"
                                    onChange={(e) => {
                                      this.updateTextField('estandar_4_5_2', e.target.value);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} className="mb2">
                                  <Typography
                                    style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                                  >
                                    {this.getEstandarText('estandar_4_5_files')}
                                  </Typography>
                                  <FilesManager
                                    id="estandar_4_5_files"
                                    reportId={this.state.reportId}
                                    unionDataBase={'estandar_4_5_files_directus_files'}
                                    files={this.state.estandar_4_5_files}
                                    updateParent={(_field, _value) => {
                                      this.setState({ [_field]: _value }, () => {
                                        this.checkValidity();
                                      });
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Container>

        <div className="no-print-rf">
          <MyBottomNavigation />
        </div>

        {/* Popup Responsables */}
        <Dialog
          open={this.state.showDialogResponsables}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Añadir responsable</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid item xs={12} className="mb1">
                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="outlined-basic1"
                    error={false}
                    label="Nombre*"
                    onChange={(e) => {
                      this.updateTextField('nombreResponsable', e.target.value);
                    }}
                    value={this.state.nombreResponsable}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="outlined-basic1"
                    error={false}
                    label="Rol*"
                    onChange={(e) => {
                      this.updateTextField('rolResponsable', e.target.value);
                    }}
                    value={this.state.rolResponsable}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                this.toggleDialog('DialogResponsables');
                this.cleanResponsableForm();
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                this.agregarResponsable();
              }}
              disabled={this.state.agregarResponsableDisabled}
              variant="contained"
              color="primary"
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Popup Capacitaciones */}
        <Dialog
          open={this.state.showDialogCapacitaciones}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loader show={this.state.showLoaderCapacitacion} />
          <DialogTitle id="alert-dialog-title">Añadir capacitación</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid item xs={12} className="mb1">
                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="outlined-basic4"
                    value={this.state.capacitacionFecha}
                    error={false}
                    onChange={(e) => {
                      this.updateTextField('capacitacionFecha', e.target.value);
                    }}
                    label="Fecha*"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      error: false,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="outlined-basic1"
                    onChange={(e) => {
                      this.updateTextField('capacitacionTema', e.target.value);
                    }}
                    value={this.state.capacitacionTema}
                    label="Tema*"
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="contenidosInput"
                    label="Contenidos*"
                    multiline
                    fullWidth
                    rows={4}
                    value={this.state.capacitacionContenidos}
                    onChange={(e) => {
                      this.updateTextField('capacitacionContenidos', e.target.value);
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="outlined-basic1"
                    onChange={(e) => {
                      this.updateTextField('capacitacionLugar', e.target.value);
                    }}
                    value={this.state.capacitacionLugar}
                    label="Lugar*"
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="asistentensInput"
                    label="Asistentes"
                    multiline
                    fullWidth
                    rows={4}
                    value={this.state.capacitacionAsistentes}
                    onChange={(e) => {
                      this.updateTextField('capacitacionAsistentes', e.target.value);
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    id="observacionesInput"
                    label="Observaciones"
                    multiline
                    fullWidth
                    rows={4}
                    value={this.state.capacitacionObservaciones}
                    onChange={(e) => {
                      this.updateTextField('capacitacionObservaciones', e.target.value);
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb2 mt2">
                  <label htmlFor="test" className="mb-10">
                    Documento de verificación*
                  </label>

                  {this.state.actualizandoCapacitacion ? (
                    <div>
                      <div style={{ color: '#000', marginBottom: 30 + 'px' }}>
                        Archivo actual:{' '}
                        <a
                          href={this.state.capacitacionFileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#000' }}
                        >
                          {this.state.capacitacionFileName}
                        </a>
                      </div>
                      <div style={{ fontStyle: 'italic' }}>
                        Nota: subir un nuevo archivo reemplazará al actual.
                      </div>
                    </div>
                  ) : null}

                  <TextField
                    id="capacitacionDocumentoVerificacion"
                    type="file"
                    label=""
                    variant="outlined"
                    onChange={(e) => {
                      this.updateTextField('capacitacionDocumentoVerificacion', e.target.files[0]);
                    }}
                  />
                </FormControl>
              </Grid>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            {this.state.actualizandoCapacitacion ? (
              <Button
                onClick={() => {
                  this.toggleDialog('DialogCapacitaciones');
                  this.eliminarCapacitacion(
                    this.state.capacitacionId,
                    null,
                    this.state.capacitacionFileId.id,
                  );
                }}
                variant="contained"
                color="primary"
              >
                Eliminar
              </Button>
            ) : null}

            <Button
              onClick={() => {
                this.toggleDialog('DialogCapacitaciones');
                this.cleanCapacitacionForm();
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>

            {this.state.actualizandoCapacitacion ? (
              <Button
                disabled={this.state.agregarCapacitacionDisabled}
                onClick={() => {
                  this.actualizarCapacitacion();
                }}
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            ) : (
              <Button
                disabled={this.state.agregarCapacitacionDisabled}
                onClick={() => {
                  this.agregarCapacitacion();
                }}
                variant="contained"
                color="primary"
              >
                Enviar
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Grid item xs={12} className="mb1"></Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(ValidacionFormulario);
