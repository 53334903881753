import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import MenuIcon from '@material-ui/icons/Menu';

import Badge from '@material-ui/core/Badge';

import PromptMessage from '../Misc/PromptMessage';

import { URLpreffix, APP_VERSION } from '../../config';
import { isLoggedIn, hasAccessTo, isOnlineV2, getUserType } from '../../helpers/helpers';
import { updateDataBases, getPendingsSoSend } from '../../indexedDB/operations';
import { getEmpresa } from '../../requests/data';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      showNoInternetPrompt: false,

      disableUpdateButton: false,
      pending: null,
    };

    this.checkPendings = null;
  }

  componentDidMount() {
    if (this.props.checkPendingsEnabled) {
      getPendingsSoSend().then((p) => {
        this.setState({ pending: p === 0 ? null : p });

        this.checkPendings = setInterval(() => {
          getPendingsSoSend().then((pendings) => {
            this.setState({ pending: pendings === 0 ? null : pendings });
          });
        }, 10000);
      });
    }
  }

  componentWillUnmount() {
    if (this.checkPendings !== null) {
      clearInterval(this.checkPendings);
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  accessSCS = (event) => {
    isOnlineV2().then((hasInternet) => {
      if (hasInternet) {
        // Should check for tipo_cosecha - fetch empresa
        const usuario_empresa_id = localStorage.getItem('usuario_empresa_id');
        getEmpresa(usuario_empresa_id).then((empresas) => {
          console.log('EMPRESA DEL USER');
          console.log(empresas);
          const [empresa] = empresas;

          this.setState({ anchorEl: event.currentTarget });

          if (empresa.tipo_cosecha === 'arandano') {
            window.location.href = URLpreffix + '/FormularioValidacion';
          }

          if (empresa.tipo_cosecha === 'yerba_mate') {
            window.location.href = URLpreffix + '/FormularioValidacionYerbaMate';
          }
        });

        // this.setState({ anchorEl: event.currentTarget });
        // window.location.href = URLpreffix + '/FormularioValidacion';
      } else {
        this.setState({ showNoInternetPrompt: true });
      }
    });
  };

  handleUpdateData = (_preventNoInternetDialog) => {
    this.setState({ disableUpdateButton: true });

    updateDataBases().then((result) => {
      if (result.status === 'ok') {
        this.setState({ disableUpdateButton: false, pending: null });
      }

      if (result.status === 'no_internet') {
        if (_preventNoInternetDialog === true) {
          this.setState({ disableUpdateButton: false, showNoInternetPrompt: false });
        } else {
          this.setState({ disableUpdateButton: false, showNoInternetPrompt: true });
        }
        return;
      }

      this.setState({ disableUpdateButton: false });
      return;
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogOut = () => {
    localStorage.setItem('usuario_logged_in', 'false');
    window.location.replace(URLpreffix + '/');
  };

  handleBackButton = () => {
    if (this.props.forceBackToHome) {
      this.props.history.push(URLpreffix + '/');
      return;
    } else {
      this.props.history.goBack();
      return;
    }
  };

  render() {
    return (
      <div className="navbar-root" class="no-print-rf">
        <PromptMessage
          showDialog={this.state.showNoInternetPrompt}
          closeDialog={() => {
            this.setState({ showNoInternetPrompt: false });
          }}
          title="No hay internet"
          content="Sólo pueden enviarse datos al servidor con conexión a internet."
        />
        <AppBar position="fixed">
          <Toolbar>
            {this.props.disableBackButton ? null : (
              <IconButton
                edge="start"
                className="navbar-menuButton"
                color="inherit"
                aria-label="menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleBackButton}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}

            <Typography variant="h6" className="navbar-title">
              MonitoreoTA Arándanos
            </Typography>

            {this.props.withUpdateButton ? (
              <IconButton
                disabled={this.state.disableUpdateButton}
                edge="start"
                className="navbar-updateButton"
                color="inherit"
                aria-label="menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleUpdateData}
              >
                <Badge badgeContent={this.state.pending} color="error">
                  <AutorenewIcon />
                </Badge>
              </IconButton>
            ) : null}

            <IconButton
              edge="start"
              className="navbar-menuButton"
              color="inherit"
              aria-label="menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose} sx={{ justifyContent: 'center' }}>
                <Link
                  style={{ textDecoration: 'none', color: 'black' }}
                  to={URLpreffix + '/'}
                  className="link"
                >
                  Inicio
                </Link>
              </MenuItem>

              {isLoggedIn() && hasAccessTo('scs') ? (
                <MenuItem onClick={this.accessSCS} sx={{ justifyContent: 'center' }}>
                  SCS
                </MenuItem>
              ) : null}

              {isLoggedIn() ? (
                <MenuItem onClick={this.handleLogOut} sx={{ justifyContent: 'center' }}>
                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={URLpreffix + '/logout'}
                    className="link"
                  >
                    Salir
                  </Link>
                </MenuItem>
              ) : null}

              <p className="text-center">{APP_VERSION}</p>

              {isLoggedIn() && getUserType() === 'empresa' ? (
                <p className="text-center">ID: {localStorage.getItem('usuario_id')}</p>
              ) : null}
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(NavBar);
