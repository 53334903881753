import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import { URLpreffix } from '../../config';

import { checklIfTAExists } from '../../requests/data';


class AltaTrabajadorP2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showScrapFailed: false,
      showError: false,
      inputError: false,
      errorMsg: "",
      dni: ""
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);
    if (queryParams.scrapFailed) this.setState({ showScrapFailed: true });
    localStorage.setItem('stateUID', null);
  }


  onDniChange = (e) => {
    const reg = new RegExp(/^\d+$/);

    if (reg.test(e.target.value) === false && e.target.value !== '') {
      return;
    }

    if (e.target.value.length > 9) return;

    if (e.target.value.length >= 5) this.setState({ inputError: false, errorMsg: "", dni: e.target.value, showError: false, showScrapFailed: false });
    else this.setState({ dni: e.target.value });
  };

  onClickContinue = () => {

    // Checkeo longitud minima de DNI
    if (this.state.dni.length < 5) {
      this.setState({ inputError: true, errorMsg: "Se debe ingresar un DNI válido" });
      return;
    }

    // Para alta TAs siempre hay internet;
    // hago check a DB para ver si el dni ya esta cargado
    checklIfTAExists(this.state.dni)
      .then(exists => {
        if (!exists) {
          localStorage.setItem('stateDni', this.state.dni);
          this.props.history.push(URLpreffix+'/AltaTrabajadorP3');
        } else {
          this.setState({ showError: true });
        }
      });
  };

  render() {
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Alta de T.A.
              </Typography>

              {
                this.state.showScrapFailed === true ? (
                  <Grid item xs={12}>
                    <Alert severity="error" className="">
                      <AlertTitle><b>Datos inaccesibles</b></AlertTitle>
                      No se pudieron encontrar datos con la tarjeta escaneada.<br/>
                      <b>Ingrese el DNI manualmente</b>.
                    </Alert>
                  </Grid>
                ) : (
                  <Typography variant="body1">
                    Ingrese manualmente el número de DNI para saber si se encuentra dado de alta
                  </Typography>
                )
              }

            </Grid>

            <Grid item xs={12} className="mb1">
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic"
                  label="DNI"
                  variant="outlined"
                  type="number"
                  onChange={this.onDniChange}
                  value={this.state.dni}
                  error={this.state.inputError}
                  helperText={this.state.errorMsg}
                />
              </FormControl>
            </Grid>

            {
              this.state.showError === true ? (
                <Grid item xs={12}>
                <Alert severity="warning" className="mb1">
                  <AlertTitle>DNI existente</AlertTitle>
                  Ya existe un/a TA registrado/a con ese número de DNI.
                </Alert>
              </Grid>
              ) : null
            }

            <Grid item xs={12} className="mb1 text-center">
              <Button onClick={this.onClickContinue} variant="contained" color="primary" size="large">
                Comenzar alta
              </Button>

            </Grid>

          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(AltaTrabajadorP2);
