import { API_URI } from '../config';

export const authenticate = (_u, _p) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/auth/authenticate';

    let body = {
      email: _u,
      password: _p,
      mode: 'cookie',
    };

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        resolve(json);
      })
      .catch(() => {
        console.error('Error authenticating user');
        reject();
      });
  });
};

export const logOut = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/auth/logout';

    let body = {};

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Response logout:');
        console.log(json);
        resolve();
      });
  });
};

export const setUserDataLS = (_obj) => {
  console.log('setUserDataLS');
  // UPDATE: ahora recibo un obj de la siguiente estructura:
  // { id, tipo, nombre, apellido, rol, empresa_id, contratista_id }
  // _obj es un object de response de directus:
  // _obj.data.user === 2fa_secret: null
  //                    avatar: null
  //                    company: null
  //                    email: "martinrivelle@gmail.com"
  //                    empresa_id: "0"
  //                    external_id: null
  //                    first_name: "Admin"
  //                    id: "1"
  //                    last_name: "User"
  //                    locale: "en-US"
  //                    locale_options: null
  //                    password_reset_token: null
  //                    role: "1"
  //                    status: "active"
  //                    theme: "auto"
  //                    timezone: "UTC"
  //                    title: null
  //let user = _obj.data.user;
  localStorage.setItem('usuario_logged_in', 'true');
  localStorage.setItem('usuario_tipo', _obj.tipo);
  localStorage.setItem('usuario_id', _obj.id);
  localStorage.setItem('usuario_nombre', _obj.nombre);
  localStorage.setItem('usuario_apellido', _obj.apellido);
  localStorage.setItem('usuario_rol', _obj.rol);
  localStorage.setItem('usuario_empresa_id', _obj.empresa_id);
  localStorage.setItem('usuario_contratista_id', _obj.contratista_id);
  localStorage.setItem('usuario_dni', _obj.usuario_externo_dni);
  localStorage.setItem('usuario_permisos', _obj.permisos);

  // Hardcoded at every APP - Arandanos is a special case where we have to check for "is not any other app" (importante to take into account if a new app is created)
  localStorage.setItem('app', 'arandanos');
};

export const cleanUserDataLS = () => {
  localStorage.setItem('usuario_logged_in', null);
  localStorage.setItem('usuario_tipo', null);
  localStorage.setItem('usuario_id', null);
  localStorage.setItem('usuario_nombre', null);
  localStorage.setItem('usuario_apellido', null);
  localStorage.setItem('usuario_rol', null);
  localStorage.setItem('usuario_empresa_id', null);
  localStorage.setItem('usuario_contratista_id', null);
  localStorage.setItem('usuario_dni', null);
  localStorage.setItem('usuario_permisos', null);
};
