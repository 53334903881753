import React from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { addRecord, updateDataBases } from '../../indexedDB/operations';
import { getCurrentDateTime, getIDInCorrectType, getGeolocation } from '../../helpers/helpers';

export default function IngresoTrabajadorP1(props) {
  let queryParams = qs.parse(props.location.search);

  let history = useHistory();

  const [values, setValues] = React.useState({
    lugarTrabajo: queryParams.lugar ? queryParams.lugar : '',
    capataz: queryParams.capataz ? queryParams.capataz : '',
    lugarTrabajoError: false,
    capatazError: false,
    showLoader: false,
  });

  const idTA = getIDInCorrectType(queryParams.id);
  const operationType = queryParams.type;
  const datetime = getCurrentDateTime();
  const taNombre = queryParams.tanombre;
  const taApellido = queryParams.taapellido;

  let geoLocation = null;
  getGeolocation().then((pos) => (geoLocation = pos));

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, [prop + 'Error']: false });
  };

  const onClickRegister = () => {
    // Validaciones
    let validationSuccess = true;
    let err = {};

    if (values.lugarTrabajo === '') {
      err.lugarTrabajoError = true;
      validationSuccess = false;
    }

    if (values.capataz === '') {
      err.capatazError = true;
      validationSuccess = false;
    }

    if (validationSuccess === false) {
      setValues({ ...values, ...err });
      return;
    }

    setValues({ ...values, showLoader: true });

    let ingreso_egreso = {
      id: 'tmp-ingresoEgresoTA-' + Date.now(),
      empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
      adolescente_id: idTA,
      registrado_usuario_id: parseInt(localStorage.getItem('usuario_id')),
      fecha_horario: datetime,
      geo_lat_long: geoLocation,
      tipo: operationType,
      lugar: values.lugarTrabajo,
      capataz_a_cargo: values.capataz,
    };

    addRecord('ingresos_egresos', ingreso_egreso).then(() => {
      let registerSyncEvent = true;
      updateDataBases(registerSyncEvent).then(() => {
        history.push(URLpreffix + '/IngresoTrabajadorP2');
      });
    });
  };

  return (
    <main>
      <Loader show={values.showLoader} />
      <NavBar />

      <Container maxWidth="sm" className="">
        <Grid container spacing={3}>
          <Grid item xs={12} className="mb2">
            <Typography variant="h5" className="mb1">
              {operationType === 'ingreso' ? 'Ingreso' : 'Egreso'}
            </Typography>

            <Typography variant="body1">
              Se está registrando el {operationType} de {taNombre} {taApellido}.
            </Typography>
          </Grid>

          <Grid item xs={12} className="mb1">
            <FormControl fullWidth variant="outlined" className="mb1">
              <TextField
                error={values.lugarTrabajoError}
                id="outlined-basic1"
                label="Lugar de trabajo*"
                variant="outlined"
                value={values.lugarTrabajo}
                onChange={handleChange('lugarTrabajo')}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" className="mb1">
              <TextField
                error={values.capatazError}
                id="outlined-basic2"
                label="Capataz a cargo*"
                variant="outlined"
                value={values.capataz}
                onChange={handleChange('capataz')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="mb1 text-center">
            <Button onClick={onClickRegister} variant="contained" color="primary" size="large">
              Registrar
            </Button>
          </Grid>
        </Grid>
      </Container>

      <MyBottomNavigation />
    </main>
  );
}
