import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import LogoFooter from '../Misc/LogoFooter';
import { CONTACT_PHONE_NUMBER, WSAP_CHAT_URL } from '../../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  margin: {
    margin: '20px',
  },
  withoutLabel: {
    marginTop: '60px',
  },
  textField: {
    width: '25ch',
  },
  mainContainer: {},
  mb0_5: {
    marginBottom: '10px',
  },
  mb1: {
    marginBottom: '20px',
  },
  mb2: {
    marginBottom: '40px',
  },
  mr1: {
    marginRight: '20px',
  },
  p0: {
    padding: '0px',
  },
  cardContentHome: {
    minHeight: '120px',
  },
  fs13: {
    fontSize: '13px',
  },
  inlineCardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  footerNav: {},
});

export default function InstructivoInstalacion() {
  const classes = useStyles();

  return (
    <main className="Home">
      <NavBar />

      <Container maxWidth="sm" className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography className="mb-15" variant="h5">
            ¿Cómo instalar la WEBAPP y para qué sirve?
          </Typography>
          <p className="mb1">
            Las web apps son sitios webs que se pueden instalar en su celular y utilizar funciones
            especiales como navegación offline y acceso directos desde el escritorio. Para poder
            utilizar la herramienta de monitoreo le recomendamos instalarla en su celular realizando
            los siguiente pasos:
          </p>

          <Typography className="mb1 mt1" variant="h6">
            Instalación
          </Typography>

          <div className="installation-step">
            <Typography className="" variant="h6" color="textSecondary">
              Paso 1:
            </Typography>

            <p>
              <b>Opción A:</b> Si ya ha ingresado puede que Android le ofrezca al pie de página la
              opción: “Agregar Monitoreo TA App a la página de inicio como muestra esta captura:
            </p>
            <div>
              <img
                className="image image-responsive"
                src={'./img/Imagen-instalacion-1.jpg'}
                alt="img1"
              />
            </div>

            <p>
              <b>Opción B:</b> De todas formas usted puede iniciar el proceso de manera manual
              ingresando a menú de Google Chrome de los 3 puntitos
              <div>
                <img
                  className="image"
                  style={{ width: '60px', marginTop: '20px', marginBottom: '20px' }}
                  src={'./img/Imagen-instalacion-2.jpg'}
                  alt="img2"
                />
              </div>
              y presionando en la opción: “Agregar a la pantalla Principal” como muestra la captura:
            </p>
            <div>
              <img
                className="image image-responsive"
                src={'./img/Imagen-instalacion-3.jpg'}
                alt="img3"
              />
            </div>
          </div>

          <div className="installation-step">
            <Typography className="" variant="h6" color="textSecondary">
              Paso 2:
            </Typography>

            <p>Confirme la instalación presionando en “Agregar”</p>
            <div>
              <img
                className="image image-responsive"
                src={'./img/Imagen-instalacion-4.jpg'}
                alt="img4"
              />
            </div>
          </div>

          <div className="installation-step">
            <Typography className="" variant="h6" color="textSecondary">
              Paso 3:
            </Typography>
            <p>
              En el caso de usar un celular con Android, usted podrá ver el avance de la descarga en
              su barra de tareas. En el caso de utilizar un celular con iOS la instalación se hace
              en un segundo plano.
            </p>
            <div>
              <img
                className="image image-responsive"
                src={'./img/Imagen-instalacion-5.jpg'}
                alt="img5"
              />
            </div>
          </div>

          <div className="installation-step">
            <Typography className="" variant="h6" color="textSecondary">
              Paso 4:
            </Typography>
            <p>
              Busque y abra la WEBAPP desde su escritorio y continue las instrucciones de ingreso
              desde allí.
            </p>
            <div>
              <img
                className="image image-responsive"
                src={'./img/Imagen-instalacion-6.jpg'}
                alt="img6"
              />
            </div>
          </div>

          <Typography align="center" variant="h4" className="mt2">
            Ayuda y consultas técnicas
          </Typography>
          <Typography align="center" variant="h6" className="">
            Durante el pilotaje puede contactar
            <br /> al equipo de soporte mediante
            <br /> whastapp al siguiente numero:
            <br />{' '}
            <a href={WSAP_CHAT_URL} target="_blank" rel="noopener noreferrer">
              {CONTACT_PHONE_NUMBER}
            </a>
          </Typography>
        </Grid>

        <LogoFooter />
      </Container>
    </main>
  );
}
