import React from 'react';
import { useHistory } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import AppsIcon from '@material-ui/icons/Apps';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';

import { URLpreffix } from '../../config';
import { getUserType } from '../../helpers/helpers';


export default function MyBottomNavigation() {
  let history = useHistory();

  return (
    <BottomNavigation showLabels className="no-print-rf">
      
      { 
        getUserType() === "empresa" ? <BottomNavigationAction onClick={() => { history.push(URLpreffix+'/'); }} label="Inicio" icon={<AppsIcon />} /> : null
      }

      <BottomNavigationAction onClick={() => { history.push(URLpreffix+'/SituacionRiesgoP1'); }} label="Reportar Situación de Riesgo TI" icon={<FeedbackIcon />} />
      <BottomNavigationAction onClick={() => { history.push(URLpreffix+'/Ayuda'); }} label="Ayuda" icon={<HelpIcon />} />
    </BottomNavigation>
  );
}
