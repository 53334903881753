import React from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import { URLpreffix, PRESCRIPCIONES } from '../../config';


export default function ControlTrabajadorP3(props) {

  let history = useHistory();

  let queryParams = qs.parse(props.location.search);

  let prescripciones = [];
  if (queryParams.codes) {
    let aux = queryParams.codes.split('|');

    for (let i = 0; i < aux.length; i++) {
      if (PRESCRIPCIONES[aux[i]]) {
        prescripciones.push((
          <Alert severity="warning" className="mb1">
            <AlertTitle>Atención necesaria</AlertTitle>
            {PRESCRIPCIONES[aux[i]].response}
          </Alert>
        ));
      }
    }
  }

  return (
    <main>
    <NavBar disableBackButton />

    <Container maxWidth="sm">

    <Grid container spacing={3}>

        <Grid item xs={12} className="mb1">
          <Typography variant="h6" className="mb1">
            Monitoreo del trabajador
          </Typography>

          {
            prescripciones
          }

          <Alert severity="success">
            <AlertTitle>Operación registrada con éxito.</AlertTitle>
          </Alert>
        </Grid>

        <Grid item xs={12} className="mb1 text-center">
          <Button onClick={() => { history.push(URLpreffix+'/homeEmpleados'); }} variant="contained" color="primary" size="large">
            Volver a pantalla de inicio
          </Button>
        </Grid>



      </Grid>

    </Container>

    <MyBottomNavigation />

    </main>

  );
}
