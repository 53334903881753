import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function FloatBar(props) {
  return (
    <AppBar className={'floatbar ' + props.severity} style={{ top: 64 + 'px' }}>
      <Toolbar>
        <Typography className="float-bar-data" variant="h6" color="textSecondary">
          <span className="scs-completado" style={{ opacity: props.opacity }}>
            Completado {props.current}/{props.total}
          </span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
