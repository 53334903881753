import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ListIcon from '@material-ui/icons/List';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import LogoFooter from '../Misc/LogoFooter';

import { URLpreffix } from '../../config';
import { isOnlineV2, hasAccessTo } from '../../helpers/helpers';
import { getPendientes } from '../../indexedDB/operations';

class HomeEmpleadosFull extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showeDialog: false,
      lotesPendientes: null,
      adolescentesPendientes: null,
    };

    this.checkPendings = null;
  }

  componentDidMount() {
    getPendientes(parseInt(localStorage.getItem('usuario_empresa_id'))).then((pendientes) => {
      this.setState({
        lotesPendientes: pendientes.lotes !== 0 ? pendientes.lotes : null,
        adolescentesPendientes: pendientes.adolescentes !== 0 ? pendientes.adolescentes : null,
      });

      this.checkPendings = setInterval(() => {
        getPendientes(parseInt(localStorage.getItem('usuario_empresa_id'))).then((pendientes) => {
          this.setState({
            lotesPendientes: pendientes.lotes !== 0 ? pendientes.lotes : null,
            adolescentesPendientes: pendientes.adolescentes !== 0 ? pendientes.adolescentes : null,
          });
        });
      }, 10000);
    });
  }

  componentWillUnmount() {
    if (this.checkPendings !== null) {
      clearInterval(this.checkPendings);
    }
  }

  goToAltaTrabajador = () => {
    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        // Seteo LS
        localStorage.setItem('stateAltaTrabajador', 'working');
        localStorage.setItem('stateDni', '');
        localStorage.setItem('stateNombre', '');
        localStorage.setItem('stateApellido', '');

        // Inicio proceso de alta
        return this.props.history.push(URLpreffix + '/AltaTrabajadorP1');
      }

      this.setState({ showDialog: true });
      return;
    });
  };

  render() {
    return (
      <main className="Home">
        <NavBar disableBackButton withUpdateButton checkPendingsEnabled />

        <Dialog
          open={this.state.showDialog}
          onClose={() => {
            this.setState({ showDialog: true });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sin conexión a internet</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Las altas de Trabajadores/as Adolescentes sólo pueden hacerse con conexión a internet.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ showDialog: false });
              }}
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Container maxWidth="sm">
          <Typography className="mb-15" variant="h6" color="textSecondary">
            Seleccione una opción
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card className="home-empleados-root">
                <CardActionArea>
                  <CardContent
                    onClick={() => {
                      this.props.history.push(URLpreffix + '/SituacionRiesgoP1');
                    }}
                    className="home-empleados-cardContentHome"
                  >
                    <FeedbackIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                    <Typography variant="body2" color="textSecondary" component="p">
                      Reporte situaciones que pueden ser de riesgo TI, tanto dentro como fuera del
                      campo.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    onClick={() => {
                      this.props.history.push(URLpreffix + '/SituacionRiesgoP1');
                    }}
                    color="primary"
                    className="home-empleados-fs13"
                  >
                    Reporte situación de riesgo TI
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {hasAccessTo('altas') ? (
              <Grid item xs={6}>
                <Card className="home-empleados-root">
                  <CardActionArea>
                    <CardContent
                      onClick={this.goToAltaTrabajador}
                      className="home-empleados-cardContentHome"
                    >
                      <PersonAddIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                      <Typography variant="body2" color="textSecondary" component="p">
                        Todos los <strong>T.A.</strong> deben estar dados de alta.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={this.goToAltaTrabajador}
                      color="primary"
                      className="home-empleados-fs13"
                    >
                      Dar de alta
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            {hasAccessTo('ingresos') ? (
              <Grid item xs={6}>
                <Card className="home-empleados-root">
                  <CardActionArea>
                    <CardContent
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ingresoEgreso');
                      }}
                      className="home-empleados-cardContentHome"
                    >
                      <CompareArrowsIcon
                        className="home-empleados-mb0_5"
                        style={{ fontSize: 40 }}
                      />
                      <Typography variant="body2" color="textSecondary" component="p">
                        Se debe registrar ingresos y egresos al campo del <strong>T.A.</strong>.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ingresoEgreso');
                      }}
                      color="primary"
                      className="home-empleados-fs13"
                    >
                      Ingreso/Egreso
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            {hasAccessTo('monitoreoTA') ? (
              <Grid item xs={6}>
                <Card className="home-empleados-root">
                  <CardActionArea>
                    <CardContent
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlTrabajadorP1');
                      }}
                      className="home-empleados-cardContentHome"
                    >
                      <Badge badgeContent={this.state.adolescentesPendientes} color="error">
                        <CheckCircleOutlineIcon
                          className="home-empleados-mb0_5"
                          style={{ fontSize: 40 }}
                        />
                      </Badge>

                      <Typography variant="body2" color="textSecondary" component="p">
                        Los <strong>T.A.</strong> deben ser monitoreados 1 vez por día.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlTrabajadorP1');
                      }}
                      color="primary"
                      className="home-empleados-fs13"
                    >
                      Monitorear T.A.
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            {hasAccessTo('monitoreoL') ? (
              <Grid item xs={6}>
                <Card className="home-empleados-root">
                  <CardActionArea>
                    <CardContent
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlLoteP1');
                      }}
                      className="home-empleados-cardContentHome"
                    >
                      <CropFreeIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                      <Typography variant="body2" color="textSecondary" component="p">
                        Control de los lotes
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlLoteP1');
                      }}
                      color="primary"
                      className="home-empleados-fs13"
                    >
                      Monitorear lote
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            {hasAccessTo('monitoreoEF') ? (
              <Grid item xs={6}>
                <Card className="home-empleados-root">
                  <CardActionArea>
                    <CardContent
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlEntregaFrutaP1');
                      }}
                      className="home-empleados-cardContentHome"
                    >
                      <AllInboxIcon className="home-empleados-mb0_5" style={{ fontSize: 40 }} />
                      <Typography variant="body2" color="textSecondary" component="p">
                        Control de puntos de entrega de fruta
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => {
                        this.props.history.push(URLpreffix + '/ControlEntregaFrutaP1');
                      }}
                      color="primary"
                      className="home-empleados-fs13"
                    >
                      Monitorear punto
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            {hasAccessTo('listadoTA') ? (
              <Grid item xs={12}>
                <Card
                  onClick={() => {
                    this.props.history.push(URLpreffix + '/ListadoTrabajadoresP1');
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        variant="body2"
                        className="home-empleados-inlineCardTitle"
                        component="p"
                      >
                        <ListIcon style={{ fontSize: 40 }} />
                        <Button color="primary" className="home-empleados-fs13">
                          Ver lista de T.A. registrados
                        </Button>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : null}
          </Grid>

          <LogoFooter></LogoFooter>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(HomeEmpleadosFull);
