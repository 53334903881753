import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer';

import { dbInit } from './indexedDB/operations';

// Store de Redux; no se usa
const store = createStore(reducer);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

/////////////////////////////////////////////////////////
// Al iniciar la app, si la DB no se encuenta en el    //
// dispositivo, se crea la estructura                  //
dbInit().then(() => {
  console.log('Pase por aca init DB!');
});

ReactDOM.render(app, document.getElementById('root'));

// Registro del SW
//serviceWorker.unregister();
serviceWorker.register({
  // Al actualizar el SW, se fuerza un alert que obliga
  // a realizar la accion "skip-waiting" y luego recarga
  // la app para que los cambios tengan efecto
  onUpdate: (registration) => {
    alert("¡Nueva versión disponible!  Presione 'Aceptar' para actualizar.");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  },
  onSuccess: (registration) => {
    registration.addEventListener('fetch', (event) => {
      event.respondWith(fetch(event.request));
    });
  },
});
