import React from 'react';

import { getAllRecords } from '../indexedDB/operations';

import NavBar from './Navigation/NavBar';

import { DEV_SERVER, API_URI } from '../config';

import { testRequest } from '../requests/test';
import { testLogOut } from '../requests/test';
import { getUserData } from '../requests/test';
import { createTestDirectusItem } from '../requests/test';

import { isOnlineV2 } from '../helpers/helpers';
import { getItemsFromCollection, sendNewItem } from '../requests/data';
import { updateToLastRemoteVersion } from '../indexedDB/operations';
import {
  testFunc,
  getRecordsFlex,
  getRecords,
  getAdolescentesByEmpresa,
  getLogs,
  addRecord,
} from '../indexedDB/operations';

import QrReader from 'react-qr-scanner';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileToSend: null,
      lotesAsignados: '',
      date1: '',
      date2: '',
      cantidadLotes: 0,
      logs: null,
      testState: 'sin cambios',
      todos: [],
      userLogeado: false,
      userName: null,
      userMail: null,
      qrReadResult: 'No result',
      appMode: false,
      activateQrReader: false,
    };
  }

  componentDidMount() {
    testRequest().then((r) => this.setState({ testState: r }));

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.setState({ appMode: true });
    }

    getLogs(false).then((logs) => {
      let arr = logs.map((l) => {
        let color = 'black';
        if (l.description.includes('OK')) color = 'green';
        if (l.description.includes('WARN')) color = 'orange';
        if (l.description.includes('ERR')) color = 'red';

        return (
          <div style={{ marginLeft: '5px' }}>
            {l.id} - <span style={{ color: color }}>{l.description}</span>
          </div>
        );
      });
      this.setState({ logs: arr });
    });
  }

  authenticate = () => {};

  logOut = () => {
    testLogOut().then(() => {
      console.log('End logOut req...');
    });
  };

  userData = () => {
    getUserData().then((d) => {
      if (d.data) {
        this.setState({
          userLogeado: true,
          userName: d.data.first_name + ' ' + d.data.last_name,
          userMail: d.data.email,
        });
      } else {
        this.setState({ userLogeado: false, userName: null, userMail: null });
      }

      console.log('End getuserdata...');
    });
  };

  create = () => {
    createTestDirectusItem().then(() => {
      console.log('Se creo el Item...');
    });
  };

  testFetch = () => {
    fetch('/')
      .then(function (response) {
        return response;
      })
      .then(function (myJson) {
        console.log('Fetch exitoso!');
      });
  };

  testGetAll = () => {
    getAllRecords('adolescentes')
      .then((data) => {
        console.log('All records de adolescentes:');
        console.log(data);
      })
      .catch((msg) => console.log('Catch de testGetAll ', msg));
  };

  testGetAllForcedError = () => {
    getAllRecords('asdcentes')
      .then((data) => {
        console.log('All records de adolescentes:');
        console.log(data);
      })
      .catch((msg) => console.error('Catch de testGetAll ', msg));
  };

  getItemsTest = () => {
    getItemsFromCollection('adolescentes', true).then((items) => {
      console.log('Then de getItemsFromCollection:');
      console.log(items);
    });
  };

  getLastDB = () => {
    updateToLastRemoteVersion().then(() => {
      console.log('Termino la actualizacion de la DB...');
    });
  };

  clearTableTest = () => {
    testFunc();
  };

  qrHandleError = () => {
    console.log('Error en QR READER');
  };

  qrHhandleScan = (res) => {
    console.log(res);
    if (res !== null && res !== '') this.setState({ qrReadResult: res });
  };

  testGetRecordsFlex = () => {
    getRecordsFlex('adolescentes', 'id', 'tmp-').then((items) => {
      console.log('CB de getRecordsFlex!');
      console.log(items);
    });
  };

  testGetRecords = () => {
    getRecords('contratistas', { empresa_asociada_id: 1 }).then((items) => {
      console.log('CB getRecords:');
      console.log(items);
    });
  };

  getTAsByEmpresa = () => {
    getAdolescentesByEmpresa(1, { dni: '36082321' }).then((tas) => {
      console.log('CB de getadolescentesbyempresa!');
      console.log(tas);
    });
  };

  getAllLogs = () => {
    getLogs(true).then((logs) => {
      console.log(logs);
      let arr = logs.map((l) => {
        let color = 'black';
        if (l.description.includes('OK')) color = 'green';
        if (l.description.includes('WARN')) color = 'orange';
        if (l.description.includes('ERR')) color = 'red';

        return (
          <div style={{ marginLeft: '5px' }}>
            {l.time} - <span style={{ color: color }}>{l.description}</span>
          </div>
        );
      });
      this.setState({ logs: arr });
    });
  };

  insertLog() {
    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        addRecord('logs', { id: Date.now().toString(), description: 'Insertado manualmente' }).then(
          () => {
            getLogs(false).then((logs) => {
              let arr = logs.map((l) => {
                let color = 'black';
                return (
                  <div style={{ marginLeft: '5px' }}>
                    {l.id} - <span style={{ color: color }}>{l.description}</span>
                  </div>
                );
              });
              this.setState({ logs: arr });
              alert('Log insertado con exito');
            });
          },
        );
      } else {
        if ('serviceWorker' in navigator && 'SyncManager' in window) {
          console.log('Por registrar una req de sync...');
          navigator.serviceWorker.ready.then(function (sw) {
            sw.sync.register('sync-database');
            console.log('Sync req registrada!!!');
            alert('Se ingresara un log cuando vuelva a haber internet');
          });
        }
      }
    });
  }

  sendItemTest() {
    //adolescente_id: 5 adolescente_id	int(10) unsigned
    //capataz_a_cargo: "Roberto Test" capataz_a_cargo	varchar(200)
    //empresa_id: 1 empresa_id	int(10) unsigned
    //fecha_horario: "2020-08-10 16:13:38" fecha_horario	datetime
    //geo_lat_long: "lat:-34.6128384,long:-58.4024064" geo_lat_long	text NULL
    //lugar: "Lugar de Ejemplo" lugar	varchar(200)
    //registrado_usuario_id: 3 registrado_usuario_id	int(10) unsigned
    //tipo: "ingreso" tipo	varchar(100)

    console.log('sendItemTest');
    let data = {
      adolescente_id: 5,
      capataz_a_cargo: 'Roberto Test',
      empresa_id: 1,
      fecha_horario: '2020-08-10 16:13:38',
      geo_lat_long: 'lat:-34.6128384,long:-58.4024064',
      lugar: 'Lugar de Ejemplo',
      registrado_usuario_id: 3,
      tipo: 'ingreso',
    };
    console.log(data);
    sendNewItem('ingresos_egresos', data)
      .then(() => console.log('Enviado'))
      .catch((err) => console.log(err));
  }

  calcularLotesAleatorios = () => {
    // Pares de lotes
    let lotes = 1;
    let paresLotes = lotes / 2;
    if (!Number.isInteger(paresLotes)) paresLotes = parseInt(paresLotes + 0.5);

    // 1ro de Julio
    let fixedDate = new Date('2020-07-01T04:00:00');
    let varDate = new Date('2020-07-07T04:00:00');

    let msDiff = varDate.getTime() - fixedDate.getTime();
    let daysDiff = Math.floor(msDiff / (1000 * 3600 * 24));

    let aux = daysDiff;
    if (aux > paresLotes) {
      while (aux > paresLotes) {
        aux -= paresLotes;
      }
    }
  };

  onChangeFile = (e) => {
    console.log(e.target.files);
    this.setState({ fileToSend: e.target.files[0] });
  };

  onClickEnviarArchivo = () => {
    const file = this.state.fileToSend;
    console.log(file);
    const formData = new FormData();

    formData.append('file', file);

    console.log(formData);

    const options = {
      method: 'POST',
      body: formData,
      // If you add this, upload won't work
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // }
    };

    fetch(API_URI + '/files', options)
      .then((resp) => resp.json())
      .then((json) => console.log(json))
      .catch((err) => console.error(err));
  };

  render() {
    const previewStyle = {
      height: 400,
      width: 400,
    };

    return (
      <React.Fragment>
        <NavBar />
        <h1>
          Home page {DEV_SERVER === 1 ? 'dev env' : 'prod env'} {this.state.testState}
        </h1>
        <h1>Esta en modo APP? {this.state.appMode === true ? 'Si' : 'No'}</h1>
        <button onClick={this.authenticate}>Authenticate rivellemartin@gmail.com</button>
        <br />
        <button onClick={this.logOut}>Log out</button>
        <br />
        <button onClick={this.userData}>User data</button>
        <br />
        <button onClick={this.create}>Create item</button>
        <br />
        <button onClick={this.getCollectionItems}>Get items collection test_directus</button>
        <br />
        <br />
        <button onClick={this.getItemsTest}>Get items con y sin paginacion</button>
        <br />
        <button onClick={this.getLastDB}>Get last remote version</button>
        <br />
        <button onClick={this.clearTableTest}>Clear table</button>
        <br />
        <button onClick={this.testGetRecords}>Get records</button>
        <br />
        <button onClick={this.testGetRecordsFlex}>Get records flex</button>
        <br />
        <button onClick={this.getTAsByEmpresa}>Get TAs by Empresa</button>
        <br />
        <button onClick={this.sendItemTest}>Send Item Test</button>
        <br />
        <br />
        <br />
        <br />
        <br />

        <input type="file" onChange={this.onChangeFile} />
        <button onClick={this.onClickEnviarArchivo}>Enviar archivo</button>

        <br />
        <br />
        <br />
        <br />

        <label>Date 1</label>
        <input
          type="text"
          onChange={(e) => {
            this.setState({ date1: e.target.value });
          }}
        />
        <br />
        <label>Date 2</label>
        <input
          type="text"
          onChange={(e) => {
            this.setState({ date2: e.target.value });
          }}
        />
        <br />
        <label>Cantidad de lotes</label>
        <input
          type="text"
          onChange={(e) => {
            this.setState({ cantidadLotes: e.target.value });
          }}
        />
        <br />
        <button onClick={this.calcularLotesAleatorios}>Calcular</button>
        <br />
        <br />
        {this.state.lotesAsignados}

        <br />
        <br />
        <br />
        <br />
        <p>
          <b>Test Background Sync;</b> Si el dispositivo esta online, "Insert Log" guarda
          directamente una entrada en DB, de lo contrario se genera un Sync event que cargara una
          entrada cuando vuelva a haber conexion.
          <br />
          Nota: para visualizar el log cargado en el sync event hay que actualizar la page o usar el
          boton "Actualizar".
        </p>
        <button
          onClick={() => {
            this.insertLog();
          }}
        >
          Insert Log
        </button>
        <button
          onClick={() => {
            getLogs(false).then((logs) => {
              let arr = logs.map((l) => {
                let color = 'black';
                return (
                  <div style={{ marginLeft: '5px' }}>
                    {l.id} - <span style={{ color: color }}>{l.description}</span>
                  </div>
                );
              });
              this.setState({ logs: arr });
            });
          }}
        >
          Actualizar
        </button>
        <br />
        {this.state.logs}
        <br />
        <br />
        <br />
        <br />

        <button
          onClick={() => {
            if ('serviceWorker' in navigator && 'SyncManager' in window) {
              console.log('Por registrar una req de sync...');
              navigator.serviceWorker.ready.then(function (sw) {
                sw.sync.register('sync-database');
                console.log('Sync req registrada!!!');
              });
            } else {
              console.log('Problema con serviceWorker o SyncManager');
            }
          }}
        >
          Queue sync request
        </button>
        <br />
        <br />
        <br />
        <br />

        <h3>Tests DexieJS</h3>
        <button onClick={this.testGetAll}>Test Get All and Catch</button>
        <br />
        <button onClick={this.testGetAllForcedError}>Test Get All ForcedError</button>
        <br />
        <br />
        <br />

        <h3>TEST LOGIN PERSISTENTE</h3>
        <button onClick={this.userData}>Todavia estoy logeado?</button>
        <br />
        <h4>Logeado: {this.state.userLogeado === false ? 'NO' : 'SI'}</h4>
        <h4>Nombre: {this.state.userName}</h4>
        <h4>Nombre: {this.state.userMail}</h4>
        <br />
        <br />

        <h1>Nuevo content</h1>
        <h1>Nuevo content 2</h1>
        <h1>Nuevo content 3</h1>
        <h1>Nuevo content 4</h1>
        <button onClick={this.testFetch}>Test fetch</button>
        <br />
        <h1>Online logs: {localStorage.getItem('online_logs')}</h1>
        <h1>Offline logs: {localStorage.getItem('offline_logs')}</h1>

        <button
          onClick={() => {
            this.setState({ activateQrReader: !this.state.activateQrReader });
          }}
        >
          Activar escaner QR
        </button>
        <br />
        {this.state.activateQrReader === true ? (
          <div>
            <QrReader
              delay={this.state.delay}
              style={previewStyle}
              onError={this.qrHandleError}
              onScan={this.qrHhandleScan}
            />
            <p>{this.state.qrReadResult}</p>
          </div>
        ) : null}

        <br />
        <br />
        <br />
        <br />
        <br />

        <button onClick={this.getAllLogs}>Mostrar TODOS</button>
        <br />
        {this.state.logs}
      </React.Fragment>
    );
  }
}

export default Home;
