import React from 'react';
import Avatar from '@material-ui/core/Avatar';

export default function CardAvatar(props) {
  return (
    <Avatar aria-label="recipe">
      {
        props.nombre[0]
      }
    </Avatar>
  )
}