import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Alert, AlertTitle } from '@material-ui/lab';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import DoubleCheck from '../Misc/DoubleCheck';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';

import { getCurrentDateTime, getGeolocation, getUserType } from '../../helpers/helpers';
import { getRecords, addRecord, updateDataBases } from '../../indexedDB/operations';

class ControlEntregaFrutaP2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      switchLabel: 'No',
      listaContratistas: null,
      loteNombre: '',

      showAlertCasoSospechoso: 'none',
      showLoader: false,

      contratista_id: '',

      lote_id: 0, // desde querystring
      fecha_horario: getCurrentDateTime(),
      capataz: '',
      geo_lat_long: null, // Se actualiza si el user tiene y permite geolocation
      control_dni: null,
      caso_sospechoso: null,
      observaciones: '',
      motivo_no_cosecha: '',
      dni_fecha_nacimiento_trabajador_1: '',
      dni_fecha_nacimiento_trabajador_2: '',
      dni_fecha_nacimiento_trabajador_3: '',
      dni_fecha_nacimiento_trabajador_4: '',
      dni_fecha_nacimiento_trabajador_5: '',

      contratista_idError: false,
      capatazError: false,
      control_dniError: false,
      caso_sospechosoError: false,
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);

    getGeolocation().then((g) => this.setState({ geo_lat_long: g }));

    getRecords('contratistas', {
      empresa_asociada_id: parseInt(localStorage.getItem('usuario_empresa_id')),
    }).then((contratistas) => {
      let arr = contratistas.map((c) => <option value={c.id}>{c.nombre}</option>);
      this.setState({
        loteNombre: queryParams.nombre,
        lote_id: parseInt(queryParams.lote_id),
        listaContratistas: arr,
      });
    });
  }

  handleChange = (prop) => (e) => {
    this.setState({ [prop]: e.target.value, [prop + 'Error']: false, showEnviarMessage: false });
  };

  handleDoubleCheckChange = (s) => {
    let showAlertCasoSospechoso = false;
    if (s.name === 'caso_sospechoso') {
      showAlertCasoSospechoso = s.selection;
    }

    this.setState({
      [s.name]: s.selection,
      showAlertCasoSospechoso: showAlertCasoSospechoso,
      [s.name + 'Error']: false,
      showEnviarMessage: false,
    });
  };

  onClickEnviar = () => {
    this.setState({ showLoader: true });

    let control;
    // Si hay cosecha se valida...
    let validationSuccess = true;
    let obj = {};
    if (this.state.showForm) {
      if (this.state.contratista_id === '') {
        obj.contratista_idError = true;
        validationSuccess = false;
      }

      if (this.state.caso_sospechoso === null) {
        obj.caso_sospechosoError = true;
        validationSuccess = false;
      }

      if (this.state.capataz === '') {
        obj.capatazError = true;
        validationSuccess = false;
      }

      if (this.state.dni_fecha_nacimiento_trabajador_1 === '') {
        obj.dni_fecha_nacimiento_trabajador_1Error = true;
        validationSuccess = false;
      }

      if (this.state.dni_fecha_nacimiento_trabajador_2 === '') {
        obj.dni_fecha_nacimiento_trabajador_2Error = true;
        validationSuccess = false;
      }

      if (this.state.dni_fecha_nacimiento_trabajador_3 === '') {
        obj.dni_fecha_nacimiento_trabajador_3Error = true;
        validationSuccess = false;
      }

      if (this.state.dni_fecha_nacimiento_trabajador_4 === '') {
        obj.dni_fecha_nacimiento_trabajador_4Error = true;
        validationSuccess = false;
      }

      if (this.state.dni_fecha_nacimiento_trabajador_5 === '') {
        obj.dni_fecha_nacimiento_trabajador_5Error = true;
        validationSuccess = false;
      }

      if (validationSuccess === false) {
        this.setState({ ...obj, showLoader: false, showEnviarMessage: true });
        return;
      }

      control = {
        id: 'tmp-controlEntregaFruta-' + Date.now(),
        hay_cosecha: true,
        contratista_id: parseInt(this.state.contratista_id),

        registrado_usuario_id: this.state.registrado_usuario_id,

        usuario_externo_nombre:
          getUserType() === 'capataz'
            ? `${localStorage.getItem('usuario_nombre')} ${localStorage.getItem(
                'usuario_apellido',
              )}`
            : null,
        usuario_externo_dni:
          getUserType() === 'capataz' ? `${localStorage.getItem('usuario_dni')}` : null,

        lote_id: this.state.lote_id, // desde querystring
        fecha_horario: this.state.fecha_horario,
        capataz: this.state.capataz,
        geo_lat_long: this.state.geo_lat_long, // Se actualiza si el user tiene y permite geolocation
        caso_sospechoso: this.state.caso_sospechoso,
        observaciones: this.state.observaciones === '' ? null : this.state.observaciones,
        motivo_no_cosecha: '',
        dni_fecha_nacimiento_trabajador_1: this.state.dni_fecha_nacimiento_trabajador_1,
        dni_fecha_nacimiento_trabajador_2: this.state.dni_fecha_nacimiento_trabajador_2,
        dni_fecha_nacimiento_trabajador_3: this.state.dni_fecha_nacimiento_trabajador_3,
        dni_fecha_nacimiento_trabajador_4: this.state.dni_fecha_nacimiento_trabajador_4,
        dni_fecha_nacimiento_trabajador_5: this.state.dni_fecha_nacimiento_trabajador_5,
      };
    } else {
      // Este dia no hay cosecha... Envio un control de 'hay_cosecha' = false
      if (this.state.motivo_no_cosecha === '') {
        obj.motivo_no_cosechaError = true;
        validationSuccess = false;
      }

      if (validationSuccess === false) {
        this.setState({ ...obj, showLoader: false, showEnviarMessage: true });
        return;
      }

      control = {
        id: 'tmp-controlEntregaFruta-' + Date.now(),
        hay_cosecha: false,
        contratista_id: parseInt(this.state.contratista_id),

        registrado_usuario_id: this.state.registrado_usuario_id,

        usuario_externo_nombre:
          getUserType() === 'capataz'
            ? `${localStorage.getItem('usuario_nombre')} ${localStorage.getItem(
                'usuario_apellido',
              )}`
            : null,
        usuario_externo_dni:
          getUserType() === 'capataz' ? `${localStorage.getItem('usuario_dni')}` : null,

        lote_id: this.state.lote_id, // desde querystring
        fecha_horario: this.state.fecha_horario,
        capataz: null,
        geo_lat_long: this.state.geo_lat_long, // Se actualiza si el user tiene y permite geolocation
        caso_sospechoso: null,
        observaciones: this.state.observaciones === '' ? null : this.state.observaciones,
        motivo_no_cosecha: this.state.motivo_no_cosecha,
        dni_fecha_nacimiento_trabajador_1: '',
        dni_fecha_nacimiento_trabajador_2: '',
        dni_fecha_nacimiento_trabajador_3: '',
        dni_fecha_nacimiento_trabajador_4: '',
        dni_fecha_nacimiento_trabajador_5: '',
      };
    }

    addRecord('control_entrega_fruta', control)
      .then(() => {
        let registerSyncEvent = true;
        updateDataBases(registerSyncEvent).then(() => {
          if (this.state.caso_sospechoso === true) {
            this.props.history.push(
              URLpreffix + '/SituacionRiesgoP1?lote_id=' + this.state.lote_id,
            );
          } else {
            this.props.history.push(URLpreffix + '/ControlEntregaFrutaP3');
          }
        });
      })
      .catch((err) => console.error(err));
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />

        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Monitoreo del punto de entrega correspondiente al lote{' '}
                <strong>{this.state.loteNombre}</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <Alert severity="">
                <AlertTitle>¿Hoy se cosecha en este lote?</AlertTitle>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={() => {
                          this.setState({
                            showForm: !this.state.showForm,
                            switchLabel: this.state.showForm ? 'No' : 'Si',
                          });
                        }}
                      />
                    }
                    label={this.state.switchLabel}
                  />
                </FormGroup>
              </Alert>
            </Grid>

            {this.state.showForm ? (
              <Grid item xs={12} className="">
                <Typography variant="p" className="labelCheckbox mb2 mt1">
                  1. Datos del lote:
                </Typography>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <InputLabel
                    error={this.state.contratista_idError}
                    htmlFor="outlined-contratista-native-simple"
                  >
                    Contratista*
                  </InputLabel>
                  <Select
                    native
                    label="Contratista"
                    error={this.state.contratista_idError}
                    inputProps={{
                      name: 'Contratista',
                      id: 'outlined-contratista-native-simple',
                    }}
                    onChange={this.handleChange('contratista_id')}
                  >
                    <option aria-label="None" value="" />
                    {this.state.listaContratistas}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className="">
                  <TextField
                    id="outlined-basic"
                    error={this.state.capatazError}
                    onChange={this.handleChange('capataz')}
                    label="Capataz o cuadrillero a cargo"
                    variant="outlined"
                  />
                </FormControl>

                <Typography variant="p" className="labelCheckbox mb2 mt1">
                  2. Monitoreo:
                </Typography>

                <Box>
                  <Typography>
                    Controlar DNI o Fecha de Nacimiento de cinco trabajadores/as:
                  </Typography>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic"
                      error={this.state.dni_fecha_nacimiento_trabajador_1Error}
                      onChange={this.handleChange('dni_fecha_nacimiento_trabajador_1')}
                      label="Trabajador/a 1"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic"
                      error={this.state.dni_fecha_nacimiento_trabajador_2Error}
                      onChange={this.handleChange('dni_fecha_nacimiento_trabajador_2')}
                      label="Trabajador/a 2"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic"
                      error={this.state.dni_fecha_nacimiento_trabajador_3Error}
                      onChange={this.handleChange('dni_fecha_nacimiento_trabajador_3')}
                      label="Trabajador/a 3"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic"
                      error={this.state.cdni_fecha_nacimiento_trabajador_4Error}
                      onChange={this.handleChange('dni_fecha_nacimiento_trabajador_4')}
                      label="Trabajador/a 4"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="">
                    <TextField
                      id="outlined-basic"
                      error={this.state.dni_fecha_nacimiento_trabajador_5Error}
                      onChange={this.handleChange('dni_fecha_nacimiento_trabajador_5')}
                      label="Trabajador/a 5"
                      variant="outlined"
                    />
                  </FormControl>
                </Box>

                <DoubleCheck
                  onChange={this.handleDoubleCheckChange}
                  error={this.state.caso_sospechosoError}
                  name="caso_sospechoso"
                  withLabels
                  text="¿Registra situación de riesgo TI?"
                />

                {this.state.showAlertCasoSospechoso === true ? (
                  <Alert severity="warning">
                    <AlertTitle>Situación de riesgo TI.</AlertTitle>
                    Por favor envie el reporte para continuar con el registro de la situación.
                  </Alert>
                ) : null}

                <Typography variant="p" className="labelCheckbox mb1 mt1">
                  Observaciones
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  fullWidth
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={this.handleChange('observaciones')}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Typography variant="p" className="labelCheckbox mb1 mt1">
                  Motivo por el que no hay cosecha:
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  fullWidth
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={this.handleChange('motivo_no_cosecha')}
                />
              </React.Fragment>
            )}

            {this.state.showEnviarMessage ? (
              <Grid item xs={12} className="mt1">
                <Alert severity="error">
                  <AlertTitle>
                    <b>Campos sin completar.</b>
                  </AlertTitle>
                  Falta completar campos obligatorios.
                </Alert>
              </Grid>
            ) : null}

            {!this.state.showForm ? (
              <React.Fragment>
                <Typography variant="p" className="labelCheckbox mb1 mt1">
                  Observaciones
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  fullWidth
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={this.handleChange('observaciones')}
                />
              </React.Fragment>
            ) : null}

            <Grid item xs={12} className="mb1 text-center">
              <Button onClick={this.onClickEnviar} variant="contained" color="primary" size="large">
                Enviar monitoreo
              </Button>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ControlEntregaFrutaP2);
