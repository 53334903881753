import React from 'react';
import Grid from '@material-ui/core/Grid';


export default function LogoFooter() {
  return (
    <Grid className="mt2" container spacing={3}>
      <Grid className="text-center" item xs={12}>
        <img className="logo-par" src={"./img/logo-par-v2.jpg"} alt="" />
      </Grid>

      <Grid item xs={12}>
        <hr className="logo-hr" />
      </Grid>

      <Grid className="text-center" item xs={6}>
        <img className="logo-par" src={"./img/logo-dya.jpg"} alt="" />
      </Grid>

      <Grid item xs={6}>
        <img className="logo-abc" src={"./img/logo-abc.jpg"} alt="" />
      </Grid>
    </Grid>
  );
}
