import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigationC from '../../Navigation/MyBottomNavigationC';
import DoubleCheck from '../../Misc/DoubleCheck';
import Loader from '../../Misc/Loader';

import { URLpreffix } from '../../../config';
import {
  getAdolescentesByEmpresa,
  addRecord,
  updateDataBases,
} from '../../../indexedDB/operations';
import { getCurrentDateTime, getGeolocation, getIDInCorrectType } from '../../../helpers/helpers';

const listaTareas = [
  'Laborales administrativas',
  'Control planillas',
  'Control de recepción de fruta',
  'Control de peso de las bandejas',
  'Llevar el listado de bandejas entregadas por cosechero',
  'Entrega de fichas por bandeja cosechada',
  'Monitoreo de personal de campo',
  'Identificación de plagas y enfermedades',
  'Control de calidad de fruta',
  'Otras',
];

//////////////////////////////////////////////////////////////
// UPDATE 27/08/2020; controlTA UE segun doc "Cuestionario  //
// sobre Control Capataz".                                  //
//    El formulario se reduce en campos y algunos cambian;  //
//    a partir de la  actualizacion, controlTA empleado y   //
//    controlTA UE son entidades totalmente distintas.      //
//////////////////////////////////////////////////////////////

class ControlTrabajadorUEP2 extends React.Component {
  constructor(props) {
    super(props);

    let queryParams = qs.parse(props.location.search);
    let d = new Date();
    let year = d.getFullYear();

    this.state = {
      showLoader: true,
      showRegistrarControlMessage: false,
      load: false,

      showForm: true, // No hay switch que habilite el form
      otrasEnabled: false,

      nombreTA: '',
      apellidoTA: '',
      dniTA: '',

      otraTarea: '',

      id: 'tmp-controlTA-' + Date.now(),
      empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
      adolescente_id: getIDInCorrectType(queryParams.idTA),
      contratista_id: getIDInCorrectType(queryParams.idContratista),
      lote_id: getIDInCorrectType(queryParams.idLote),
      semana: queryParams.numeroSemana + '/' + year,
      dni_usuario_externo: parseInt(localStorage.getItem('usuario_dni')),
      fecha_horario: getCurrentDateTime(),
      geo_lat_long: null,

      // Del form
      tareas_realizadas: '',
      acceso_agua: '',
      utilizo_herramienta_maquinaria: '',
      utilizo_proteccion: '',
      fue_alguien_empresa: '',
      observaciones: '',

      tareas_realizadasError: false,
      acceso_aguaError: false,
      utilizo_herramienta_maquinariaError: false,
      utilizo_proteccionError: false,
      fue_alguien_empresaError: false,
    };
  }

  componentWillMount() {
    this.tareasRealizadas = new Set();
  }

  componentDidMount() {
    let empresaID = parseInt(localStorage.getItem('usuario_empresa_id'));

    getAdolescentesByEmpresa(empresaID, { id: this.state.adolescente_id }).then((a) => {
      getGeolocation().then((pos) =>
        this.setState({
          load: true,
          geo_lat_long: pos,
          nombreTA: a[0].nombre,
          apellidoTA: a[0].apellido,
          dniTA: a[0].dni,
          showLoader: false,
        }),
      );
    });
  }

  onChangeSwitch = () => {
    this.setState({
      showForm: !this.state.showForm,
      lugar_de_trabajo: !this.state.lugar_de_trabajo,
    });
  };

  createItem = (label) => (
    <FormControlLabel
      key={label}
      control={<Checkbox onChange={this.handleCheckboxChange(label)} name="gilad" />}
      label={label}
    />
  );

  createCheckBoxes = () => {
    return listaTareas.map(this.createItem);
  };

  handleCheckboxChange = (label) => (ev) => {
    // Caso especial checkbox Otras
    if (label === 'Otras') {
      this.setState({
        otrasEnabled: !this.state.otrasEnabled,
        otraTarea: '',
        tareas_realizadasError: false,
        showRegistrarControlMessage: false,
      });
      return;
    }

    this.setState({ tareas_realizadasError: false, showRegistrarControlMessage: false });

    if (this.tareasRealizadas.has(label)) this.tareasRealizadas.delete(label);
    else this.tareasRealizadas.add(label);
  };

  handleInputChange = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value,
      [prop + 'Error']: false,
      showRegistrarControlMessage: false,
    });
  };

  handleDoubleCheck = (sel) => {
    this.setState({
      [sel.name]: sel.selection,
      [sel.name + 'Error']: false,
      showRegistrarControlMessage: false,
    });
  };

  registrarControl = () => {
    let tr = Array.from(this.tareasRealizadas).join('|');

    if (this.state.otraTarea !== '') {
      if (tr === '') tr += this.state.otraTarea;
      else tr += '|' + this.state.otraTarea;
    }
    if (this.state.showForm === false) {
      // El trabajador no esta en su sitio; se envia control vacio -> tr a null
      tr = null;
    }

    // Validaciones
    let errs = {};
    let validationSuccess = true;

    if (tr === '') {
      errs.tareas_realizadasError = true;
      validationSuccess = false;
    }

    if (this.state.acceso_agua === '') {
      errs.acceso_aguaError = true;
      validationSuccess = false;
    }

    if (this.state.utilizo_herramienta_maquinaria === '') {
      errs.utilizo_herramienta_maquinariaError = true;
      validationSuccess = false;
    }

    if (this.state.utilizo_proteccion === '') {
      errs.utilizo_proteccionError = true;
      validationSuccess = false;
    }

    if (this.state.fue_alguien_empresa === '') {
      errs.fue_alguien_empresaError = true;
      validationSuccess = false;
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showRegistrarControlMessage: true });
      return;
    }

    this.setState({ showLoader: true });
    let obs = this.state.observaciones;
    if (obs === '') obs = null;

    let control = {
      id: this.state.id,
      empresa_id: this.state.empresa_id,
      adolescente_id: this.state.adolescente_id,
      contratista_id: this.state.contratista_id,
      lote_id: this.state.lote_id,
      semana: this.state.semana,
      dni_usuario_externo: this.state.dni_usuario_externo,
      fecha_horario: this.state.fecha_horario,
      geo_lat_long: this.state.geo_lat_long,
      tareas_realizadas: tr,
      acceso_agua: this.state.acceso_agua,
      utilizo_herramienta_maquinaria: this.state.utilizo_herramienta_maquinaria,
      utilizo_proteccion: this.state.utilizo_proteccion,
      fue_alguien_empresa: this.state.fue_alguien_empresa,
      observaciones: this.state.observaciones,
    };

    addRecord('control_ta_usuarios_externos', control)
      .then(() => {
        let registerSyncEvent = true;
        updateDataBases(registerSyncEvent).then(() => {
          let codigos = '?codes=';

          if (this.state.acceso_agua === false) codigos += '014|';
          if (this.state.utilizo_herramienta_maquinaria === true) codigos += '017|';
          if (this.state.fue_alguien_empresa === false) codigos += '016|';

          this.props.history.push(URLpreffix + '/ControlTrabajadorUEP3' + codigos);
        });
      })
      .catch((err) => console.error(err));
  };

  render() {
    if (this.state.load === false)
      return (
        <main>
          <Loader show={this.state.showLoader} />
          <NavBar />
          <MyBottomNavigationC />
        </main>
      );
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h5" className="mb1">
                Monitoreo del trabajador:
                <br />
                <strong>
                  {this.state.nombreTA +
                    ' ' +
                    this.state.apellidoTA +
                    ' - DNI: ' +
                    this.state.dniTA}
                </strong>
              </Typography>
            </Grid>

            {this.state.showForm ? (
              <React.Fragment>
                <Grid item xs={12} className="mb1">
                  <hr className="mb2" />

                  <FormControl component="fieldset" className="">
                    <FormLabel component="legend">
                      <Typography
                        variant="p"
                        className={
                          this.state.tareas_realizadasError
                            ? 'labelCheckbox text-color-error'
                            : 'labelCheckbox'
                        }
                      >
                        1. ¿Qué tareas/actividades realizó el/la trabajador/a adolescente durante la
                        semana?*
                      </Typography>
                    </FormLabel>

                    <FormHelperText>Seleccionar todas las que correspondan.</FormHelperText>

                    <FormGroup className="mt0_5">{this.createCheckBoxes()}</FormGroup>

                    {this.state.otrasEnabled === true ? (
                      <TextField
                        id="otraTareaInput"
                        label="¿Cuál?"
                        value={this.state.otraTarea}
                        onChange={this.handleInputChange('otraTarea')}
                        variant="outlined"
                        className="mb2 ml2"
                      />
                    ) : null}
                  </FormControl>

                  <hr className="mb2" />

                  <Typography variant="p" className="labelCheckbox mb1 mt1">
                    2. Contestar según lo ocurrido durante la semana*
                  </Typography>

                  <DoubleCheck
                    error={this.state.acceso_aguaError}
                    withLabels
                    onChange={this.handleDoubleCheck}
                    name="acceso_agua"
                    text="Durante la semana de referencia, ¿Los/as adolescentes tuvieron acceso siempre y en todo momento a agua potable?"
                  />

                  <DoubleCheck
                    error={this.state.utilizo_herramienta_maquinariaError}
                    withLabels
                    onChange={this.handleDoubleCheck}
                    name="utilizo_herramienta_maquinaria"
                    text="¿Utilizó alguna herramienta/maquinaria en su trabajo?"
                  />

                  <DoubleCheck
                    error={this.state.utilizo_proteccionError}
                    withLabels
                    onChange={this.handleDoubleCheck}
                    name="utilizo_proteccion"
                    text="¿Utilizó elementos de protección?"
                  />

                  <DoubleCheck
                    error={this.state.fue_alguien_empresaError}
                    withLabels
                    onChange={this.handleDoubleCheck}
                    name="fue_alguien_empresa"
                    text="¿Vino alguna persona de la empresa en la semana a supervisar el trabajo del/la adolescente durante la jornada de trabajo?"
                  />

                  <hr className="mb2" />

                  <Typography variant="p" className="labelCheckbox mb1 mt1">
                    Observaciones
                  </Typography>

                  <TextField
                    id="observacionesInput"
                    label=""
                    multiline
                    fullWidth
                    rows={4}
                    value={this.state.observaciones}
                    onChange={this.handleInputChange('observaciones')}
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            ) : null}

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={this.registrarControl}
                variant="contained"
                color="primary"
                size="large"
              >
                Enviar monitoreo
              </Button>

              {this.state.showRegistrarControlMessage ? (
                <Grid item xs={12} className="mb1 mt1">
                  <Alert severity="error">
                    <AlertTitle>
                      <b>Campos sin completar.</b>
                    </AlertTitle>
                    Falta completar campos obligatorios.
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigationC />
      </main>
    );
  }
}

export default withRouter(ControlTrabajadorUEP2);
