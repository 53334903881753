import React from 'react';
import { useHistory } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';

import { URLpreffix } from '../../config';

export default function MyBottomNavigationC() {

  let history = useHistory();

  return (

    <BottomNavigation showLabels>
      <BottomNavigationAction onClick={() => { history.push(URLpreffix+'/SituacionRiesgoP1'); }} label="Reportar Situación de Riesgo TI" icon={<FeedbackIcon />} />
      <BottomNavigationAction onClick={() => { history.push(URLpreffix+'/Ayuda'); }} label="Ayuda" icon={<HelpIcon />} />
    </BottomNavigation>

  );
}
