import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import CardTrabajador from './elements/CardTrabajador';
import Message from '../Message';

import { getAdolescentesByEmpresa } from '../../indexedDB/operations';

class ControlTrabajadorP1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardsTA: null,
    };
  }

  componentDidMount() {
    getAdolescentesByEmpresa(localStorage.getItem('usuario_empresa_id'))
      .then((items) => {
        if (items.length > 0) {
          let cards = items.map((i) => {
            return <CardTrabajador key={i.id} {...i} />;
          });

          this.setState({ cardsTA: cards });
        }
      })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Monitorear a un trabajador adolescente.
              </Typography>

              {this.state.cardsTA?.length ? (
                <Typography variant="body1">
                  Seleccione al trabajador. Solo los trabajadores dados de alta puede ser
                  monitoreados.
                </Typography>
              ) : (
                <Message text="¡No hay adolescentes registrados/as!" severity="info" />
              )}
            </Grid>

            <Grid item xs={12} className="mb1">
              {this.state.cardsTA}
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ControlTrabajadorP1);
