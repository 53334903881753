import Dexie from 'dexie';

// DB version; solo cambiar si es necesario actualizar schemas.
// No tiene nada que ver con el db version que usamos para
// actualizar la DB.
// CHANGELOG:
//    v3 -> Reemplazo de la tabla de protocolos por incidencias
//    v4 -> Agregado de tabla nueva de incidencias
//    v5 -> Agregado de control entrega de fruta
const DB_VERSION = '5';

// DB name
const DB_NAME = 'MonitoreoTA';

// Tablas e Indexed props
const TABLES = {
  logs: '++id',

  adolescentes: 'id, dni, uid_renatre, empresa_id, usuario_aprueba_alta_id, contratistas_id',
  empresas: 'id',
  lotes: 'id, empresa_id',
  contratistas: 'id, empresa_asociada_id',

  control_ta: 'id, empresa_id, adolescente_id, registrado_usuario_id, fecha_horario',
  control_ta_usuarios_externos:
    'id, empresa_id, adolescente_id, dni_usuario_externo, fecha_horario',
  ingresos_egresos: 'id, empresa_id, adolescente_id, registrado_usuario_id, fecha_horario, tipo',
  control_lotes: 'id, contratista_id, registrado_usuario_id, lote_id',
  control_entrega_fruta: 'id, contratista_id, registrado_usuario_id, lote_id',
  control_documentacion:
    'id, dni_adolescente, dni_usuario_externo, empresa_id, contratista_id, fecha_horario',

  precontroles: 'id, contratista_id, adolescente_id',
  // {v2} protocolos: 'id, empresa_id, contratista_id, registrado_usuario_id, control_asociado_id, lote_id',
  incidencias: 'id, registrado_usuario_id, dni_usuario_externo',
  incidenciasv2: 'id, registrado_usuario_id, dni_usuario_externo',
  persona_responsable: 'id',

  logs_usuarios_externos: 'id',

  datos_usuarios: 'id',
  db_version: 'id',
};

const db = new Dexie(DB_NAME);
db.version(DB_VERSION).stores(TABLES);

export default db;
