import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import CardTAResumen from './elements/CardTAResumen';
import Message from '../Message';

import { getAdolescentesByEmpresa } from '../../indexedDB/operations';

class ListadoTrabajadoresP1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listaTA: [],
    };
  }

  componentDidMount() {
    // Busco lista de trabajadores
    let empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));
    getAdolescentesByEmpresa(empresa_id)
      .then((items) => {
        let cards = items.map((i) => {
          return <CardTAResumen {...i} key={i.id} />;
        });

        this.setState({ listaTA: cards });
      })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Listado de trabajadores adolescente en la empresa
              </Typography>

              {this.state.listaTA?.length ? (
                <Typography variant="body1">
                  Seleccione al trabajador para ver todos los registros asociados.
                </Typography>
              ) : (
                <Message text="¡No hay adolescentes registrados/as!" severity="info" />
              )}
            </Grid>

            <Grid item xs={12} className="mb1">
              {this.state.listaTA === [] ? null : this.state.listaTA}
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ListadoTrabajadoresP1);
