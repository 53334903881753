import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import DoubleCheck from '../Misc/DoubleCheck';
import Loader from '../Misc/Loader';

import { addRecord, updateDataBases } from '../../indexedDB/operations';
import { URLpreffix } from '../../config';
import { getIDInCorrectType, getCurrentDateTime } from '../../helpers/helpers';

class IncidenciasV2P1 extends React.Component {
  constructor(props) {
    super(props);

    let usuario_tipo = localStorage.getItem('usuario_tipo');

    let registrado_usuario_id = null;
    let dni_usuario_externo = null;
    let contratista_id = null;
    let empresa_id = null;

    if (usuario_tipo === 'capataz') {
      registrado_usuario_id = null;
      empresa_id = null;
      dni_usuario_externo = localStorage.getItem('usuario_dni');
      contratista_id = localStorage.getItem('usuario_contratista_id');
    }
    if (usuario_tipo === 'empresa') {
      registrado_usuario_id = parseInt(localStorage.getItem('usuario_id'));
      empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));
      dni_usuario_externo = null;
      contratista_id = null;
    }

    let queryParams = qs.parse(props.location.search);

    let monitoreo_lote_asociado = null;
    let lote_id = null;

    if (queryParams.lote_id) {
      monitoreo_lote_asociado = true;
      lote_id = getIDInCorrectType(queryParams.lote_id);
    }

    this.state = {
      showLoader: false,

      empresa_id: empresa_id,
      contratista_id: contratista_id,

      tipo_incidencia: '',
      fecha_horario: getCurrentDateTime().split(' ')[0],

      registrado_usuario_id: registrado_usuario_id,
      dni_usuario_externo: dni_usuario_externo,

      dentro_fuera_campo: '',

      tipo_situacion: '',
      tipo_situacion_otro: '',

      provincia: '',

      posible_dialogar: '',

      nombre_sujeto: '',

      genero_sujeto: '',

      localidad_sujeto: '',

      edad_sujeto: '',

      tiene_permiso: '',

      permiso_otorgado_por: '',

      asiste_escuela: '',
      sujeto_escuela_nombre: '',
      sujeto_escuela_grado: '',

      observaciones: '',

      edad_aproximada_sujeto: '',

      acompaniado_por_adulto: '',
      parentezco_acompaniante: '',

      dentro_fuera_campoError: false,
      tipo_situacionError: false,
      provinciaError: false,
      tipo_situacion_otroError: false,
      genero_sujetoError: false,
      tiene_permisoError: false,
      asiste_escuelaError: false,
      parentezco_acompanianteError: false,
      observacionesError: false,
      edad_aproximada_sujetoError: false,

      alta_ta_asociado: null,
      usuario_alta_ta: null,

      monitoreo_lote_asociado: monitoreo_lote_asociado,
      lote_id: lote_id,

      acompaniado_por_adultoError: false,

      showRegistrarIncidenciaMessage: false,
    };
  }

  componentDidMount() {}

  resetForm = () => {
    this.setState({
      nombre_sujeto: null,
      genero_sujeto: '',
      lugar_nacimineto_sujeto: null,
      edad_sujeto: null,
      numero_documento_sujeto: null,
      acompaniado_por_adulto: '',
      observaciones: null,

      responsable_empresa: null,

      tieneDNI: null,

      genero_sujetoError: false,
      acompaniado_por_adultoError: false,
      observacionesError: false,
      tieneDNIError: false,
      tipo_incidenciaError: false,

      showRegistrarIncidenciaMessage: false,
    });
  };

  handleChange = (prop) => (e) => {
    let misc = {};

    // Cuando se selecciona un tipo de incidencia distinto a 'Otro'
    // reseteamos el campo de texto de especifiar
    if (prop === 'tipo_situacion' && e.target.value !== 'Otro') {
      misc['tipo_situacion_otro'] = '';
    }

    if (prop === 'dentro_fuera_campo') {
      misc['tipo_situacion_otro'] = '';
      misc['tipo_situacion'] = '';
    }

    this.setState({
      [prop]: e.target.value,
      [prop + 'Error']: false,
      showRegistrarIncidenciaMessage: false,
      ...misc,
    });

    if (prop === 'tipo_incidencia') this.resetForm();
  };

  handleDoubleCheck = (sel) => {
    this.setState({
      [sel.name]: sel.selection,
      [sel.name + 'Error']: false,
      showRegistrarIncidenciaMessage: false,
    });
  };

  onClickRegistrarIncidencia = () => {
    // Validaciones
    let errs = {};
    let validationSuccess = true;

    if (
      this.state.tipo_incidencia ===
        'Presencia de niños/as o adolescentes no registrados/as en el campo' ||
      this.state.tipo_incidencia === 'Presencia de niños/as acompañando un adulto en el campo'
    ) {
      if (this.state.genero_sujeto === '') {
        errs.genero_sujetoError = true;
        validationSuccess = false;
      }

      if (this.state.acompaniado_por_adulto === '') {
        errs.acompaniado_por_adultoError = true;
        validationSuccess = false;
      }

      if (this.state.tieneDNI === null) {
        errs.tieneDNIError = true;
        validationSuccess = false;
      }

      if (this.state.tipo_incidencia === '') {
        errs.tipo_incidenciaError = true;
        validationSuccess = false;
      }
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showRegistrarIncidenciaMessage: true });
      return;
    }

    this.setState({ showLoader: true });

    let incidencia = {
      id: 'tmp-incidencia-' + Date.now(),
      tipo_incidencia: this.state.tipo_incidencia,
      fecha_horario: this.state.fecha_horario,
      dni_usuario_externo: this.state.dni_usuario_externo,
      registrado_usuario_id: this.state.registrado_usuario_id,
      alta_ta_asociado: this.state.alta_ta_asociado,
      usuario_alta_ta: this.state.usuario_alta_ta,
      monitoreo_lote_asociado: this.state.monitoreo_lote_asociado,
      lote_id: this.state.lote_id,

      contratista_id: this.state.contratista_id,
      empresa_id: this.state.empresa_id,

      nombre_sujeto: this.state.nombre_sujeto,
      genero_sujeto: this.state.genero_sujeto === '' ? null : this.state.genero_sujeto,
      lugar_nacimineto_sujeto: this.state.lugar_nacimineto_sujeto,
      edad_sujeto: this.state.edad_sujeto,
      numero_documento_sujeto: this.state.numero_documento_sujeto,
      acompaniado_por_adulto:
        this.state.acompaniado_por_adulto === '' ? null : this.state.acompaniado_por_adulto,
      observaciones: this.state.observaciones,
      responsable_empresa: this.state.responsable_empresa,
    };

    addRecord('incidencias', incidencia)
      .then(() => {
        // Tipo 1:
        // Presencia de niños/as o adolescentes no registrados/as en el campo
        // Presencia de niños/as acompañando un adulto en el campo

        // Tipo 2:
        // Niño/a o adolescente no registrado que quiere ingresar al campo
        // Niño/a o adolescente no registrado que quiere subir al transporte

        let tipo = '2';
        if (
          this.state.tipo_incidencia ===
          'Presencia de niños/as o adolescentes no registrados/as en el campo'
        )
          tipo = '1';
        if (
          this.state.tipo_incidencia === 'Presencia de niños/as acompañando un adulto en el campo'
        )
          tipo = '1';

        // Si es Tipo 2, no hay una instancia de actualizacion de la incidencia -> updateDataBases antes de avanzar!
        if (tipo === '2') {
          updateDataBases().then(() => {
            this.props.history.push(
              URLpreffix + '/ReporteIncidenciaP2?id=' + incidencia.id + '&tipo=' + tipo,
            );
          });
        } else {
          this.props.history.push(
            URLpreffix + '/ReporteIncidenciaP2?id=' + incidencia.id + '&tipo=' + tipo,
          );
        }
      })
      .catch((err) => console.error(err));
  };

  onClickRegistrarSituacionRiesgo = () => {
    // Validaciones
    let errs = {};
    let validationSuccess = true;

    if (this.state.dentro_fuera_campo === '') {
      errs.dentro_fuera_campoError = true;
      validationSuccess = false;
    }

    if (this.state.tipo_situacion === '' && this.state.dentro_fuera_campo !== '') {
      errs.tipo_situacionError = true;
      validationSuccess = false;
    }

    if (this.state.provincia === '') {
      errs.provinciaError = true;
      validationSuccess = false;
    }

    if (this.state.tipo_situacion === 'Otro' && this.state.tipo_situacion_otro === '') {
      errs.tipo_situacion_otroError = true;
      validationSuccess = false;
    }

    if (this.state.posible_dialogar === true) {
      if (this.state.genero_sujeto === '') {
        errs.genero_sujetoError = true;
        validationSuccess = false;
      }

      if (
        this.state.tiene_permiso === '' &&
        (this.state.edad_sujeto === '16' || this.state.edad_sujeto === '17')
      ) {
        errs.tiene_permisoError = true;
        validationSuccess = false;
      }

      if (this.state.asiste_escuela === '') {
        errs.asiste_escuelaError = true;
        validationSuccess = false;
      }
    }

    if (this.state.acompaniado_por_adulto === '') {
      errs.acompaniado_por_adultoError = true;
      validationSuccess = false;
    }

    if (this.state.parentezco_acompaniante === '' && this.state.acompaniado_por_adulto === true) {
      errs.parentezco_acompanianteError = true;
      validationSuccess = false;
    }

    if (this.state.observaciones === '') {
      errs.observacionesError = true;
      validationSuccess = false;
    }

    if (this.state.edad_aproximada_sujeto === '' && this.state.posible_dialogar === false) {
      errs.edad_aproximada_sujetoError = true;
      validationSuccess = false;
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showRegistrarIncidenciaMessage: true });
      return;
    }

    this.setState({ showLoader: true });

    let situacion = {
      id: 'tmp-incidencia-' + Date.now(),
      registrado_usuario_id: this.state.registrado_usuario_id,
      dni_usuario_externo: this.state.dni_usuario_externo,
      fecha_horario: this.state.fecha_horario + ' 00:00:00',
      dentro_o_fuera_del_campo:
        this.state.dentro_fuera_campo === '' ? null : this.state.dentro_fuera_campo,
      tipo_de_situacion:
        this.state.tipo_situacion !== 'Otro'
          ? this.state.tipo_situacion
          : this.state.tipo_situacion_otro,
      provincia: this.state.provincia,
      posible_dialogar: this.state.posible_dialogar,
      nombre_sujeto: this.state.nombre_sujeto === '' ? null : this.state.nombre_sujeto,
      genero_sujeto: this.state.genero_sujeto,
      localidad_sujeto: this.state.localidad_sujeto === '' ? null : this.state.localidad_sujeto,
      edad_sujeto: this.state.edad_sujeto === '' ? null : parseInt(this.state.edad_sujeto),
      edad_aproximada_sujeto:
        this.state.edad_aproximada_sujeto === '' ? null : this.state.edad_aproximada_sujeto,
      permiso_trabajo_sujeto: this.state.tiene_permiso === '' ? null : this.state.tiene_permiso,
      quien_otorgo_permiso:
        this.state.permiso_otorgado_por === '' ? null : this.state.permiso_otorgado_por,
      asiste_escuela: this.state.asiste_escuela === '' ? null : this.state.asiste_escuela,
      grado_sujeto: this.state.sujeto_escuela_grado === '' ? null : this.state.sujeto_escuela_grado,
      nombre_escuela:
        this.state.sujeto_escuela_nombre === '' ? null : this.state.sujeto_escuela_nombre,
      descripcion_situacion: this.state.observaciones,
      sujeto_acompaniado: this.state.acompaniado_por_adulto,
      parentezco_acompanante:
        this.state.parentezco_acompaniante === '' ? null : this.state.parentezco_acompaniante,
      alta_ta_asociado: this.state.alta_ta_asociado,
      usuario_alta_ta: this.state.usuario_alta_ta,
      monitoreo_lote_asociado: this.state.monitoreo_lote_asociado,
      lote_id: this.state.lote_id,
      empresa_id: this.state.empresa_id,
      contratista_id: this.state.contratista_id,
    };

    addRecord('incidenciasv2', situacion)
      .then(() => {
        // Luego de guardar localmente la situacion, vamos a la segunda parte de la misma
        this.props.history.push(URLpreffix + '/SituacionRiesgoP2?id=' + situacion.id);
      })
      .catch((err) => console.error(err));
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h5">Registro de situación de riesgo TI:</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic4"
                  onChange={this.handleChange('fecha_horario')}
                  label="Fecha"
                  type="date"
                  variant="outlined"
                  value={this.state.fecha_horario}
                  defaultValue=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel
                  error={this.state.dentro_fuera_campoError}
                  htmlFor="outlined-age-native-simple"
                >
                  ¿La situación es dentro o fuera del campo?
                </InputLabel>
                <Select
                  native
                  label="¿La situación es dentro o fuera del campo?"
                  error={this.state.dentro_fuera_campoError}
                  inputProps={{
                    name: '¿La situación es dentro o fuera del campo?',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={this.handleChange('dentro_fuera_campo')}
                >
                  <option aria-label="None" value="" />
                  <option value={'dentro'}>Dentro</option>
                  <option value={'fuera'}>Fuera</option>
                </Select>
              </FormControl>

              {this.state.dentro_fuera_campo !== '' ? (
                <React.Fragment>
                  {this.state.dentro_fuera_campo === 'fuera' ? (
                    <FormControl fullWidth variant="outlined" className="mb1">
                      <InputLabel
                        error={this.state.tipo_situacionError}
                        htmlFor="outlined-age-native-simple"
                      >
                        Tipo de situación
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de situación"
                        error={this.state.tipo_situacionError}
                        inputProps={{
                          name: 'Tipo de situación',
                          id: 'outlined-age-native-simple',
                        }}
                        value={this.state.tipo_situacion}
                        onChange={this.handleChange('tipo_situacion')}
                      >
                        <option aria-label="None" value="" />
                        <option value={'Un NNoA se acerca a un campo para preguntar por trabajo'}>
                          Un NNoA se acerca a un campo para preguntar por trabajo
                        </option>
                        <option value={'Un NNoA intenta ingresar a un campo'}>
                          Un NNoA intenta ingresar a un campo
                        </option>
                        <option
                          value={
                            'Un NNoA intenta subir a un transporte de trabajadores/ contratista'
                          }
                        >
                          Un NNoA intenta subir a un transporte de trabajadores/contratista
                        </option>
                        <option value={'Otro'}>Otro</option>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth variant="outlined" className="mb1">
                      <InputLabel
                        error={this.state.tipo_situacionError}
                        htmlFor="outlined-age-native-simple"
                      >
                        Tipo de situación
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de situación"
                        error={this.state.tipo_situacionError}
                        inputProps={{
                          name: 'Tipo de situación',
                          id: 'outlined-age-native-simple',
                        }}
                        value={this.state.tipo_situacion}
                        onChange={this.handleChange('tipo_situacion')}
                      >
                        <option aria-label="None" value="" />
                        <option value={'NNoA le lleva agua/comida a padres/madres/adultos'}>
                          NNoA le lleva agua/comida a padres/madres/adultos
                        </option>
                        <option
                          value={
                            'NNoA está dentro del campo pero no se lo observa realizando ninguna tarea vinculada con el arándano'
                          }
                        >
                          NNoA está dentro del campo pero no se lo observa realizando ninguna tarea
                          vinculada con el arándano
                        </option>
                        <option value={'NNoA está empaquetando/armando cajas'}>
                          NNoA está empaquetando/armando cajas
                        </option>
                        <option value={'NNoA recolectando/cosechando arándanos'}>
                          NNoA recolectando/cosechando arándanos
                        </option>
                        <option value={'Otro'}>Otro</option>
                      </Select>
                    </FormControl>
                  )}
                </React.Fragment>
              ) : null}

              {this.state.tipo_situacion === 'Otro' ? (
                <FormControl fullWidth variant="outlined" className="mb1">
                  <TextField
                    error={this.state.tipo_situacion_otroError}
                    id="outlined-basic1"
                    onChange={this.handleChange('tipo_situacion_otro')}
                    label="Especificar tipo de situación"
                    variant="outlined"
                  />
                </FormControl>
              ) : null}

              <React.Fragment>
                <FormControl fullWidth variant="outlined" className="mb1">
                  <InputLabel
                    error={this.state.provinciaError}
                    htmlFor="outlined-age-native-simple"
                  >
                    Provincia donde se identifica la situación
                  </InputLabel>
                  <Select
                    native
                    label="Provincia donde se identifica la situación"
                    error={this.state.provinciaError}
                    inputProps={{
                      name: 'Provincia donde se identifica la situación',
                      id: 'outlined-age-native-simple',
                    }}
                    onChange={this.handleChange('provincia')}
                  >
                    <option aria-label="None" value="" />
                    <option value={'Buenos Aires'}>Buenos Aires</option>
                    <option value={'Entre Ríos'}>Entre Ríos</option>
                    <option value={'Tucumán'}>Tucumán</option>
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mb1">
                  <DoubleCheck
                    error={this.state.posible_dialogarError}
                    withLabels
                    onChange={this.handleDoubleCheck}
                    name="posible_dialogar"
                    text="¿Se puede dialogar con el NNoA?"
                  />
                </FormControl>

                {this.state.posible_dialogar === true ? (
                  <React.Fragment>
                    <FormControl fullWidth variant="outlined" className="mb1">
                      <TextField
                        id="outlined-basic1"
                        onChange={this.handleChange('nombre_sujeto')}
                        label="Preguntar: ¿Cómo te llamas?"
                        variant="outlined"
                      />
                      <FormHelperText>Nombre y si es posible apellido.</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className="mb1">
                      <InputLabel
                        error={this.state.genero_sujetoError}
                        htmlFor="outlined-age-native-simple"
                      >
                        Género
                      </InputLabel>
                      <Select
                        native
                        label="Género"
                        error={this.state.genero_sujetoError}
                        inputProps={{
                          name: 'Género',
                          id: 'outlined-age-native-simple',
                        }}
                        onChange={this.handleChange('genero_sujeto')}
                      >
                        <option aria-label="None" value="" />
                        <option value={'Otro'}>Otro</option>
                        <option value={'Masculino'}>Masculino</option>
                        <option value={'Femenino'}>Femenino</option>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className="mb1">
                      <TextField
                        id="outlined-basic1"
                        onChange={this.handleChange('localidad_sujeto')}
                        label="Preguntar: ¿De que localidad sos?"
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className="mb1">
                      <TextField
                        id="outlined-basic1"
                        type="number"
                        onChange={this.handleChange('edad_sujeto')}
                        label="Preguntar: ¿Cuántos años tenés?"
                        variant="outlined"
                      />
                      <FormHelperText>
                        Si no responde, o se sospecha que no es la edad real, indicar edad
                        aproximada del niño/a.
                      </FormHelperText>
                    </FormControl>

                    {this.state.edad_sujeto === '16' || this.state.edad_sujeto === '17' ? (
                      <React.Fragment>
                        <FormControl fullWidth variant="outlined" className="mb1">
                          <InputLabel
                            error={this.state.tiene_permisoError}
                            htmlFor="outlined-age-native-simple"
                          >
                            Preguntar: ¿Contás con un permiso para trabajar?
                          </InputLabel>
                          <Select
                            native
                            label="Preguntar: ¿Contás con un permiso para trabajar?"
                            error={this.state.tiene_permisoError}
                            inputProps={{
                              name: 'Preguntar: ¿Contás con un permiso para trabajar?',
                              id: 'outlined-age-native-simple',
                            }}
                            onChange={this.handleChange('tiene_permiso')}
                          >
                            <option aria-label="None" value="" />
                            <option value={'Si'}>Si</option>
                            <option value={'No'}>No</option>
                          </Select>
                        </FormControl>

                        {this.state.tiene_permiso === 'Si' ? (
                          <FormControl fullWidth variant="outlined" className="mb1">
                            <TextField
                              id="outlined-basic1"
                              onChange={this.handleChange('permiso_otorgado_por')}
                              label="Preguntar: ¿Quién otorgó ese permiso?"
                              variant="outlined"
                            />
                          </FormControl>
                        ) : null}
                      </React.Fragment>
                    ) : null}

                    <FormControl fullWidth variant="outlined" className="mb1">
                      <InputLabel
                        error={this.state.asiste_escuelaError}
                        htmlFor="outlined-age-native-simple"
                      >
                        Preguntar: ¿Vas a la escuela?
                      </InputLabel>
                      <Select
                        native
                        label="Preguntar: ¿Vas a la escuela?"
                        error={this.state.asiste_escuelaError}
                        inputProps={{
                          name: 'Preguntar: ¿Vas a la escuela?',
                          id: 'outlined-age-native-simple',
                        }}
                        onChange={this.handleChange('asiste_escuela')}
                      >
                        <option aria-label="None" value="" />
                        <option value={'Si'}>Si</option>
                        <option value={'No'}>No</option>
                      </Select>
                    </FormControl>

                    {this.state.asiste_escuela === 'Si' ? (
                      <React.Fragment>
                        <FormControl fullWidth variant="outlined" className="mb1">
                          <TextField
                            id="outlined-basic1"
                            onChange={this.handleChange('sujeto_escuela_grado')}
                            label="Preguntar: ¿En qué grado estás?"
                            variant="outlined"
                          />
                        </FormControl>

                        <FormControl fullWidth variant="outlined" className="mb1">
                          <TextField
                            id="outlined-basic1"
                            onChange={this.handleChange('sujeto_escuela_nombre')}
                            label="Preguntar: ¿A qué escuela vas?"
                            variant="outlined"
                          />
                        </FormControl>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}

                {this.state.posible_dialogar !== '' ? (
                  <React.Fragment>
                    <FormControl fullWidth variant="outlined" className="mb1">
                      <Typography variant="p" className="">
                        Caracterice la situación encontrada:
                      </Typography>
                      <TextField
                        id="observacionesInput"
                        label=""
                        multiline
                        fullWidth
                        rows={4}
                        value={null}
                        error={this.state.observacionesError}
                        variant="outlined"
                        onChange={this.handleChange('observaciones')}
                      />
                    </FormControl>

                    {!this.state.posible_dialogar ? (
                      <FormControl fullWidth variant="outlined" className="mb1">
                        <InputLabel
                          error={this.state.edad_aproximada_sujetoError}
                          htmlFor="outlined-age-native-simple"
                        >
                          Registrar edad aproximada del NNoA
                        </InputLabel>
                        <Select
                          native
                          label="Registrar edad aproximada del NNoA"
                          error={this.state.edad_aproximada_sujetoError}
                          inputProps={{
                            name: 'Registrar edad aproximada del NNoA',
                            id: 'outlined-age-native-simple',
                          }}
                          onChange={this.handleChange('edad_aproximada_sujeto')}
                        >
                          <option aria-label="None" value="" />
                          <option value={'Niño o niña de hasta 5 años'}>
                            Niño o niña de hasta 5 años
                          </option>
                          <option value={'Niño o niña de 6 a 12 años'}>
                            Niño o niña de 6 a 12 años
                          </option>
                          <option value={'Adolescente de 13 a 15 años'}>
                            Adolescente de 13 a 15 años
                          </option>
                          <option value={'Adolescente de 16 a 17 años'}>
                            Adolescente de 16 a 17 años
                          </option>
                        </Select>
                      </FormControl>
                    ) : null}

                    <FormControl fullWidth variant="outlined" className="mb1">
                      <DoubleCheck
                        error={this.state.acompaniado_por_adultoError}
                        withLabels
                        onChange={this.handleDoubleCheck}
                        name="acompaniado_por_adulto"
                        text="¿El o la niña/o estaba acompañado por alguna persona adulta?"
                      />
                    </FormControl>

                    {this.state.acompaniado_por_adulto === true ? (
                      <FormControl fullWidth variant="outlined" className="mb1">
                        <InputLabel
                          error={this.state.parentezco_acompanianteError}
                          htmlFor="outlined-age-native-simple"
                        >
                          ¿Qué relación de parentesco tiene?
                        </InputLabel>
                        <Select
                          native
                          label="¿Qué relación de parentesco tiene?"
                          error={this.state.parentezco_acompanianteError}
                          inputProps={{
                            name: '¿Qué relación de parentesco tiene?',
                            id: 'outlined-age-native-simple',
                          }}
                          onChange={this.handleChange('parentezco_acompaniante')}
                        >
                          <option aria-label="None" value="" />
                          <option value={'Madre/padre/tutor'}>Madre/padre/tutor</option>
                          <option value={'Hermanos/as, primos/as o tíos/as'}>
                            Hermanos/as, primos/as o tíos/as
                          </option>
                          <option value={'Vecinos/No familiar'}>Vecinos/No familiar</option>
                        </Select>
                      </FormControl>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </Grid>

            {this.state.posible_dialogar !== '' ? (
              <Grid item xs={12} className="mb1 text-center">
                <Button
                  onClick={this.onClickRegistrarSituacionRiesgo}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Registrar situación de riesgo
                </Button>
                {this.state.showRegistrarIncidenciaMessage ? (
                  <Grid item xs={12} className="mb1 mt1">
                    <Alert severity="error">
                      <AlertTitle>
                        <b>Campos sin completar.</b>
                      </AlertTitle>
                      Falta completar campos obligatorios.
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(IncidenciasV2P1);
