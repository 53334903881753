import React from 'react';
import { withRouter } from 'react-router-dom';

import QrReader from 'react-qr-reader';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import Loader from '../Misc/Loader';
import PromptMessage from '../Misc/PromptMessage';

import { URLpreffix } from '../../config';
import { scrapRenatre, checklIfTAExists } from '../../requests/data';

class AltaTrabajadorP1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showNoDataDialog: false,

      dnis: [],

      delay: 250,
      result: 'No result',
    };
  }

  componentDidMount() {}

  onClickIngresoManual = () => {
    // Seteo LS
    localStorage.setItem('stateAltaTrabajador', 'working');
    localStorage.setItem('stateDni', '');
    localStorage.setItem('stateNombre', '');
    localStorage.setItem('stateApellido', '');

    this.props.history.push(URLpreffix + '/AltaTrabajadorP2');
  };

  qrHandleError = () => {
    // TODO: handle QR read error
  };

  qrHandleScan = (data) => {
    this.setState({ result: data });
    if (data) {
      this.setState({ showLoader: true });

      let link = data;

      // Se escaneo algo que no es un link de tarjeta de Trabajador rural
      if (!link.includes('https://portal.renatre.org.ar/padron/trabajadores')) {
        this.setState({ showLoader: false });
        return;
      }

      // Obtengo codigo unico RENATRE
      let uid = link.split('?uid=')[1];

      // Busco data de la tarjeta en el sitio de RENATRE
      scrapRenatre(uid).then((data) => {
        if (data !== null) {
          // Tengo data...
          checklIfTAExists(data.dni).then((exists) => {
            if (!exists) {
              localStorage.setItem('stateAltaTrabajador', 'working');
              localStorage.setItem('stateDni', data.dni);
              localStorage.setItem('stateNombre', data.nombre);
              localStorage.setItem('stateApellido', data.apellido);
              localStorage.setItem('stateUID', data.uid_renatre);

              this.props.history.push(URLpreffix + '/AltaTrabajadorP3');
            } else {
              this.setState({ showNoDataDialog: true, showLoader: false });
            }
          });
        } else {
          // No se encontro data...
          this.props.history.push(URLpreffix + '/AltaTrabajadorP2?scrapFailed=1');
        }
      });
    }
  };

  onScanQR = () => {
    //////////////////////////////////////////////////////////
    // TODO: check if this still has utility                //
    localStorage.setItem('stateAltaTrabajador', 'working');
    localStorage.setItem('stateDni', '32111555');
    localStorage.setItem('stateNombre', 'Nombre desde QR');
    localStorage.setItem('stateApellido', 'Apellido desde QR');

    this.props.history.push(URLpreffix + '/AltaTrabajadorP3');
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <PromptMessage
          title="Trabajador/a existente"
          content="Ya existe un trabajador/a registrado con ese DNI."
          showDialog={this.state.showNoDataDialog}
          closeDialog={() => {
            this.setState({ showNoDataDialog: false });
          }}
        />
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Alta de T.A.
              </Typography>

              <Typography variant="body1">
                Puede ingresar los datos manualmente o utilizar la tarjeta de trabajador rural.
              </Typography>

              <div className="position-relative">
                <img
                  className="img-libreta-trabajador-rural"
                  src={'./img/libreta-trabajador-rural.jpg'}
                  alt="Libreta trabajador"
                />
              </div>
            </Grid>

            <Grid item xs={12} className="mb1">
              <div
                style={{ backgroundColor: '#000', width: '200px', height: '200px', margin: 'auto' }}
              >
                <QrReader
                  delay={300}
                  onError={this.qrHandleError}
                  onScan={this.qrHandleScan}
                  style={{ width: '100%' }}
                />
              </div>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={this.onClickIngresoManual}
                variant="contained"
                color="secondary"
                size="large"
              >
                Ingresar manualmente DNI
              </Button>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(AltaTrabajadorP1);
