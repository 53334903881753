import React from 'react';
import { withRouter } from 'react-router-dom';
import QrReader from 'react-qr-reader';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { URLpreffix } from '../../../config';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigationC from '../../Navigation/MyBottomNavigationC';
import Loader from '../../Misc/Loader';

import { checkIfTAExistsLocalByUID } from '../../../indexedDB/operations';
import { scrapRenatre } from '../../../requests/data';

class ControlDocumentacionP1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      log: 'scrap',
    };
  }

  componentDidMount() {}

  qrHandleError = () => {
    // TODO: handle QR read error
  };

  qrHandleScan = (qrData) => {
    if (qrData) {
      let link = qrData;
      this.setState({ log: qrData });

      // Se escaneo algo que no es un link de tarjeta de Trabajador rural...
      if (!link.includes('https://portal.renatre.org.ar/padron/trabajadores')) {
        this.setState({ showLoader: false });
        return;
      }

      this.setState({ showLoader: true });

      let uid = link.split('?uid=')[1];

      // Intento scrapear... Si falla o no hay conexion devuelve null
      scrapRenatre(uid).then((data) => {
        // Si hay data...
        if (data !== null) {
          this.props.history.push(URLpreffix + '/ControlDocumentacionP3?dni=' + data.dni);
        } else {
          // Fallo el scrap o no hay internet
          // Busco TA por renatre uid
          checkIfTAExistsLocalByUID(uid).then((ta) => {
            if (ta !== null) {
              this.props.history.push(URLpreffix + '/ControlDocumentacionP3?dni=' + ta.dni);
            } else {
              // No se encontro por renatre uid; a view de ingreso DNI
              this.props.history.push(URLpreffix + '/ControlDocumentacionP2?scrapFailed=1');
            }
          });
        }
      });
    }
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar forceBackToHome />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h6" align="center" className="mb1">
                Controle si el trabajador adolescente <br />
                está dado de alta.
              </Typography>

              <div className="position-relative">
                <img
                  className="img-libreta-trabajador-rural"
                  src={'./img/libreta-trabajador-rural.jpg'}
                  alt="Libreta trabajador"
                />
              </div>
            </Grid>

            <Grid item xs={12} className="mb1">
              <div
                style={{ backgroundColor: '#000', width: '200px', height: '200px', margin: 'auto' }}
              >
                <QrReader
                  delay={300}
                  onError={this.qrHandleError}
                  onScan={this.qrHandleScan}
                  style={{ width: '100%' }}
                />
              </div>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={() => {
                  this.props.history.push(URLpreffix + '/ControlDocumentacionP2');
                }}
                variant="contained"
                color="secondary"
                size="large"
              >
                Ingresar DNI manualmente
              </Button>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigationC />
      </main>
    );
  }
}

export default withRouter(ControlDocumentacionP1);
