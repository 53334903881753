import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import CardAvatar from '../../Misc/CardAvatar';

import { URLpreffix } from '../../../config';
import { getTimeFromString } from '../../../helpers/helpers';

export default function CardTrabajador(props) {
  let history = useHistory();

  const esEgreso = props.hoyEgreso !== null ? true : false;

  const hrEg =
    props.hoyEgreso !== null ? (
      <Chip size="small" label={getTimeFromString(props.hoyEgreso.fecha_horario)} />
    ) : (
      '✗'
    );
  const capEg = props.hoyEgreso !== null ? props.hoyEgreso.capataz_a_cargo : '✗';
  const lugEg = props.hoyEgreso !== null ? props.hoyEgreso.lugar : '✗';

  const hrIng =
    props.hoyIngreso !== null ? (
      <Chip size="small" label={getTimeFromString(props.hoyIngreso.fecha_horario)} />
    ) : (
      '✗'
    );
  const capIng = props.hoyIngreso !== null ? props.hoyIngreso.capataz_a_cargo : '✗';
  const lugIng = props.hoyIngreso !== null ? props.hoyIngreso.lugar : '✗';

  const onActionClick = (_type) => (ev) => {
    let url = '';
    if (_type === 'ingreso') {
      url =
        URLpreffix +
        '/IngresoTrabajadorP1?id=' +
        props.id +
        '&type=ingreso' +
        '&tanombre=' +
        props.nombre +
        '&taapellido=' +
        props.apellido;
      if (props.hoyIngreso !== null) url += '&capataz=' + capIng + '&lugar=' + lugIng;
      history.push(url);
      return;
    }

    if (_type === 'egreso') {
      url =
        URLpreffix +
        '/IngresoTrabajadorP1?id=' +
        props.id +
        '&type=egreso' +
        '&tanombre=' +
        props.nombre +
        '&taapellido=' +
        props.apellido;
      if (props.hoyIngreso !== null) url += '&capataz=' + capIng + '&lugar=' + lugIng;
      history.push(url);
      return;
    }

    if (_type === 'monitoreo') {
      history.push(URLpreffix + '/ControlTrabajadorP2?idTA=' + props.id);
    }
  };

  return (
    <Card className="mb1">
      <CardHeader
        avatar={<CardAvatar nombre="Test" />}
        title={props.nombre + ' ' + props.apellido}
        subheader={'DNI ' + props.dni}
      />

      {esEgreso ? (
        <CardContent>
          <Typography variant="body2" component="p">
            Horario de egreso: {hrEg}
            <br />
            Capataz: {capEg} <br />
            Lugar: {lugEg} <br />
          </Typography>
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant="body2" component="p">
            Horario de ingreso: {hrIng}
            <br />
            Capataz: {capIng} <br />
            Lugar: {lugIng} <br />
          </Typography>
        </CardContent>
      )}

      <CardActions>
        {props.hoyIngreso === null && props.hoyEgreso === null ? (
          <Button
            onClick={onActionClick('ingreso')}
            variant="contained"
            color="primary"
            size="small"
          >
            Ingreso
          </Button>
        ) : null}

        {props.hoyEgreso === null ? (
          <Button
            onClick={onActionClick('egreso')}
            variant="contained"
            color="primary"
            size="small"
          >
            Egreso
          </Button>
        ) : null}

        {props.hoyMonitoreo === null && props.hoyEgreso === null ? (
          <Button
            onClick={onActionClick('monitoreo')}
            variant="contained"
            color="secondary"
            size="small"
          >
            Monitorear
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
}
