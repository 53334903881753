import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import Loader from '../Misc/Loader';

import CardTrabajador from './elements/CardTrabajador';

import { getAdolescentesByEmpresa } from '../../indexedDB/operations';
import Message from '../Message';

class IngresoEgreso extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      cardsTA: null,
    };
  }

  componentDidMount() {
    getAdolescentesByEmpresa(localStorage.getItem('usuario_empresa_id'))
      .then((items) => {
        if (items.length > 0) {
          let cards = items.map((i) => {
            return <CardTrabajador key={i.id} {...i} />;
          });

          this.setState({ cardsTA: cards, showLoader: false });
        } else {
          this.setState({ cardsTA: null, showLoader: false });
        }
      })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Monitorear a un/a trabajador/a adolescente.
              </Typography>

              {this.state.cardsTA ? (
                <Typography variant="body1">Seleccione al/la trabajador/a.</Typography>
              ) : (
                <Message text="¡No hay adolescentes registrados/as!" severity="info" />
              )}
            </Grid>

            <Grid item xs={12} className="mb1">
              {this.state.cardsTA}
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(IngresoEgreso);
