import db from './config';

import { getLastRemoteVersion, sendNewItem, getUsersData } from '../requests/data';
import { getCurrentDate, isOnlineV2, getParLotes } from '../helpers/helpers';

// Logger Test
export const getLogs = (all) => {
  return new Promise((resolve, reject) => {
    if (all === true) {
      db['logs']
        .limit(1440)
        .reverse()
        .toArray((data) => {
          resolve(data);
        })
        .catch(() => {
          reject('DB ERROR');
        });
    } else {
      // Solo los ultimos 5
      db['logs']
        .limit(60)
        .reverse()
        .toArray((data) => {
          resolve(data);
        })
        .catch(() => {
          reject('DB ERROR');
        });
    }
  });
};
//////////////

// Funcion - Determina si existe la estructura de la DB en local
// Return - Promise
export const dbInit = () => {
  return new Promise((resolve, reject) => {
    db['db_version'].toArray((data) => {
      resolve();
    });
  });
};

// Funcion - Elimina todos los elementos de una collection
// Params - _collection (string)
// Return - Promise
const clearTable = (_collection) => {
  return new Promise((resolve, reject) => {
    db[_collection]
      .clear()
      .then(() => {
        console.log('Collection ' + _collection + ' cleared...');
        resolve();
      })
      .catch(() => {
        console.error('DB ERROR on clearTable');
        reject();
      });
  });
};

// Funcion - Agrega multiples elementos a una collection
// Params - _collection (string), _data (array)
// Return - Promise
const bulkAdd = (_collection, _data) => {
  return new Promise((resolve, reject) => {
    db[_collection]
      .bulkAdd(_data)
      .then(() => {
        console.log('Bulk add to ' + _collection + ' successful...');
        resolve();
      })
      .catch(() => {
        console.log('DB ERROR on bulkAdd');
        reject();
      });
  });
};

// Funcion - Busca todos los elementos de una collection
// Params - _collection (string)
// Return - Promise
export const getAllRecords = (_collection) => {
  return new Promise((resolve, reject) => {
    db[_collection]
      .toArray((data) => {
        resolve(data);
      })
      .catch(() => {
        reject('DB ERROR');
      });
  });
};

export const testFunc = () => {
  clearTable('adolescentes').then(() => {});
};

/**
 * Envia los datos que correspondan al server
 * @function
 * @return {promise} True cuando no hubo errores, err cuando se registra error
 */
export const sendDataToServer = () => {
  return new Promise((resolve, reject) => {
    // Tengo que buscar todos los items que tengan ID temporal y enviarlos al server
    // control_lotes, control_ta e ingresos_egresos
    let arrProm = [];
    db['ingresos_egresos']
      .filter((x) => {
        if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
        else return false;
      })
      .toArray()
      .then((ingresos_egresos) => {
        for (let i = 0; i < ingresos_egresos.length; i++) {
          delete ingresos_egresos[i].id; // El id lo asigna directus (autoincrement)
          //console.log(ingresos_egresos[i]);
          arrProm.push(sendNewItem('ingresos_egresos', ingresos_egresos[i]));
        }

        db['control_ta']
          .filter((x) => {
            if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
            else return false;
          })
          .toArray()
          .then((controles_ta) => {
            for (let i = 0; i < controles_ta.length; i++) {
              delete controles_ta[i].id; // El id lo asigna directus (autoincrement)
              //console.log(controles_ta[i]);
              arrProm.push(sendNewItem('control_ta', controles_ta[i]));
            }

            db['control_ta_usuarios_externos']
              .filter((x) => {
                if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                else return false;
              })
              .toArray()
              .then((controles_ta_ue) => {
                for (let i = 0; i < controles_ta_ue.length; i++) {
                  delete controles_ta_ue[i].id; // El id lo asigna directus (autoincrement)
                  arrProm.push(sendNewItem('control_ta_usuarios_externos', controles_ta_ue[i]));
                }

                db['control_documentacion']
                  .filter((x) => {
                    if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                    else return false;
                  })
                  .toArray()
                  .then((controles_documentacion) => {
                    for (let i = 0; i < controles_documentacion.length; i++) {
                      delete controles_documentacion[i].id;
                      arrProm.push(
                        sendNewItem('control_documentacion', controles_documentacion[i]),
                      );
                    }

                    db['incidenciasv2']
                      .filter((x) => {
                        if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                        else return false;
                      })
                      .toArray()
                      .then((incidencias) => {
                        for (let i = 0; i < incidencias.length; i++) {
                          delete incidencias[i].id;
                          arrProm.push(sendNewItem('incidencias_v2', incidencias[i]));
                        }

                        db['logs_usuarios_externos']
                          .filter((x) => {
                            if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                            else return false;
                          })
                          .toArray()
                          .then((logsue) => {
                            for (let i = 0; i < logsue.length; i++) {
                              delete logsue[i].id;
                              arrProm.push(sendNewItem('logs_usuarios_externos', logsue[i]));
                            }

                            Promise.all(arrProm).then(() => {
                              // Se envio todo lo sencillo al server; ahora envio aquellas
                              // cosas que tienen una relacion entre si, por lo que primero
                              // tengo que enviar un grupo, conseguir los IDs del server,
                              // actualizar el ID en el otro grupo y enviarlo
                              db['control_lotes']
                                .filter((x) => {
                                  if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                                  else return false;
                                })
                                .toArray()
                                .then((controles_lotes) => {
                                  arrProm = [];
                                  for (let i = 0; i < controles_lotes.length; i++) {
                                    let tmpId = controles_lotes[i].id;
                                    delete controles_lotes[i].id;
                                    arrProm.push(
                                      sendNewItem('control_lotes', controles_lotes[i], tmpId),
                                    );
                                  }

                                  Promise.all(arrProm).then((controlesLotesIds) => {
                                    console.log('controlesLotesIds:');
                                    console.log(controlesLotesIds);

                                    // controlLotesIds es un array de objetos que tienen dos props:
                                    //      remote_id -> id real del control en el server
                                    //      local_id -> id temporal que tenia antes de ser enviado
                                    // Con esto, antes de enviar protocolos, tengo que reemplazar
                                    // en los que corresponda el id temporal del control asociado
                                    // por el id real

                                    db['control_entrega_fruta']
                                      .filter((x) => {
                                        if (typeof x['id'] === 'string')
                                          return x['id'].includes('tmp-');
                                        else return false;
                                      })
                                      .toArray()
                                      .then((controles_entrega_fruta) => {
                                        arrProm = [];
                                        for (let i = 0; i < controles_entrega_fruta.length; i++) {
                                          let tmpId = controles_entrega_fruta[i].id;
                                          delete controles_entrega_fruta[i].id;
                                          arrProm.push(
                                            sendNewItem(
                                              'control_entrega_fruta',
                                              controles_entrega_fruta[i],
                                              tmpId,
                                            ),
                                          );
                                        }

                                        Promise.all(arrProm).then((controlesEntregaFrutaIds) => {
                                          console.log('controlesEntregaFrutaIds:');
                                          console.log(controlesEntregaFrutaIds);

                                          // controlesEntregaFrutaIds es un array de objetos que tienen dos props:
                                          //      remote_id -> id real del control en el server
                                          //      local_id -> id temporal que tenia antes de ser enviado
                                          // Con esto, antes de enviar protocolos, tengo que reemplazar
                                          // en los que corresponda el id temporal del control asociado
                                          // por el id real

                                          console.log('Se termino de mandar todo al server...');
                                          resolve(true);
                                        });
                                      });
                                  });
                                });
                            });
                          });
                      });
                  });
              });
          });
      })
      .catch((err) => reject(err));
  });
};

// Funcion - Actualiza la DB local a la version que existe en el server
// Return - Promise
export const updateToLastRemoteVersion = () => {
  return new Promise((resolve, reject) => {
    getLastRemoteVersion().then((lastVersion) => {
      console.log('Remote DB last version:');
      console.log(lastVersion);
      // Con la data actualizo la DB local

      let updateTableProm = (_collection, _data) => {
        return new Promise((resolve, reject) => {
          clearTable(_collection).then(() => {
            bulkAdd(_collection, _data).then(() => {
              console.log('Finished updating ' + _collection);
              resolve();
            });
          });
        });
      };

      let arrProm = [];
      for (let i = 0; i < lastVersion.length; i++) {
        let collection = Object.keys(lastVersion[i])[0];
        let data = lastVersion[i][collection];
        arrProm.push(updateTableProm(collection, data));
      }

      // Tablas que SOLO se limpian y no se traen datos nuevos del server
      arrProm.push(clearTable('control_documentacion'));
      arrProm.push(clearTable('logs_usuarios_externos'));
      arrProm.push(clearTable('control_ta_usuarios_externos'));
      arrProm.push(clearTable('control_documentacion'));
      arrProm.push(clearTable('incidenciasv2'));

      // Busco data de usuarios (nombre y apellido)
      getUsersData().then((usersData) => {
        arrProm.push(updateTableProm('datos_usuarios', usersData));

        Promise.all(arrProm).then(() => {
          console.log('Promise all updates finished...');
          resolve();
        });
      });
    });
  });
};

/**
 * Envia datos al servidor y, si no hay error, actualiza datos desde el servidor
 * @function
 * @return {}
 */
export const updateDataBases = (_registerSyncEvent) => {
  return new Promise((resolve, reject) => {
    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        sendDataToServer()
          .then((transactionOK) => {
            console.log('Resultado del envio de data al server:');
            console.log(transactionOK);

            if (transactionOK) {
              // El envio al server no tuvo errores; actualizo a la version del server
              updateToLastRemoteVersion().then(() => {
                console.log('Finalizo actualizacion de DB!');
                resolve({ status: 'ok', error: null });
              });
            } else {
              resolve({ status: 'fail', error: 'Error al enviar data al server' });
            }
          })
          .catch((err) => {
            console.log('ERROR AL ENVIAR DATA AL SERVER');
            console.error(err);
          });
      } else {
        // No estoy online! registro un evento de BACK SYNC en el SW
        if (
          'serviceWorker' in navigator &&
          'SyncManager' in window &&
          _registerSyncEvent === true
        ) {
          console.log('Por registrar una req de sync...');
          navigator.serviceWorker.ready.then(function (sw) {
            sw.sync.register('sync-database');
            console.log('Sync req registrada!!!');
          });
        } else {
          console.log('Problema con serviceWorker o SyncManager');
        }
        resolve({ status: 'no_internet', error: null });
      }
    });
  });
};

// Funcion - Agrega un registro a la collection especificada
// Params - _collection (string), _data (object)
// Return - Promise
export const addRecord = (_collection, _data) => {
  return new Promise((resolve, reject) => {
    console.log('addRecord');
    console.log(_data);

    if (!_collection || _collection === '') reject('DB ERROR 25 - Collection not specified');

    db[_collection]
      .add(_data, 'id')
      .then(() => {
        resolve('Item agregado con exito!');
      })
      .catch((err) => reject(err));
  });
};

// Funcion - Busca los registros de la collection especificada con los filtros especificados
// Params - _collection (string), filters (object)(Optional)
// Return - Promise
export const getRecords = (_collection, _filters) => {
  return new Promise((resolve, reject) => {
    //console.log("getRecords");
    //console.log(_filters);
    //console.log(_collection);

    if (!_collection || _collection === '') reject('DB ERROR 25 - Collection not specified');

    // Cuando _filters no esta definido, busco todos los registros de la collection
    if (_filters) {
      db[_collection]
        .where(_filters)
        .toArray()
        .then((items) => {
          resolve(items);
        })
        .catch((err) => reject(err));
    } else {
      db[_collection]
        .toArray()
        .then((items) => {
          resolve(items);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getRecordsFlex = (_collection, _field, _search) => {
  return new Promise((resolve, reject) => {
    if (typeof _search === 'boolean') {
      let bool = _search;

      db[_collection]
        .filter((x) => x[_field] === bool)
        .toArray()
        .then((items) => resolve(items))
        .catch((err) => reject(err));
    } else {
      let rx = new RegExp(_search);

      db[_collection]
        .filter((x) => rx.test(x[_field]))
        .toArray()
        .then((items) => resolve(items))
        .catch((err) => reject(err));
    }
  });
};

/////////////////////////////////////////////////////////////////////////
// Especificas de MonitoreoTA

export const getAdolescentesByEmpresa = (_empresa_id, _filters) => {
  return new Promise((resolve, reject) => {
    if (!_empresa_id) reject('DB ERROR 30 - Se debe indicar un id de empresa.');

    if (!_filters) _filters = { empresa_id: parseInt(_empresa_id) };
    else _filters = { ..._filters, empresa_id: parseInt(_empresa_id) };

    getRecords('adolescentes', _filters).then((items) => {
      const currentDate = getCurrentDate();

      let arrProm = items.map((i) => {
        return new Promise((resolve, reject) => {
          //console.log(i);
          // Busco el contratista de cada adolescente
          getRecords('contratistas', { id: parseInt(i.contratista_id) }).then((c) => {
            //console.log(c);
            let contratista = null;
            if (c.length > 0) contratista = c[0];
            i.contratista = contratista;

            // Busco la empresa de cada adolescente
            getRecords('empresas', { id: parseInt(i.empresa_id) }).then((e) => {
              let empresa = null;
              if (e.length > 0) empresa = e[0];
              i.empresa = empresa;

              // Busco eventos de HOY para este adolescente
              // Ingreso
              db['ingresos_egresos']
                .where({ adolescente_id: i.id, tipo: 'ingreso' })
                .filter((x) => x['fecha_horario'].includes(currentDate))
                .toArray()
                .then((ing) => {
                  let hoyIngreso = null;
                  if (ing.length > 0) hoyIngreso = ing[0];
                  i.hoyIngreso = hoyIngreso;

                  // Egreso
                  db['ingresos_egresos']
                    .where({ adolescente_id: i.id, tipo: 'egreso' })
                    .filter((x) => x['fecha_horario'].includes(currentDate))
                    .toArray()
                    .then((eg) => {
                      let hoyEgreso = null;
                      if (eg.length > 0) hoyEgreso = eg[0];
                      i.hoyEgreso = hoyEgreso;

                      // Monitoreo
                      db['control_ta']
                        .where({ adolescente_id: i.id })
                        .filter((x) => x['fecha_horario'].includes(currentDate))
                        .toArray()
                        .then((mon) => {
                          let hoyMonitoreo = null;
                          if (mon.length > 0) hoyMonitoreo = mon[0];
                          i.hoyMonitoreo = hoyMonitoreo;

                          // Busco Ingresos/Egresos
                          db['ingresos_egresos']
                            .where({ adolescente_id: i.id })
                            .reverse()
                            .toArray()
                            .then((ingEgList) => {
                              let historicoIngresosEgresos = null;
                              if (ingEgList.length > 0) historicoIngresosEgresos = ingEgList;
                              i.historicoIngresosEgresos = historicoIngresosEgresos;

                              // Busco Monitoreos
                              db['control_ta']
                                .where({ adolescente_id: i.id })
                                .reverse()
                                .toArray()
                                .then((monList) => {
                                  let historicoMonitoreos = null;
                                  if (monList.length > 0) historicoMonitoreos = monList;
                                  i.historicoMonitoreos = historicoMonitoreos;
                                  resolve(i);
                                });
                            });
                        });
                    });
                });
            });
          });
        });
      });

      Promise.all(arrProm).then((adolescentes) => {
        //console.log(adolescentes)
        resolve(adolescentes);
      });
    });
  });
};

/**
 * Agrega el responsable de la empresa que recibe al ninio
 * @function
 * @param {string} _id_incidencia - ID de la incidencia a actualizar
 * @param {string} _responsable - Nombre del responsable
 * @return {void}
 */
export const updateIncidencia = (_id_incidencia, _responsable) => {
  return new Promise((resolve, reject) => {
    db['incidencias'].update(_id_incidencia, { responsable_empresa: _responsable }).then(() => {
      return resolve(true);
    });
  });
};

export const updateSituacion = (_id_incidencia, _info_complementaria) => {
  return new Promise((resolve, reject) => {
    db['incidenciasv2'].update(_id_incidencia, _info_complementaria).then(() => {
      return resolve(true);
    });
  });
};

/**
 * Devuelve lista de lotes de una empresa con un campo extra indicando si ya fue monitoreado en el dia actual
 * @function
 * @param {number / string} _empresa_id - ID de la empresa para la que hay que buscar los lotes
 * @return {array / null}
 */
export const getLotesByEmpresa = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    console.log('getLotesByEmpresa');
    console.log(_empresa_id);
    if (typeof _empresa_id === 'string') _empresa_id = parseInt(_empresa_id);

    let currentDate = getCurrentDate();

    db['lotes']
      .where({ empresa_id: _empresa_id })
      .toArray()
      .then((lotes) => {
        console.log(lotes);
        //resolve(lotes);

        let arrProm = lotes.map((i) => {
          return new Promise((resolve, reject) => {
            db['control_lotes']
              .where({ lote_id: i.id })
              .filter((x) => x['fecha_horario'].includes(currentDate))
              .toArray()
              .then((c) => {
                let hoyControl = null;
                if (c.length > 0) hoyControl = c[0];

                i.hoyControl = hoyControl;

                db['control_entrega_fruta']
                  .where({ lote_id: i.id })
                  .filter((x) => x['fecha_horario'].includes(currentDate))
                  .toArray()
                  .then((c) => {
                    let hoyControlPuntoEntrega = null;
                    if (c.length > 0) hoyControlPuntoEntrega = c[0];

                    i.hoyControlPuntoEntrega = hoyControlPuntoEntrega;
                    resolve(i);
                  });
              });
          });
        });

        console.log(arrProm);

        Promise.all(arrProm).then((listaLotes) => {
          console.log('listaLotes:');
          console.log(listaLotes);
          resolve(listaLotes);
        });
      });
  });
};

/**
 * Checkea si un TA se encuentra dado de alta a nivel local
 * @function
 * @param {number/string} _dni - DNI del TA a verificar
 * @return {boolean} - True cuando el TA existe, False cuando no existe
 */
export const checkIfTAExistsLocal = (_dni) => {
  return new Promise((resolve, reject) => {
    _dni = parseInt(_dni);
    let empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));

    db['adolescentes']
      .filter((x) => {
        if (x.dni === _dni && x.empresa_id === empresa_id) {
          return true;
        } else {
          return false;
        }
      })
      .toArray()
      .then((a) => {
        if (a.length > 0) resolve(true);
        else resolve(false);
      });
  });
};

export const checkIfTAExistsLocalByUID = (_uid) => {
  return new Promise((resolve, reject) => {
    let empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));

    db['adolescentes']
      .filter((x) => {
        if (x.uid_renatre === _uid && x.empresa_id === empresa_id) {
          return true;
        } else {
          return false;
        }
      })
      .toArray()
      .then((a) => {
        if (a.length > 0) resolve(a[0]);
        else resolve(null);
      });
  });
};

export const getEventById = (_collection, _filters) => {
  return new Promise((resolve, reject) => {
    console.log(_collection);
    console.log(_filters);
    getRecords(_collection, _filters).then((events) => {
      let e = null;
      console.log(events);
      if (events.length > 0) e = events[0];

      // No se encontro el evento
      if (e === null) resolve(null);

      // Se encontro el evento; agrego data de adolescente
      getRecords('adolescentes', { id: e.adolescente_id }).then((adolescentes) => {
        let a = null;
        if (adolescentes.length > 0) a = adolescentes[0];

        e.adolescente = a;

        resolve(e);
      });
    });
  });
};

export const getUserData = (_id) => {
  return new Promise((resolve, reject) => {
    if (typeof _id === 'string') _id = parseInt(_id);

    getRecords('users_data', { id: _id })
      .then((users) => {
        let u = null;
        if (users.length > 0) u = users[0];
        resolve(u);
      })
      .catch((err) => reject(err));
  });
};

/**
 * Devuelve cuantos TAs y cuantos lotes faltan monitorear en el dia para una empresa determinada
 * @function
 * @param {number} _empresa_id - ID de la empresa
 * @return {object} - Object con dos props: pendientes_ta y pendientes_lotes
 */
export const getPendientes = (_empresa_id) => {
  return new Promise((resolve, reject) => {
    let pendientes = { lotes: 0, adolescentes: 0 };

    getLotesByEmpresa(parseInt(_empresa_id))
      .then((lotes) => {
        //console.log(lotes);
        let cantidadLotes = lotes.length;

        if (cantidadLotes > 0) {
          let par = getParLotes(cantidadLotes);

          // Tengo que graficar index === par e index === par+1
          let arr = [];
          let aux = par * 2; // Este indice esta justo 1 por arriba del segundo item del par
          if (lotes[aux - 2]) {
            if (lotes[aux - 2].hoyControl === null) arr.push(lotes[aux - 2]);
          }

          if (lotes[aux - 1]) {
            if (lotes[aux - 1].hoyControl === null) arr.push(lotes[aux - 1]);
          }

          //console.log("lotes: ");
          //console.log(arr);

          let lotesPendientes = 0;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].hoyControl === null) lotesPendientes++;
          }

          pendientes.lotes = lotesPendientes;
        }

        getAdolescentesByEmpresa(parseInt(_empresa_id)).then((adolescentes) => {
          let adolescentesPendientes = 0;
          for (let i = 0; i < adolescentes.length; i++) {
            if (adolescentes[i].hoyMonitoreo === null && adolescentes[i].hoyEgreso === null)
              adolescentesPendientes++;
          }

          pendientes.adolescentes = adolescentesPendientes;

          resolve(pendientes);
        });
      })
      .catch((err) => console.log(err));
  });
};

export const getContratistas = () => {
  return new Promise((resolve, reject) => {
    getRecords('contratistas').then((contratistas) => {
      console.log(contratistas);

      let arrProm = [];
      if (contratistas.length > 0) {
        for (let i = 0; i < contratistas.length; i++) {
          let prom = new Promise((resolve, reject) => {
            db['empresas']
              .where({ id: contratistas[i].empresa_asociada_id })
              .toArray()
              .then((empresas) => {
                let e = null;
                if (empresas.length > 0) e = empresas[0];

                contratistas[i].empresa = e;

                resolve(contratistas[i]);
              });
          });

          arrProm.push(prom);
        }

        Promise.all(arrProm).then((cArr) => {
          resolve(cArr);
        });
      } else {
        resolve(contratistas);
      }
    });
  });
};

/**
 * Devuelve la cantidad de items temporales existentes
 * @function
 * @return {numeric} El numero de items
 */
export const getPendingsSoSend = () => {
  return new Promise((resolve, reject) => {
    let count = 0;
    db['adolescentes']
      .filter((x) => {
        if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
        else return false;
      })
      .toArray()
      .then((a) => {
        count += a.length;

        db['control_ta']
          .filter((x) => {
            if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
            else return false;
          })
          .toArray()
          .then((cta) => {
            count += cta.length;

            db['control_ta_usuarios_externos']
              .filter((x) => {
                if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                else return false;
              })
              .toArray()
              .then((ctae) => {
                count += ctae.length;

                db['ingresos_egresos']
                  .filter((x) => {
                    if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                    else return false;
                  })
                  .toArray()
                  .then((ie) => {
                    count += ie.length;

                    db['control_lotes']
                      .filter((x) => {
                        if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                        else return false;
                      })
                      .toArray()
                      .then((cl) => {
                        count += cl.length;

                        db['control_documentacion']
                          .filter((x) => {
                            if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                            else return false;
                          })
                          .toArray()
                          .then((cd) => {
                            count += cd.length;

                            db['incidencias']
                              .filter((x) => {
                                if (typeof x['id'] === 'string') return x['id'].includes('tmp-');
                                else return false;
                              })
                              .toArray()
                              .then((i) => {
                                count += i.length;

                                resolve(count);
                              });
                          });
                      });
                  });
              });
          });
      });
  });
};
