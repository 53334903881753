import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigationC from '../../Navigation/MyBottomNavigationC';
import Loader from '../../Misc/Loader';

import { URLpreffix } from '../../../config';
import { getLotesByEmpresa, getAdolescentesByEmpresa } from '../../../indexedDB/operations';
import {
  getISOweek,
  getYearOfISOweek,
  getDateISOweek,
  getPrettyDateFromObject,
} from '../../../helpers/helpers';

class ControlTrabajadorUEP1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      showComenzarMonitoreoMessage: false,
      load: false,

      semanasList: null,
      TAsList: null,
      lotesList: null,

      adolescente_id: '',
      lote_id: '',
      numero_semana: '',

      adolescente_idError: false,
      lote_idError: false,
      numero_semanaError: false,

      contratista_id: parseInt(localStorage.getItem('usuario_contratista_id')),
    };
  }

  componentDidMount() {
    let empresa_id = parseInt(localStorage.getItem('usuario_empresa_id'));

    let optionsLotes = [];
    let optionsSemanas = [];
    let optionsTAs = [];

    // Lista de Lotes
    getLotesByEmpresa(empresa_id).then((lotes) => {
      optionsLotes = lotes.map((lote) => <option value={lote.id}>{lote.nombre}</option>);

      // Lista TAs
      getAdolescentesByEmpresa(empresa_id).then((tas) => {
        optionsTAs = tas.map((ta) => <option value={ta.id}>DNI: {ta.dni}</option>);

        // Lista de semanas
        let today = new Date();

        let actualWeekNumber = getISOweek(today);

        let dateActualWeek = getDateISOweek(today.getFullYear(), actualWeekNumber);

        let lastDayOfWeek = new Date(
          dateActualWeek.getFullYear(),
          dateActualWeek.getMonth(),
          dateActualWeek.getDate(),
        );
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

        let optionSemanaActual = (
          <option defaultSelected value={actualWeekNumber}>
            {getPrettyDateFromObject(dateActualWeek)} al {getPrettyDateFromObject(lastDayOfWeek)}{' '}
            (Semana {actualWeekNumber})
          </option>
        );

        optionsSemanas.push(optionSemanaActual);

        this.setState({
          load: true,
          semanasList: optionsSemanas,
          TAsList: optionsTAs,
          lotesList: optionsLotes,
          showLoader: false,
          numero_semana: actualWeekNumber,
        });
      });
    });
  }

  onChange = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value,
      showComenzarMonitoreoMessage: false,
      [prop + 'Error']: false,
    });
  };

  onClickComenzarMonitoreoButton = () => {
    // Validacion
    let errs = {};
    let validationSuccess = true;

    if (this.state.adolescente_id === '') {
      errs.adolescente_idError = true;
      validationSuccess = false;
    }

    if (this.state.lote_id === '') {
      errs.lote_idError = true;
      validationSuccess = false;
    }

    if (this.state.numero_semana === '') {
      errs.numero_semanaError = true;
      validationSuccess = false;
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showComenzarMonitoreoMessage: true });
      return;
    }

    let queryParams = '?idTA=' + this.state.adolescente_id;
    queryParams += '&idContratista=' + this.state.contratista_id;
    queryParams += '&idLote=' + this.state.lote_id;
    queryParams += '&numeroSemana=' + this.state.numero_semana;
    this.props.history.push(URLpreffix + '/ControlTrabajadorUEP2' + queryParams);
  };

  render() {
    if (this.state.load === false) {
      return (
        <main>
          <Loader show={this.state.showLoader} />
          <NavBar />
          <MyBottomNavigationC />
        </main>
      );
    }

    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h6" align="center" className="mb1">
                Datos generales del monitoreo.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel error={this.state.lote_idError} htmlFor="outlined-lote-native-simple">
                  Lote*
                </InputLabel>
                <Select
                  native
                  label="Lote"
                  error={this.state.lote_idError}
                  inputProps={{
                    name: 'Lote',
                    id: 'outlined-lote-native-simple',
                  }}
                  onChange={this.onChange('lote_id')}
                >
                  <option aria-label="None" value="" />
                  {this.state.lotesList}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel
                  error={this.state.numero_semanaError}
                  htmlFor="outlined-semana-native-simple"
                >
                  Semana*
                </InputLabel>
                <Select
                  disabled
                  native
                  label="Semana"
                  error={this.state.numero_semanaError}
                  inputProps={{
                    name: 'Semana',
                    id: 'outlined-semana-native-simple',
                  }}
                  onChange={this.onChange('numero_semana')}
                >
                  {this.state.semanasList}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel
                  error={this.state.adolescente_idError}
                  htmlFor="outlined-ta-native-simple"
                >
                  Trabajador/a Adolescente*
                </InputLabel>
                <Select
                  native
                  label="Trabajador/a Adolescente"
                  error={this.state.adolescente_idError}
                  inputProps={{
                    name: 'Trabajador/a Adolescente',
                    id: 'outlined-ta-native-simple',
                  }}
                  onChange={this.onChange('adolescente_id')}
                >
                  <option aria-label="None" value="" />
                  {this.state.TAsList}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={this.onClickComenzarMonitoreoButton}
                variant="contained"
                color="secondary"
                size="large"
              >
                Comenzar monitoreo
              </Button>

              {this.state.showComenzarMonitoreoMessage ? (
                <Grid item xs={12} className="mb1 mt1">
                  <Alert severity="error">
                    <AlertTitle>
                      <b>Campos sin completar.</b>
                    </AlertTitle>
                    Falta completar campos obligatorios.
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigationC />
      </main>
    );
  }
}

export default withRouter(ControlTrabajadorUEP1);
