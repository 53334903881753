import React from 'react';
import { withRouter } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import FilesManager from './FilesManager/FilesManager';
import QuestionTitle from './Components/QuestionTitle';
import QuestionSelect from './Components/QuestionSelect';
import FloatBar from './Components/FloatBar';

import Button from '@material-ui/core/Button';

import ContentSeparator from '../Misc/ContentSeparator';
import Loader from '../Misc/Loader';

import { getFormattedDateWithSlashes } from '../../helpers/helpers';
import {
  getEmpresa,
  getFormValidationV2YerbaMate,
  updateValidationFormV2YerbaMate,
  sendNewItem,
  getItemsFromCollection,
} from '../../requests/data';
import {
  DICCIONARIO_SELECTS,
  DICCIONARIO_WARNINGS_YERBA_MATE,
  FIELDS_WITH_INFO,
} from '../../config';

class ValidacionFormulario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      load: false,

      empresaNombre: null,
      fechaUltimaActualizacion: null,

      valueEstandar1: 0,
      totalEstandar1: 0,
      severityEstandar1: 'error',
      valueEstandar2: 0,
      totalEstandar2: 0,
      severityEstandar2: 'error',
      valueEstandar3: 0,
      totalEstandar3: 0,
      severityEstandar3: 'error',
      valueEstandar4: 0,
      totalEstandar4: 0,
      severityEstandar4: 'error',

      valueGeneral: 0,
      floatBarOpacity: 1,
      totalGeneral: 0,
      severityGeneral: 'error',

      showDialogResponsables: false,
      agregarResponsableDisabled: true,
      showLoaderResponsable: false,
      nombreResponsable: '',
      rolResponsable: '',

      showDialogCapacitaciones: false,
      agregarCapacitacionDisabled: true,
      showLoaderCapacitacion: false,
      actualizandoCapacitacion: false,
      capacitacionId: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionUnionId: null,
      capacitacionFileId: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionFileName: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionFileUrl: null, // Se usa en la actualizacion de las capacitaciones
      capacitacionContenidos: '',
      capacitacionDocumentoVerificacion: '',
      capacitacionFecha: '',
      capacitacionLugar: '',
      capacitacionObservaciones: '',
      capacitacionTema: '',
      capacitacionAsistentes: '',

      empresaId: localStorage.getItem('usuario_empresa_id'),
      reportId: null,

      estandar_1_1_value: null,
      estandar_1_1_warning: false,
      estandar_1_1_approved: false,
      estandar_1_1_files: [],

      estandar_1_2_value: null,
      estandar_1_2_warning: false,
      estandar_1_2_approved: false,
      estandar_1_2_files: [],

      estandar_2_1_value: null,
      estandar_2_1_warning: false,
      estandar_2_1_approved: false,
      estandar_2_1_files: [],

      estandar_2_2_value: null,
      estandar_2_2_warning: false,
      estandar_2_2_approved: false,
      estandar_2_2_files: [],

      estandar_2_3_value: null,
      estandar_2_3_warning: false,
      estandar_2_3_approved: false,
      estandar_2_3_files: [],
      estandar_2_3_disabled: false,

      estandar_2_4_value: null,
      estandar_2_4_warning: false,
      estandar_2_4_approved: false,
      estandar_2_4_files: [],

      estandar_2_5_value: null,
      estandar_2_5_warning: false,
      estandar_2_5_approved: false,
      estandar_2_5_files: [],

      estandar_2_6_value: null,
      estandar_2_6_warning: false,
      estandar_2_6_approved: false,
      estandar_2_6_1_value: '',
      estandar_2_6_files: [],

      estandar_3_1_value: null,
      estandar_3_1_warning: false,
      estandar_3_1_approved: false,
      estandar_3_1_files: [],

      estandar_3_2_value: null,
      estandar_3_2_warning: false,
      estandar_3_2_approved: false,
      estandar_3_2_files: [],
      estandar_3_2_disabled: false,

      estandar_3_3_value: null,
      estandar_3_3_warning: false,
      estandar_3_3_approved: false,
      estandar_3_3_files: [],

      estandar_3_4_value: null,
      estandar_3_4_warning: false,
      estandar_3_4_approved: false,
      estandar_3_4_files: [],

      estandar_3_5_value: null,
      estandar_3_5_warning: false,
      estandar_3_5_approved: false,
      estandar_3_5_files: [],

      estandar_3_6_value: null,
      estandar_3_6_warning: false,
      estandar_3_6_approved: false,
      estandar_3_6_1_value: null,
      estandar_3_6_1_warning: false,
      estandar_3_6_1_info: 'Buscando datos en la webapp...',
      estandar_3_6_2_value: '',
      estandar_3_6_files: [],

      estandar_3_7_value: null,
      estandar_3_7_warning: false,
      estandar_3_7_approved: false,
      estandar_3_7_1_value: null,
      estandar_3_7_1_warning: false,
      estandar_3_7_1_info: 'Buscando datos en la webapp...',
      estandar_3_7_2_value: '',
      estandar_3_7_files: [],

      estandar_3_8_value: null,
      estandar_3_8_warning: false,
      estandar_3_8_approved: false,
      estandar_3_8_1_value: null,
      estandar_3_8_1_warning: false,
      estandar_3_8_1_info: 'Buscando datos en la webapp...',
      estandar_3_8_2_value: '',
      estandar_3_8_files: [],

      estandar_3_9_value: null,
      estandar_3_9_warning: false,
      estandar_3_9_approved: false,
      estandar_3_9_1_info: 'Buscando datos en la webapp...',
      estandar_3_9_1_value: null,
      estandar_3_9_2_value: '',
      estandar_3_9_files: [],

      estandar_4_1_value: null,
      estandar_4_1_warning: false,
      estandar_4_1_approved: false,
      estandar_4_1_files: [],

      estandar_4_2_value: null,
      estandar_4_2_warning: false,
      estandar_4_2_approved: false,
      estandar_4_2_files: [],

      estandar_4_3_value: null,
      estandar_4_3_warning: false,
      estandar_4_3_approved: false,
      estandar_4_3_files: [],

      estandar_4_4_value: null,
      estandar_4_4_warning: false,
      estandar_4_4_approved: false,
      estandar_4_4_1_value: null,
      estandar_4_4_1_info: 'Buscando datos en la webapp...',
      estandar_4_4_2_value: '',
      estandar_4_4_files: [],

      estandar_4_5_value: null,
      estandar_4_5_warning: false,
      estandar_4_5_approved: false,
      estandar_4_5_1_value: null,
      estandar_4_5_1_info: 'Buscando datos en la webapp...',
      estandar_4_5_2_value: '',
      estandar_4_5_files: [],

      testfiles: [],
    };

    this.sendChangeTextTimeout = null;
  }

  componentDidMount() {
    ///////////////////////////////////////////////
    ///////////////////////////////////////////////
    //// CAMBIAR POR EL DINAMICO OBTENIDO DE CADA USER
    let empresa_id = this.state.empresaId;
    ///////////////////////////////////////////////
    getEmpresa(empresa_id).then((empresas) => {
      let empresa = null;
      if (empresas.length > 0) empresa = empresas[0];
      getFormValidationV2YerbaMate(empresa_id).then((form) => {
        // Si no existe el form, primero lo creo...
        if (form.length === 0) {
          sendNewItem('validaciones_v2_yerba_mate', { empresa: empresa_id }).then((res) => {
            getFormValidationV2YerbaMate(empresa_id).then((form) => {
              // Fecha ultima actualizacion
              let fechaUltimaActualizacion = getFormattedDateWithSlashes(
                form[0].modified_on.split('T')[0],
              );

              // Cargo los datos del formulario...

              let obj = {};
              let keys = Object.keys(form[0]);
              for (let i = 0; i < keys.length; i++) {
                if (
                  keys[i].includes('estandar') &&
                  !keys[i].includes('responsables') &&
                  !keys[i].includes('capacitaciones')
                ) {
                  if (keys[i].includes('files')) {
                    obj[keys[i]] = form[0][keys[i]];
                  } else {
                    this.setValueAndWarning(keys[i], form[0][keys[i]]);

                    this.setFieldInfo(keys[i], form[0][keys[i]]);
                  }
                }
              }

              fetch(
                'https://webapp-scs.dya.org.ar/directus/public/admin/get-fields-data.php?tipo_cosecha=yerba_mate',
              )
                .then((res) => res.json())
                .then((fieldsData) => {
                  this.setState({
                    ...obj,

                    fechaUltimaActualizacion: fechaUltimaActualizacion,
                    empresaNombre: empresa ? empresa.nombre : null,

                    fieldsData: fieldsData.data,

                    reportId: form[0].id,
                    load: true,
                  });

                  this.checkValidity();
                });
            });
          });
        } else {
          // Ya esta cargado el form...
          // Cargo los datos del formulario...

          let fechaUltimaActualizacion = getFormattedDateWithSlashes(
            form[0].modified_on.split('T')[0],
          );

          let obj = {};
          let keys = Object.keys(form[0]);
          for (let i = 0; i < keys.length; i++) {
            if (
              keys[i].includes('estandar') &&
              !keys[i].includes('responsables') &&
              !keys[i].includes('capacitaciones')
            ) {
              if (keys[i].includes('files')) {
                obj[keys[i]] = form[0][keys[i]];
              } else {
                this.setValueAndWarning(keys[i], form[0][keys[i]]);

                this.setFieldInfo(keys[i], form[0][keys[i]]);
              }
            }
          }

          fetch(
            'https://webapp-scs.dya.org.ar/directus/public/admin/get-fields-data.php?tipo_cosecha=yerba_mate',
          )
            .then((res) => res.json())
            .then((fieldsData) => {
              this.setState({
                ...obj,

                fechaUltimaActualizacion: fechaUltimaActualizacion,
                empresaNombre: empresa ? empresa.nombre : null,

                fieldsData: fieldsData.data,

                reportId: form[0].id,
                load: true,
              });

              this.checkValidity();
            });
        }
      });
    });
  }

  checkValidity = () => {
    this.setState({ floatBarOpacity: 0 });

    let obj = {};
    let totalEstandar1 = 0;
    let valueEstandar1 = 0;
    let totalEstandar2 = 0;
    let valueEstandar2 = 0;
    let totalEstandar3 = 0;
    let valueEstandar3 = 0;
    let totalEstandar4 = 0;
    let valueEstandar4 = 0;

    // estandar_1_1
    totalEstandar1++;
    if (this.state.estandar_1_1_value === '1' && this.state.estandar_1_1_files.length > 0) {
      obj['estandar_1_1_approved'] = true;
      valueEstandar1++;
    } else {
      obj['estandar_1_1_approved'] = false;
    }

    // estandar_1_2
    totalEstandar1++;
    if (this.state.estandar_1_2_value === '1' && this.state.estandar_1_2_files.length > 0) {
      obj['estandar_1_2_approved'] = true;
      valueEstandar1++;
    } else {
      obj['estandar_1_2_approved'] = false;
    }

    // estandar_2_1
    totalEstandar2++;
    if (this.state.estandar_2_1_value === '1' && this.state.estandar_2_1_files.length > 0) {
      obj['estandar_2_1_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_1_approved'] = false;
    }

    // estandar_2_2
    totalEstandar2++;
    if (this.state.estandar_2_2_value === '1' && this.state.estandar_2_2_files.length > 0) {
      obj['estandar_2_2_approved'] = true;
      valueEstandar2++;
    } else {
      if (this.state.estandar_2_2_value === '1') {
        // "SI" pero todavia no se subio el archivo respaldatorio
        obj['estandar_2_2_approved'] = false;
      } else {
        obj['estandar_2_2_approved'] = 'NA'; // Esta pregunta es 'trabaja con contratistas'; si es NO, no suma ni resta
        totalEstandar2--;
      }
    }

    // estandar_2_3
    totalEstandar2++;
    if (this.state.estandar_2_3_value === '1' && this.state.estandar_2_3_files.length > 0) {
      obj['estandar_2_3_approved'] = true;
      valueEstandar2++;
    } else {
      // Esta pregunta es dependiente de 2_2 (tiene contratistas)
      // Cuando el value es 3, no hay que mostrar el approved
      if (this.state.estandar_2_3_value === '3') {
        obj['estandar_2_3_approved'] = 'NA';
        totalEstandar2--;
      } else {
        obj['estandar_2_3_approved'] = false;
      }
    }

    // estandar_2_4
    totalEstandar2++;
    if (this.state.estandar_2_4_value === '1' && this.state.estandar_2_4_files.length > 0) {
      obj['estandar_2_4_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_4_approved'] = false;
    }

    // estandar_2_5
    totalEstandar2++;
    if (this.state.estandar_2_5_value === '1' && this.state.estandar_2_5_files.length > 0) {
      obj['estandar_2_5_approved'] = true;
      valueEstandar2++;
    } else {
      obj['estandar_2_5_approved'] = false;
    }

    // estandar_3_1
    totalEstandar3++;
    if (this.state.estandar_3_1_value === '1' && this.state.estandar_3_1_files.length > 0) {
      obj['estandar_3_1_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_1_approved'] = false;
    }

    // estandar_3_2
    totalEstandar3++;
    if (this.state.estandar_3_2_value === '1' && this.state.estandar_3_2_files.length > 0) {
      obj['estandar_3_2_approved'] = true;
      valueEstandar3++;
    } else {
      // Esta pregunta es dependiente de 2_2 (tiene contratistas)
      // Cuando el value es 3, no hay que mostrar el approved
      if (this.state.estandar_3_2_value === '3') {
        obj['estandar_3_2_approved'] = 'NA';
        totalEstandar3--;
      } else {
        obj['estandar_3_2_approved'] = false;
      }
    }

    // estandar_3_3
    totalEstandar3++;
    if (this.state.estandar_3_3_value === '1' && this.state.estandar_3_3_files.length > 0) {
      obj['estandar_3_3_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_3_approved'] = false;
    }

    // estandar_3_4
    totalEstandar3++;
    if (this.state.estandar_3_4_value === '1' && this.state.estandar_3_4_files.length > 0) {
      obj['estandar_3_4_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_4_approved'] = false;
    }

    // estandar_3_5
    totalEstandar3++;
    if (this.state.estandar_3_5_value === '1' && this.state.estandar_3_5_files.length > 0) {
      obj['estandar_3_5_approved'] = true;
      valueEstandar3++;
    } else {
      obj['estandar_3_5_approved'] = false;
    }

    // estandar_4_1
    totalEstandar4++;
    if (this.state.estandar_4_1_value === '1' && this.state.estandar_4_1_files.length > 0) {
      obj['estandar_4_1_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_1_approved'] = false;
    }

    // estandar_4_2
    totalEstandar4++;
    if (this.state.estandar_4_2_value === '1' && this.state.estandar_4_2_files.length > 0) {
      obj['estandar_4_2_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_2_approved'] = false;
    }

    // estandar_4_3
    totalEstandar4++;
    if (this.state.estandar_4_3_value === '1' && this.state.estandar_4_3_files.length > 0) {
      obj['estandar_4_3_approved'] = true;
      valueEstandar4++;
    } else {
      obj['estandar_4_3_approved'] = false;
    }

    // Calculo colores...
    let severityEstandar1 = 'error';
    if (valueEstandar1 === totalEstandar1) {
      severityEstandar1 = 'success';
    } else {
      if (valueEstandar1 >= totalEstandar1 / 2) {
        severityEstandar1 = 'warning';
      }
    }

    let severityEstandar2 = 'error';
    if (valueEstandar2 === totalEstandar2) {
      severityEstandar2 = 'success';
    } else {
      if (valueEstandar2 >= totalEstandar2 / 2) {
        severityEstandar2 = 'warning';
      }
    }

    let severityEstandar3 = 'error';
    if (valueEstandar3 === totalEstandar3) {
      severityEstandar3 = 'success';
    } else {
      if (valueEstandar3 >= totalEstandar3 / 2) {
        severityEstandar3 = 'warning';
      }
    }

    let severityEstandar4 = 'error';
    if (valueEstandar4 === totalEstandar4) {
      severityEstandar4 = 'success';
    } else {
      if (valueEstandar4 >= totalEstandar4 / 2) {
        severityEstandar4 = 'warning';
      }
    }

    // Total general...
    let valueGeneral = valueEstandar1 + valueEstandar2 + valueEstandar3 + valueEstandar4;
    let totalGeneral = totalEstandar1 + totalEstandar2 + totalEstandar3 + totalEstandar4;
    let severityGeneral = 'error';
    if (valueGeneral === totalGeneral) {
      severityGeneral = 'success';
    } else {
      if (valueGeneral >= totalGeneral / 2) {
        severityGeneral = 'warning';
      }
    }

    obj['totalEstandar1'] = totalEstandar1;
    obj['valueEstandar1'] = valueEstandar1;
    obj['totalEstandar2'] = totalEstandar2;
    obj['valueEstandar2'] = valueEstandar2;
    obj['totalEstandar3'] = totalEstandar3;
    obj['valueEstandar3'] = valueEstandar3;
    obj['totalEstandar4'] = totalEstandar4;
    obj['valueEstandar4'] = valueEstandar4;

    obj['totalGeneral'] = totalGeneral;
    obj['severityGeneral'] = severityGeneral;

    obj['severityEstandar1'] = severityEstandar1;
    obj['severityEstandar2'] = severityEstandar2;
    obj['severityEstandar3'] = severityEstandar3;
    obj['severityEstandar4'] = severityEstandar4;

    this.setState({ ...obj }, () => {
      setTimeout(() => {
        this.setState({ floatBarOpacity: 1, valueGeneral: valueGeneral });
      }, 300);
    });
  };

  setFieldInfo = (_field, _value) => {
    // Checkeo si es un field con info...
    if (!FIELDS_WITH_INFO.hasOwnProperty(_field)) return;

    if (!FIELDS_WITH_INFO[_field]['trueValues'].includes(_value)) return;

    let fields = FIELDS_WITH_INFO[_field].fields ? FIELDS_WITH_INFO[_field].fields : 'id';
    let filter = 'filter[empresa_id][eq]=' + this.state.empresaId;
    let collection = FIELDS_WITH_INFO[_field]['collection'];

    if (_field === 'estandar_4_1_6_1') {
      filter = false;
    }

    if (
      _field === 'estandar_3_9_1' ||
      _field === 'estandar_3_6_1' ||
      _field === 'estandar_3_7_1' ||
      _field === 'estandar_4_5_1'
    ) {
      filter = 'filter[lote_id.empresa_id][eq]=' + this.state.empresaId;
    }

    if (_field === 'estandar_3_8_1') {
      filter = 'filter[contratista_id.empresa_asociada_id][eq]=' + this.state.empresaId;
    }

    getItemsFromCollection(collection, true, fields, filter).then((res) => {
      if (_field === 'estandar_3_4_1') {
        this.setState({
          estandar_3_4_1_info:
            'Se realizaron ' + res[collection].length + ' controles de documentación.',
        });
      }

      if (_field === 'estandar_3_1_6') {
        this.setState({
          estandar_3_1_6_info: 'Se realizaron ' + res[collection].length + ' ingresos y egresos.',
        });
      }

      if (_field === 'estandar_3_8_1') {
        this.setState({
          estandar_3_8_1_info:
            'Se registraron ' +
            res[collection].length +
            ' inicios de sesión de usuarios externos de la empresa.',
        });
      }

      if (_field === 'estandar_3_9') {
        // Genero chips con TA + links de sus documentos...
        let tas = res[collection];
        let total = tas.length;
        let completo = 0;
        for (let i = 0; i < tas.length; i++) {
          let doc1 = tas[i].documentacion_respaldatoria_1;
          let doc2 = tas[i].documentacion_respaldatoria_2;
          let doc3 = tas[i].documentacion_respaldatoria_3;

          if (doc1 && doc2 && doc3) completo++;
        }

        this.setState({ estandar_3_9_info: { total: total, completo: completo } });
      }

      if (_field === 'estandar_3_6_1') {
        this.setState({
          estandar_3_6_1_info:
            'Se realizaron ' +
            res[collection].length +
            ' controles en los puntos de entrega de fruta de la empresa.',
        });
      }

      if (_field === 'estandar_3_7_1') {
        this.setState({
          estandar_3_7_1_info:
            'Se realizaron ' +
            res[collection].length +
            ' controles en los puntos de entrega de fruta de la empresa.',
        });
      }

      if (_field === 'estandar_3_9_1') {
        this.setState({
          estandar_3_9_1_info:
            'Se realizaron ' + res[collection].length + ' controles en los lotes de la empresa.',
        });
      }

      if (_field === 'estandar_4_5_1') {
        this.setState({
          estandar_4_5_1_info:
            'Se realizaron ' + res[collection].length + ' controles en los lotes de la empresa.',
        });
      }

      if (_field === 'estandar_4_4_1') {
        let cantidadIncidencias = 0;
        for (let i = 0; i < res[collection].length; i++) {
          if (res[collection][i]['registrado_usuario_id']) {
            if (
              res[collection][i]['registrado_usuario_id']['empresa_id'] ===
              parseInt(this.state.empresaId)
            ) {
              cantidadIncidencias++;
            }
          }
        }

        this.setState({
          estandar_4_4_1_info:
            'Se registraron ' + cantidadIncidencias + ' situaciones de riesgo TI.',
        });
      }
    });
  };

  updateTextField = (_field, _value) => {
    if (_field.includes('estandar')) {
      this.setState({ [_field + '_value']: _value });
    } else {
      this.setState({ [_field]: _value });
    }

    // En el caso de Capacitacion y Responsable no envio la
    // actualizacion del campo, pero si reviso si ya se
    // puede enviar la capacitacion o responsable
    if (_field.includes('capacitacion')) {
      this.setState({ [_field]: _value }, () => {
        // Actualice el state; reviso si se debe habilitar
        // el boton de envio...
        if (
          this.state.capacitacionContenidos !== '' &&
          this.state.capacitacionDocumentoVerificacion !== '' &&
          this.state.capacitacionFecha !== '' &&
          this.state.capacitacionLugar !== '' &&
          this.state.capacitacionObservaciones !== '' &&
          this.state.capacitacionTema !== '' &&
          this.state.capacitacionAsistentes !== ''
        ) {
          this.setState({ agregarCapacitacionDisabled: false });
        } else {
          this.setState({ agregarCapacitacionDisabled: true });
        }
      });

      return;
    }

    if (_field.includes('Responsable')) {
      this.setState({ [_field]: _value }, () => {
        // Actualice el state; reviso si se debe habilitar
        // el boton de envio...
        if (this.state.nombreResponsable !== '' && this.state.rolResponsable !== '') {
          this.setState({ agregarResponsableDisabled: false });
        }
      });
    }

    if (this.sendChangeTextTimeout === null) {
      this.sendChangeTextTimeout = setTimeout(() => {
        clearTimeout(this.sendChangeTextTimeout);
        this.sendChangeTextTimeout = null;
        updateValidationFormV2YerbaMate(this.state.reportId, _field, _value).then(() => {
          this.checkValidity();
        });
      }, 3000);
    } else {
      clearTimeout(this.sendChangeTextTimeout);
      this.sendChangeTextTimeout = null;

      this.sendChangeTextTimeout = setTimeout(() => {
        clearTimeout(this.sendChangeTextTimeout);
        this.sendChangeTextTimeout = null;
        this.sendChangeTextTimeout = null;
        updateValidationFormV2YerbaMate(this.state.reportId, _field, _value).then(() => {
          this.checkValidity();
        });
      }, 3000);
    }
  };

  setValueAndWarning = (_field, _value) => {
    let enableWarning = false;
    let extraFieldUpdates = [];

    if (_field === 'estandar_1_1') {
      if (_value === '2' || _value === '3') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_1_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_2') {
      if (_value === '2') {
        enableWarning = true;

        // Esta es la pregunta de "trabaja con contratistas";
        // Cuando es "NO" se tiene que deshabilitar las preguntas dependientes de contratistas
        // NOTA: Si bien aca se setea el 'approved', tambien lo tenemos que setear en checkValidity
        this.setState({
          estandar_2_2_approved: 'NA',

          estandar_2_3_approved: 'NA',
          estandar_2_3_value: '3',
          estandar_2_3_disabled: true,
          estandar_2_3_warning: false,

          estandar_3_2_approved: 'NA',
          estandar_3_2_value: '3',
          estandar_3_2_disabled: true,
          estandar_3_2_warning: false,
        });

        let prom = updateValidationFormV2YerbaMate(this.state.reportId, 'estandar_2_3', '3');
        extraFieldUpdates.push(prom);

        prom = updateValidationFormV2YerbaMate(this.state.reportId, 'estandar_3_2', '3');
        extraFieldUpdates.push(prom);
      }

      if (_value === '1' || _value === null) {
        enableWarning = false;

        // Esta es la pregunta de "trabaja con contratistas";
        // Cuando es "NO" se tiene que deshabilitar las preguntas dependientes de contratistas
        // NOTA: Si bien aca se setea el 'approved', tambien lo tenemos que setear en checkValidity
        this.setState({
          estandar_2_2_approved: false,

          estandar_2_3_approved: false,
          estandar_2_3_value: null,
          estandar_2_3_disabled: false,
          estandar_2_3_warning: false,

          estandar_3_2_approved: false,
          estandar_3_2_value: null,
          estandar_3_2_disabled: false,
          estandar_3_2_warning: false,
        });

        let prom = updateValidationFormV2YerbaMate(this.state.reportId, 'estandar_2_3', null);
        extraFieldUpdates.push(prom);

        prom = updateValidationFormV2YerbaMate(this.state.reportId, 'estandar_3_2', null);
        extraFieldUpdates.push(prom);
      }
    }

    if (_field === 'estandar_2_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_2_6') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_6') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_7') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_8') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_3_9') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_1') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_2') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_3') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_4') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (_field === 'estandar_4_5') {
      if (_value === '2') {
        enableWarning = true;
      }
    }

    if (extraFieldUpdates.length > 0) {
      Promise.all(extraFieldUpdates).then(() => {});
    }

    // Si existe un campo de warning para este field, lo actualizo
    if (typeof this.state[_field + '_warning'] !== 'undefined') {
      this.setState({ [_field + '_value']: _value, [_field + '_warning']: enableWarning }, () => {
        this.checkValidity();
      });
    } else
      this.setState({ [_field + '_value']: _value }, () => {
        this.checkValidity();
      });

    return true;
  };

  updateField = (_field, _value) => {
    this.setValueAndWarning(_field, _value);

    this.setFieldInfo(_field, _value);

    updateValidationFormV2YerbaMate(this.state.reportId, _field, _value).then(() => {});
  };

  getEstandarText = (key) => {
    const fieldsData = this.state.fieldsData;

    const item = fieldsData.find((e) => e.key === key.trim());

    if (!item) return '';

    return item.text || JSON.parse(item.options)?.title || '';
  };

  render() {
    if (!this.state.load) {
      return (
        <React.Fragment>
          <Loader show={true} />
          <NavBar />
          <MyBottomNavigation />
        </React.Fragment>
      );
    }

    return (
      <>
        <NavBar />

        <div className="no-print">
          <FloatBar
            current={this.state.valueGeneral}
            total={this.state.totalGeneral}
            severity={this.state.severityGeneral}
            opacity={this.state.floatBarOpacity}
          />
        </div>

        <Container maxWidth="lg" style={{ marginTop: 105 + 'px' }} className="for-print mt-0">
          <Typography className="mb-15" variant="h6" color="textSecondary">
            Formulario de SCS
          </Typography>

          <Card
            className="mb1"
            style={{ marginLeft: -12 + 'px', marginRight: -12 + 'px', maxWidth: 'none' }}
          >
            <CardContent>
              <Typography variant="body2" component="p">
                <strong>Fecha última actualización:</strong> {this.state.fechaUltimaActualizacion}
                <br />
                <strong>Empresa:</strong> {this.state.empresaNombre} <br />
                <div className="no-print-rf">
                  <br />
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Imprimir/Descargar SCS Completo
                  </Button>
                  {/* <Button
                    style={{ marginLeft: 10 + 'px' }}
                    href="./files/SCS en Blanco.pdf"
                    download
                    variant="contained"
                    color="primary"
                  >
                    Imprimir/Descargar guía de preguntas
                  </Button>
                  <br />
                  <p style={{ fontSize: 12 + 'px' }}>
                    <strong>Nota:</strong> Si quiere descargar en PDF, en la opción "Destino" debe
                    elegir "Guardar como PDF".{' '}
                    <a href="./img/print-tut.png" target="_blank" style={{ color: '#000' }}>
                      Ver referencia
                    </a>
                  </p> */}
                </div>
              </Typography>
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            {/* PREGUNTA 1 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar1}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_1')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar1 + '/' + this.state.totalEstandar1}
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  <React.Fragment>
                    <QuestionTitle
                      title={`1.1 ${this.getEstandarText('estandar_1_1')}`}
                      approved={this.state.estandar_1_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_1_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_1_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_1_1']}
                      value={this.state.estandar_1_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_1_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography
                          style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                          className="no-print"
                        >
                          {this.getEstandarText('estandar_1_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_1_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_1_1_files_directus_files'}
                          files={this.state.estandar_1_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`1.2 ${this.getEstandarText('estandar_1_2')}`}
                      approved={this.state.estandar_1_2_approved}
                    />
                    <QuestionSelect
                      id="estandar_1_2"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_1_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_1_2']}
                      value={this.state.estandar_1_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_1_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_1_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_1_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_1_2_files_directus_files'}
                          files={this.state.estandar_1_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 2 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar2}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_2')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar2 + '/' + this.state.totalEstandar2}
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  <React.Fragment>
                    <QuestionTitle
                      title={`2.1 ${this.getEstandarText('estandar_2_1')}`}
                      approved={this.state.estandar_2_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_2_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_2_1']}
                      value={this.state.estandar_2_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_2_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography
                          style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                          className="no-print"
                        >
                          {this.getEstandarText('estandar_2_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_1_files_directus_files'}
                          files={this.state.estandar_2_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`2.2 ${this.getEstandarText('estandar_2_2')}`}
                      approved={this.state.estandar_2_2_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_2"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_2_2']}
                      value={this.state.estandar_2_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_2_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_2_files_directus_files'}
                          files={this.state.estandar_2_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`2.3 ${this.getEstandarText('estandar_2_3')}`}
                      approved={this.state.estandar_2_3_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_3"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_2_3']}
                      value={this.state.estandar_2_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_2_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_3_files_directus_files'}
                          files={this.state.estandar_2_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`2.4 ${this.getEstandarText('estandar_2_4')}`}
                      approved={this.state.estandar_2_4_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_4"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_4_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_2_4']}
                      value={this.state.estandar_2_4_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_2_4_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_4_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_4_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_4_files_directus_files'}
                          files={this.state.estandar_2_4_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`2.5 ${this.getEstandarText('estandar_2_5')}`}
                      approved={this.state.estandar_2_5_approved}
                    />
                    <QuestionSelect
                      id="estandar_2_5"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_2_5_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_2_5']}
                      value={this.state.estandar_2_5_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_2_5_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_2_5_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_2_5_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_2_5_files_directus_files'}
                          files={this.state.estandar_2_5_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 3 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar3}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_3')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar3 + '/' + this.state.totalEstandar3}
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  <React.Fragment>
                    <QuestionTitle
                      title={`3.1 ${this.getEstandarText('estandar_3_1')}`}
                      approved={this.state.estandar_3_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_3_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_3_1']}
                      value={this.state.estandar_3_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_3_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography
                          style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                          className="no-print"
                        >
                          {this.getEstandarText('estandar_3_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_1_files_directus_files'}
                          files={this.state.estandar_3_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`3.2 ${this.getEstandarText('estandar_3_2')}`}
                      approved={this.state.estandar_3_2_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_2"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_3_2']}
                      value={this.state.estandar_3_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_3_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_2_files_directus_files'}
                          files={this.state.estandar_3_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`3.3 ${this.getEstandarText('estandar_3_3')}`}
                      approved={this.state.estandar_3_3_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_3"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_3_3']}
                      value={this.state.estandar_3_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_3_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_3_files_directus_files'}
                          files={this.state.estandar_3_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`3.4 ${this.getEstandarText('estandar_3_4')}`}
                      approved={this.state.estandar_3_4_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_4"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_4_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_3_4']}
                      value={this.state.estandar_3_4_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_3_4_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_4_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_4_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_4_files_directus_files'}
                          files={this.state.estandar_3_4_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`3.5 ${this.getEstandarText('estandar_3_5')}`}
                      approved={this.state.estandar_3_5_approved}
                    />
                    <QuestionSelect
                      id="estandar_3_5"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_3_5_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_3_5']}
                      value={this.state.estandar_3_5_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_3_5_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_3_5_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_3_5_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_3_5_files_directus_files'}
                          files={this.state.estandar_3_5_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* PREGUNTA 4 */}
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                severity="error"
                className={'accordion ' + this.state.severityEstandar4}
              >
                <Typography className="accordion title text">
                  {this.getEstandarText('estandar_4')}
                </Typography>
                <Typography className="ml-auto accordion title">
                  {this.state.valueEstandar4 + '/' + this.state.totalEstandar4}
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="accordion header-content-separator">
                <Grid item xs={12} className="mb1 mt-25">
                  <React.Fragment>
                    <QuestionTitle
                      title={`4.1 ${this.getEstandarText('estandar_4_1')}`}
                      approved={this.state.estandar_4_1_approved}
                    />

                    <QuestionSelect
                      id="estandar_4_1"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_1_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_4_1']}
                      value={this.state.estandar_4_1_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />

                    {this.state.estandar_4_1_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography
                          style={{ marginBottom: 5 + 'px', fontSize: 14 + 'px' }}
                          className="no-print"
                        >
                          {this.getEstandarText('estandar_4_1_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_1_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_1_files_directus_files'}
                          files={this.state.estandar_4_1_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`4.2 ${this.getEstandarText('estandar_4_2')}`}
                      approved={this.state.estandar_4_2_approved}
                    />
                    <QuestionSelect
                      id="estandar_4_2"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_2_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_4_2']}
                      value={this.state.estandar_4_2_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_4_2_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_4_2_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_2_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_2_files_directus_files'}
                          files={this.state.estandar_4_2_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>

                  <ContentSeparator />

                  <React.Fragment>
                    <QuestionTitle
                      title={`4.3 ${this.getEstandarText('estandar_4_3')}`}
                      approved={this.state.estandar_4_3_approved}
                    />
                    <QuestionSelect
                      id="estandar_4_3"
                      label="Seleccionar..."
                      showWarning={this.state.estandar_4_3_warning}
                      warningMessage={DICCIONARIO_WARNINGS_YERBA_MATE['estandar_4_3']}
                      value={this.state.estandar_4_3_value}
                      options={[
                        { text: 'Si', value: DICCIONARIO_SELECTS['Si'] },
                        { text: 'No', value: DICCIONARIO_SELECTS['No'] },
                      ]}
                      onChange={(id, value) => {
                        this.updateField(id, value);
                      }}
                    />
                    {this.state.estandar_4_3_value === '1' ? (
                      <Grid item xs={12} className="mb2">
                        <Typography style={{ marginBottom: 5 + 'px' }}>
                          {this.getEstandarText('estandar_4_3_files')}
                        </Typography>
                        <FilesManager
                          id="estandar_4_3_files"
                          reportId={this.state.reportId}
                          unionDataBase={'estandar_4_3_files_directus_files'}
                          files={this.state.estandar_4_3_files}
                          updateParent={(_field, _value) => {
                            this.setState({ [_field]: _value }, () => {
                              this.checkValidity();
                            });
                          }}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withRouter(ValidacionFormulario);
