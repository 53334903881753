import { API_URI } from '../config';

export const examplePOST = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/';

    let body = {};

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        resolve();
      });
  });
};

export const exampleGET = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        resolve();
      });
  });
};

////////////////////////////////////////////////////////

export const testRequest = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('resolve de la req');
    }, 2000);
  });
};

export const testLogOut = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/auth/logout';

    let body = {};

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Response authenticate:');
        console.log(json);
        resolve();
      });
  });
};

export const getUserData = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/users/me';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        resolve(json);
      });
  });
};

export const getTestDirectusItems = () => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/test_directus?fields=*.*';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        resolve();
      });
  });
};

export const createTestDirectusItem = (_data) => {
  return new Promise((resolve, reject) => {
    let url = API_URI + '/items/test_directus';

    let body = {
      nombre: 'Cargado desde el navegador',
      test_relationships: [
        {
          name_ref: 55,
        },
        {
          name_ref: 2,
        },
      ],
    };

    let options = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log('Response createTestDirectusItem:');
        console.log(json);
        resolve();
      });
  });
};
