export const DEV_SERVER = 1;

// Version de la app -> se muestra en el menu
export const APP_VERSION = 'v1.3.14';

///////////////////////////////////////////////////////
// IMPORTANTE:                                       //
//     El cambio en el package json no se puede      //
//     hacer con una variable. Hay que setearlo      //
//     manualmente antes del build.                  //
//                                                   //
//     Tambien hay un cambio de BASE URL que se      //
//     debe hacer en el metodo onupdate del  sw      //
//     en index.js                                   //
///////////////////////////////////////////////////////

// Para /app (produccion)  (monitoreo-ta.c-bucket.site)
export const URLpreffix = '/app';
// Package.json -> "homepage": "https://webapp-scs.dya.org.ar/app"

// Para /demo  (monitoreo-ta.c-bucket.site)
//export const URLpreffix = '/demo';
// Package.json -> "homepage": "https://webapp-scs.dya.org.ar/demo"

// Para TimpleStage
//export const URLpreffix = '/demo/misc/MonitoreoTA';

export const BASE_URI = 'https://webapp-scs.dya.org.ar';
export const API_URI = BASE_URI + '/directus/public/monitoreota';

export const CONTACT_PHONE_NUMBER = '+54 11 32807111';
export const WSAP_CHAT_URL = `https://api.whatsapp.com/send?phone=${CONTACT_PHONE_NUMBER.replace(
  /\+| /g,
  '',
)}`;

/////////////////////////////////////////////////////////
// Definicion de prescripciones para cada checkbox del //
// control de adolescentes                             //
export const PRESCRIPCIONES = {
  '001': {
    text: 'El trabajador adolescente…. ¿utiliza la identificación otorgada por la empresa (tarjeta, pechera, gorra, barbijo)',
    response: 'El/la trabajador/a adolescente debe utilizar identificación',
  },
  '002': {
    text: 'El trabajo que realiza, ¿requiere la utilización de elementos de protección personal como mascarillas, trajes, guantes, etc.?',
    response: '',
  },
  '003': {
    text: '¿Los utiliza?',
    response: 'El/la trabajador/a adolescente debe utilizar elementos de protección',
  },
  '004': {
    text: 'En la tarea/trabajo que realiza el/la adolescente trabajador/a ¿utiliza herramientas peligrosas como machete, guadaña o cuchillo?',
    response: 'El/la trabajador/a adolescente no debe utilizar herramientas peligrosas',
  },
  '005': {
    text: '¿Se encuentra utilizando maquinaria como empacadoras, montacargas, clasificadoras?',
    response: 'El/la trabajador/a adolescente no debe utilizar maquinaria',
  },
  '006': {
    text: '¿El trabajo o tarea que realiza lo hace expuesto al sol y a altas temperaturas?',
    response: 'El/la trabajador/a adolescente no debe estar expuesto al sol y a altas temperaturas',
  },
  '007': {
    text: '¿El trabajo o tarea que realiza lo hace expuesto a bajas temperaturas?',
    response: 'El/la trabajador/a adolescente no debe estar expuesto a bajas temperaturas',
  },
  '008': {
    text: '¿Se observa agua bebible para que puedan beber los/as trabajadores?',
    response: 'El/la trabajador/a adolescente debe tener acceso a agua potable en todo momento',
  },
  '009': {
    text: '¿El trabajo/tarea que realiza el/la adolescente trabajador/a lo hace agachado o en posición de cuclillas?',
    response: 'El/la trabajador/a adolescente no debe realizar tareas agachado o en cuclillas',
  },
  '010': {
    text: '¿El trabajo/tarea que realiza el/la adolescente trabajador/a requiere el uso de fuerza, como por ejemplo cargar 5 bandejas “Wenco” (que equivale a más 8 kilos)?',
    response: 'El/la trabajador/a adolescente no debe levantar pesos mayores a 5kg',
  },
  '011': {
    text: '¿El/la adolescente trabajador/a desarrolla su trabajo con los brazos a una altura superior a los hombros?',
    response:
      'El/la trabajador/a adolescente no debe realizar tareas con los brazos a una altura superior a los hombros',
  },
  '012': {
    text: '¿El/la adolescente trabajador/a desarrolla su trabajo manipulando productos agroquímicos?',
    response: 'El/la trabajador/a adolescente no debe manipular agroquímicos',
  },
  '013': {
    text: '¿Alguna persona de la empresa te vino a ver durante la jornada de trabajo?',
    response:
      'El/la trabajador/a adolescente debe ser controlado periódicamente por personal de la empresa',
  },

  '014': {
    text: 'Durante la semana de referencia, ¿Los/as adolescentes tuvieron acceso siempre y en todo momento a agua potable?',
    response:
      'Los/as trabajadores/as adolescentes deben tener acceso a agua potable siempre y en todo momento',
  },
  '015': {
    text: '¿Utilizó elementos de protección?',
    response: 'Los/as trabajadores/as adolescentes deben utilizar elementos de protección',
  },
  '016': {
    text: '¿Vino alguna persona de la empresa en la semana a supervisar el trabajo del/la adolescente durante la jornada de trabajo?',
    response:
      'Los/as trabajadores/as adolescentes deben ser controlados por personal de la empresa periódicamente',
  },
  '017': {
    text: '¿Utilizó alguna herramienta/maquinaria en su trabajo?',
    response:
      'Los/as trabajadores/as adolescentes no deben utilizar herramientas peligrosas o maquinaria',
  },
};

/////////////////////////////////////////////////////////
// Pares Key - Value para los selects del formulario   //
// SCS                                                 //
export const DICCIONARIO_SELECTS = {
  'Si lo tiene': '1',
  'Está en proceso': '2',
  'No tiene': '3',

  Si: '1',
  No: '2',

  'Si con todos': '1',
  'Si con algunos': '2',
  'No realiza contratos': '3',

  'No, utiliza otros registros': '2',

  'Sí, todos los días': '1',
  'Sí, algunos días': '2',
  'No controla nunca': '3',

  'Si, de todos los trabajadores': '1',
  'Solo de algunos trabajadores y los otros verifica con DNI': '2',
  'No cuenta con registro de sus trabajadores': '3',

  'No corresponde responder': '5',
};

/////////////////////////////////////////////////////////
// Declaracion de cuales campos del formulario SCS     //
// muestran info y datos de cada uno de ellos que son  //
// importantes para mostrar dicha data                 //
export const FIELDS_WITH_INFO = {
  estandar_3_1_1: {
    trueValues: ['1'],
    collection: 'adolescentes',
  },

  estandar_3_4_1: {
    trueValues: ['1'],
    collection: ['control_documentacion'],
  },

  estandar_3_6_1: {
    fields: 'id',
    trueValues: ['1'],
    collection: ['control_lotes'],
  },

  estandar_3_7_1: {
    fields: 'id',
    trueValues: ['1'],
    collection: ['logs_usuarios_externos'],
  },

  estandar_3_1_6: {
    trueValues: ['1'],
    collection: ['ingresos_egresos'],
  },

  estandar_3_8_1: {
    fields: '*.*',
    trueValues: ['1'],
    collection: ['control_entrega_fruta'],
  },

  estandar_3_9_1: {
    fields: 'id',
    trueValues: ['1'],
    collection: ['control_lotes'],
  },

  estandar_4_4_1: {
    fields: '*.*',
    trueValues: ['1'],
    collection: ['incidencias_v2'],
  },

  estandar_4_5_1: {
    fields: 'id',
    trueValues: ['1'],
    collection: ['control_lotes'],
  },
};

/////////////////////////////////////////////////////////
// Texto de advertencia para cada campo del formulario //
// SCS que corresponda                                 //
export const DICCIONARIO_WARNINGS = {
  estandar_1_1:
    'Para cumplimentar el estándar la empresa debe tener un documento donde se manifieste formalmente la política de la empresa en el cumplimiento de las normas vigentes sobre TIyA.',

  estandar_1_2:
    'Para cumplimentar el estándar la empresa debe contar con personal encargado de la implementación de los lineamientos para el cumplimiento del SCS.',

  estandar_2_1: 'Para cumplimentar el estándar la empresa debe realizar capacitaciones sobre TIyA.',

  // No aplica - estandar_2_2: '',

  estandar_2_3:
    'Para cumplimentar el estándar la empresa debe incluir en los contratos con contratistas una cláusula que haga referencia a la prohibición del trabajo infantil y sus medidas preventivas.',

  estandar_2_4:
    'Para cumplimentar el estándar la empresa debe realizar acciones de difusión y sensibilización en prevención de trabajo infantil.',

  estandar_2_5:
    'Para cumplimentar el estándar la empresa debe tener un acuerdo firmado con ABC, APRATUC, APAMA, CAPAB o alguna institución educativa y/u organismo estatal para proveer servicios de educación en la comunidad.',

  estandar_2_6:
    'Para cumplimentar el estándar la empresa debe participar de la implementación de acciones hacia la comunidad.',

  estandar_3_1:
    'Para cumplimentar el estándar la empresa debe controlar la edad de los trabajadores/as que ingresan al campo con el DNI.',

  estandar_3_2:
    'Para cumplimentar el estándar la empresa debe contar con lista de los trabajadores que son transportados por el/los contratista/s.',

  estandar_3_3:
    'Para cumplimentar el estándar la empresa debe contar con la documentación de TODOS los colectivos para el transporte de los trabajadores (carnet de manejo, VTV, seguros, permisos, etc).',

  estandar_3_4:
    'Para cumplimentar el estándar la empresa debe verificar diariamente la edad de los trabajadores que ingresan al transporte.',

  estandar_3_5:
    'Para cumplimentar el estándar la empresa debe contar con personal capacitado para el control de documentación a la entrada de la finca/campo.',

  estandar_3_6:
    'Para cumplimentar el estándar la empresa debe realizar monitoreos en el punto de entrega/peso de fruta todos los días de cosecha.',

  estandar_3_7:
    'Para cumplimentar el estándar la empresa debe tener personal externo que utiliza sistemas de monitoreos para control de situaciones de trabajo infantil.',

  estandar_3_8:
    'Para cumplimentar el estándar la empresa debe tener personal externo que realiza monitoreos en el punto de entrega/peso de fruta.',

  estandar_3_9:
    'Para cumplimentar el estándar la empresa debe realizar monitoreos aleatorios dentro del campo.',

  estandar_4_1:
    'Para cumplimentar el estándar la empresa debe contar con un procedimiento escrito de los pasos a dar en caso de que un niño/a menor de 18 años se presente a buscar trabajo.',

  estandar_4_2:
    'Para cumplimentar el estándar la empresa debe contar con un procedimiento escrito de los pasos a dar en caso que un trabajador adolescente que realiza labores administrarativas no cuente con la documentación para trabajar.',

  estandar_4_3:
    'Para cumplimentar el estándar la empresa debe contar con un directorio disponible de teléfonos con información de contacto necesaria para la acción urgente ante casos de TI.',

  estandar_4_4:
    'Para cumplimentar el estándar la empresa debe tener un sistema de registro de situaciones de riesgo TI.',

  estandar_4_5:
    'Para cumplimentar el estándar la empresa debe contar con un sistema de monitoreo y seguimiento para la prevención del TI y el seguimiento del TA.',
};

export const DICCIONARIO_WARNINGS_YERBA_MATE = {
  estandar_1_1:
    'Para cumplimentar el estándar la empresa debe tener un documento donde se manifieste formalmente la política de la empresa en el cumplimiento de las normas vigentes sobre TIyA.',

  estandar_1_2:
    'Para cumplimentar el estándar la empresa debe contar con personal encargado de la implementación de los lineamientos para el cumplimiento del SCS.',

  estandar_2_1: 'Para cumplimentar el estándar la empresa debe realizar capacitaciones sobre TIyA.',

  estandar_2_2:
    'Para cumplimentar el estándar la empresa debe instalar carteleria, distribuir folletos y demás material de sensibilización.',

  estandar_2_3:
    'Para cumplimentar el estándar la empresa debe demostrar mecanismos para que los contratistas y proveedores cumplan la legislación sobre trabajo infantil y adolescente.',

  estandar_2_4:
    'Para cumplimentar el estándar la empresa debe realizar acciones de promoción de servicios comunitarios educativos.',

  estandar_2_5:
    'Para cumplimentar el estándar la empresa debe realizar acciones de sensibilización.',

  estandar_3_1:
    'Para cumplimentar el estándar la empresa debe controlar la edad de los trabajadores/as que ingresan al secadero y el molino.',

  estandar_3_2:
    'Para cumplimentar el estándar la empresa debe realizar acciones de sensibilización.',

  estandar_3_3:
    'Para cumplimentar el estándar la empresa debe contar con la documentación de TODOS los colectivos para el transporte de los trabajadores (carnet de manejo, VTV, seguros, permisos, etc).',

  estandar_3_4:
    'Para cumplimentar el estándar la empresa debe realizar monitoreos aleatorios para verificar la edad de los trabajadores.',

  estandar_3_5:
    'Para cumplimentar el estándar la empresa debe con información de los niños que viven en secaderos y molinos.',

  estandar_4_1:
    'Para cumplimentar el estándar la empresa debe contar con un procedimiento escrito de los pasos a dar en caso de que un niño/a menor de 18 años se presente a buscar trabajo.',

  estandar_4_2:
    'Para cumplimentar el estándar la empresa debe contar con un directorio disponible de teléfonos con información de contacto necesaria para la acción urgente ante casos de TI.',

  estandar_4_3:
    'Para cumplimentar el estándar la empresa debe contar con un sistema de información sobre situaciones de riesgo de trabajo infantil.',
};
