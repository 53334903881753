import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigationC from '../../Navigation/MyBottomNavigationC';
import Loader from '../../Misc/Loader';

import { addRecord, checkIfTAExistsLocal, updateDataBases } from '../../../indexedDB/operations';
import { getCurrentDateTime, isOnlineV2 } from '../../../helpers/helpers';
import { URLpreffix } from '../../../config';

class ControlDocumentacionP3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      show: false,
      taHablitado: false,
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);

    let dni = queryParams.dni ? parseInt(queryParams.dni) : 0;

    checkIfTAExistsLocal(dni).then((exists) => {
      let habilitado = exists;

      let control = {
        id: 'tmp-ControlDocumentacion-' + Date.now(),
        dni_adolescente: dni,
        dni_usuario_externo: parseInt(localStorage.getItem('usuario_dni')),
        nombre_usuario_externo: localStorage.getItem('usuario_nombre'),
        apellido_usuario_externo: localStorage.getItem('usuario_apellido'),
        empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
        contratista_id: parseInt(localStorage.getItem('usuario_contratista_id')),
        fecha_horario: getCurrentDateTime(),
        adolescente_habilitado: habilitado,
      };

      addRecord('control_documentacion', control).then(() => {
        let registerSyncEvent = true;
        updateDataBases(registerSyncEvent).then(() => {
          isOnlineV2().then((isOnline) => {
            if (isOnline) {
              this.setState({ show: true, taHablitado: habilitado, showLoader: false });
            } else {
              this.setState({ show: true, taHablitado: habilitado, showLoader: false });
            }
          });
        });
      });
    });
  }

  render() {
    if (this.state.show === false)
      return (
        <main>
          <Loader show={this.state.showLoader} />
          <NavBar />
          <MyBottomNavigationC />
        </main>
      );

    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar disableBackButton />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            {this.state.taHablitado === false ? (
              <Grid item xs={12} className="mb1">
                <Typography variant="h6" align="center" className="mb1">
                  El trabajador no está dado de alta
                </Typography>

                <Alert severity="error">
                  <AlertTitle>Todavía no puede ir al campo</AlertTitle>
                  La empresa todavía no ha dado de alta a este trabajador.
                  <br />
                  El contratatista o capataz deberá presentar la siguiente información en la empresa
                  antes del que el trabajador adolescente pueda ir al campo: <br />
                  <br />
                  Certificado de escolaridad: ✔<br />
                  Apto médico: ✔<br />
                  Autorización de ambos padres: ✔<br />
                  Visado de autoridad laboral local: ✔<br />
                </Alert>
              </Grid>
            ) : null}

            {this.state.taHablitado === true ? (
              <Grid item xs={12} className="mb1">
                <Typography variant="h6" align="center" className="mb1">
                  El trabajador está dado de alta
                </Typography>

                <Alert severity="success">
                  <AlertTitle>Se encuentra habilitado para ir al campo.</AlertTitle>
                </Alert>
              </Grid>
            ) : null}

            <Grid item xs={12} className="text-center">
              <Button
                onClick={() => {
                  this.props.history.push(URLpreffix + '/ControlDocumentacionP1');
                }}
                variant="contained"
                color="secondary"
                size="large"
              >
                Controlar otro DNI
              </Button>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={() => {
                  this.props.history.push(URLpreffix + '/homeCapataz');
                }}
                variant="contained"
                color="primary"
                size="large"
              >
                Volver a pantalla de inicio
              </Button>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigationC />
      </main>
    );
  }
}

export default withRouter(ControlDocumentacionP3);
