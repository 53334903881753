import React from 'react';

import qs from 'query-string';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import { URLpreffix } from '../../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  margin: {
    margin: '20px',
  },
  withoutLabel: {
    marginTop: '60px',
  },
  textField: {
    width: '25ch',
  },
  mainContainer: {},
  mb1: {
    marginBottom: '20px',
  },
  mb2: {
    marginBottom: '40px',
  },
  mr1: {
    marginRight: '20px',
  },
  p0: {
    padding: '0px',
  },
});

export default function AltaTrabajadorP4(props) {
  const classes = useStyles();

  let history = useHistory();

  let queryParams = qs.parse(props.location.search);
  let nombre = queryParams.n ? queryParams.n : null;
  let apellido = queryParams.a ? queryParams.a : null;

  return (
    <main>
      <NavBar disableBackButton />

      <Container maxWidth="sm" className={classes.mainContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.mb1}>
            <Typography variant="h6" className={classes.mb1}>
              Se ha dado de alta a{' '}
              <b>
                {nombre} {apellido}
              </b>{' '}
              en el sistema de monitoreo.
            </Typography>

            <Alert severity="success">
              <AlertTitle>Alta registrado con éxito.</AlertTitle>
            </Alert>
          </Grid>

          <Grid item xs={12} className="mb1 text-center">
            <Button
              onClick={() => {
                history.push(URLpreffix + '/homeEmpleados');
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              Volver a pantalla de inicio
            </Button>
          </Grid>
        </Grid>
      </Container>

      <MyBottomNavigation></MyBottomNavigation>
    </main>
  );
}
