import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { isStandAlone, isOnlineV2 } from '../../helpers/helpers';
import { updateToLastRemoteVersion, sendDataToServer } from '../../indexedDB/operations';

class Welcome2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      load: false,
      showlLoader: true,
    };
  }

  componentDidMount() {
    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        sendDataToServer()
          .then((transactionOK) => {
            if (transactionOK) {
              // El envio al server no tuvo errores; actualizo a la version del server
              updateToLastRemoteVersion().then(() => {
                this.setState({ load: true, showLoader: false });
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.setState({ load: true, showLoader: false });
      }
    });
  }

  render() {
    if (this.state.load === false) {
      return (
        <main>
          <Loader show={true} />
          {isStandAlone() === true ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
          ) === false ? (
            <NavBar disableBackButton />
          ) : (
            <NavBar />
          )}
        </main>
      );
    }

    return (
      <main>
        <Loader show={this.state.showLoader} />
        {isStandAlone() === true ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) === false ? (
          <NavBar disableBackButton />
        ) : (
          <NavBar />
        )}

        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h6" className="mb1">
                ¿Eres personal de la empresa o usuario externo?
              </Typography>

              <Typography variant="body1">
                Según su rol en la producción, la webapp le presentará diferentes opciones y
                funcionalidades.
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <Button
                href={URLpreffix + '/loginEmpleados'}
                variant="contained"
                className="ml-7"
                color="primary"
                size=""
                style={{ marginBottom: '15px' }}
              >
                Soy de la empresa
              </Button>

              <Button
                href={URLpreffix + '/loginCapataz'}
                variant="contained"
                className="ml-7"
                color="secondary"
                size=""
                style={{ marginBottom: '15px' }}
              >
                Soy usuario externo
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>
    );
  }
}

export default withRouter(Welcome2);
