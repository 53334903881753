import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Alert } from '@material-ui/lab';

export default function QuestionSelect(props) {
  return (
    <Grid item xs={12} className="mb1">
      <FormControl fullWidth variant="outlined" className="">
        <InputLabel error={false} htmlFor="outlined-age-native-simple">
          {props.label}
        </InputLabel>
        <Select
          native
          disabled={props.disabled ? true : false}
          label={props.label}
          error={false}
          inputProps={{
            name: props.label,
            id: 'outlined-age-native-simple',
          }}
          value={props.value ? props.value : ''}
          onChange={(e) => {
            props.onChange(props.id, e.target.value === '' ? null : e.target.value);
          }}
        >
          <option aria-label="None" value="" />

          {props.options.map((option) => (
            <option key={option.value} aria-label="None" value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
      {props.showWarning && props.warningMessage ? (
        <Grid item xs={12} className="mb1 mt1">
          <Alert severity="warning">{props.warningMessage}</Alert>
        </Grid>
      ) : null}
    </Grid>
  );
}
