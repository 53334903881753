import React from 'react';

import ReactDropzone from 'react-dropzone';

import Loader from '../../Misc/Loader';

import { sendNewItem, sendFileV2, deleteFile } from '../../../requests/data';

class FilesManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      files: [],
    };
  }

  componentDidMount() {
    if (this.props.files) {
      let arr = [];
      for (let j = 0; j < this.props.files.length; j++) {
        let unionTableId = this.props.files[j].id || null;
        let fileId = this.props.files[j].directus_files_id
          ? this.props.files[j].directus_files_id.id
          : null;
        let fileType = this.props.files[j].directus_files_id
          ? this.props.files[j].directus_files_id.type
          : null;
        let fileUrl = this.props.files[j].directus_files_id
          ? this.props.files[j].directus_files_id.data.full_url
          : null;
        let fileName = this.props.files[j].directus_files_id
          ? this.props.files[j].directus_files_id.filename_download
          : null;

        // Para compatibilidad con el objeto que devuelvo al parent; sin
        // esto no puedo cargar files que haya devuelto al parent
        if (!unionTableId && !fileId && !fileType && !fileUrl && !fileName) {
          unionTableId = this.props.files[j].unionTableId;
          fileId = this.props.files[j].fileId;
          fileType = this.props.files[j].fileType;
          fileUrl = this.props.files[j].fileUrl;
          fileName = this.props.files[j].fileName;
        }

        let obj = {
          unionTableId: unionTableId,
          fileId: fileId,
          fileType: fileType,
          fileUrl: fileUrl,
          preview: fileUrl,
          fileName: fileName,
        };

        arr.push(obj);
      }

      this.setState({ files: arr, showLoader: false });
    } else {
      this.setState({ showLoader: false });
    }
  }

  deleteThisFile = (fileId, unionId) => {
    // Lo saco del state
    let files = this.state.files;
    let filesBackUp = files;
    for (let i = 0; i < files.length; i++) {
      if (files[i]['fileId'] === fileId) {
        files.splice(i, 1);
        break;
      }
    }

    this.setState({ files: files }, () => {
      this.props.updateParent(this.props.id, this.state.files);
    });

    deleteFile(fileId, unionId, this.props.unionDataBase)
      .then((json) => {})
      .catch((err) => {
        // Hubo un error al eliminar el archivo; restauro
        // el state...
        this.setState({ files: filesBackUp }, () => {
          this.props.updateParent(this.props.id, this.state.files);
        });
      });
  };

  onPreviewDrop = (files) => {
    this.setState({ showLoader: true });

    // Object para el state
    // fileId: 45
    // fileType: "image/jpeg"
    // fileUrl: "https://monitoreo-ta.c-bucket.site/directus/public/uploads/monitoreota/originals/cf485c4b-2493-42cb-b7b7-d7a0c3c0d3fb.jpeg"
    // preview: "https://monitoreo-ta.c-bucket.site/directus/public/uploads/monitoreota/originals/cf485c4b-2493-42cb-b7b7-d7a0c3c0d3fb.jpeg"
    // unionTableId: 24

    let arrProm = [];

    for (let i = 0; i < files.length; i++) {
      arrProm.push(
        sendFileV2(files[i], files[i]['name']).then((file_id) => {
          sendNewItem(this.props.unionDataBase, {
            validaciones_v2_id: this.props.reportId,
            directus_files_id: file_id,
          }).then((res) => {
            let union_table_id = res.remote_id;

            let f = files[i];
            var blob = new Blob([f], {
              type: f.type || 'application/*',
            });

            f = window.URL.createObjectURL(blob);

            let obj = {
              fileId: file_id,
              unionTableId: union_table_id,
              fileType: files[i].type || 'application/*',
              fileUrl: f,
              preview: f,
              fileName: files[i]['name'],
            };

            this.setState({ files: [...this.state.files, obj] }, () => {
              this.props.updateParent(this.props.id, this.state.files);
            });
          });
        }),
      );
    }

    Promise.all(arrProm).then(() => {
      this.setState({ showLoader: false });
    });
  };

  render() {
    const previewStyle = {
      marginTop: 2 + 'px',
      display: 'inline-flex',
      width: 15 + 'px',
      height: 'auto',
      marginRight: 7 + 'px',
    };

    const previewContainerStyle = {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    const closeStyle = {
      backgroundColor: 'white',
      cursor: 'pointer',
      height: 22 + 'px',
      marginRight: 8 + 'px',
      marginTop: 3 + 'px',
      fontWeight: 700,
    };

    const dropZoneStyle = {
      borderStyle: 'dashed',
      borderRadius: 5 + 'px',
      textAlign: 'center',
      borderColor: '#ccc',
    };

    return (
      <React.Fragment>
        <div
          style={{
            borderStyle: 'solid',
            padding: 25 + 'px',
            borderWidth: 1 + 'px',
            borderRadius: 5 + 'px',
            borderColor: '#ccc',
            position: 'relative',
          }}
        >
          <Loader show={this.state.showLoader} forFileManager={true} />
          <ReactDropzone onDrop={this.onPreviewDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className="no-print-rf">
                <div {...getRootProps()} style={dropZoneStyle}>
                  <input {...getInputProps()} />
                  <p>Haga click para añadir archivos o arrástrelos</p>
                </div>
              </section>
            )}
          </ReactDropzone>
          <p style={{ fontSize: 14 + 'px', fontWeight: 700 }} className="only-print mt-minus10">
            Adjuntados
          </p>

          {this.state.files.map((file) => {
            if (file.fileName) {
              return (
                <div>
                  <div style={previewContainerStyle}>
                    <span
                      style={closeStyle}
                      onClick={() => {
                        this.deleteThisFile(file.fileId, file.unionTableId);
                      }}
                    >
                      X
                    </span>
                    <img
                      alt="Preview"
                      key={file.preview}
                      src={'./img/google-drive-file.png'}
                      style={previewStyle}
                    />
                    <a href={file.fileUrl} download style={{ color: '#000000' }}>
                      {file.fileName.length > 53
                        ? file.fileName.substring(0, 50) + '...'
                        : file.fileName}
                    </a>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default FilesManager;
