import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import CardAvatar from '../Misc/CardAvatar';

import { getEventById } from '../../indexedDB/operations';
import { getPrettyDateTimeFromString, getIDInCorrectType } from '../../helpers/helpers';

class ListadoTrabajadoresP3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showData: false,
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);

    let collection = null;
    let id = queryParams.id;

    if (queryParams.tipo === 'Ingreso' || queryParams.tipo === 'Egreso') {
      collection = 'ingresos_egresos';
    } else {
      collection = 'control_ta';
    }

    getEventById(collection, { id: getIDInCorrectType(id) }).then((i) => {
      if (i === null) return;

      let fechaHorario = getPrettyDateTimeFromString(i.fecha_horario);

      this.setState({
        showData: true,
        tipo: queryParams.tipo,
        fechaHorario: fechaHorario,
        nombre: i.adolescente.nombre,
        apellido: i.adolescente.apellido,
        dni: i.adolescente.dni,
        capataz_a_cargo: i.capataz_a_cargo ? i.capataz_a_cargo : null,
        lugar: i.lugar ? i.lugar : null,

        utiliza_identificacion: i.utiliza_identificacion ? i.utiliza_identificacion : null,
        lugar_de_trabajo: i.lugar_de_trabajo ? i.lugar_de_trabajo : null,
        requiere_proteccion: i.requiere_proteccion ? i.requiere_proteccion : null,
        utiliza_proteccion: i.utiliza_proteccion ? i.utiliza_proteccion : null,
        hay_agua: i.hay_agua ? i.hay_agua : null,
        expuesto_alta_temp: i.expuesto_alta_temp ? i.expuesto_alta_temp : null,
        expuesto_baja_temp: i.expuesto_baja_temp ? i.expuesto_baja_temp : null,
        esta_agachado: i.esta_agachado ? i.esta_agachado : null,
        levanta_mas_5k: i.levanta_mas_5k ? i.levanta_mas_5k : null,
        utiliza_agroquimicos: i.utiliza_agroquimicos ? i.utiliza_agroquimicos : null,
        utiliza_herramientas_peligrosas: i.utiliza_herramientas_peligrosas
          ? i.utiliza_herramientas_peligrosas
          : null,
        utiliza_maquinaria: i.utiliza_maquinaria ? i.utiliza_maquinaria : null,
        fue_alguien: i.fue_alguien ? i.fue_alguien : null,
        observaciones: i.observaciones ? i.observaciones : null,
      });
    });
  }

  render() {
    if (this.state.showData === false)
      return (
        <main>
          <NavBar />
          <MyBottomNavigation />
        </main>
      );

    let datos = null;
    if (this.state.tipo === 'Monitoreo') {
      datos = (
        <React.Fragment>
          <strong>DATOS DEL {this.state.tipo.toUpperCase()}</strong>
          <br />
          <br />
          <strong>Fecha y Horario:</strong> <Chip size="small" label={this.state.fechaHorario} />{' '}
          <br />
          <strong>¿Está en lugar de trabajo?</strong> {this.state.lugar_de_trabajo ? 'Si' : 'No'}{' '}
          <br />
          {this.state.lugar_de_trabajo === true ? (
            <React.Fragment>
              <strong>¿Utiliza identificación?</strong>{' '}
              {this.state.utiliza_identificacion ? 'Si' : 'No'} <br />
              <strong>¿Requiere protección?</strong> {this.state.requiere_proteccion ? 'Si' : 'No'}{' '}
              <br />
              {this.state.requiere_proteccion === true ? (
                <React.Fragment>
                  <strong>¿La utiliza?</strong> {this.state.utiliza_proteccion ? 'Si' : 'No'} <br />
                </React.Fragment>
              ) : null}
              <strong>¿Hay agua disponible?</strong> {this.state.hay_agua ? 'Si' : 'No'} <br />
              <strong>¿Está expuesto/a a altas temp?</strong>{' '}
              {this.state.expuesto_alta_temp ? 'Si' : 'No'} <br />
              <strong>¿Está expuesto/a a bajas temp?</strong>{' '}
              {this.state.expuesto_baja_temp ? 'Si' : 'No'} <br />
              <strong>¿Realiza el trabajo agachado/a?</strong>{' '}
              {this.state.esta_agachado ? 'Si' : 'No'} <br />
              <strong>¿Levanta pesos mayores a 5kg?</strong>{' '}
              {this.state.levanta_mas_5k ? 'Si' : 'No'} <br />
              <strong>¿Utiliza agroquímicos?</strong>{' '}
              {this.state.utiliza_agroquimicos ? 'Si' : 'No'} <br />
              <strong>¿Utiliza herramientas peligrosas?</strong>{' '}
              {this.state.utiliza_herramientas_peligrosas ? 'Si' : 'No'} <br />
              <strong>¿Utiliza maquinaria?</strong> {this.state.utiliza_maquinaria ? 'Si' : 'No'}{' '}
              <br />
              <strong>¿Fue visitado/a por alguien de la empresa?</strong>{' '}
              {this.state.fue_alguien ? 'Si' : 'No'} <br />
              {this.state.observaciones !== null ? (
                <React.Fragment>
                  <strong>Observaciones:</strong> {this.state.observaciones} <br />
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    } else {
      datos = (
        <React.Fragment>
          <strong>DATOS DEL {this.state.tipo.toUpperCase()}</strong>
          <br />
          <br />
          <strong>Fecha y Horario:</strong> <Chip size="small" label={this.state.fechaHorario} />{' '}
          <br />
          <strong>Lugar de trabajo:</strong> {this.state.lugar} <br />
          <strong>Capataz:</strong> {this.state.capataz_a_cargo}
          <br />
        </React.Fragment>
      );
    }

    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="">
              <Typography variant="h5" className="mb1">
                Detalles del registro de {this.state.tipo.toLowerCase()}.
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <Card className="mb1">
                <CardHeader
                  avatar={<CardAvatar nombre={this.state.nombre} />}
                  title={this.state.nombre + ' ' + this.state.apellido}
                  subheader={'DNI ' + this.state.dni}
                />
                <CardContent>
                  <Typography variant="body2" component="p">
                    {datos}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ListadoTrabajadoresP3);
