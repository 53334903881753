import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import Loader from '../Misc/Loader';

import { getCurrentDateTime } from '../../helpers/helpers';
import { getContratistas, addRecord } from '../../indexedDB/operations';
import { setUserDataLS } from '../../requests/users';
import { URLpreffix } from '../../config';

class LoginCapataz extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      showLoginMessage: false,

      optionsContratistas: null,

      nombre: '',
      apellido: '',
      dni: '',
      contratista_id: null,
      empresa_id: null,

      nombreError: false,
      apellidoError: false,
      dniError: false,
      contratista_idError: false,
    };
  }

  componentDidMount() {
    getContratistas().then((contratistas) => {
      contratistas.sort(function (a, b) {
        if (a.empresa.nombre < b.empresa.nombre) {
          return -1;
        }
        if (a.empresa.nombre > b.empresa.nombre) {
          return 1;
        }
        return 0;
      });

      let options = contratistas.map((c) => (
        <option value={c.id + '/' + c.empresa_asociada_id}>
          {c.empresa.nombre} - {c.nombre}
        </option>
      ));

      this.setState({ optionsContratistas: options, showLoader: false });
    });
  }

  handleChange = (prop) => (event) => {
    // Select de contratista...
    if (prop === 'contratista_id') {
      // El value de las options viene en el formato contratistaID/empresaID para
      // poder sacar ambos datos del mismo select
      let contratistaID = null;
      let empresaID = null;
      let aux = event.target.value.split('/');

      if (aux[1]) {
        contratistaID = aux[0];
        empresaID = aux[1];
      }

      this.setState({
        contratista_id: contratistaID,
        empresa_id: empresaID,
        showLoginMessage: false,
        [prop + 'Error']: false,
      });
    } else {
      // Cualquier input que no sea el select...

      if (prop === 'dni') {
        const reg = new RegExp(/^\d+$/);

        if (reg.test(event.target.value) === false && event.target.value !== '') {
          return;
        }

        if (event.target.value.length > 9) {
          return;
        }

        this.setState({
          [prop]: event.target.value,
          showLoginMessage: false,
          [prop + 'Error']: false,
        });
      } else {
        this.setState({
          [prop]: event.target.value,
          showLoginMessage: false,
          [prop + 'Error']: false,
        });
      }
    }
  };

  onClickContinuarButton = () => {
    // Validacion
    let errs = {};
    let validationSuccess = true;

    if (this.state.nombre === '' || this.state.nombre === null) {
      errs.nombreError = true;
      validationSuccess = false;
    }

    if (this.state.apellido === '' || this.state.apellido === null) {
      errs.apellidoError = true;
      validationSuccess = false;
    }

    if (this.state.contratista_id === '' || this.state.contratista_id === null) {
      errs.contratista_idError = true;
      validationSuccess = false;
    }

    if (this.state.dni === '' || this.state.dni === null) {
      errs.dniError = true;
      validationSuccess = false;
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showLoginMessage: true });
      return;
    }

    this.setState({ showLoader: true });

    let obj = {
      id: null,
      tipo: 'capataz',
      nombre: this.state.nombre,
      apellido: this.state.apellido,
      rol: null,
      empresa_id: this.state.empresa_id,
      contratista_id: this.state.contratista_id,
      usuario_externo_dni: this.state.dni,
    };

    setUserDataLS(obj);

    let logUE = {
      id: 'tmp-logUE-' + Date.now(),
      fecha_horario: getCurrentDateTime(),
      dni: parseInt(this.state.dni),
      nombre: this.state.nombre,
      apellido: this.state.apellido,
      contratista_id: this.state.contratista_id,
    };

    addRecord('logs_usuarios_externos', logUE)
      .then(() => {
        window.location.replace(URLpreffix + '/');
      })
      .catch((err) => window.location.replace(URLpreffix + '/'));
  };

  render() {
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h6" className="mb1">
                Complete sus datos
              </Typography>

              <Typography variant="body1">
                Le solicitamos por única vez sus datos personales.
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic1"
                  error={this.state.nombreError}
                  label="Nombre*"
                  variant="outlined"
                  onChange={this.handleChange('nombre')}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic2"
                  error={this.state.apellidoError}
                  label="Apellido*"
                  variant="outlined"
                  onChange={this.handleChange('apellido')}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic3"
                  type="number"
                  error={this.state.dniError}
                  label="DNI*"
                  value={this.state.dni}
                  variant="outlined"
                  onChange={this.handleChange('dni')}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" className="mb1">
                <InputLabel
                  error={this.state.contratista_idError}
                  htmlFor="outlined-age-native-simple"
                >
                  Contratista*
                </InputLabel>
                <Select
                  native
                  label="Contratista"
                  error={this.state.contratista_idError}
                  inputProps={{
                    name: 'Contratista',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={this.handleChange('contratista_id')}
                >
                  <option aria-label="None" value="" />
                  {this.state.optionsContratistas}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={this.onClickContinuarButton}
                variant="contained"
                color="secondary"
                size="large"
              >
                Continuar
              </Button>

              {this.state.showLoginMessage ? (
                <Grid item xs={12} className="mb1 mt1">
                  <Alert severity="error">
                    <AlertTitle>
                      <b>Campos sin completar.</b>
                    </AlertTitle>
                    Falta completar campos obligatorios.
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </main>
    );
  }
}

export default LoginCapataz;
