import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Alert, AlertTitle } from '@material-ui/lab';

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import DoubleCheck from '../Misc/DoubleCheck';
import PromptMessage from '../Misc/PromptMessage';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { addRecord, updateDataBases, getRecords } from '../../indexedDB/operations';
import { sendNewTA, sendFile } from '../../requests/data';
import { edadEnRango, getCurrentDateTime, isOnlineV2 } from '../../helpers/helpers';

class AltaTrabajadorP3 extends React.Component {
  constructor(props) {
    super(props);

    let uidRenatre = localStorage.getItem('stateUID');
    if (!uidRenatre || uidRenatre === 'null' || uidRenatre === null) {
      uidRenatre = null;
    }

    this.state = {
      showForm: false,
      rangoEdad: 'en_rango',

      showDialogMenor: false,
      showDialogMayor: false,
      dialogTitle: '',
      dialogContent: '',

      id: 'tmp-altaTA-' + Date.now(),
      dni: localStorage.getItem('stateDni'),
      uid_renatre: uidRenatre,
      empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
      nombre: localStorage.getItem('stateNombre'),
      apellido: localStorage.getItem('stateApellido'),
      genero: '',
      fecha_nacimiento: '',
      asiste_escuela: '',
      asiste_formacion: '',
      asiste_con_familia: '',
      contratista_id: '',
      responsable_empresa_a_cargo: '',
      usuario_aprueba_alta_id: parseInt(localStorage.getItem('usuario_id')),

      nombreError: false,
      apellidoError: false,
      fecha_nacimientoError: false,
      generoError: false,
      responsable_empresa_a_cargoError: false,
      contratista_idError: false,
      asiste_escuelaError: false,
      asiste_formacionError: false,
      asiste_con_familiaError: false,

      enviarAltaMessage: false,

      showLoader: false,
      showNoInternetDialog: false,

      listaContratistas: null,

      file1: null,
      file2: null,
      file3: null,
    };
  }

  componentDidMount() {
    getRecords('contratistas', {
      empresa_asociada_id: parseInt(localStorage.getItem('usuario_empresa_id')),
    }).then((contratistas) => {
      let arr = contratistas.map((c) => <option value={c.id}>{c.nombre}</option>);
      this.setState({
        listaContratistas: arr,
      });
    });
  }

  handleChange = (prop) => (event) => {
    if (prop === 'fecha_nacimiento') {
      let fecha_nacimiento = event.target.value;
      let rangoEdad = edadEnRango(fecha_nacimiento);
      this.setState({
        [prop]: event.target.value,
        rangoEdad: rangoEdad,
        [prop + 'Error']: false,
        enviarAltaMessage: false,
      });
      return;
    }

    this.setState({
      [prop]: event.target.value,
      [prop + 'Error']: false,
      enviarAltaMessage: false,
    });
  };

  handleChangeFile = (prop) => (event) => {
    this.setState({ [prop]: event.target.files[0] });
  };

  handleDoubleCheckChange = (_r) => {
    this.setState({
      [_r.name]: _r.selection,
      [_r.name + 'Error']: false,
      enviarAltaMessage: false,
    });
  };

  onClickEnviarAlta = () => {
    this.setState({ showLoader: true });

    let validationSuccess = true;
    let obj = {};

    if (this.state.nombre === '') {
      obj.nombreError = true;
      validationSuccess = false;
    }

    if (this.state.apellido === '') {
      obj.apellidoError = true;
      validationSuccess = false;
    }

    if (this.state.genero === '') {
      obj.generoError = true;
      validationSuccess = false;
    }

    if (this.state.fecha_nacimiento === '') {
      obj.fecha_nacimientoError = true;
      validationSuccess = false;
    }

    if (this.state.responsable_empresa_a_cargo === '') {
      obj.responsable_empresa_a_cargoError = true;
      validationSuccess = false;
    }

    if (this.state.contratista_id === '') {
      obj.contratista_idError = true;
      validationSuccess = false;
    }

    if (this.state.asiste_con_familia === '') {
      obj.asiste_con_familiaError = true;
      validationSuccess = false;
    }
    if (this.state.asiste_escuela === '') {
      obj.asiste_escuelaError = true;
      validationSuccess = false;
    }
    if (this.state.asiste_formacion === '') {
      obj.asiste_formacionError = true;
      validationSuccess = false;
    }

    if (validationSuccess === false) {
      this.setState({ ...obj, enviarAltaMessage: true, showLoader: false });
      return;
    }

    // Es menor o mayor
    if (this.state.rangoEdad !== 'en_rango') {
      if (this.state.rangoEdad === 'mayor') {
        return this.setState({
          dialogTitle: 'Mayor de edad.',
          dialogContent: 'No es posible dar de alta a mayores de edad.',
          showDialogMayor: true,
          showLoader: false,
        });
      }
      if (this.state.rangoEdad === 'menor') {
        this.setState({
          dialogTitle: 'Menor de edad.',
          dialogContent: 'No es posible dar de alta a menores de edad.',
          showDialogMenor: true,
          showLoader: false,
        });
        // Ademas de mostrar el prompt, si es menor de edad
        // se ingresa un registro de incidencia asociado al
        // usuario que quizo realizar el alta
        let incidencia = {
          id: 'tmp-incidencia-' + Date.now(),
          tipo_incidencia: 'Intento de alta de un niño/a menor de 16 años',
          fecha_horario: getCurrentDateTime(),
          dni_usuario_externo: null,
          registrado_usuario_id: parseInt(localStorage.getItem('usuario_id')),
          alta_ta_asociado: true,
          usuario_alta_ta: parseInt(localStorage.getItem('usuario_id')),
          monitoreo_lote_asociado: null,
          lote_id: null,

          empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
          contratista_id: null,

          nombre_sujeto: null,
          genero_sujeto: null,
          lugar_nacimineto_sujeto: null,
          edad_sujeto: null,
          numero_documento_sujeto: null,
          acompaniado_por_adulto: null,
          observaciones: null,
          responsable_empresa: null,
        };

        addRecord('incidencias', incidencia).then(() => {});
      }

      return;
    }

    let d = {
      dni: this.state.dni,
      uid_renatre: this.state.uid_renatre === '' ? null : this.state.uid_renatre,
      empresa_id: this.state.empresa_id,
      nombre: this.state.nombre,
      apellido: this.state.apellido,
      genero: this.state.genero,
      fecha_nacimiento: this.state.fecha_nacimiento,
      asiste_escuela: this.state.asiste_escuela,
      asiste_formacion: this.state.asiste_formacion,
      asiste_con_familia: this.state.asiste_con_familia,
      contratista_id: this.state.contratista_id,
      responsable_empresa_a_cargo: this.state.responsable_empresa_a_cargo,
      usuario_aprueba_alta_id: this.state.usuario_aprueba_alta_id,
      documentacion_respaldatoria_1: null,
      documentacion_respaldatoria_2: null,
      documentacion_respaldatoria_3: null,
    };

    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        sendFile(this.state.file1, {
          file_number: 1,
          empresa_id: d.empresa_id,
          nombre: d.nombre,
          apellido: d.apellido,
          dni: d.dni,
        }).then((f1res) => {
          d.documentacion_respaldatoria_1 = f1res;
          sendFile(this.state.file2, {
            file_number: 2,
            empresa_id: d.empresa_id,
            nombre: d.nombre,
            apellido: d.apellido,
            dni: d.dni,
          }).then((f2res) => {
            d.documentacion_respaldatoria_2 = f2res;
            sendFile(this.state.file3, {
              file_number: 3,
              empresa_id: d.empresa_id,
              nombre: d.nombre,
              apellido: d.apellido,
              dni: d.dni,
            }).then((f3res) => {
              d.documentacion_respaldatoria_3 = f3res;

              sendNewTA(d)
                .then((ta) => {
                  // Guardo el ta que me devuelve el server
                  addRecord('adolescentes', ta.data)
                    .then(() => {
                      localStorage.setItem('stateAltaTrabajador', 'idle');
                      localStorage.setItem('stateDni', '');
                      localStorage.setItem('stateNombre', '');
                      localStorage.setItem('stateApellido', '');

                      // Actualizo datos de las DB
                      updateDataBases()
                        .then(() => {
                          this.props.history.push(
                            URLpreffix +
                              '/AltaTrabajadorP4?n=' +
                              this.state.nombre +
                              '&a=' +
                              this.state.apellido,
                          );
                        })
                        .catch((err) => console.error(err));
                    })
                    .catch((err) => {
                      console.error(err);
                      this.setState({ showLoader: false });
                    });
                })
                .catch((err) => {
                  this.setState({ showLoader: false });
                });
            });
          });
        });
      } else {
        this.setState({ showLoader: false, showNoInternetDialog: true });
      }
    });
  };

  render() {
    return (
      <main>
        <PromptMessage
          title="Sin conexión a internet"
          content="Las altas de Trabajadores/as Adolescentes sólo pueden hacerse con conexión a internet."
          showDialog={this.state.showNoInternetDialog}
          closeDialog={() => {
            this.setState({ showNoInternetDialog: false });
          }}
        />

        <PromptMessage
          showDialog={this.state.showDialogMenor}
          closeDialog={() => {
            this.setState({ showDialogMenor: false });
          }}
          title="Tiene menos de 16 años."
          content="La fecha de nacimiento ingresada corresponde a una persona menor de edad. No se podrá generar el alta."
        />

        <PromptMessage
          showDialog={this.state.showDialogMayor}
          closeDialog={() => {
            this.setState({ showDialogMayor: false });
          }}
          title="Tiene más de 16 años."
          content="La fecha de nacimiento ingresada corresponde a una persona mayor de edad. No se podrá generar el alta."
        />

        <Loader show={this.state.showLoader} />
        <NavBar />
        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h5" className="mb1">
                Alta de T.A.
              </Typography>

              <Alert severity="warning">
                <AlertTitle>Alta temprana</AlertTitle>
                El contratatista o capataz deberá presentar la siguiente información: <br />
                <br />
                Certificado de escolaridad: ✔<br />
                Apto médico: ✔<br />
                Autorización de ambos padres: ✔<br />
                Visado de autoridad laboral local: ✔<br />
                <br />
                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={() => {
                          this.setState({ showForm: !this.state.showForm });
                        }}
                      />
                    }
                    label="El trabajador ya tiene el alta temprana de la empresa"
                  />
                </FormGroup>
              </Alert>
            </Grid>

            {this.state.showForm === true ? (
              <React.Fragment>
                <Grid item xs={12} className="mb1">
                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic1"
                      onChange={this.handleChange('dni')}
                      value={this.state.dni}
                      label="DNI"
                      disabled
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic1"
                      onChange={this.handleChange('uid_renatre')}
                      value={this.state.uid_renatre}
                      label="Código Único RENATRE"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic2"
                      error={this.state.nombreError}
                      onChange={this.handleChange('nombre')}
                      value={this.state.nombre}
                      label="Nombre*"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic3"
                      error={this.state.apellidoError}
                      onChange={this.handleChange('apellido')}
                      value={this.state.apellido}
                      label="Apellido*"
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="outlined-basic4"
                      error={this.state.fecha_nacimientoError}
                      onChange={this.handleChange('fecha_nacimiento')}
                      label="Fecha de Nacimiento*"
                      type="date"
                      variant="outlined"
                      defaultValue=""
                      InputLabelProps={{
                        shrink: true,
                        error: this.state.fecha_nacimientoError,
                      }}
                    />
                  </FormControl>

                  {this.state.rangoEdad === 'menor' ? (
                    <Grid item xs={12} className="mb1">
                      <Alert severity="error">
                        <AlertTitle>
                          <b>Menor de edad.</b>
                        </AlertTitle>
                        La fecha de nacimiento ingresada corresponde a un/a menor de edad. No se
                        podrá generar el alta.
                      </Alert>
                    </Grid>
                  ) : null}

                  {this.state.rangoEdad === 'mayor' ? (
                    <Grid item xs={12} className="mb1">
                      <Alert severity="error">
                        <AlertTitle>
                          <b>Mayor de edad.</b>
                        </AlertTitle>
                        La fecha de nacimiento ingresada corresponde a un/a mayor de edad. No se
                        podrá generar el alta.
                      </Alert>
                    </Grid>
                  ) : null}

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <InputLabel error={this.state.generoError} htmlFor="outlined-age-native-simple">
                      Género*
                    </InputLabel>
                    <Select
                      native
                      label="Género"
                      error={this.state.generoError}
                      defaultValue=""
                      inputProps={{
                        name: 'Sexo',
                        id: 'outlined-age-native-simple',
                      }}
                      onChange={this.handleChange('genero')}
                    >
                      <option aria-label="None" value="" />
                      <option value={'otro'}>Otro</option>
                      <option value={'hombre'}>Masculino</option>
                      <option value={'mujer'}>Femenino</option>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <InputLabel
                      error={this.state.contratista_idError}
                      htmlFor="outlined-age-native-simple"
                    >
                      Contratista*
                    </InputLabel>
                    <Select
                      native
                      label="Contratista"
                      error={this.state.contratista_idError}
                      inputProps={{
                        name: 'Sexo',
                        id: 'outlined-age-native-simple',
                      }}
                      onChange={this.handleChange('contratista_id')}
                    >
                      <option aria-label="None" value="" />
                      {this.state.listaContratistas}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb1">
                    <TextField
                      id="input-responsable-empresa"
                      onChange={this.handleChange('responsable_empresa_a_cargo')}
                      error={this.state.responsable_empresa_a_cargoError}
                      label="Responsable de la empresa a cargo*"
                      variant="outlined"
                    />
                    <FormHelperText>
                      Todos los adolescentes deberán estar a cargo de un resposanble por parte de la
                      empresa.
                    </FormHelperText>
                  </FormControl>

                  <DoubleCheck
                    withLabels
                    text="Asiste actualmente a la escuela*"
                    name="asiste_escuela"
                    onChange={this.handleDoubleCheckChange}
                    error={this.state.asiste_escuelaError}
                  />

                  <DoubleCheck
                    withLabels
                    text="Asiste a algun programa de formación*"
                    name="asiste_formacion"
                    onChange={this.handleDoubleCheckChange}
                    error={this.state.asiste_formacionError}
                  />

                  <DoubleCheck
                    withLabels
                    text="Vino a trabajar con un familiar, amigo/a o conocido/a*"
                    name="asiste_con_familia"
                    onChange={this.handleDoubleCheckChange}
                    error={this.state.asiste_con_familiaError}
                  />

                  <FormControl fullWidth variant="outlined" className="mb2 mt3">
                    <label htmlFor="test" className="mb-10">
                      Documentación respaldatoria 1
                    </label>
                    <TextField
                      id="test"
                      type="file"
                      label=""
                      variant="outlined"
                      onChange={this.handleChangeFile('file1')}
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb2">
                    <label htmlFor="test2" className="mb-10">
                      Documentación respaldatoria 2
                    </label>
                    <TextField
                      id="test2"
                      type="file"
                      variant="outlined"
                      onChange={this.handleChangeFile('file2')}
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" className="mb2">
                    <label htmlFor="test3" className="mb-10">
                      Documentación respaldatoria 3
                    </label>
                    <TextField
                      id="test3"
                      type="file"
                      variant="outlined"
                      onChange={this.handleChangeFile('file3')}
                    />
                  </FormControl>
                </Grid>

                {this.state.enviarAltaMessage ? (
                  <Grid item xs={12} className="">
                    <Alert severity="error">
                      <AlertTitle>
                        <b>Campos sin completar.</b>
                      </AlertTitle>
                      Falta completar campos obligatorios.
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item xs={12} className="mb1 text-center">
                  <Button
                    onClick={this.onClickEnviarAlta}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Enviar alta
                  </Button>
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(AltaTrabajadorP3);
