import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import NavBar from '../Navigation/NavBar';
import LogoFooter from '../Misc/LogoFooter';

import { URLpreffix } from '../../config';
import { isStandAlone } from '../../helpers/helpers';

export default function Welcome() {
  // Si es standAlone entonces directamente paso a Welcome2
  if (isStandAlone()) return window.location.replace(URLpreffix + '/Welcome2');
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ===
    false
  )
    return window.location.replace(URLpreffix + '/Welcome2');

  let history = new useHistory();

  return (
    <main>
      <NavBar disableBackButton />

      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12} className="mb2">
            <Typography variant="h5" className="mb1">
              Bienvenido/a a la App de Monitoreo del Sistema de Cumplimiento Social.
            </Typography>

            <Typography variant="body1">
              Esta herramienta está diseñada para facilitar el seguimiento en el campo del trabajo
              adolescente protegido y la prevención del trabajo infantil.
            </Typography>
          </Grid>

          <Grid item xs={12} className="mb1">
            <Button
              onClick={() => {
                history.push(URLpreffix + '/comoInstalar');
              }}
              href={URLpreffix + '/comoInstalar'}
              variant="contained"
              color="primary"
              size="large"
            >
              Instalar
            </Button>
            <br />

            <Button
              onClick={() => {
                history.push(URLpreffix + '/Welcome2');
              }}
              href={URLpreffix + '/Welcome2'}
              className="p0 mt1"
              color="primary"
            >
              Continuar sin instalar (no recomendado)
            </Button>
          </Grid>
        </Grid>

        <LogoFooter />
      </Container>
    </main>
  );
}
