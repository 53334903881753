import React from 'react';
import qs from 'query-string';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { Alert, AlertTitle } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { updateIncidencia, updateDataBases } from '../../indexedDB/operations';


export default function ReporteIncidenciaP2(props) {

  let history = useHistory();

  const [values, setValues] = React.useState({
    disableFinalizarButton: true,
    responsable: '',
    showLoader: false
  });

  let queryParams = qs.parse(props.location.search);
  let type = queryParams.tipo ? queryParams.tipo : null;
  let id_incidencia = queryParams.id ? queryParams.id : null;

  const updateIncidenciaForm = () => {
    setValues({ ...values, showLoader: true });
    updateIncidencia(id_incidencia, values.responsable)
      .then(() => {

        let registerSyncEvent = true;
        updateDataBases(registerSyncEvent)
          .then(() => {
            history.push(URLpreffix+'/ReporteIncidenciaP3');
          });

      });

  }

  const handleChange = (e) => {
    let disabled = true;
    if (e.target.value !== '') disabled = false;

    setValues({ ...values, disableFinalizarButton: disabled, responsable: e.target.value });
  }

  ///////////////////////////////////////////////
  // Lo que se muestra va a depender del tipo  //
  // de incidencia registrada:                 //
  //         -> Tipo 1: hay que llevar al      //
  //                    responsable de la      //
  //                    empresa                //
  //         -> Tipo 2: solo muestra info      //
  ///////////////////////////////////////////////

  return (
    <main>
      <Loader show={values.showLoader} />
      <NavBar disableBackButton />

      <Container maxWidth="sm">
        <Grid container spacing={3}>
            {
              type === '2' ? (
                <React.Fragment>
                  <Grid item xs={12} className="mb1">
                    <Alert severity="error">
                      <AlertTitle>Instrucciones</AlertTitle>

                      Si la incidencia se trata de un/a <b>niño/a</b> informar que está prohibido el trabajo, acompañamiento al campo, etc. para los/as niños/as.<br/>
                      <br/>
                      Si la incidencia se trata de un o <b>adolescente de 16 o 17 años</b>, usted debe
                      informar sobre los requisitos para la contratación e indicar que no puede subirse al transporte o ingresar al campo.<br/>
                      <br/>
                      <br/>
                      <b>Requisitos para la contratación de adolescentes de 16 y 17 años:</b><br/>
                      ✔ Autorización de los padres visada por la autoridad laboral competente (Secretaría de Trabajo de la Provincia de Tucumán)<br/>
                      ✔ Apto médico (emitido por CAPS de la zona u hospital)<br/>
                      ✔ Certificado de alumno regula emitido por la escuela a la que asiste.<br/>
                      <br/>
                    </Alert>
                  </Grid>

                  <Grid item xs={12} className="mb1 text-center">
                    <Button onClick={()=>{history.push(URLpreffix+'/')}} variant="contained" color="primary" size="large">
                      Volver a pantalla de inicio
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : null
            }

            {
              type === '1' ? (
                <React.Fragment>
                  <Grid item xs={12} className="mb1">

                    <Alert severity="error">
                      <AlertTitle>Instrucción</AlertTitle>

                      <b>Sin cerrar esta ventana</b> lleve al/la
                      niño/a a la administración y luego complete
                      el siguiente campo con el nombre del empleado
                      responsable de la recepción para poder
                      finalizar el registro de la incidencia.<br/>
                    </Alert>

                    <FormControl fullWidth variant="outlined" className="mb1 mt1">
                      <TextField id="outlined-basic1" onChange={handleChange} label="Responsable del niño/a" variant="outlined" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} className="mb1 text-center">
                    <Button disabled={values.disableFinalizarButton} onClick={updateIncidenciaForm} variant="contained" color="primary" size="large">
                      Finalizar registro de incidencia
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : null
            }
        </Grid>
      </Container>

      <MyBottomNavigation />
    </main>

  );
}
