import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import CardTADetalle from './elements/CardTADetalle';
import ItemRegistroHistorico from './elements/ItemRegistroHistorico';

import { getAdolescentesByEmpresa, getRecords } from '../../indexedDB/operations';
import { getIDInCorrectType, getTimeStampFromString } from '../../helpers/helpers';

class ListadoTrabajadoresP2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardTA: null,
      registrosHistoricos: null,
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);
    let idTA = getIDInCorrectType(queryParams.id);

    getAdolescentesByEmpresa(localStorage.getItem('usuario_empresa_id'), { id: idTA })
      .then((items) => {
        let ta = items[0];

        let rh = [];
        if (Array.isArray(ta.historicoIngresosEgresos))
          rh = [...rh, ...ta.historicoIngresosEgresos];
        if (Array.isArray(ta.historicoMonitoreos)) rh = [...rh, ...ta.historicoMonitoreos];

        rh.sort((a, b) => {
          return getTimeStampFromString(b.fecha_horario) - getTimeStampFromString(a.fecha_horario);
        });

        let arrProm = [];
        for (let i = 0; i < rh.length; i++) {
          let prom = new Promise((resolve, reject) => {
            getRecords('datos_usuarios', { id: rh[i].registrado_usuario_id }).then((users) => {
              if (users.length > 0) rh[i].registrado_usuario_data = users[0];
              else users[i].registrado_usuario_data = null;

              resolve();
            });
          });

          arrProm.push(prom);
        }

        Promise.all(arrProm).then(() => {
          let regs = rh.map((reg) => {
            return <ItemRegistroHistorico key={reg.id} {...reg} />;
          });

          this.setState({ cardTA: <CardTADetalle {...ta} />, registrosHistoricos: regs });
        });
      })
      .catch((err) => console.error(err));
  }

  render() {
    if (this.state.cardTA === null)
      return (
        <main>
          <NavBar />
          <MyBottomNavigation />
        </main>
      );
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="">
              <Typography variant="h5" className="mb1">
                Datos y registros del trabajador.
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              {this.state.cardTA}

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Fecha</TableCell>
                      <TableCell align="left">Tipo</TableCell>
                      <TableCell align="left">Usuario</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this.state.registrosHistoricos}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ListadoTrabajadoresP2);
