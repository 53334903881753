const initialState = {
  test: 1,
};

const reducer = (state = initialState, action) => {
  if (!action.type) action.type = 'DEFAULT';
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
