import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigation from '../../Navigation/MyBottomNavigation';

import { URLpreffix } from '../../../config';
import { getRecords } from '../../../indexedDB/operations';
import { getIDInCorrectType } from '../../../helpers/helpers';

class ControlDocumentacionP2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,

      inputError: false,
      errorMsg: '',
      dni: '',

      nombreEmpresa: null,
    };
  }

  componentDidMount() {
    let queryParams = qs.parse(this.props.location.search);
    if (queryParams.scrapFailed) this.setState({ showError: true });

    getRecords('empresas', {
      id: getIDInCorrectType(localStorage.getItem('usuario_empresa_id')),
    }).then((e) => {
      if (e.length > 0) this.setState({ nombreEmpresa: e[0].nombre });
    });
  }

  onDniChange = (e) => {
    const reg = new RegExp(/^\d+$/);

    if (reg.test(e.target.value) === false && e.target.value !== '') {
      return;
    }

    if (e.target.value.length > 9) return;

    if (e.target.value.length >= 5)
      this.setState({ inputError: false, errorMsg: '', dni: e.target.value, showError: false });
    else this.setState({ dni: e.target.value });
  };

  onClickContinue = () => {
    if (this.state.dni.length < 5) {
      this.setState({ inputError: true, errorMsg: 'Se debe ingresar un DNI válido' });
      return;
    }

    this.props.history.push(URLpreffix + '/ControlDocumentacionP3?dni=' + this.state.dni);
  };

  render() {
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Control de documentación
              </Typography>

              {this.state.showError === true ? (
                <Grid item xs={12}>
                  <Alert severity="error" className="">
                    <AlertTitle>
                      <b>Datos inaccesibles</b>
                    </AlertTitle>
                    No se pudieron encontrar datos con la tarjeta escaneada.
                    <br />
                    <b>Ingrese el DNI manualmente</b>.
                  </Alert>
                </Grid>
              ) : (
                <Typography variant="body1">
                  Ingrese manualmente el número de DNI para saber si está en la base de datos{' '}
                  {this.state.nombreEmpresa !== null
                    ? ' de la empresa: ' + this.state.nombreEmpresa
                    : null}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} className="mb1">
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic"
                  label="DNI"
                  type="number"
                  variant="outlined"
                  onChange={this.onDniChange}
                  value={this.state.dni}
                  error={this.state.inputError}
                  helperText={this.state.errorMsg}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                onClick={this.onClickContinue}
                variant="contained"
                color="primary"
                size="large"
              >
                Controlar documentación
              </Button>
            </Grid>
          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ControlDocumentacionP2);
