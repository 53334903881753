import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import NavBar from '../Navigation/NavBar';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { isOnlineV2 } from '../../helpers/helpers';
import { authenticate, setUserDataLS } from '../../requests/users';

export default function LoginEmpleados() {
  const [values, setValues] = React.useState({
    showLoader: false,
    showNoInternetMessage: false,
    showLoginMessage: false,
    email: '',
    password: '',
    showPassword: false,
    disableActions: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, showLoginMessage: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const logIn = () => {
    setValues({ ...values, disableActions: true, showLoader: true });

    isOnlineV2().then((isOnline) => {
      if (isOnline) {
        authenticate(values.email, values.password)
          .then((auth_res) => {
            if (auth_res.data !== undefined) {
              let obj = {
                id: auth_res.data.user.id,
                tipo: 'empresa',
                nombre: auth_res.data.user.first_name,
                apellido: auth_res.data.user.last_name,
                rol: auth_res.data.user.role,
                empresa_id: auth_res.data.user.empresa_id,
                contratista_id: null,
                permisos: auth_res.data.user.permisos,
              };

              setUserDataLS(obj);
              window.location.replace(URLpreffix + '/');
            } else {
              setValues({
                ...values,
                disableActions: false,
                showLoader: false,
                showLoginMessage: true,
              });
            }
          })
          .catch(() => {
            setValues({
              ...values,
              disableActions: false,
              showLoader: false,
              showLoginMessage: true,
            });
          });
      } else {
        setValues({ ...values, showNoInternetMessage: true });
        setTimeout(() => {
          setValues({ ...values, showNoInternetMessage: false });
        }, 7000);
      }
    });
  };

  return (
    <main>
      <Loader show={values.showLoader} />

      <NavBar />

      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12} className="mb2">
            <Typography variant="h6" className="mb1">
              Ingresar
            </Typography>

            <Typography variant="body1">
              Según su rol en la empresa la webapp le ofrecerá diferentes opciones y accesos a la
              información.
            </Typography>
          </Grid>

          <Grid item xs={12} className="mb2">
            <div>
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  disabled={values.disableActions}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={handleChange('email')}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  disabled={values.disabledActions}
                  id="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12} className="mb1 text-center">
            <Button
              onClick={logIn}
              disabled={values.disableActions}
              variant="contained"
              align="center"
              className="mr1"
              color="primary"
              size="large"
            >
              Ingresar
            </Button>

            {values.showLoginMessage ? (
              <Grid item xs={12} className="mb1 mt1">
                <Alert severity="error">
                  <AlertTitle>
                    <b>No se pudo iniciar sesión.</b>
                  </AlertTitle>
                  El email o la contraseña son incorrectos.
                </Alert>
              </Grid>
            ) : null}

            {values.showNoInternetMessage ? (
              <Grid item xs={12} className="mb1 mt1">
                <Alert severity="error">
                  <AlertTitle>
                    <b>No hay internet.</b>
                  </AlertTitle>
                  Sólo se puede iniciar sesión conectado a internet.
                </Alert>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
