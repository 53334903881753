import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import NavBar from '../../Navigation/NavBar';
import MyBottomNavigation from '../../Navigation/MyBottomNavigation';

import { URLpreffix } from '../../../config';
import { getRecords } from '../../../indexedDB/operations';


class ControlTrabajadorUEDNI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      inputError: false,
      errorMsg: "",
      dni: ""
    };
  }

  componentDidMount() {

  }


  onDniChange = (e) => {
    const reg = new RegExp(/^\d+$/);

    if (reg.test(e.target.value) === false && e.target.value !== '') {
      return;
    }

    if (e.target.value.length >= 5) this.setState({ inputError: false, errorMsg: "", dni: e.target.value, showError: false });
    else this.setState({ dni: e.target.value });
  };

  onClickContinue = () => {
    if (this.state.dni.length < 5) {
      this.setState({ inputError: true, errorMsg: "Se debe ingresar un DNI válido" });
      return;
    }

    // Checkeo si existe el TA a nivel local
    getRecords('adolescentes', { dni: parseInt(this.state.dni) })
      .then(a => {
        if (a.length > 0) this.props.history.push(URLpreffix+'/ControlTrabajadorUEP2?idTA='+a[0].id);
        else this.setState({ showError: true });
      });

  };

  render() {
    return (
      <main>
        <NavBar />

        <Container maxWidth="sm" className="">
          <Grid container spacing={3}>
            <Grid item xs={12} className="mb2">
              <Typography variant="h5" className="mb1">
                Monitoreo de T.A.
              </Typography>

              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </Typography>
            </Grid>

            <Grid item xs={12} className="mb1">
              <FormControl fullWidth variant="outlined" className="mb1">
                <TextField
                  id="outlined-basic"
                  label="DNI"
                  variant="outlined"
                  onChange={this.onDniChange}
                  value={this.state.dni}
                  error={this.state.inputError}
                  helperText={this.state.errorMsg}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button onClick={this.onClickContinue} variant="contained" color="primary" size="large">
                Comenzar monitoreo
              </Button>

            </Grid>

            {
              this.state.showError === true ? (
                <Grid item xs={12}>
                <Alert severity="warning" className="mb1">
                  <AlertTitle>DNI inexistente</AlertTitle>
                  No existe un T.A. registrado con ese número de DNI.
                </Alert>
              </Grid>
              ) : null
            }

          </Grid>
        </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ControlTrabajadorUEDNI);
