import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import { getLotesByEmpresa } from '../../indexedDB/operations';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { URLpreffix } from '../../config';
import { CircularProgress } from '@material-ui/core';

const ControlLoteP1 = () => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [lotesOptions, setLotesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disableControl, setDisableControl] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getLotesByEmpresa(parseInt(localStorage.getItem('usuario_empresa_id')))
      .then((lotes) => {
        const availableLotes = lotes.filter((e) => !e.hoyControl);

        setLotesOptions(availableLotes.map((e) => ({ nombre: e.nombre, id: e.id })));

        if (!availableLotes.length) {
          setDisableControl(true);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <main>
      <NavBar />

      <Container maxWidth="sm" className="">
        <Grid container spacing={3}>
          <Grid item xs={12} className="">
            <Typography variant="h5" className="">
              Monitorear un Lote
            </Typography>

            {loading ? (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {disableControl ? (
                  <Alert severity="success">
                    <AlertTitle>Ya se controló un lote el día de hoy.</AlertTitle>
                  </Alert>
                ) : (
                  <>
                    <Typography variant="body1">Seleccione el lote que desea controlar.</Typography>

                    <Box className="mb1">
                      <Autocomplete
                        disablePortal
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="lotes-autoselect"
                        options={lotesOptions}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Seleccione lote..." />
                        )}
                        getOptionLabel={(option) => option.nombre}
                      />
                    </Box>
                    <Button
                      disable={true}
                      onClick={() => {
                        history.push(
                          URLpreffix +
                            '/ControlLoteP2?nombre=' +
                            value.nombre +
                            '&lote_id=' +
                            value.id,
                        );
                      }}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Monitorear
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>

      <MyBottomNavigation />
    </main>
  );
};

export default ControlLoteP1;
