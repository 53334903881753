import React from 'react';
import { Alert } from '@material-ui/lab';

type TMessageProps = {
  text: string;
  severity: 'info' | 'error' | 'warning' | 'success';
};

const Message = ({ text, severity }: TMessageProps) => {
  return <Alert severity={severity}>{text}</Alert>;
};

export default Message;
