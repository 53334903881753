import React from 'react';
import { useHistory } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { URLpreffix } from '../../../config';
import { getPrettyDateTimeFromString } from '../../../helpers/helpers';

export default function CardTAResumen(props) {
  let history = useHistory();

  let usuario =
    props.registrado_usuario_data !== null
      ? props.registrado_usuario_data.first_name + ' ' + props.registrado_usuario_data.last_name
      : null;
  let prettyDateTime = getPrettyDateTimeFromString(props.fecha_horario);
  let tipo = 'Monitoreo';
  if (props.tipo !== undefined) {
    tipo = props.tipo === 'ingreso' ? 'Ingreso' : 'Egreso';
  }

  let url = URLpreffix + '/ListadoTrabajadoresP3?tipo=' + tipo + '&id=' + props.id;

  return (
    <TableRow
      onClick={() => {
        history.push(url);
      }}
    >
      <TableCell align="left">
        <Chip size="small" label={prettyDateTime} />
      </TableCell>
      <TableCell align="left">
        <Chip size="small" color="primary" label={tipo} />
      </TableCell>
      <TableCell align="left">{usuario}</TableCell>
    </TableRow>
  );
}
