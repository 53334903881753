import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import DoubleCheck from '../Misc/DoubleCheck';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { getAdolescentesByEmpresa, addRecord, updateDataBases } from '../../indexedDB/operations';
import { getCurrentDateTime, getGeolocation, getIDInCorrectType } from '../../helpers/helpers';

const listaTareas = [
  "Laborales administrativas",
  "Control planillas",
  "Control de recepción de fruta",
  "Control de peso de las bandejas",
  "Llevar el listado de bandejas entregadas por cosechero",
  "Entrega de fichas por bandeja cosechada",
  "Monitoreo de personal de campo",
  "Identificación de plagas y enfermedades",
  "Control de calidad de fruta",
  "Otras"
];

class ControlTrabajadorP2 extends React.Component {
  constructor(props) {
    super(props);

    let queryParams = qs.parse(props.location.search);

    this.state = {
      load: false,
      showEnviarMonitoreoMessage: false,
      showLoader: true,

      showForm: false,
      otrasEnabled: false,

      nombreTA: "",
      apellidoTA: "",
      dniTA: "",

      otraTarea: "",
      observaciones: "",

      id: 'tmp-controlTA-'+Date.now(),
      empresa_id: parseInt(localStorage.getItem('usuario_empresa_id')),
      adolescente_id: getIDInCorrectType(queryParams.idTA),
      registrado_usuario_id: parseInt(localStorage.getItem('usuario_id')),
      fecha_horario: getCurrentDateTime(),
      geo_lat_long: null,
      lugar_de_trabajo: false, // Es si se encuentra en el lugar de trabajo
      tareas_realizadas: '',
      utiliza_identificacion: '',
      requiere_proteccion: '',
      utiliza_proteccion: '',
      utiliza_herramientas_peligrosas: '',
      utiliza_maquinaria: '',
      expuesto_alta_temp: '',
      expuesto_baja_temp: '',
      hay_agua: '',
      esta_agachado: '',
      levanta_mas_5k: '',
      brazos_arriba: '',
      utiliza_agroquimicos: '',
      fue_alguien: '',

      tareas_realizadasError: false,
      utiliza_identificacionError: false,
      requiere_proteccionError: false,
      utiliza_proteccionError: false,
      utiliza_herramientas_peligrosasError: false,
      utiliza_maquinariaError: false,
      expuesto_alta_tempError: false,
      expuesto_baja_tempError: false,
      hay_aguaError: false,
      esta_agachadoError: false,
      levanta_mas_5kError: false,
      brazos_arribaError: false,
      utiliza_agroquimicosError: false,
      fue_alguienError: false
    };
  }

  componentWillMount() {
    this.tareasRealizadas = new Set();
  }

  componentDidMount() {
    let empresaID = parseInt(localStorage.getItem('usuario_empresa_id'));
    getAdolescentesByEmpresa(empresaID, { id: this.state.adolescente_id })
      .then(a => {
        getGeolocation().then(pos => this.setState({ load: true, geo_lat_long: pos, nombreTA: a[0].nombre, apellidoTA: a[0].apellido, dniTA: a[0].dni, showLoader: false }));
      });
  }


  onChangeSwitch = () => {
    let errs = {
      tareas_realizadasError: false,
      utiliza_identificacionError: false,
      requiere_proteccionError: false,
      utiliza_proteccionError: false,
      utiliza_herramientas_peligrosasError: false,
      utiliza_maquinariaError: false,
      expuesto_alta_tempError: false,
      expuesto_baja_tempError: false,
      hay_aguaError: false,
      esta_agachadoError: false,
      levanta_mas_5kError: false,
      brazos_arribaError: false,
      utiliza_agroquimicosError: false,
      fue_alguienError: false,
      showEnviarMonitoreoMessage: false
    };

    this.setState({ showForm: !this.state.showForm, lugar_de_trabajo: !this.state.lugar_de_trabajo, ...errs });
  }

  createItem = label => (
    <FormControlLabel
      key={label}
      control={<Checkbox onChange={this.handleCheckboxChange(label)} name="gilad" />}
      label={label}
    />
  )

  createCheckBoxes = () => {
    return listaTareas.map(this.createItem);
  }

  handleCheckboxChange = label => ev => {

    // Caso especial checkbox Otras
    if (label === 'Otras') {
      this.setState({ otrasEnabled: !this.state.otrasEnabled, otraTarea: "", showEnviarMonitoreoMessage: false, tareas_realizadasError: false });
      return;
    }

    this.setState({ showEnviarMonitoreoMessage: false, tareas_realizadasError: false });

    if (this.tareasRealizadas.has(label)) this.tareasRealizadas.delete(label);
    else this.tareasRealizadas.add(label);
  }

  handleInputChange = prop => e => {
    this.setState({ [prop]: e.target.value, [prop+'Error']: false, showEnviarMonitoreoMessage: false });
  }

  handleDoubleCheck = sel => {
    this.setState({ [sel.name]: sel.selection, [sel.name+'Error']: false, showEnviarMonitoreoMessage: false });
  }

  registrarControl = () => {

    let tr = Array.from(this.tareasRealizadas).join('|');

    if (this.state.otraTarea !== "") {
      if (tr === "") tr += this.state.otraTarea;
      else tr += '|' + this.state.otraTarea;
    }
    if (this.state.showForm === false) {
      // El trabajador no esta en su sitio; se envia control vacio -> tr a null
      tr = null;
    }

    // Validaciones
    // Solo se valida si el adolescente esta en el lugar de trabajo...
    let errs = {};
    let validationSuccess = true;

    if (this.state.lugar_de_trabajo) {
      if (tr === '') {
        errs.tareas_realizadasError = true;
        validationSuccess = false;
      }

      if (this.state.utiliza_identificacion === '') {
        errs.utiliza_identificacionError = true;
        validationSuccess = false;
      }

      if (this.state.requiere_proteccion === '') {
        errs.requiere_proteccionError = true;
        validationSuccess = false;
      }

      if (this.state.utiliza_proteccion === '') {
        errs.utiliza_proteccionError = true;
        validationSuccess = false;
      }

      if (this.state.utiliza_herramientas_peligrosas === '') {
        errs.utiliza_herramientas_peligrosasError = true;
        validationSuccess = false;
      }

      if (this.state.utiliza_maquinaria === '') {
        errs.utiliza_maquinariaError = true;
        validationSuccess = false;
      }

      if (this.state.expuesto_alta_temp === '') {
        errs.expuesto_alta_tempError = true;
        validationSuccess = false;
      }

      if (this.state.expuesto_baja_temp === '') {
        errs.expuesto_baja_tempError = true;
        validationSuccess = false;
      }

      if (this.state.hay_agua === '') {
        errs.hay_aguaError = true;
        validationSuccess = false;
      }

      if (this.state.esta_agachado === '') {
        errs.esta_agachadoError = true;
        validationSuccess = false;
      }

      if (this.state.levanta_mas_5k === '') {
        errs.levanta_mas_5kError = true;
        validationSuccess = false;
      }

      if (this.state.brazos_arriba === '') {
        errs.brazos_arribaError = true;
        validationSuccess = false;
      }

      if (this.state.utiliza_agroquimicos === '') {
        errs.utiliza_agroquimicosError = true;
        validationSuccess = false;
      }

      if (this.state.fue_alguien === '') {
        errs.fue_alguienError = true;
        validationSuccess = false;
      }
    }

    if (!validationSuccess) {
      this.setState({ ...errs, showEnviarMonitoreoMessage: true });
      return;
    }

    this.setState({ showLoader: true });

    let obs = this.state.observaciones;
    if (obs === "") obs = null;

    let control = {
      id: this.state.id,
      empresa_id: this.state.empresa_id,
      adolescente_id: this.state.adolescente_id,
      registrado_usuario_id: this.state.registrado_usuario_id,
      fecha_horario: this.state.fecha_horario,
      geo_lat_long: this.state.geo_lat_long,
      lugar_de_trabajo: this.state.lugar_de_trabajo,
      tareas_realizadas: tr,
      utiliza_identificacion: this.state.utiliza_identificacion,
      requiere_proteccion: this.state.requiere_proteccion,
      utiliza_proteccion: this.state.utiliza_proteccion,
      utiliza_herramientas_peligrosas: this.state.utiliza_herramientas_peligrosas,
      utiliza_maquinaria: this.state.utiliza_maquinaria,
      expuesto_alta_temp: this.state.expuesto_alta_temp,
      expuesto_baja_temp: this.state.expuesto_baja_temp,
      hay_agua: this.state.hay_agua,
      esta_agachado: this.state.esta_agachado,
      levanta_mas_5k: this.state.levanta_mas_5k,
      brazos_arriba: this.state.brazos_arriba,
      utiliza_agroquimicos: this.state.utiliza_agroquimicos,
      observaciones: obs,
      fue_alguien: this.state.fue_alguien,
    };

    // Cuando el TA no esta en el lugar de trabajo seteo valores del monitoreo a null
    if (!this.state.lugar_de_trabajo) {
      control = {
        id: this.state.id,
        empresa_id: this.state.empresa_id,
        adolescente_id: this.state.adolescente_id,
        registrado_usuario_id: this.state.registrado_usuario_id,
        fecha_horario: this.state.fecha_horario,
        geo_lat_long: this.state.geo_lat_long,
        lugar_de_trabajo: this.state.lugar_de_trabajo,
        tareas_realizadas: null,
        utiliza_identificacion: null,
        requiere_proteccion: null,
        utiliza_proteccion: null,
        utiliza_herramientas_peligrosas: null,
        utiliza_maquinaria: null,
        expuesto_alta_temp: null,
        expuesto_baja_temp: null,
        hay_agua: null,
        esta_agachado: null,
        levanta_mas_5k: null,
        brazos_arriba: null,
        utiliza_agroquimicos: null,
        observaciones: null,
        fue_alguien: null
      };
    }

    addRecord('control_ta', control)
      .then(() => {

        let registerSyncEvent = true;
        updateDataBases(registerSyncEvent)
          .then(() => {

            // Prescripciones
            let codigos = '?codes=';
            if (this.state.utiliza_identificacion === false) codigos += "001|";
            if (this.state.requiere_proteccion === true && this.state.utiliza_proteccion === false) codigos += "003|";
            if (this.state.utiliza_herramientas_peligrosas === true) codigos += "004|";
            if (this.state.utiliza_maquinaria === true) codigos += "005|";
            if (this.state.expuesto_alta_temp === true) codigos += "006|";
            if (this.state.expuesto_baja_temp === true) codigos += "007|";
            if (this.state.hay_agua === false) codigos += "008|";
            if (this.state.esta_agachado === true) codigos += "009|";
            if (this.state.levanta_mas_5k === true) codigos += "010|";
            if (this.state.brazos_arriba === true) codigos += "011|";
            if (this.state.utiliza_agroquimicos === true) codigos += "012|";
            if (this.state.fue_alguien === false) codigos += "013|";

            this.props.history.push(URLpreffix+'/ControlTrabajadorP3'+codigos);

          });

      });

  }


  render() {
    if (this.state.load === false) return (<main><NavBar /><Loader show={this.state.showLoader} /><MyBottomNavigation /></main>);
    return (
      <main>
        <Loader show={this.state.showLoader} />
        <NavBar />

        <Container maxWidth="sm" className="" >

          <Grid container spacing={3}>
            <Grid item xs={12} className="mb1">
              <Typography variant="h5" className="mb1">
                Monitoreo del trabajador:<br/>
                <strong>{this.state.nombreTA+' '+this.state.apellidoTA+' - DNI: '+this.state.dniTA}</strong>
              </Typography>

              <FormGroup>
                <FormControlLabel
                  control={<Switch onChange={this.onChangeSwitch} color="primary" />}
                  label="El trabajador se encuentra en su sitio."
                />
              </FormGroup>
            </Grid>

            {
              this.state.showForm ? (
                <React.Fragment>
                  <Grid item xs={12} className="mb1">

                    <hr className="mb2" />

                    <FormControl component="fieldset" className="">
                      <FormLabel component="legend">
                        <Typography variant="p" className={this.state.tareas_realizadasError ? "labelCheckbox text-color-error" : "labelCheckbox"}>
                          1. ¿Qué tarea/actividad está realizando el/la trabajador/a adolescente?*
                        </Typography>
                      </FormLabel>

                      <FormHelperText>Seleccionar todas las que correspondan.</FormHelperText>

                      <FormGroup className="mt0_5">
                        {
                          this.createCheckBoxes()
                        }
                      </FormGroup>

                      {
                        this.state.otrasEnabled === true ? (
                          <TextField id="otraTareaInput" label="¿Cuál?" value={this.state.otraTarea} onChange={this.handleInputChange('otraTarea')} variant="outlined" className="mb2 ml2"  />
                        ) : null
                      }

                    </FormControl>

                    <hr className="mb2" />

                    <Typography variant="p" className="labelCheckbox mb2 mt1">
                      2. Contestar según lo que observa en el momento del monitoreo*
                    </Typography>

                      <DoubleCheck error={this.state.utiliza_identificacionError} withLabels onChange={this.handleDoubleCheck} name="utiliza_identificacion" text="El trabajador adolescente…. ¿utiliza la identificación otorgada por la empresa (tarjeta, pechera, gorra, barbijo)" />

                      <DoubleCheck error={this.state.requiere_proteccionError} withLabels onChange={this.handleDoubleCheck} name="requiere_proteccion" text="El trabajo que realiza, ¿requiere la utilización de elementos de protección personal como mascarillas, trajes, guantes, etc.?" />

                      <DoubleCheck error={this.state.utiliza_proteccionError} withLabels onChange={this.handleDoubleCheck} name="utiliza_proteccion" text="¿Los utiliza?" />

                      <DoubleCheck error={this.state.utiliza_herramientas_peligrosasError} withLabels onChange={this.handleDoubleCheck} name="utiliza_herramientas_peligrosas" text="En la tarea/trabajo que realiza el/la adolescente trabajador/a ¿utiliza herramientas peligrosas como machete, guadaña o cuchillo?" />

                      <DoubleCheck error={this.state.utiliza_maquinariaError} withLabels onChange={this.handleDoubleCheck} name="utiliza_maquinaria" text="¿Se encuentra utilizando maquinaria como empacadoras, montacargas, clasificadoras?" />

                      <DoubleCheck error={this.state.expuesto_alta_tempError} withLabels onChange={this.handleDoubleCheck} name="expuesto_alta_temp" text="¿El trabajo o tarea que realiza lo hace expuesto al sol y a altas temperaturas?" />

                      <DoubleCheck error={this.state.expuesto_baja_tempError} withLabels onChange={this.handleDoubleCheck} name="expuesto_baja_temp" text="¿El trabajo o tarea que realiza lo hace expuesto a bajas temperaturas?" />

                      <DoubleCheck error={this.state.hay_aguaError} withLabels onChange={this.handleDoubleCheck} name="hay_agua" text="¿Se observa agua bebible para que puedan beber los/as trabajadores?" />

                      <DoubleCheck error={this.state.esta_agachadoError} withLabels onChange={this.handleDoubleCheck} name="esta_agachado" text="¿El trabajo/tarea que realiza el/la adolescente trabajador/a lo hace agachado o en posición de cuclillas?" />

                      <DoubleCheck error={this.state.levanta_mas_5kError} withLabels onChange={this.handleDoubleCheck} name="levanta_mas_5k" text="¿El trabajo/tarea que realiza el/la adolescente trabajador/a requiere el uso de fuerza, como por ejemplo cargar 5 bandejas “Wenco” (que equivale a más 8 kilos)?" />

                      <DoubleCheck error={this.state.brazos_arribaError} withLabels onChange={this.handleDoubleCheck} name="brazos_arriba" text="¿El/la adolescente trabajador/a desarrolla su trabajo con los brazos a una altura superior a los hombros?" />

                      <DoubleCheck error={this.state.utiliza_agroquimicosError} withLabels onChange={this.handleDoubleCheck} name="utiliza_agroquimicos" text="¿El/la adolescente trabajador/a desarrolla su trabajo manipulando productos agroquímicos?" />

                      <DoubleCheck error={this.state.fue_alguienError} withLabels onChange={this.handleDoubleCheck} name="fue_alguien" text="¿Alguna persona de la empresa te vino a ver durante la jornada de trabajo?" />

                    <hr className="mb2" />

                    <Typography variant="p" className="labelCheckbox mb1 mt1">
                      Observaciones
                    </Typography>


                    <TextField
                      id="observacionesInput"
                      label=""
                      multiline
                      fullWidth
                      rows={4}
                      value={this.state.observaciones}
                      onChange={this.handleInputChange('observaciones')}
                      variant="outlined"
                    />

                  </Grid>
                </React.Fragment>
              ) : null
            }

            <Grid item xs={12} className="mb1 text-center">
              <Button onClick={ this.registrarControl } variant="contained" color="primary" size="large">
                Enviar monitoreo
              </Button>

              {
                this.state.showEnviarMonitoreoMessage ? (
                  <Grid item xs={12} className="mb1 mt1">
                    <Alert severity="error">
                      <AlertTitle><b>Campos sin completar.</b></AlertTitle>
                      Falta completar campos obligatorios.
                    </Alert>
                  </Grid>
                ) : null
              }

            </Grid>

          </Grid>
         </Container>

        <MyBottomNavigation />
      </main>
    );
  }
}

export default withRouter(ControlTrabajadorP2);
