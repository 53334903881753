import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NavBar from './Navigation/NavBar';
import LogoFooter from './Misc/LogoFooter';
import { CONTACT_PHONE_NUMBER, WSAP_CHAT_URL } from '../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  mainContainer: {},
  mb1: {
    marginBottom: '20px',
    marginTop: 30 + 'px',
  },
  mb2: {
    marginBottom: '40px',
  },
  mr1: {
    marginRight: '20px',
  },
  p0: {
    padding: '0px',
  },

  mt1: {},
});

export default function Welcome() {
  const classes = useStyles();

  return (
    <main>
      <NavBar />

      <Container maxWidth="sm" className={classes.mainContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.mb2}>
            <Typography variant="h4" className="mt1">
              Ayuda
            </Typography>
            <p>
              Este es el sistema de ayuda de la WebApp, una herramienta digital impulsada en el
              marco del PROYECTO PAR{' '}
              <strong>liderado por DyA en asociación con ABC y en consulta con las empresas</strong>
              .
            </p>
            <p>
              Esta herramienta es parte del <strong>“Sistema de Cumplimiento Social” (SCS)</strong>{' '}
              y busca facilitar el monitoreo en campo del trabajo y prevenir el trabajo infantil.
            </p>

            <p>
              Está formada por 2 grandes módulos:
              <ul>
                <li className="mb-5">
                  Una <strong>webapp</strong> que los usuarios deberán usar en sus teléfonos
                  móviles.
                </li>
                <li className="mb-5">
                  Un <strong>panel de control/plataforma</strong> online para los responsables de la
                  empresa.
                </li>
              </ul>
              Una WebApp es una web con funcionalidades de aplicación, que brinda, entre otras
              prestaciones, la posibilidad de trabajar sin conexión. Es fácil de instalar y
              compatible con cualquier teléfono inteligente.
            </p>

            <Typography variant="h6" className="mt2">
              Funcionalidad de Reporte de Situación de Riesgo TI:
            </Typography>
            <p>
              Esta es una funcionalidad común para usuarios externos e internos destinada a
              registrar situaciones que pueden ser de riesgo TI con datos claves para su seguimiento
              posterior. Las posibles situaciones son:
              <ul>
                <li className="mb-5">Un NNoA se acerca a un campo para preguntar por trabajo</li>
                <li className="mb-5">Un NNoA intenta ingresar a un campo</li>
                <li className="mb-5">
                  Un NNoA intenta subir a un transporte de trabajadores/ contratista
                </li>
                <li className="mb-5">NNoA le lleva agua/comida a padres/madres/adultos</li>
                <li className="mb-5">
                  NNoA está dentro del campo pero no se lo observa realizando ninguna tarea
                  vinculada con el arándano
                </li>
                <li className="mb-5">NNoA está empaquetando/armando cajas</li>
                <li className="">NNoA recolectando/cosechando arándanos</li>
              </ul>
            </p>

            <Typography variant="h6" className="mt2">
              Funcionalidades de Control de Lotes:
            </Typography>
            <p>
              Esta funcionalidad está desarrollada solo para usuarios internos (de la empresa).
              Consiste en un formulario para el control aleatorio de lotes cosechados. Debe
              realizarse todos los días de cosecha. Permite registrar información de la cuadrilla de
              trabajadores que está trabajando en el lote y requiere completar la información de 5
              trabajadores aleatoriamente, con Nro de DNI o Fecha de nacimiento.
            </p>

            <Typography variant="h6" className="mt2">
              Funcionalidades de Punto de Entrega de Fruta:
            </Typography>
            <p>
              Esta funcionalidad está desarrollada para personal interno de la empresa y algunos
              usuarios externos como capataces. Consiste en un formulario que debe completarse una
              vez completado el monitoreo del lote y permite registrar información de 5 trabajadores
              que hayan entregado fruta con DNI o Fecha de nacimiento.
            </p>

            <Typography align="center" variant="h4" className="mt2">
              Ayuda y consultas técnicas
            </Typography>
            <Typography align="center" variant="h6" className="">
              Durante la implementación puede contactar
              <br /> al equipo de soporte mediante
              <br /> whatsapp al siguiente numero:
              <br />{' '}
              <a href={WSAP_CHAT_URL} target="_blank" rel="noopener noreferrer">
                {CONTACT_PHONE_NUMBER}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <LogoFooter />
      </Container>
    </main>
  );
}
