import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';

import { URLpreffix } from '../../config';

export default function ControlEntregaFrutaP3() {
  let history = useHistory();

  return (
    <main>
      <NavBar disableBackButton />

      <Container maxWidth="sm" className="">
        <Grid container spacing={3}>
          <Grid item xs={12} className="mb1">
            <Typography variant="h6" className="mb1">
              Monitoreo de punto de entrega de fruta
            </Typography>

            <Alert severity="success" className="mt1 mb1">
              <AlertTitle>Operación registrada con éxito.</AlertTitle>
            </Alert>
          </Grid>

          <Grid item xs={12} className="mb1 text-center">
            <Button
              onClick={() => {
                history.push(URLpreffix + '/homeEmpleados');
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              Volver a pantalla de inicio
            </Button>
          </Grid>
        </Grid>
      </Container>

      <MyBottomNavigation />
    </main>
  );
}
