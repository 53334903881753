import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Loader(props) {

  const classes = useStyles();

  let backForFileManager = {
    height: 100+'%',
    position: 'inherit',
    zIndex: 1
  };

  let parentForFileManager = {
    position: 'absolute',
    width: 100+'%',
    height: 100+'%',
    top: 0,
    left: 0,
    zIndex: props.show ? 1 : -1
  };

  if (props.forFileManager) {
    return (
      <div style={parentForFileManager}>
        <Backdrop style={backForFileManager} className={classes.backdrop} open={props.show} onClick={null}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.show} onClick={null}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
