import React from 'react';
import qs from 'query-string';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import { Alert, AlertTitle } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import NavBar from '../Navigation/NavBar';
import MyBottomNavigation from '../Navigation/MyBottomNavigation';
import Loader from '../Misc/Loader';

import { URLpreffix } from '../../config';
import { updateSituacion, updateDataBases } from '../../indexedDB/operations';

export default function IncidenciasV2P2(props) {
  let history = useHistory();

  const [values, setValues] = React.useState({
    disableFinalizarButton: true,
    responsable: '',
    otrasEnabled: false,
    tareas: [],
    otrasTareas: '',
    showLoader: false,
    observacionesAdicionales: '',
  });

  let queryParams = qs.parse(props.location.search);
  let id_incidencia = queryParams.id ? queryParams.id : null;

  const updateIncidenciaForm = () => {
    let acciones_empresa = null;
    let observaciones_adicionales = null;

    if (values.tareas.length > 0) {
      acciones_empresa = values.tareas.join('|');
    }

    if (values.otrasTareas !== '') {
      acciones_empresa += acciones_empresa !== null ? '|' + values.otrasTareas : values.otrasTareas;
    }

    if (values.observaciones_adicionales !== '') {
      observaciones_adicionales = values.observacionesAdicionales;
    }

    let informacion_complementaria = {
      acciones_empresa: acciones_empresa,
      observaciones_adicionales: observaciones_adicionales,
    };

    setValues({ ...values, showLoader: true });
    updateSituacion(id_incidencia, informacion_complementaria).then(() => {
      let registerSyncEvent = true;
      updateDataBases(registerSyncEvent).then(() => {
        history.push(URLpreffix + '/SituacionRiesgoP3');
      });
    });
  };

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const listaTareas = ['Derivación a autoridades locales', 'Se brindó información', 'Otras'];

  const handleCheckboxChange = (label) => (ev) => {
    // Caso especial checkbox Otras
    if (label === 'Otras') {
      setValues({ ...values, otrasEnabled: !values.otrasEnabled, otrasTareas: '' });
      return;
    }

    let tareas = values.tareas;

    if (tareas.indexOf(label) > -1) tareas.splice(tareas.indexOf(label), 1);
    else tareas.push(label);

    setValues({ ...values, tareas: tareas });
  };

  const createCheckBoxes = () => {
    return listaTareas.map((label) => (
      <FormControlLabel
        key={label}
        control={<Checkbox onChange={handleCheckboxChange(label)} name="gilad" />}
        label={label}
      />
    ));
  };

  return (
    <main>
      <Loader show={values.showLoader} />
      <NavBar disableBackButton />

      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <React.Fragment>
            <Grid item xs={12} className="mb1">
              <Alert severity="error">
                <AlertTitle>Instrucción</AlertTitle>
                <b>Sin cerrar esta ventana</b>, usted debe dar aviso al personal de la empresa de la
                situación encontrada y llevar a la administración al NNoA para su acompañamiento.
                <br />
                <br />
                Luego complete los siguientes campos para finalizar el registro:
              </Alert>
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={12} className="mb1">
              <Typography variant="p" className="">
                ¿Qué acciones fueron realizadas por la empresa frente a la situación de riesgo?
              </Typography>
              <FormHelperText>Seleccionar todas las que correspondan.</FormHelperText>
              <FormGroup className="mt0_5 mb1">
                {createCheckBoxes()}

                {values.otrasEnabled === true ? (
                  <TextField
                    id="otraTareaInput"
                    label="¿Cuáles?"
                    value={values.otrasTareas}
                    onChange={handleChange('otrasTareas')}
                    variant="outlined"
                    className="mb2 ml2"
                  />
                ) : null}
              </FormGroup>

              <FormControl fullWidth variant="outlined" className="mb1">
                <Typography variant="p" className="">
                  Observaciones adicionales:
                </Typography>
                <TextField
                  id="observacionesInput"
                  label=""
                  multiline
                  fullWidth
                  rows={4}
                  value={values.observacionesAdicionales}
                  error={false}
                  variant="outlined"
                  onChange={handleChange('observacionesAdicionales')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} className="mb1 text-center">
              <Button
                disabled={false}
                onClick={updateIncidenciaForm}
                variant="contained"
                color="primary"
                size="large"
              >
                Finalizar registro de la situación
              </Button>
            </Grid>
          </React.Fragment>
        </Grid>
      </Container>

      <MyBottomNavigation />
    </main>
  );
}
